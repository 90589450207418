/* React */
import { useState } from 'react';
/* ANTD */
import { Button, Form, Select } from 'antd';

/* Components */
import MessageListItemText from './MessageListItemText';
import MessageListItemFlex from './MessageListItemFlex';
/* Styles */
import style from './MessageListItem.module.css';

const selectOptions = [
  { value: 'text', label: 'message' },
  { value: 'flex', label: 'flex message' },
];

/* //////////////////////////////////////////////////////////////// */
export default function MessageListItem({
  fields,
  field,
  remove,
  index,
  defaultType = 'text',
}) {
  const [type, setType] = useState(defaultType);

  const handleSelectChange = (selectedValue) => {
    setType(selectedValue);
  };
  /* JSX ---------------------------------------------------------- */
  return (
    <Form.Item
      className={style['item__container']}
      required={false}
      key={field.key}
    >
      <label className={style['item__label']}>訊息 {index + 1}</label>
      <div className={style['item__operations']}>
        <Form.Item name={[field.name, 'type']} initialValue={'text'}>
          <Select
            // defaultValue={'text'} [NOTE] default value muse set in Form.Item
            value={type}
            style={{ width: 160 }}
            options={selectOptions}
            onChange={handleSelectChange}
          />
        </Form.Item>

        {fields.length > 1 ? (
          <Button
            className="dynamic-delete-button"
            onClick={() => remove(field.name)}
          >
            刪除
          </Button>
        ) : null}
      </div>
      {type === 'text' ? <MessageListItemText field={field} /> : null}
      {type === 'flex' ? <MessageListItemFlex field={field} /> : null}
    </Form.Item>
  );
}
