/* React */
import { createContext, useCallback, useState } from 'react';

const PaginationContext = createContext({
  curPage: '',
  navigate: (page) => {},
});

/* //////////////////////////////////////////////////////////////// */
export default function PaginationContextProvider({ children }) {
  const [curPage, setCurPage] = useState('MessageTable');

  const navigate = useCallback((page) => {
    setCurPage(page);
  }, []);

  /* JSX ---------------------------------------------------------- */
  return (
    <PaginationContext.Provider value={{ curPage, navigate }}>
      {children}
    </PaginationContext.Provider>
  );
}

export { PaginationContext, PaginationContextProvider };
