import React from "react";
import style from "./styles/Spinner.module.css";
import "./styles/Spinner.css";

export default function Spinner() {
  return (
    // <div className={style.loadingSpinnerDiv}>
    //   <div className={style.loadingSpinner}/>
    // </div>
    // <div className={style.spinnerContainer}>
    //     <div className={style.loadingSpinner}/>
    // </div>
    <div className={style.spinnerContainer}>
      {/* <img className={style.spinnerImg} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/035eab44-93d0-4cef-5c6e-52317441f100/public"></img> */}
      <img className={style.spinnerImg} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/626a2075-adb4-4597-7553-1fa4862eed00/public"></img>    
    </div>
  );
}