import React, { useEffect, useState } from 'react';
import {
  Layout,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Space,
  DatePicker,
  TimePicker,
  Button,
  Dropdown,
  Form,
  List,
  Modal,
  Tag,
} from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import style from './styles/LineNarrowcast.module.css';
import '../styles/LineAnt.css';
import dayjs from 'dayjs';

/*** Import Line Component ***/
import LineAudience from './LineComponents/LineAudience';
import LineAttribute from './LineComponents/LineAttribute';
import LineCastInterface from './LineComponents/LineCastInterface';

function LineNarrowcast() {
  const { Content } = Layout;
  const { CheckableTag } = Tag;
  const [btnLoadings, setBtnLoadings] = useState([]);
  const [sendingTime, setSendingTime] = useState(0);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [showInputNumber, setShowInputNumber] = useState(false);
  const [checkList, setCheckList] = useState([{}]);
  const { status, errors } = Form.Item.useStatus();
  const [openAudienceModal, setOpenAudienceModal] = useState(false);
  const [audienceData, setAudienceData] = useState([
    { _id: '001', name: '測試受眾1' },
    { _id: '002', name: '測試受眾2' },
    { _id: '003', name: '測試受眾3' },
  ]); // 受眾資訊
  // const [selectedTagsId, setSelectedTagsId] = useState([]); // 選中受眾列表
  const [openAttributeModal, setOpenAttributeModal] = useState(false);
  const [attributeData, setAttributeData] = useState([
    { _id: '001', name: '測試屬性1' },
    { _id: '002', name: '測試屬性2' },
    { _id: '003', name: '測試屬性3' },
  ]); // 屬性資訊
  const [recentAttribute, setRecentAttribute] = useState([]);

  /*** 載入常用屬性 ***/
  const recentAttributeLoad = () => {
    const recentAttributeString = window.localStorage.getItem('rctatt');
    const recentAttributeArray = !!recentAttributeString
      ? JSON.parse(recentAttributeString)
      : [];
    setRecentAttribute(recentAttributeArray);
  };
  useEffect(() => {
    console.log(recentAttribute);
  }, [recentAttribute]);
  useEffect(() => {
    recentAttributeLoad();
  }, []);
  useEffect(() => {
    recentAttributeLoad();
  }, [attributeData]); /*** END 載入常用屬性 ***/

  const showAudienceModal = () => {
    setOpenAudienceModal(true);
  };
  const handleAudienceModalOk = () => {
    setOpenAudienceModal(false);
  };
  const handleAudienceModalCancel = () => {
    setOpenAudienceModal(false);
  };
  const handleAttributeModalOk = () => {
    setOpenAttributeModal(false);
  };
  const handleAttributeModalCancel = () => {
    setOpenAttributeModal(false);
  };

  const items = [
    {
      label: '草稿1',
      key: '1',
    },
    {
      label: '草稿2',
      key: '2',
    },
    {
      label: '草稿3',
      key: '3',
    },
    {
      label: '草稿4',
      key: '4',
    },
  ];
  const recentAttrTest = [
    {
      key: '647593c28b71e19c5dac639d',
      label: '標籤2',
    },
    {
      key: '647593d08b71e19c5dac63a0',
      label: '標籤3',
    },
    {
      key: '647593e18b71e19c5dac63a3',
      label: '標籤4',
    },
    {
      key: '647593e18b71e19c5dac63a3',
      label: '標籤4',
    },
  ];

  const enterLoading = (index) => {
    setBtnLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setBtnLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const radioOnChange = (e) => {
    console.log('radio checked', e.target.value);
    setSendingTime(e.target.value);
  };

  const datePickerOnChange = (time, timeString) => {
    console.log(time, timeString);
    setSendingTime(1);
  };

  const timePickerOnChange = (time, timeString) => {
    console.log(time, timeString);
    setSendingTime(1);
  };

  const checkboxOnChange = (event) => {
    const id = event?.target?.id || '';
    const checked = event?.target?.checked || false;
    const checkElement = { id, checked };
    // console.log(checkElement);
    // console.log(checkList);
    // console.log(checkList[0]);
    // console.log(Object.values(checkList[0]).length > 0);
    if (Object.values(checkList[0]).length > 0) {
      // CheckList 有值，取代或新增check資料
      let checkListArray = new Array();
      let hasSameId = false;
      checkListArray = checkList.map((obj) => {
        // console.log(obj);
        const objId = obj?.id || '';
        // const objChecked = obj?.checked || false;
        // console.log(objId);
        // console.log(objChecked);
        if (objId === id) {
          obj.checked = checked; // 取代check資料
          // console.log(obj.checked);
          hasSameId = true;
        }
        // console.log(obj);
        return obj;
      });
      // console.log(hasSameId);
      if (!hasSameId) {
        // 未比對到同ID的物件，新增check資料
        setCheckList((current) => [...current, checkElement]);
      } else {
        // 有比對到同ID的物件，取代原CheckList
        // console.log(checkListArray);
        setCheckList(checkListArray);
      }
    } else {
      // 無值則新增check資料
      let checkListArray = new Array();
      checkListArray.push(checkElement);
      setCheckList(checkListArray);
    }
    // const santiCheckList = [...new Set(checkList.map(itm => JSON.stringify(itm)))].map(i => JSON.parse(i));
    // console.log(santiCheckList);
    let msgLimitIsChecked = false;
    for (let el of checkList) {
      // msgLimit前次設定的值
      if (Object.values(el).includes('msgLimit')) {
        msgLimitIsChecked = el?.checked || false;
      }
    }
    if ((id === 'msgLimit' && checked) || msgLimitIsChecked) {
      // msgLimit事件觸發設定的值或前次設定的值
      setShowInputNumber(true);
    } else {
      setShowInputNumber(false);
    }
  };
  useEffect(() => {
    console.log(checkList);
  }, [checkList]);

  const msgLimitOnChange = (value) => {
    console.log(value);
  };

  /*** 取得受眾設定列表 ***/
  const getAudienceSettingList = (e, urlArg) => {
    if (!!e) {
      e.preventDefault();
    }
    console.log('getAudienceSettingList');
    console.log(urlArg);
    const apiUrl = process.env.REACT_APP_APIURL;
    const url = !!urlArg
      ? urlArg
      : apiUrl + '/chat-tags' + '?limit=50&offset=0';
    console.log(url);
    // fetch(url, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    // })
    // .then((r) => r.json())
    // .then((result) => {
    //     console.log(result);
    //     /*
    //         [
    //             {
    //                 "_id": "64547fd77af19c5afde1c548",
    //                 "name": "string",
    //                 "category": "default",
    //                 "description": " ",
    //                 "priority": 0,
    //                 "__v": 0
    //             }
    //         ]
    //     */
    //     if( Array.isArray(result) && result.length > 0 ) {
    //         let resultExist = false;
    //         for( let v of result ) {
    //         const settingTagId = v?._id || "";
    //         if( !!settingTagId ) {
    //             resultExist = true;
    //             break;
    //         }
    //         }
    //         console.log(resultExist);
    //         if( resultExist ) {
    //         setAudienceData(result);
    //         }
    //     }
    // })
    // .catch((error) => {
    //     console.log('error',error);
    // });
  };
  useEffect(() => {
    getAudienceSettingList();
  }, []);
  useEffect(() => {
    console.log(audienceData);
  }, [audienceData]);
  /*** END 取得使用者設定列表 ***/

  /*** 新增受眾 ***/
  const addAudience = (triggerTagId, checked) => {
    console.log(triggerTagId);
    console.log(checked);
    // const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    // if( globalAdminPanelSocketConnected ) {
    // globalAdminPanelSocket.emit('addAudience', {
    //     tagIds: [triggerTagId],
    // });
    // handleAddTag(triggerTagId, checked); // 目前無法判斷是否真的新增成功(無回傳值)
    // console.log('User Detail : AddUserTags Emit !');
    // } else {
    // console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    // }
  }; /*** END 新增受眾 ***/

  /*** 刪除受眾 ***/
  const removeAudience = (triggerTagId) => {
    console.log('removeAudience', triggerTagId);
    // const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    // if( globalAdminPanelSocketConnected ) {
    // globalAdminPanelSocket.emit('removeAudience', {
    //     tagId: triggerTagId,
    // });
    // console.log('User Detail : removeAudience Emit !');
    // handleCloseTag(triggerTagId); // 目前無法判斷是否真的刪成功(無回傳值)
    // } else {
    // console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    // alert("刪除失敗（與伺服器連線中斷）！")
    // }
  };
  /*** END 刪除受眾 ***/

  /*** 取得屬性設定列表 ***/
  const getAttributeSettingList = (e, urlArg) => {
    if (!!e) {
      e.preventDefault();
    }
    console.log('getAttributeSettingList');
    console.log(urlArg);
    const apiUrl = process.env.REACT_APP_APIURL;
    const url = !!urlArg
      ? urlArg
      : apiUrl + '/chat-tags' + '?limit=50&offset=0';
    console.log(url);
    // fetch(url, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    // })
    // .then((r) => r.json())
    // .then((result) => {
    //     console.log(result);
    //     /*
    //         [
    //             {
    //                 "_id": "64547fd77af19c5afde1c548",
    //                 "name": "string",
    //                 "category": "default",
    //                 "description": " ",
    //                 "priority": 0,
    //                 "__v": 0
    //             }
    //         ]
    //     */
    //     if( Array.isArray(result) && result.length > 0 ) {
    //         let resultExist = false;
    //         for( let v of result ) {
    //         const settingTagId = v?._id || "";
    //         if( !!settingTagId ) {
    //             resultExist = true;
    //             break;
    //         }
    //         }
    //         console.log(resultExist);
    //         if( resultExist ) {
    //         setAudienceData(result);
    //         }
    //     }
    // })
    // .catch((error) => {
    //     console.log('error',error);
    // });
  };
  useEffect(() => {
    getAttributeSettingList();
  }, []);
  useEffect(() => {
    console.log(attributeData);
  }, [attributeData]);
  /*** END 取得屬性設定列表 ***/

  /*** 新增屬性 ***/
  const addAttribute = (triggerTagId, checked) => {
    console.log(triggerTagId);
    console.log(checked);
    // const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    // if( globalAdminPanelSocketConnected ) {
    // globalAdminPanelSocket.emit('addAudience', {
    //     tagIds: [triggerTagId],
    // });
    // handleAddTag(triggerTagId, checked); // 目前無法判斷是否真的新增成功(無回傳值)
    // console.log('User Detail : AddUserTags Emit !');
    // } else {
    // console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    // }
  }; /*** END 新增屬性 ***/

  /*** 刪除屬性 ***/
  const removeAttribute = (triggerTagId) => {
    console.log('removeAttribute', triggerTagId);
    // const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    // if( globalAdminPanelSocketConnected ) {
    // globalAdminPanelSocket.emit('removeAudience', {
    //     tagId: triggerTagId,
    // });
    // console.log('User Detail : removeAudience Emit !');
    // handleCloseTag(triggerTagId); // 目前無法判斷是否真的刪成功(無回傳值)
    // } else {
    // console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    // alert("刪除失敗（與伺服器連線中斷）！")
    // }
  };
  /*** END 刪除屬性 ***/

  return (
    <Form
      id={'LineNarrowcastForm'}
      className={style.lineNarrowcastForm}
      onFinish={() => {}}
    >
      <h4 className={style.lineNarrowcastH4}>窄播模式（Narrowcast）</h4>
      <Form.Item className={style.lineNarrowcastFormItem} name="button">
        <Space className={style.lineNarrowcastSpaceSendBtn}>
          <Dropdown.Button
            className={style.lineNarrowcastSendBtn}
            type="primary"
            icon={<DownOutlined />}
            // loading={btnLoadings[0]}
            menu={{ items }}
            placement="bottom"
            onClick={() => enterLoading(0)}
          >
            儲存草稿
          </Dropdown.Button>
          <Button
            className={style.lineNarrowcastSendBtn}
            type="primary"
            // loading={btnLoadings[1]}
            onClick={() => enterLoading(1)}
          >
            傳送測試訊息
          </Button>
        </Space>
      </Form.Item>
      <Form.Item
        className={style.lineNarrowcastFormItem}
        name="object"
        showCount
      >
        <div className={style.lineNarrowcastDivObject}>
          <h6 className={style.lineNarrowcastH6}>窄播名稱</h6>
          <Space
            className={`${style.lineNarrowcastSpace} ${style.lineNarrowcastSpaceObject}`}
            direction="vertical"
          >
            <Input
              id="NarrowcastName"
              className={style.lineNarrowcastInputObject}
              size="large"
              placeholder="設定窄播群組名稱"
              maxLength={100}
            />
            {/* addonBefore={<div>窄播群組名稱</div>} */}
          </Space>
        </div>
      </Form.Item>
      <Form.Item
        className={style.lineNarrowcastFormItem}
        name="audience"
        showCount
      >
        <div className={style.lineNarrowcastDivAudience} id="LineNarrowcast">
          <h6 className={style.lineNarrowcastH6}>窄播對象</h6>
          <Content
            className={`${style.lineNarrowcastContent} ${style.lineNarrowcastSpace}`}
          >
            <section id="LineAudienceSection">
              <Space
                className={`${style.lineNarrowcastSpaceAudience}`}
                direction="vertical"
              >
                <Button
                  className={style.lineNarrowcastSendBtn}
                  type="primary"
                  // loading={btnLoadings[2}
                  onClick={() => {
                    enterLoading(2);
                    setOpenAudienceModal(true);
                  }}
                  icon={<SearchOutlined />}
                >
                  搜尋受眾
                </Button>
                <Modal
                  getContainer={document.getElementById('LineAudienceSection')}
                  className={style.lineNarrowcasteModalAudience}
                  open={openAudienceModal}
                  footer={null}
                  onOk={handleAudienceModalOk}
                  onCancel={handleAudienceModalCancel}
                >
                  <LineAudience></LineAudience>
                </Modal>
                <div className={`${style.lineNarrowcastAudienceContainer}`}>
                  {audienceData.length > 0 ? (
                    audienceData.map((v, i) => {
                      let audienceId = v?._id || '';
                      let audienceName = v?.name || '';
                      return (
                        <>
                          <TweenOneGroup
                            enter={{
                              scale: 0.8,
                              opacity: 0,
                              type: 'from',
                              duration: 100,
                            }}
                            onEnd={(e) => {
                              if (e.type === 'appear' || e.type === 'enter') {
                                e.target.style = 'display: inline-block';
                              }
                            }}
                            leave={{
                              opacity: 0,
                              width: 0,
                              scale: 0,
                              duration: 200,
                            }}
                            appear={false}
                          >
                            {/* {userInfoTags.map(tagsForMap)} */}
                            <span style={{ display: 'inline-block' }}>
                              <Tag
                                className={`${style.lineNarrowcastTags} ${style.lineNarrowcastAudienceTags}`}
                                color="cyan"
                                closable
                                onClose={(e) => {
                                  e.preventDefault();
                                  removeAudience(audienceId);
                                }}
                              >
                                {audienceName}
                              </Tag>
                            </span>
                            {/* {audienceName} */}
                          </TweenOneGroup>
                        </>
                      );
                    })
                  ) : (
                    <div
                      className={`${style.lineNarrowcastTag} ${style.lineNarrowcastNoTags}`}
                    >
                      <div className={`${style.lineNarrowcastSubTitle}`}>
                        <span>此使用者未設定任何受眾</span>
                      </div>
                    </div>
                  )}
                </div>
              </Space>
            </section>
            <section id="LineAttributeSection">
              <Space
                className={`${style.lineNarrowcastSpace} ${style.lineNarrowcastSpaceAttribute}`}
                direction="vertical"
              >
                <div className={style.lineNarrowcastDivFilterByAttribute}>
                  <Button
                    className={style.lineNarrowcastSendBtn}
                    type="primary"
                    // loading={btnLoadings[3]}
                    onClick={() => {
                      enterLoading(3);
                      setOpenAttributeModal(true);
                    }}
                    icon={<SearchOutlined />}
                  >
                    依屬性篩選
                  </Button>
                  <Dropdown.Button
                    className={`${style.lineNarrowcastSendBtn} ${style.lineNarrowcastDropdown}`}
                    type="primary"
                    icon={<DownOutlined />}
                    // loading={btnLoadings[4]}
                    menu={{ items: recentAttribute }}
                    placement="bottom"
                    onClick={() => enterLoading(4)}
                  >
                    常用屬性
                  </Dropdown.Button>
                </div>
                <Modal
                  getContainer={document.getElementById('LineAttributeSection')}
                  className={style.lineNarrowcasteModalAttribute}
                  open={openAttributeModal}
                  footer={null}
                  onOk={handleAttributeModalOk}
                  onCancel={handleAttributeModalCancel}
                >
                  <LineAttribute></LineAttribute>
                </Modal>
                <div className={`${style.lineNarrowcastAttributeContainer}`}>
                  {attributeData.length > 0 ? (
                    attributeData.map((v, i) => {
                      let attributeId = v?._id || '';
                      let attributeName = v?.name || '';
                      return (
                        <>
                          <TweenOneGroup
                            enter={{
                              scale: 0.8,
                              opacity: 0,
                              type: 'from',
                              duration: 100,
                            }}
                            onEnd={(e) => {
                              if (e.type === 'appear' || e.type === 'enter') {
                                e.target.style = 'display: inline-block';
                              }
                            }}
                            leave={{
                              opacity: 0,
                              width: 0,
                              scale: 0,
                              duration: 200,
                            }}
                            appear={false}
                          >
                            {/* {userInfoTags.map(tagsForMap)} */}
                            <span style={{ display: 'inline-block' }}>
                              <Tag
                                className={`${style.lineNarrowcastTags} ${style.lineNarrowcastAttributeTags}`}
                                color="green"
                                closable
                                onClose={(e) => {
                                  e.preventDefault();
                                  removeAttribute(attributeId);
                                }}
                              >
                                {attributeName}
                              </Tag>
                            </span>
                            {/* {audienceName} */}
                          </TweenOneGroup>
                        </>
                      );
                    })
                  ) : (
                    <div
                      className={`${style.lineNarrowcastTag} ${style.lineNarrowcastNoTags}`}
                    >
                      <div className={`${style.lineNarrowcastSubTitle}`}>
                        <span>此使用者未設定任何屬性</span>
                      </div>
                    </div>
                  )}
                </div>
              </Space>
            </section>
          </Content>
        </div>
      </Form.Item>
      <Form.Item
        className={style.lineNarrowcastFormItem}
        name="sendingTime"
        rules={[{ required: true, message: `【傳送時間】為必填欄位！` }]}
      >
        <Radio.Group
          className={style.lineNarrowcastRadioGroup}
          onChange={radioOnChange}
          value={sendingTime}
          defaultValue={0}
        >
          <h6 className={style.lineNarrowcastH6}>傳送時間</h6>
          <div className={style.lineNarrowcastDivTime}>
            <Space
              className={`${style.lineNarrowcastSpace} ${style.lineNarrowcastSpaceTime}`}
              direction="vertical"
            >
              <Radio className={style.lineNarrowcastRadio} value={0}>
                <span className={style.lineNarrowcastRadioText}>立即傳送</span>
              </Radio>
              <Radio
                className={`${style.lineNarrowcastRadio} ${style.lineNarrowcastRadioSchedule}`}
                value={1}
              >
                <span className={style.lineNarrowcastRadioText}>預約傳送</span>
                <div className={`${style.lineNarrowcastRadioPicker}`}>
                  <DatePicker
                    className={`${style.lineNarrowcastRadioDatePicker}`}
                    placeholder={'選擇傳送日期'}
                    defaultValue={dayjs(new Date())}
                    onChange={datePickerOnChange}
                    suffixIcon={null}
                  />
                  <TimePicker
                    className={`${style.lineNarrowcastRadioTimePicker}`}
                    placeholder={'選擇傳送時間'}
                    defaultValue={dayjs(new Date()).add(5, 'minute')}
                    onChange={timePickerOnChange}
                    suffixIcon={null}
                  />
                </div>
              </Radio>
            </Space>
          </div>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        className={`${style.lineNarrowcastFormItem} ${style.lineNarrowcastFormItemAdvanceSetting}`}
        name="advance"
      >
        <h6 className={style.lineNarrowcastH6}>進階設定</h6>
        <Space
          className={`${style.lineNarrowcastSpace} ${style.lineNarrowcastSpaceAdvanceSetting}`}
          direction="vertical"
        >
          <Checkbox
            className={style.lineNarrowcastSpaceCheckBox}
            id={'lineVoom'}
            onChange={checkboxOnChange}
          >
            張貼至LINE VOOM
          </Checkbox>
          <Checkbox
            className={style.lineNarrowcastSpaceCheckBox}
            id={'msgLimit'}
            onChange={checkboxOnChange}
          >
            指定群發訊息則數的上限
          </Checkbox>
          {showInputNumber ? (
            <InputNumber
              className={style.lineNarrowcastSpaceCheckBoxInputNumber}
              min={1}
              max={500000}
              defaultValue={50000}
              onChange={msgLimitOnChange}
            />
          ) : (
            <></>
          )}
          <Checkbox
            className={style.lineNarrowcastSpaceCheckBox}
            id={'ABTesting'}
            onChange={checkboxOnChange}
          >
            建立A/B測試
          </Checkbox>
          <Checkbox
            className={style.lineNarrowcastSpaceCheckBox}
            id={'Advertise'}
            onChange={checkboxOnChange}
          >
            設定廣告活動
          </Checkbox>
        </Space>
      </Form.Item>
      <Form.Item
        className={`${style.lineNarrowcastFormItem} ${style.lineNarrowcastFormItemLineCastInterface}`}
        name="訊息輸入欄位"
        rules={[{ required: true, message: `【訊息輸入欄位】為必填欄位！` }]}
        getFieldError={(name) => {
          return `【${name}】為必填欄位！`;
        }}
      >
        <Space className={style.lineNarrowcastSpaceLineCastInterface}>
          <LineCastInterface></LineCastInterface>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default LineNarrowcast;
