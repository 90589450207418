/* Query */
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
/* Toast */
import toast from 'react-hot-toast';

/* Hooks */
import useDialog from '../../../../../../hooks/useDialog';
/* Services */
import {
  createRichMenuTemplate,
  deleteRichMenu,
  getRichMenus,
  setRichMenuAsDefault,
  uploadRichMenuImage,
} from '../../../../../../services/line/richmenu';

/* //////////////////////////////////////////////////////////////// */
export function useGetRichMenus({ page, offset, search }) {
  const { data, error, isLoading } = useQuery({
    queryFn: () => getRichMenus({ page, offset, search }),
    queryKey: ['rich-menu'],
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return { data, error, isLoading };
}

/* //////////////////////////////////////////////////////////////// */
const createRichMenu = async ({ createTemplateReq, imageFile }) => {
  const { lineRichMenuId } = await createRichMenuTemplate({
    reqData: createTemplateReq,
  });

  const formData = new FormData();
  formData.append('file', imageFile);
  await uploadRichMenuImage({ lineRichMenuId, reqData: formData });

  await setRichMenuAsDefault({ lineRichMenuId });
};
export function useCreateRichMenu(onSuccessCb) {
  const queryClient = useQueryClient();
  const dialog = useDialog();

  const {
    mutate: onCreateRichMenu,
    data,
    error,
    isLoading,
  } = useMutation({
    mutationFn: createRichMenu,

    onSuccess: () => {
      toast.success('建立 Rich menu 成功');
      queryClient.invalidateQueries({ queryKey: ['rich-menu'] });
      onSuccessCb?.();
    },

    onError: (error) => {
      const errMessage =
        typeof error === 'string'
          ? error
          : 'message' in error
          ? error.message
          : '發生錯誤';
      dialog.error({ message: errMessage });
    },
  });

  return { onCreateRichMenu, isLoading, error, data };
}

/* //////////////////////////////////////////////////////////////// */
export function useDeleteRichMenu(onSuccessCb) {
  const queryClient = useQueryClient();
  const dialog = useDialog();

  const {
    mutate: onDeleteRichMenu,
    data,
    error,
    isLoading,
  } = useMutation({
    mutationFn: deleteRichMenu,

    onSuccess: () => {
      toast.success('已刪除 Rich menu');
      queryClient.invalidateQueries({ queryKey: ['rich-menu'] });
      onSuccessCb?.();
    },

    onError: (error) => {
      const errMessage =
        typeof error === 'string'
          ? error
          : 'message' in error
          ? error.message
          : '發生錯誤';
      dialog.error({ message: errMessage });
    },
  });

  return { onDeleteRichMenu, isLoading, error, data };
}

/* //////////////////////////////////////////////////////////////// */
export function useSetRichMenuAsDefault(onSuccessCb) {
  const queryClient = useQueryClient();
  const dialog = useDialog();

  const {
    mutate: onSetRichMenuAsDefault,
    data,
    error,
    isLoading,
  } = useMutation({
    mutationFn: setRichMenuAsDefault,

    onSuccess: () => {
      toast.success('已更改預設的 Rich menu');
      queryClient.invalidateQueries({ queryKey: ['rich-menu'] });
      onSuccessCb?.();
    },

    onError: (error) => {
      const errMessage =
        typeof error === 'string'
          ? error
          : 'message' in error
          ? error.message
          : '發生錯誤';
      dialog.error({ message: errMessage });
    },
  });

  return { onSetRichMenuAsDefault, isLoading, error, data };
}
