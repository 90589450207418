import { httpRequest } from '../../../utils/httpRequest';

/* //////////////////////////////////////////////////////////////// */
export const createRichMenuTemplate = async ({ reqData }) => {
  const res = await httpRequest(`/rich-menu`, {
    method: 'POST',
    body: JSON.stringify(reqData),
  });

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};
