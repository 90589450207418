import { httpRequest } from '../../utils/httpRequest';

/* //////////////////////////////////////////////////////////////// */
export const getTags = async () => {
  const res = await httpRequest('/chat-tags');

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};
