import { httpRequest } from '../../../utils/httpRequest';

/* //////////////////////////////////////////////////////////////// */
export const getMessages = async ({
  page,
  messagePerPage,
  year,
  month,
  search,
  method,
}) => {
  const urlParams = new URLSearchParams();

  if (page && messagePerPage) urlParams.set('limit', messagePerPage);
  if (page && messagePerPage)
    urlParams.set('offset', (page - 1) * messagePerPage);
  if (year) urlParams.set('year', year);
  if (month) urlParams.set('month', month);
  if (search) urlParams.set('search', search);
  if (method && method !== 'all') urlParams.set('method', method);

  const res = await httpRequest(`/send-message?${urlParams.toString()}`);

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};
