/* React */
import React, { useEffect, useRef, useState } from 'react';
/* ANTD */
import { Button, Table } from 'antd';
/* Day */
import dayjs from 'dayjs';

/* Components */
import { Modal } from '../../../../../../../Component/ui';
import MessageDetailModal from './MessageDetailModal';
/* Styles */
import style from './MessagesTable.module.css';

export const sendMethodMap = {
  broadcast: '廣播',
  multicast: '依標籤傳送',
};
export const approvingStatusMap = {
  wait: '待審核',
  agree: '同意',
  reject: '拒絕',
};

const columns = [
  { title: '建立者', dataIndex: 'publishUserId', key: 'publishUserId' },
  {
    title: '標題 (內部參考)',
    dataIndex: 'purpose',
    key: 'purpose',
    render: (purpose, messageData) => {
      return (
        <div
          style={{
            width: '200px',
            whiteSpace: 'nowrap',
            overflow: 'clip',
            textOverflow: 'ellipsis',
          }}
        >
          {purpose}
        </div>
      );
    },
    align: 'center',
  },
  {
    title: '發送方式',
    dataIndex: 'sendMethod',
    key: 'sendMethod',
    render: (sendMethod) => <span>{sendMethodMap[sendMethod]}</span>,
    align: 'center',
  },
  {
    title: '建立時間',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => <TimeCeil time={createdAt} />,
    align: 'center',
  },
  {
    title: '預約時間',
    dataIndex: 'estimatedSendingTime',
    key: 'estimatedSendingTime',
    render: (time, messageData) => {
      if (messageData.isSendImmediately) return <span>立即發送</span>;
      return <TimeCeil time={time} />;
    },
    align: 'center',
  },
  {
    title: '審核結果',
    dataIndex: 'approvingStatus',
    key: 'approvingStatus',
    render: (approvingStatus) => (
      <span className={style[`approving-status--${approvingStatus}`]}>
        {approvingStatusMap[approvingStatus]}
      </span>
    ),
    align: 'center',
  },

  {
    title: '發送時間',
    dataIndex: 'sentAt',
    key: 'sentAt',
    render: (sentAt, messageData) => {
      if (sentAt) return <TimeCeil time={sentAt} />;
      if (messageData.sendingStatus === 'cancel')
        return <span>已取消發送</span>;
      return null;
    },
    align: 'center',
  },

  // [NOTE] Another way to trigger show detail modal
  // {
  //   title: '查看詳細',
  //   dataIndex: '_id',
  //   key: '_id',
  //   render: (_id, messageData) => {
  //     console.log(_id, messageData);

  //     return (
  //       <>
  //         <div>
  //           <Modal.Content name={_id}>
  //             <LineMessagesForm asModal lineMessageId={_id} />
  //           </Modal.Content>
  //         </div>

  //         <Modal.OpenButton opens={_id}>
  //           <Button>查看</Button>
  //         </Modal.OpenButton>
  //       </>
  //     );
  //   },
  // },
];

/* //////////////////////////////////////////////////////////////// */
export default function MessagesTable({ messagesData }) {
  const [currentId, setCurrentId] = useState(undefined);
  const [lastClickTime, setLastClickTime] = useState(new Date());

  const buttonEl = useRef();

  useEffect(() => {
    if (!currentId) return;

    buttonEl.current?.click(); // Trigger modal open
  }, [currentId, lastClickTime]);

  /* JSX ---------------------------------------------------------- */
  return (
    <>
      <Modal>
        <div>
          <Modal.Content name={currentId}>
            <MessageDetailModal lineMessageId={currentId} />
          </Modal.Content>
        </div>

        <Modal.OpenButton opens={currentId}>
          <Button ref={buttonEl} style={{ display: 'none' }} />
        </Modal.OpenButton>
      </Modal>

      <div className={style['table__container']}>
        <Table
          style={{ width: 'max-content' }}
          columns={columns}
          dataSource={messagesData}
          pagination={false}
          onRow={(message) => ({
            onClick: () => {
              setCurrentId(message._id);
              setLastClickTime(new Date());
            },
          })}
        />
      </div>
    </>
  );
}

function TimeCeil({ time }) {
  return (
    <>
      <span>{dayjs(time).format('YYYY-MM-DD')}</span>
      <br />
      <span>{dayjs(time).format('HH:mm:ss')}&nbsp;(GMT+8)</span>
    </>
  );
}
