/* React */
import { createContext, useCallback, useState } from 'react';

const RichMenuEditorContext = createContext({
  uploadImageFile: null,
  uploadImageDimensions: { height: 0, width: 0 },
  renderFigureSize: { height: 0, width: 0 },
  areas: [],
  onResetContext: () => {},
  onInitContext: () => {},
  onUploadImage: () => {},
  onPreviewImageLoaded: () => {},
  onAreasChange: () => {},
  onRemoveAreaRow: () => {},
});

function RichMenuEditorContextProvider({ children }) {
  const [uploadImageFile, setUploadImageFile] = useState(null);
  const [uploadImageDimensions, setUploadImageDimensions] = useState({
    height: 0,
    width: 0,
  }); // Actual image height and width
  const [renderFigureSize, setRenderFigureSize] = useState({
    height: 0,
    width: 0,
  }); // The render figure element height an width
  const [areas, setAreas] = useState([]); // Line rich menu areas data

  const onResetContext = useCallback(() => {
    setUploadImageFile(null);
    setUploadImageDimensions({ height: 0, width: 0 });
  }, []);
  const onInitContext = useCallback(() => {
    // [TODO]
  }, []);
  const onUploadImage = useCallback((uploadImage, uploadImageDimensions) => {
    setUploadImageFile(uploadImage);
    setUploadImageDimensions(uploadImageDimensions);
  }, []);
  const onPreviewImageLoaded = useCallback(({ height, width }) => {
    setRenderFigureSize({ height, width });
  }, []);
  const onAreasChange = useCallback((areas) => {
    setAreas(areas);
  }, []);
  const onRemoveAreaRow = useCallback((removeIdx) => {
    setAreas((areas) => areas.filter((_, index) => removeIdx !== index));
  }, []);

  return (
    <RichMenuEditorContext.Provider
      value={{
        onResetContext,
        onInitContext,
        uploadImageFile,
        uploadImageDimensions,
        renderFigureSize,
        onUploadImage,
        onPreviewImageLoaded,
        areas,
        onAreasChange,
        onRemoveAreaRow,
      }}
    >
      {children}
    </RichMenuEditorContext.Provider>
  );
}

export { RichMenuEditorContext, RichMenuEditorContextProvider };
