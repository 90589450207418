/* React */
import {
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

/* Style */
import style from './Modal.module.css';

const ModalContext = createContext({
  openModalName: null,
  onOpenModal: (modalName) => {},
  onCloseModal: () => {},
});

function Modal({ children }) {
  const [openModalName, setOpenModalName] = useState(null);

  const onOpenModal = useCallback((modalName) => {
    setOpenModalName(modalName);
  }, []);
  const onCloseModal = useCallback(() => {
    setOpenModalName(null);
  }, []);

  return (
    <ModalContext.Provider value={{ openModalName, onOpenModal, onCloseModal }}>
      {children}
    </ModalContext.Provider>
  );
}

function OpenButton({ children, opens }) {
  const { onOpenModal } = useContext(ModalContext);

  return cloneElement(children, { onClick: () => onOpenModal(opens) });
}

function Content({ children, name }) {
  const { openModalName, onCloseModal } = useContext(ModalContext);

  if (name !== openModalName) return null;

  return createPortal(
    <>
      <div className={style.overlay} onClick={onCloseModal} />
      <div className={style.modal}>
        <button className={style['close-modal-button']} onClick={onCloseModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div>{cloneElement(children, { onCloseModal: onCloseModal })}</div>
      </div>
    </>,
    document.body
  );
}

Modal.OpenButton = OpenButton;
Modal.Content = Content;

export default Modal;
