/* React */
// import React from 'react';
/* ANTD */
import { Tag as AntdTag } from 'antd';

/* Styles */
import style from './Tag.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function Tag({ children, closable = false, onClick, onClose }) {
  /* JSX ---------------------------------------------------------- */
  return (
    <AntdTag
      className={style['tag']}
      color="geekblue"
      closable={closable}
      onClick={onClick}
      onClose={onClose}
    >
      {children}
    </AntdTag>
  );
}
