import React, { useState, useContext,useEffect,useRef } from 'react'
import style from './styles/AccountExist.module.css';
import { Container,Row,Col } from 'react-bootstrap';
import popupStyle from '../../Components/styles/popup.module.css';
import Modal from 'react-bootstrap/Modal'
import AuthContext from '../login/components/AuthContext';
//引入loading 轉圈圈元件
// import LoadingSpinner from '../../Components/LoadingSpinner';
import Spinner from '../../../Component/Spinner/Spinner'
import liff from '@line/liff/dist/lib';
import LoginParent from '../login/LoginParent';




function AccountExist(props) {
  const {isFromCustomer,setSheet,setClearData,LoginToken} =props;
  const { authorized, setAuth, auth,setEmail,email } = useContext(AuthContext);

  //設定Modal 顯示與否
  const [show, setShow] = useState(false);
  // 設定loading 轉圈圈顯示與否
  const [isLoading, setIsLoading] = useState(false);
  // 顯示錯誤訊息
  const [RegxpError, setRegxpError] = useState('');
  // 設定登入資料
  const [myform, setMyform] = useState({});
  // 設定改訂email 可輸入
  // const [EmailStatus, setEmailStatus] = useState(false);

   // 自動填入參照欄位
   const autoEmail = useRef(null);

   /* 時時記錄表單欄位值 */
   const changeFields = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerEmail: '' });
    const id = event.target.id;
    const val = event.target.value;
    // // console.log({ id, val });
    setMyform({ ...myform, [id]: val });
    setRegxpError('');
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
    const {setSheet}=props;
    
    return (
      <>
          <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
                <Col className={popupStyle.modal}>
                <Modal.Header className={popupStyle.modalHeader} closeButton>
                  <Modal.Title className={popupStyle.modalTitle} ></Modal.Title>
                </Modal.Header>
                <Modal.Body className={popupStyle.modalBody} >
                  <LoginParent className={popupStyle.modalLoginParent} ></LoginParent>
                </Modal.Body>
              </Col>
          </Modal>
        </>
      </>
    );
  }
   
  //這不是我使用
  // const whenSubmit = (event) => {
  //   event.preventDefault();
  //   //刪除前段錯誤訊息
  //   setRegxpError('');
  //   setIsLoading(true);
  //      //密碼比對
  //   if(!myform.password){
  //       setIsLoading(false);
  //       setRegxpError('請填寫輸入密碼欄位')
  //       return;
  //   }
  //   let myformTobodyformat = {};    
  //   let password = myform.password;
  //   let redirectUrl = window.location.href;
  //   if(EmailStatus===true){
  //     let email = myform.email;
  //       // 若自動填入，則email為自動填入值
  //       if( !! autoEmail.current ) {
  //         email = autoEmail.current.value;
  //       }
  //       if(!email){
  //         setIsLoading(false);
  //         setRegxpError('請填寫輸入電子信箱欄位');
  //         return;
  //       }
    
    
  //     myformTobodyformat={
  //       email,password,redirectUrl
  //     }
  //     // console.log('用戶自己寫email',myformTobodyformat)

  //   }else{
  //     myformTobodyformat={
  //       email,password,redirectUrl
  //     }
  //     // console.log('系統帶入',myformTobodyformat)

  //   }
  
  //   // console.log('myformTobodyformat',myformTobodyformat)

  //    // 串接KidPro官網登入UAA
  //    fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/login`, {
  //     method: 'POST',
  //     body: JSON.stringify(myformTobodyformat),
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     // console.log('result',result);
  //     if(result.statusCode === 200) {
  //       setIsLoading(false);
  //       setRegxpError('登入成功');
  //       window.localStorage.setItem( 'loginToken', result.data.loginToken );
  //       window.localStorage.removeItem('LIFF_STOREregister'); 
  //       // let loginToken = window.localStorage.getItem( 'loginToken' );
  //       // 登入成功跳轉
  //       window.location.assign( "/CheckActivity?redir=kidpro" ); // 登入成功後導向官網登入再返回CheckActivity頁面
  //     } else if(result.statusCode === 400) {
  //       setIsLoading(false);
  //       setRegxpError('登入失敗，錯誤代碼 400');
  //     } else if(result.statusCode === 403) {
  //       setIsLoading(false);
  //       setRegxpError('帳號或密碼錯誤，錯誤代碼 403');
  //     } else {
  //       setIsLoading(false);
  //       setRegxpError('登入失敗！');
  //     }
  //   })
  //   .catch((error) => {
  //     setIsLoading(false);
  //     setRegxpError('伺服器忙線中，請稍後再試！');
  //   });
  // }

  /* 使用者點選登入 */
  const whenSubmit = ( event ) => {
    event.preventDefault();
    // 刪除前段錯誤訊息
    setRegxpError( '' );
    setIsLoading( true );
    window.localStorage.removeItem('LIFF_STOREregister'); 
    let lineRegisterLoginToken = window.localStorage.getItem( 'lineRegisterLoginToken' );
    window.localStorage.setItem( 'loginToken', lineRegisterLoginToken );
    if( window.localStorage.getItem('isFromAdmin') ) {
      window.location.href = '/Admin';
    } else if( window.localStorage.getItem('isFromCustomerChatRoomLogin') ) {
      window.location.href = '/ChatRoomLogin';
    } else {
      window.location.href = '/?line_login=true';
    }

    // const loginToken = window.localStorage.getItem('loginToken') ?? '';
    // const isAdminLogin = window.location.path.toLowerCase() === 'adminlogin';
    // const params = new URLSearchParams(window.location.search);
    // const refParam = params.get('ref');
    // const isFromAdmin = refParam !== null && refParam.toLowerCase() === 'admin';
    // console.log('isFromAdmin',isFromAdmin);
    // if( isAdminLogin && !! loginToken && ! isFromAdmin ) {
    //   window.location.href = '/Admin?ref=AdminLogin';
    // } else {
    //     window.location.href = '/';
    // }
    
    // window.location.assign( "/CheckActivity?redir=kidpro" ); // 登入成功後導向官網登入再返回CheckActivity頁面

  } /* END Function 使用者點選登入 */

  /* 點選登入觸發 */
  // const whenSubmit = (event) => {
  //   event.preventDefault();
  //   window.localStorage.setItem( 'loginToken', LoginToken);
  //   window.localStorage.removeItem('LIFF_STOREregister'); 
  //   window.location.assign( "/CheckActivity?redir=kidpro" ); // 登入成功後導向官網登入再返回CheckActivity頁面
  // }

  /* 清空用戶資料，返回最初登入頁面 */
  let LIFF_STOREregister =window.localStorage.getItem('LIFF_STOREregister'); 
  const ReturntoRegister =(event)=>{
    event.preventDefault();
    // if( LIFF_STOREregister ) {
    //   window.localStorage.removeItem('LIFF_STOREregister'); 
    // }
    liff.logout();
    // 如果FB物件存在，且為登入中，將FB登出
    if( !! window.FB ) {
      window.FB.getLoginStatus(function (response) {
        if(response.status==='connected') {
          window.FB.logout(function (response) {
            // console.log('handleFBLogout', response);
          });
        }
      });
    }
    setSheet('LoginIndex');
    setEmail(null);
    localStorage.clear();
  }

  // useEffect( () => {
  //   clearLoginToken(); // register路徑只用來判斷是否綁定LINE，不做login功能
  // }, [] );

  return (
    <>
     <Col>
          <div>
            <p className={style.title}>帳號已存在</p>
          </div>
        </Col>
        <Col>
          <div className={style.accountCard}>
            <div>
              <span className={style.subtile}>老客戶登入</span>
              {/* <span className={style.clearAccount} onClick={()=>setEmailStatus(!EmailStatus)}>這不是我</span> */}
            </div>
            {/* <div>
              {EmailStatus?(<input type="email" id="email" name='email' ref={autoEmail} onChange={changeFields} value={myform.email} className={style.password_input} required></input>):(<p  className={style.account}>{email}</p>)}
              
            </div> */}
            <div>
              <p className={style.account}>{email}</p>  
            </div>
          </div>
          {/* <div className={style.passwordCard}>
            <div>
              <p className={style.password}>輸入密碼</p>
            </div>
            <div>
              <input type="password" id="password" name='password' onChange={changeFields} value={myform.password} className={style.password_input} required></input>
            </div>
            <div className={style.Forgot_passwordSec}>
              <a className={style.Forgot_password} href="https://kid-pro.com/my-account/lost-password/" target="_blank">忘記密碼</a>
            </div>
          </div> */}

          <div className={style.RegxpErrorSec}>
            <p className={style.RegxpError}>{RegxpError}</p>
          </div>

          <div className={style.confirmTitle}>
            <p>是否登入?</p>
          </div>
          <div className={style.confirmSec} >
            <div className={style.login_btnSec}  >
              {isLoading===true?(< Spinner></Spinner>):(<button onClick={whenSubmit} className={style.login_btn}>登入</button>)}
            </div>
            <div className={style.Return} >
              <button className={style.ReturnButton} onClick={ReturntoRegister}>返回</button>
            </div>
          </div>
        </Col>
     
       
   

    
    </>
  
  )
}

export default AccountExist