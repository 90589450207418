import React, { useState, useEffect, useContext } from 'react';
import { socketConnect } from 'socket.io-react';
import style from "./styles/SocketAdmin.module.css";
import liff from '@line/liff';
import CryptoJS  from 'crypto-js';

/*** Import Context Parameters ***/
import { GlobalAdminSocket } from './SocketAdminConnect';
import { GlobalRoomId } from '../../Pages/Admin/components/ChatRoom/ChatRoom';
import { GlobalJoinRoom } from '../../Pages/Admin/components/ChatRoom/ChatRoom';
import { GlobalHasJoinedRoom } from '../../Pages/Admin/components/ChatRoom/ChatRoom';

function SocketAdminListening(props) {
    // const {globalAdminUserSocket, globalAdminPanelSocket, setGlobalAdminUserSocket, setGlobalAdminPanelSocket, roomId} = props;
    const [globalAdminSocket , setGlobalAdminSocket ] = useContext(GlobalAdminSocket);
    const [globalRoomId , setGlobalRoomId ] = useContext(GlobalRoomId);
    const [globalJoinRoom, setGlobalJoinRoom] = useContext(GlobalJoinRoom);
    const [globalHasJoinedRoom , setGlobalHasJoinedRoom] = useContext(GlobalHasJoinedRoom);
    useEffect(() => {
        console.log('globalRoomId', globalRoomId);
        console.log('globalJoinRoom', globalJoinRoom);
        console.log('globalHasJoinedRoom', globalHasJoinedRoom);
      }, [globalRoomId, globalJoinRoom, globalHasJoinedRoom])
    // let globalAdminUserSocket = null;
    // let globalAdminPanelSocket = null;
    // let globalAdminUserSocketConnected = false;
    // let globalAdminPanelSocketConnected = false;

    // const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState(globalAdminSocket?.globalAdminUserSocket); // 初始值設定正確才能及時觸發主動連線
    // const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState(globalAdminSocket?.globalAdminPanelSocket);
    // const [globalAdminUserSocketConnected, setGlobalAdminUserSocketConnected] = useState(globalAdminSocket?.globalAdminUserSocket?.connected);
    // const [globalAdminPanelSocketConnected, setGlobalAdminPanelSocketConnected] = useState(globalAdminSocket?.globalAdminPanelSocket?.connected);

    // const [globalAdminUserSocketConnected, setGlobalAdminUserSocketConnected] = useState(false);
    // const [globalAdminPanelSocketConnected, setGlobalAdminPanelSocketConnected] = useState(false);

    const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState( globalAdminSocket?.globalAdminUserSocket || {} ); // 初始值設定正確才能及時觸發主動連線
    const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState( globalAdminSocket?.globalAdminPanelSocket || {} );

    const loginToken = window.localStorage.getItem('loginToken') ?? "";

    // const globalAdminUserSocket = globalAdminSocket?.adminUserSocket || "";
    // const globalAdminPanelSocket = globalAdminSocket?.adminPanelSocket || "";
    // const isGlobalAdminUserSocket = globalAdminUserSocket?.connected || false;
    // const isGlobalAdminPanelSocket = globalAdminPanelSocket?.connected || false;

    // let globalAdminUserSocket = null;
    // let globalAdminPanelSocket = null;
    // let isGlobalAdminUserSocket = false;
    // let isGlobalAdminPanelSocket = false;
    // if( Object.keys(globalAdminSocket).length > 0 ) {
    //     globalAdminUserSocket = globalAdminSocket.hasOwnProperty('adminUserSocket') ? globalAdminSocket?.globalAdminUserSocket: null;
    //     globalAdminPanelSocket = globalAdminSocket.hasOwnProperty('adminPanelSocket') ? globalAdminSocket?.globalAdminPanelSocket : null;
    //     isGlobalAdminUserSocket = globalAdminUserSocket.hasOwnProperty('connected') ? globalAdminUserSocket.connected : false;
    //     isGlobalAdminPanelSocket = globalAdminPanelSocket.hasOwnProperty('connected') ? globalAdminPanelSocket.connected : false;
    //     console.log({globalAdminUserSocket,globalAdminPanelSocket,isGlobalAdminUserSocket,isGlobalAdminPanelSocket});
    // }


    // let globalAdminUserSocket = null;
    // let globalAdminPanelSocket = null;
    // let isGlobalAdminUserSocket = false;
    // let isGlobalAdminPanelSocket = false;

    // const adminSocketInit = async() => {
    //     globalAdminUserSocket = globalAdminSocket.hasOwnProperty('adminUserSocket') ? globalAdminSocket.adminUserSocket: null;
    //     globalAdminPanelSocket = globalAdminSocket.hasOwnProperty('adminPanelSocket') ? globalAdminSocket.adminPanelSocket : null;
    //     isGlobalAdminUserSocket = globalAdminUserSocket.hasOwnProperty('connected') ? globalAdminUserSocket.connected : false;
    //     isGlobalAdminPanelSocket = globalAdminPanelSocket.hasOwnProperty('connected') ? globalAdminPanelSocket.connected : false;
    //     console.log({globalAdminUserSocket,globalAdminPanelSocket,isGlobalAdminUserSocket,isGlobalAdminPanelSocket});
    // }

    // /*** 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/
    // const initializeAdminUserAndPanelSocket = async() => {
    //     console.log(globalAdminSocket);
    //     globalAdminUserSocket = typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminUserSocket || null : null;
    //     globalAdminPanelSocket = typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminPanelSocket || null : null;
    //     // setHasResetAdminUserAndPanelSocket(true);
    // }
    // useEffect(() => {
    //     initializeAdminUserAndPanelSocket();
    // }, []);
    // useEffect(() => {
    //     initializeAdminUserAndPanelSocket();
    // }, [globalAdminSocket]);  
    // useEffect(() => {
    //     console.log(globalAdminUserSocket)
    //     console.log(globalAdminPanelSocket)
    // }, [globalAdminSocket, globalAdminUserSocket, globalAdminPanelSocket]);
    // /*** END 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/    

    // /*** 確認 User 及 Panel Socket連線狀態 ***/
    // const checkAdminUserAndPanelSocketIsConnected = async() => {
    //     if( !! globalAdminUserSocket ) {
    //         console.log("GlobalAdminUserSocket Connect");
    //         const adminUserSocketConnected = typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0 ? globalAdminUserSocket ?. connected || false : false;
    //         globalAdminUserSocketConnected = adminUserSocketConnected;
    //     }
    //     if( !! globalAdminPanelSocket ) {
    //         console.log("GlobalAdminPanelSocket Connect");
    //         const adminPanelSocketConnected = typeof globalAdminPanelSocket === 'object' && globalAdminPanelSocket !== null && Object.keys(globalAdminPanelSocket).length > 0 ? globalAdminPanelSocket ?. connected || false : false;
    //         globalAdminPanelSocketConnected = adminPanelSocketConnected;
    //     }
    // }
    // useEffect(() => {
    //     checkAdminUserAndPanelSocketIsConnected();
    // }, [globalAdminUserSocket, globalAdminPanelSocket]);
    // useEffect(() => {
    //     console.log({globalAdminUserSocketConnected, globalAdminPanelSocketConnected});
    // }, [globalAdminUserSocketConnected, globalAdminPanelSocketConnected]);
    // /*** END 確認 User 及 Panel Socket連線狀態 ***/   
         
    /*** 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/
    const initializeAdminUserAndPanelSocket = async() => {
        console.log(globalAdminSocket);
        // setGlobalAdminUserSocket({});
        // setGlobalAdminPanelSocket({});
        // console.log(globalAdminSocket?.globalAdminUserSocket);
        // console.log(globalAdminSocket?.globalAdminPanelSocket);
        // console.log(globalAdminSocket.globalAdminUserSocket.connected);
        // console.log(globalAdminSocket.globalAdminPanelSocket.connected);
        // globalAdminUserSocket = await ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminUserSocket || null : null );
        // globalAdminPanelSocket = await ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminPanelSocket || null : null );
        let adminUserSocket = null;
        if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
            console.log(globalAdminSocket);
            adminUserSocket = globalAdminSocket?.globalAdminUserSocket || null;
            console.log(adminUserSocket);
            setGlobalAdminUserSocket(adminUserSocket); // 最後改動此處，讓GlobalAdminSocket連線後設定GlobalAdminUserSocket
        }
        let adminPanelSocket = null;
        if ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ) {
            adminPanelSocket = globalAdminSocket?.globalAdminPanelSocket || null;
            console.log(adminPanelSocket);
            setGlobalAdminPanelSocket(adminPanelSocket);
        }
        // if( ! globalAdminUserSocket || ! globalAdminPanelSocket ) {
        //   globalAdminUserSocket = assignGlobalAdminUserSocketConnected;
        //   globalAdminPanelSocket = assignGlobalAdminPanelSocketConnected;
        // }
        // await checkAdminUserAndPanelSocketIsConnected(globalAdminUserSocket, globalAdminPanelSocket);
    }
    /*** END 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/    

    // /*** 確認 User 及 Panel Socket 連線狀態 ***/
    // const checkAdminUserAndPanelSocketIsConnected = async (globalAdminUserSocket, globalAdminPanelSocket) => {
    //     console.log({ globalAdminUserSocket, globalAdminPanelSocket });
    //     console.log(!!globalAdminUserSocket);
    //     console.log(!!globalAdminPanelSocket);
    //     if ( !! globalAdminUserSocket ) {
    //     console.log("GlobalAdminUserSocket Connect");
    //     console.log(typeof globalAdminUserSocket === 'object');
    //     console.log(globalAdminUserSocket !== null);
    //     console.log(Object.keys(globalAdminUserSocket).length > 0);
    //     let adminUserSocketConnected = null;
    //     if ( typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0 ) {
    //         adminUserSocketConnected = globalAdminUserSocket?.connected || null;
    //         console.log(adminUserSocketConnected);
    //     }
    //     console.log(adminUserSocketConnected);
    //     setGlobalAdminUserSocketConnected(adminUserSocketConnected);
    //     console.log(globalAdminUserSocketConnected);
    //     }
    //     if ( !! globalAdminPanelSocket ) {
    //         console.log("GlobalAdminPanelSocket Connect");
    //         console.log(typeof globalAdminPanelSocket === 'object');
    //         console.log(globalAdminPanelSocket !== null);
    //         console.log(Object.keys(globalAdminPanelSocket).length > 0);
    //         let adminPanelSocketConnected = null;
    //         if ( typeof globalAdminPanelSocket === 'object' && globalAdminPanelSocket !== null && Object.keys(globalAdminPanelSocket).length > 0 ) {
    //             adminPanelSocketConnected = globalAdminPanelSocket?.connected || null;
    //             console.log(adminPanelSocketConnected);
    //         }
    //         console.log(adminPanelSocketConnected);
    //         console.log(globalAdminPanelSocketConnected);
    //         setGlobalAdminPanelSocketConnected(adminPanelSocketConnected);
    //         console.log(globalAdminPanelSocketConnected);
    //     }
    //     console.log({ globalAdminUserSocketConnected, globalAdminPanelSocketConnected });
    // };
    // // useEffect(() => {
    // //   checkAdminUserAndPanelSocketIsConnected();
    // // }, [globalAdminUserSocket, globalAdminPanelSocket]);
    // useEffect(() => {
    //     console.log({globalAdminUserSocketConnected, globalAdminPanelSocketConnected});
    // }, [globalAdminUserSocketConnected, globalAdminPanelSocketConnected]);
    // /*** END 確認 User 及 Panel Socket連線狀態 ***/        

    /*** Socket監聽事件 ***/
    const listeningAdminJoinRoom = async() => {
        const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
        console.log(globalAdminUserSocket);
        console.log(!!globalAdminUserSocket);
        console.log(globalAdminUserSocketConnected);
        console.log(globalRoomId);
        if( !! globalAdminUserSocket && globalAdminUserSocketConnected ) {
            globalAdminUserSocket.on("joinRoom", (data) => {
                console.log(data, 'Socket Admin Join Room Success !');
                setGlobalHasJoinedRoom(true);
            });
            globalAdminUserSocket.on("leaveRoom", (data) => {
                console.log(data, 'Socket Admin Leave Room Success !');
                setGlobalHasJoinedRoom(false);
            });
            globalAdminUserSocket.on("error", (data) => {
                console.log(data, 'Socket Admin Join or Leave Room Error !');
                adminLeaveRoom();
                // setGlobalHasJoinedRoom(false);
                // setGlobalRoomId("");
            });
        } else {
            console.log('Socket Admin Listening : GlobalAdmin【User】SocketConnected not Connected !');
        }
    } /*** END Socket監聽事件 ***/

    /*** 管理者取得使用者資訊 ***/
    const listeningUserInfo = async() => {
        const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
        console.log(globalAdminPanelSocket);
        console.log(!!globalAdminPanelSocket);
        console.log(globalAdminPanelSocketConnected);
        console.log(globalRoomId);
        if( !! globalAdminPanelSocket && globalAdminPanelSocketConnected ) {
            globalAdminPanelSocket.on("userInfo", (data) => {
                console.log(data, 'Socket Admin Get User Info Success !');
            });
        } else {
            console.log('Socket Admin Listening : GlobalAdmin【Panel】SocketConnected not Connected !');
        }
    }
    // const emitUserInfo = async() => {
    //     const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    //     console.log(globalAdminPanelSocketConnected);
    //     console.log(globalRoomId);
    //     if( globalAdminPanelSocketConnected && !! globalRoomId ) {
    //     globalAdminPanelSocket.emit('userInfo', {roomId: globalRoomId});
    //     console.log('Emit: Administer Get User Info !');
    //     } else if( ! globalAdminPanelSocketConnected ) {
    //     console.log("GlobalAdminPanelSocket not Connected !");
    //     } else {
    //     console.log("GlobalRoomId not Exist!");
    //     } 
    // }
    // const asyncListeningAndEmitUserInfo = async() => {
    //     await listeningUserInfo();
    //     await emitUserInfo();
    // }
    // useEffect(() => {
    //     asyncListeningAndEmitUserInfo();
    // }, []);
    /*** END 管理者取得使用者資訊 ***/      

    /*** 同步連線初始化及監聽 ***/
    const asyncInitAndListening = async() => {
        await initializeAdminUserAndPanelSocket();
        await listeningAdminJoinRoom();
        // await listeningUserInfo();
        // await emitUserInfo();
    }
    useEffect(() => {
        asyncInitAndListening();
    }, []);
    // useEffect(() => {
    //     asyncInitAndListening();
    // }, [globalAdminSocket, globalRoomId, globalJoinRoom]);
    // useEffect(() => {
    //     initializeAdminUserAndPanelSocket();
    // }, []);
    // useEffect(() => {
    //     initializeAdminUserAndPanelSocket();
    // }, [globalAdminSocket, globalRoomId, globalHasJoinedRoom]);  
    // useEffect(() => {
    //     console.log('globalAdminUserSocket', globalAdminUserSocket)
    //     console.log('globalAdminPanelSocket', globalAdminPanelSocket)
    // }, [globalAdminUserSocket, globalAdminPanelSocket]);
     /*** END 同步連線初始化及監聽 ***/

    /*** 管理者加入房間 ***/
    const adminJoinRoom = async(globalRoomId) => {
        console.log(loginToken);
        console.log(globalAdminUserSocket);
        const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
        console.log(globalAdminUserSocketConnected);
        console.log(globalRoomId);
        if( !! loginToken && globalAdminUserSocketConnected && !! globalRoomId ) {
            globalAdminUserSocket.emit('joinRoom', {roomId: globalRoomId});
            console.log('Administer join room!');
        }
    } /*** END 管理者加入房間 ***/

    /*** 管理者離開房間 ***/
    const adminLeaveRoom = async() => {
        // e?.preventDefault();
        const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
        if( !! loginToken && globalAdminUserSocketConnected && !! globalRoomId ) {
            globalAdminUserSocket.emit('leaveRoom', {roomId: globalRoomId});
            console.log('Administer Leave Room !');
        } 
    }/*** END 管理者離開房間 ***/

    // /*** 管理者離開並加入房間 ***/
    // const asyncAdminLeaveRoomAndAdminJoinRoom = async() => {
    //     await adminLeaveRoom();
    //     await adminJoinRoom();
    // }
    // useEffect(() => {
    //     asyncAdminLeaveRoomAndAdminJoinRoom();
    // }, [globalRoomId]);
    // /*** END 管理者離開並加入房間 ***/


    /*** 同步監聽及加入房間事件 ***/
    // const asyncCheckAdminJoinRoomAndAdminJoinRoom = async() => {
    //     await listeningAdminJoinRoom();
    //     await adminJoinRoom();
    // }
    // useEffect(() => {
    //     asyncCheckAdminJoinRoomAndAdminJoinRoom();
    // }, [globalAdminUserSocket, globalRoomId]);
    /*** END 同步監聽及加入房間事件 ***/

    // useEffect(() => {
    //     const asyncQueue = async() => {
    //         await adminSocketInit();
    //         await adminGetRoomList();
    //         await adminJoinRoom();
    //     }
    //     asyncQueue();
    // }, []);

    // useEffect(() => {
    //     const asyncQueue = async() => {
    //         await adminSocketInit();
    //         await adminGetRoomList();
    //         await adminJoinRoom();
    //     }
    //     asyncQueue();
    // }, [globalAdminSocket]);

}

export default socketConnect(SocketAdminListening);
