/* React */
import { useContext } from 'react';

/* Store */
import { PaginationContext } from '../store/PaginationContext';

/* //////////////////////////////////////////////////////////////// */
export default function usePaginationContext() {
  const PaginationCtx = useContext(PaginationContext);

  return PaginationCtx;
}
