/* Query */
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
/* Toast */
import toast from 'react-hot-toast';

/* Hooks */
import usePaginationContext from '../../usePaginationContext';
import useDialog from '../../../../../../hooks/useDialog';
/* Services */
import {
  getMessages,
  getMessageById,
  createMessage,
  modifyMessage,
  confirmMessage,
  cancelMessage,
} from '../../../../../../services/line/messages';

/* //////////////////////////////////////////////////////////////// */
export function useGetMessages(args) {
  const {
    page = 1,
    messagePerPage,
    year,
    month,
    search = '',
    method,
  } = args || {};

  const { data, error, isLoading } = useQuery({
    queryFn: () =>
      getMessages({ page, messagePerPage, year, month, search, method }),
    queryKey: ['broadcast-messages', page, `${year}-${month}`, search, method],
    keepPreviousData: true,
  });

  return { data, error, isLoading };
}

/* //////////////////////////////////////////////////////////////// */
export function useGetMessageById(lineMessageId) {
  const { data, error, isLoading } = useQuery({
    queryFn: () => getMessageById(lineMessageId),

    // [NOTE] refetching data every time
    queryKey: ['broadcast-messages'],
    cacheTime: 0,
    retry: false,
  });

  return { data, sendMessagesData: data?.sendMessagesData, error, isLoading };
}

/* //////////////////////////////////////////////////////////////// */
export function useCreateMessage(onSuccessCb) {
  const queryClient = useQueryClient();
  const dialog = useDialog();
  const { navigate } = usePaginationContext();

  const {
    mutate: onCreateMessage,
    data,
    error,
    isLoading,
  } = useMutation({
    mutationFn: createMessage,

    onSuccess: () => {
      toast.success('建立訊息成功');
      queryClient.invalidateQueries({ queryKey: ['broadcast-messages'] });
      onSuccessCb();
      navigate('MessageTable');
    },

    onError: (error) => {
      console.log('error from useCreateMessage: \n', error);
      const errMessage =
        typeof error === 'string'
          ? error
          : 'message' in error
          ? error.message
          : '發生錯誤';
      dialog.error({ message: errMessage });
    },
  });

  return { onCreateMessage, isLoading, error, data };
}

/* //////////////////////////////////////////////////////////////// */
export function useModifyMessage(onSuccessFn) {
  const queryClient = useQueryClient();

  const dialog = useDialog();

  const {
    mutate: onModifyMessage,
    data,
    error,
    isLoading,
  } = useMutation({
    mutationFn: modifyMessage,

    onSuccess: () => {
      toast.success('編輯訊息成功');
      queryClient.invalidateQueries({ queryKey: ['broadcast-messages'] });
      onSuccessFn?.();
    },

    onError: (error) => {
      console.log('error from useModifyMessage: \n', error);
      const errMessage =
        typeof error === 'string'
          ? error
          : 'message' in error
          ? error.message
          : '發生錯誤';
      dialog.error({ message: errMessage });
    },
  });

  return { onModifyMessage, isLoading, error, data };
}

/* //////////////////////////////////////////////////////////////// */
export function useConfirmMessage(onSuccessCb) {
  const queryClient = useQueryClient();

  const dialog = useDialog();

  const {
    mutate: onConfirmMessage,
    data,
    error,
    isLoading,
  } = useMutation({
    mutationFn: confirmMessage,

    onSuccess: () => {
      toast.success('已審核訊息');
      queryClient.invalidateQueries({ queryKey: ['broadcast-messages'] });
      onSuccessCb?.();
    },

    onError: (error) => {
      console.log('error from useConfirmMessage: \n', error);
      const errMessage =
        typeof error === 'string'
          ? error
          : 'message' in error
          ? error.message
          : '發生錯誤';
      dialog.error({ message: errMessage });
    },
  });

  return { onConfirmMessage, isLoading, error, data };
}

/* //////////////////////////////////////////////////////////////// */
export function useCancelMessage(onSuccessCb) {
  const queryClient = useQueryClient();

  const dialog = useDialog();

  const {
    mutate: onCancelMessage,
    data,
    error,
    isLoading,
  } = useMutation({
    mutationFn: cancelMessage,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['broadcast-messages'] });
      onSuccessCb?.();
    },

    onError: (error) => {
      console.log('error from useConfirmMessage: \n', error);
      const errMessage =
        typeof error === 'string'
          ? error
          : 'message' in error
          ? error.message
          : '發生錯誤';
      dialog.error({ message: errMessage });
    },
  });

  return { onCancelMessage, isLoading, error, data };
}
