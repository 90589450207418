/* React */
import { useContext } from 'react';

/* Store */
import { PaginationContext } from './PaginationContext';

/* //////////////////////////////////////////////////////////////// */
export default function usePaginationContext() {
  return useContext(PaginationContext);
}
