/* React */
// import React from 'react';
/* ANTD */
import { Button } from 'antd';

/* Component */
import { Modal } from '../ui';
import TagSelectorForm from './TagSelectorForm';

/* //////////////////////////////////////////////////////////////// */
export default function TagSelector({
  onSelect, // ({tagSet, selectedTags}) => void
  defaultTagSet,
  defaultSelectedTags,
}) {
  /* JSX ---------------------------------------------------------- */
  return (
    <Modal>
      <Modal.Content>
        <TagSelectorForm
          onSelect={onSelect}
          defaultTagSet={defaultTagSet}
          defaultSelectedTags={defaultSelectedTags}
        />
      </Modal.Content>

      <Modal.OpenButton>
        <Button>選擇標籤</Button>
      </Modal.OpenButton>
    </Modal>
  );
}
