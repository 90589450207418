import React, { useState, useContext, useEffect, useRef } from 'react';
import { MessageList, MessageBox, TextMessage } from 'react-chat-elements';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import jwt_decode from 'jwt-decode';
// import browser from "browser";
// import download from 'download';
// import fs from 'fs';
import { GlobalOutlined } from '@ant-design/icons';

/*** CSS ***/
import style from './styles/ChatRoomContent.module.css';
import './styles/ChatRoomRceComponent.css';

/*** Import Socket Component ***/
// import SocketAdminListening from '../../../../../SocketIO/Admin/SocketAdminListening';

/*** Import Chat Room Content Components ***/
import AntDatePicker from './MessageComponents/AntDatePicker';
import RceText from './MessageComponents/RceText';
import RcePhoto from './MessageComponents/RcePhoto';
import RceVideo from './MessageComponents/RceVideo';

/*** Import Context Parameters ***/
import { GlobalAdminSocket } from '../../../../../SocketIO/Admin/SocketAdminConnect';
import { GlobalRoomId } from '../ChatRoom';
import { GlobalJoinRoom } from '../ChatRoom';
import { GlobalHasJoinedRoom } from '../ChatRoom';
import { GlobalUserInfo } from '../ChatRoom';

function ChatRoomContent(props) {
  // const {globalAdminUserSocket, globalAdminPanelSocket, setGlobalAdminUserSocket, setGlobalAdminPanelSocket, roomId, joinRoom} = props;
  // const globalSocket = useContext(GlobalSocket);
  // const isGlobalSocket = globalSocket?.connected || false;
  const [globalAdminSocket, setGlobalAdminSocket] =
    useContext(GlobalAdminSocket);
  const [globalRoomId, setGlobalRoomId] = useContext(GlobalRoomId);
  const [globalJoinRoom, setGlobalJoinRoom] = useContext(GlobalJoinRoom);
  const [globalHasJoinedRoom, setGlobalHasJoinedRoom] =
    useContext(GlobalHasJoinedRoom);
  const [globalUserInfo, setGlobalUserInfo] = useContext(GlobalUserInfo);
  // useEffect(() => {console.log(globalUserInfo)}, [globalUserInfo]);

  const [textMsgArray, setTextMsgArray] = useState([]);
  const [msgExist, setMsgExist] = useState(false);
  const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState(
    globalAdminSocket?.globalAdminUserSocket || {}
  ); // 初始值設定正確才能及時觸發主動連線
  const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState(
    globalAdminSocket?.globalAdminPanelSocket || {}
  );
  const [hasGotPreMsg, setHasGotPreMsg] = useState(false); // 是否已取得前次訊息
  const [hasGotNewMsg, setHasGotNewMsg] = useState(false); // 是否收到新訊息
  const [adminName, setAdminName] = useState('');
  const loginToken = window.localStorage.getItem('loginToken');
  // const [getMessagesData, setGetMessagesData] = useState({});
  // const [msgIdChannelArr, setMsgIdChannelArr] = useState([['','CHAT']]);
  // let msgIdChannelArrTemp = [];
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  // const[defaultMsg, setDefaultMsg] = useState(null);
  // useEffect(() => {
  //   let defaultMsgTemp = window.localStorage.getItem('defaultMsg', defaultMsg) ?? "";
  //   console.log('defaultMsg1',{defaultMsgTemp});
  //   if( !! defaultMsgTemp ) {
  //     let defaultMsgObj = JSON.parse(defaultMsgTemp);
  //     setDefaultMsg(defaultMsgObj);
  //     window.localStorage.removeItem('defaultMsg');
  //     console.log('defaultMsg2',{defaultMsg});
  //   }
  // },[window.localStorage.getItem('defaultMsg')]);

  /*** 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/
  const initializeAdminUserAndPanelSocket = async () => {
    // console.log(globalAdminSocket);
    // console.log(globalAdminSocket?.globalAdminUserSocket);
    // console.log(globalAdminSocket?.globalAdminPanelSocket);
    // console.log(globalAdminSocket.globalAdminUserSocket.connected);
    // console.log(globalAdminSocket.globalAdminPanelSocket.connected);
    // globalAdminUserSocket = await ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminUserSocket || null : null );
    // globalAdminPanelSocket = await ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminPanelSocket || null : null );
    let adminUserSocket = null;
    if (
      typeof globalAdminSocket === 'object' &&
      globalAdminSocket !== null &&
      Object.keys(globalAdminSocket).length > 0
    ) {
      adminUserSocket = globalAdminSocket?.globalAdminUserSocket || null;
      // console.log(adminUserSocket);
      setGlobalAdminUserSocket(adminUserSocket); // 最後改動此處，讓GlobalAdminSocket連線後設定GlobalAdminUserSocket
    }
    let adminPanelSocket = null;
    if (
      typeof globalAdminSocket === 'object' &&
      globalAdminSocket !== null &&
      Object.keys(globalAdminSocket).length > 0
    ) {
      adminPanelSocket = globalAdminSocket?.globalAdminPanelSocket || null;
      // console.log(adminPanelSocket);
      setGlobalAdminPanelSocket(adminPanelSocket);
    }
    // if( ! globalAdminUserSocket || ! globalAdminPanelSocket ) {
    //   globalAdminUserSocket = assignGlobalAdminUserSocketConnected;
    //   globalAdminPanelSocket = assignGlobalAdminPanelSocketConnected;
    // }
    // await checkAdminUserAndPanelSocketIsConnected(globalAdminUserSocket, globalAdminPanelSocket);
  };
  useEffect(() => {
    initializeAdminUserAndPanelSocket();
  }, []);
  useEffect(() => {
    initializeAdminUserAndPanelSocket();
  }, [globalAdminSocket, globalRoomId]);
  useEffect(() => {
    console.log('globalAdminUserSocket', globalAdminUserSocket);
    console.log('globalAdminPanelSocket', globalAdminPanelSocket);
  }, [globalAdminUserSocket, globalAdminPanelSocket]);
  /*** END 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/

  /*** 取得加密的使用者資料 ***/
  let defaultUserData = null;
  // let rederCount = 0;
  const [userData, setUserData] = useState({});
  const lineAppId = process.env.REACT_APP_LINE_APP_ID;
  const cpo = window.localStorage.getItem('cpo')
    ? window.localStorage.getItem('cpo')
    : '';
  const getCipherUserData = () => {
    const accessToken = window.localStorage.getItem(
      'LIFF_STORE:' + lineAppId + ':accessToken'
    )
      ? window.localStorage.getItem('LIFF_STORE:' + lineAppId + ':accessToken')
      : '';
    if (!!accessToken && !!cpo) {
      try {
        const userDataJson = CryptoJS.AES.decrypt(cpo, `${accessToken}`);
        // console.log(userDataJson);
        const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
        // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
        const userDataDecode = JSON.parse(userDataUTF8);
        // setUserData(userDataDecode);

        // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
        // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));

        setUserData((userData) => ({ ...userData, userDataDecode }));
        // console.log({userData});
        defaultUserData = userDataDecode;
        // console.log({defaultUserData});
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };
  useEffect(() => {
    getCipherUserData();
  }, [cpo, globalRoomId]);
  /*** END 取得加密的使用者資料 ***/

  /*** 取得管理者資料 ***/
  const getAdminName = async () => {
    const decoded = jwt_decode(loginToken);
    console.log(decoded);
    const email = decoded?.email || '';
    const atIndex = email.indexOf('@');
    const adminUsername = atIndex !== -1 ? email.substring(0, atIndex) : '';
    console.log(adminUsername);
    setAdminName(adminUsername);
    return adminUsername;
  };
  useEffect(() => {
    getAdminName();
  }, []);
  useEffect(() => {
    getAdminName();
  }, [loginToken, textMsgArray]);
  useEffect(() => {
    console.log(adminName);
    // let textMsgArrayTemp = textMsgArray;
    // setTextMsgArray(textMsgArrayTemp);
  }, [adminName]);
  /*** END 取得使用者資料 ***/

  // /*** 預載localStorge中的訊息 ***/
  // const reloadingMsg = () => {
  //   const preMsg = window.localStorage.getItem('textMsgArray') ? JSON.parse(window.localStorage.getItem('textMsgArray')) : "";
  //   setTextMsgArray(preMsg);
  // }
  // useEffect(()=>{
  //   reloadingMsg();
  // }, []);
  // /*** END 預載localStorge中的訊息 ***/

  // useEffect(()=>{
  //   console.log(globalUserInfo);
  // }, []);
  // useEffect(()=>{
  //   console.log(globalUserInfo);
  // }, [globalUserInfo]);

  /*** 預載舊訊息(剛進入房間時觸發) ***/
  const loadingPrevMessage = (data, adminNameValue) => {
    console.log(data);
    console.log(adminNameValue);
    if (Object.keys(data).length > 0) {
      let textMsgArrayTemp = [];
      const messages = data?.messages || '';
      console.log(globalUserInfo);
      messages
        .slice()
        .sort(
          (a, b) =>
            new Date(a.getDate).getTime() - new Date(b.getDate).getTime()
        )
        .map((v, i) => {
          let userStatus,
            userId,
            lineId,
            userName,
            avatar,
            email,
            statusMessage;
          userStatus =
            userId =
            lineId =
            userName =
            avatar =
            email =
            statusMessage =
              '';
          const getId = v?.id || '';
          const getUserType = v?.userType || 'CS';
          const getChannelId = v?.channelId || '';
          const getChannelType = v?.channelType || 'CHAT';
          const position = getUserType
            ? getUserType === 'CS'
              ? 'left'
              : 'right'
            : 'left';
          const getType = v?.type || '';
          const getText = v?.text || '';
          const getDataType = v?.data?.fileType || '';
          const getDataName = v?.data?.name || '';
          const getDataUri = v?.data?.uri || '';
          const getDataUrl = v?.data?.url || '';
          const getDataThumbnailUrl = data?.data?.thumbnailUrl || '';
          const getDataBuffer = v?.data?.buffer || '';
          const getDate = v?.date || new Date();
          /*
          const globalUserInfo = {
            roomId,
            userId,
            userName,
            channels,
            channelEntries,
            channelChatId,
            channelLineId,
            channelFbId,
            channelMailId,
            lineEntries,
            lineId,
            lineName,
            linePictureUrl,
            lineLanguage,
            lineStatusMessage,
            fbEntries,
            fbId,
            email,
            tags,
          }
        */
          if (!!getUserType && getUserType !== 'CS') {
            // 身分為顧客，登入時才會將資料存入localStorage
            //   userStatus = getDataBuffer?.userStatus || "【一般使用者】";
            //   firstName = getDataBuffer?.firstName || "";
            //   lastName = getDataBuffer?.lastName || "";
            //   email = getDataBuffer?.email || "";
            //   phone = getDataBuffer?.phone || "";
            //   lineId = getDataBuffer?.lineId || "";
            //   isLineBind = getDataBuffer?.isLineBind || false;
            //   avatar = getDataBuffer?.avatar || "";
            //   statusMessage = getDataBuffer?.statusMessage || "";
            userId = globalUserInfo?.userId || '';
            lineId = globalUserInfo?.lineId || '';
            userStatus = !userId ? '【訪客】' : `【訪客 ID:${userId}】`;
            userName =
              globalUserInfo?.userName ||
              globalUserInfo?.lineName ||
              userStatus;
            avatar =
              globalUserInfo?.linePictureUrl ||
              'https://www.gravatar.com/avatar/?d=mp';
            email = globalUserInfo?.email || '';
            statusMessage = globalUserInfo?.lineStatusMessage || '';
          } else {
            // const adminNameTemp = getAdminName();
            // adminNameTemp.then((adminNameValue) => {
            //   console.log(adminNameValue);
            //   userStatus = !! adminNameValue ? `${adminNameValue}` : "【客服人員】" ;
            //   console.log(userStatus);
            // });
            userStatus = !!adminNameValue ? adminNameValue : '【客服人員】';
            console.log(userStatus);
            avatar = '/cs.png';
          }
          // if( !! getId ) {
          if (!!getId) {
            setMsgExist(true);
            const textMsgEl = {
              position,
              userStatus,
              userId,
              lineId,
              userName,
              avatar,
              email,
              statusMessage,
              getId,
              getUserType,
              getChannelId,
              getChannelType,
              getType,
              getText,
              getDataType,
              getDataName,
              getDataUri,
              getDataUrl,
              getDataThumbnailUrl,
              getDataBuffer,
              getDate,
              isBroadcast: v?.isBroadcast,
            };
            textMsgArrayTemp.push(textMsgEl);
          }
        });
      setTextMsgArray(textMsgArrayTemp);
      setHasGotPreMsg(true); // 設定已載完前次訊息
      console.log(textMsgArrayTemp);
      console.log(textMsgArray);
    }
  };
  // useEffect(() => {
  //   console.log(getMessagesData);
  //   loadingPrevMessage(getMessagesData);
  // }, [getMessagesData, globalUserInfo]);
  /*** END 預載舊訊息***/

  /*** 使用者傳送訊息，將訊息存入State ***/
  const handleNewTextMsg = async (data) => {
    console.log({ data });
    clearRedupulicMsg();
    /*  預設資料
        const userDataDefault = {
            userId: profile.userId,
            userStatus: "【一般使用者】",
            firstName: "",
            lastName: profile.displayName,
            email: "",
            phone: "",
            lineId: profile.userId,
            isLineBind: false,
            avatar: profile.pictureUrl,
            statusMessage: profile.statusMessage
        };
        const data = {
            "id": "string",
            "userId": "string",
            "type": "text",
            "status": "waiting",
            "text": "string",
            "data": {
                "type": "string",
                "name": "string",
                "uri": "string",
                "buffer": {}
            }
        }
        new
        const data = {
          channelId
          channelType
          data : {
            name
            thumbnailUrl
            url
          }
          date
          text
          type
          userType
        }
    */
    let userStatus, userId, lineId, userName, avatar, email, statusMessage;
    userStatus =
      userId =
      lineId =
      userName =
      avatar =
      email =
      statusMessage =
        '';
    const getId = data?.id || '';
    const getUserType = data?.userType || 'CS';
    const getChannelId = data?.channelId || '';
    const getChannelType = data?.channelType || 'CHAT';
    const position = getUserType
      ? getUserType === 'CS'
        ? 'left'
        : 'right'
      : 'left';
    const getType = data?.type || '';
    const getText = data?.text || '';
    const getDataType = data?.data?.fileType || '';
    const getDataName = data?.data?.name || '';
    const getDataUri = data?.data?.uri || '';
    const getDataUrl = data?.data?.url || '';
    const getDataThumbnailUrl = data?.data?.thumbnailUrl || '';
    const getDataBuffer = data?.data?.buffer || '';
    const getDate = data?.date || new Date();
    console.log(getUserType);
    if (!!getUserType && getUserType !== 'CS') {
      // 身分為顧客，登入時才會將資料存入localStorage
      // console.log("CUSTOMER");
      // userStatus = getDataBuffer?.userStatus || "【一般使用者】";
      // firstName = getDataBuffer?.firstName || "";
      // lastName = getDataBuffer?.lastName || "";
      // email = getDataBuffer?.email || "";
      // phone = getDataBuffer?.phone || "";
      // lineId = getDataBuffer?.lineId || "";
      // isLineBind = getDataBuffer?.isLineBind || false;
      // avatar = getDataBuffer?.avatar || "";
      // statusMessage = getDataBuffer?.statusMessage || "";
      console.log(globalUserInfo);
      userId = globalUserInfo?.userId || '';
      lineId = globalUserInfo?.lineId || '';
      userStatus = !userId ? '【訪客】' : `【訪客 ID:${userId}】`;
      userName =
        globalUserInfo?.userName || globalUserInfo?.lineName || userStatus;
      avatar =
        globalUserInfo?.linePictureUrl ||
        'https://www.gravatar.com/avatar/?d=mp';
      email = globalUserInfo?.email || '';
      statusMessage = globalUserInfo?.lineStatusMessage || '';
    } else {
      console.log('CS');
      console.log(adminName);
      userStatus = !!adminName ? `${adminName}` : '【客服人員】';
      avatar = '/cs.png';
    }
    // console.log(getText);
    // if( !! getId ) {
    if (!!getId) {
      console.log(getId);
      setMsgExist(true);
      // let newTextMsgArray = textMsgArray;
      const newTextMsg = {
        position,
        userStatus,
        userId,
        lineId,
        userName,
        avatar,
        email,
        statusMessage,
        getId,
        getUserType,
        getChannelId,
        getChannelType,
        getType,
        getText,
        getDataType,
        getDataName,
        getDataUri,
        getDataUrl,
        getDataThumbnailUrl,
        getDataBuffer,
        getDate,
        isBroadcast: data?.isBroadcast,
      };
      let lastEl = textMsgArray?.slice(-1)[0] || ''; // 取得Massage State中最後一個訊息物件(slice回傳值為物件陣列)
      // console.log({lastEl});
      console.log({ newTextMsg });
      // let lastElId = lastEl?.getId || ""; // 取得Massage State中最後一個訊息
      let lastElId = lastEl?.getId || ''; // 取得Massage State中最後一個訊息
      // console.log({lastElText});
      // console.log({textMsgArray});
      // console.log({lastEl});
      // console.log({lastElText});
      // console.log({getText});
      // console.log("lastElText !== getText",lastElText !== getText);
      // if( lastElId != getId ) {
      if (lastElId != getId) {
        // 比較Massage State中最後一個訊息與最新接收的訊息是否相同
        // console.log('textMsg1', {textMsgArray, newTextMsgArray, newTextMsg});
        setTextMsgArray((current) => [...current, newTextMsg]); // 若不同才更新狀態
        console.log('setTextMsgArray');
        setHasGotNewMsg(true);
        // console.log('textMsg2', {textMsgArray, newTextMsgArray, newTextMsg});
        // window.localStorage.setItem('textMsgArray',JSON.stringify(textMsgArray));
      }
      // console.log({lastName, getType, getText});
      // console.log('textMsgArray2',textMsgArray);
      // console.log('msgExist2',msgExist);
    }
  }; /*** END 使用者傳送訊息，將訊息存入State ***/
  useEffect(() => {
    console.log(textMsgArray);
  }, [JSON.stringify(textMsgArray)]);
  useEffect(() => {
    console.log(hasGotNewMsg);
  }, [hasGotNewMsg]);
  /*** Socket監聽User Socket訊息事件 ***/
  const listenUserSocketMessageEvent = async () => {
    console.log('listenUserSocketMessageEvent');
    console.log('Has Room ID ?', globalRoomId);
    console.log('Has Joined Room ?', globalHasJoinedRoom);
    // const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
    // if( globalAdminUserSocketConnected ) {
    // console.log("globalAdminUserSocketConnected is true", globalAdminUserSocketConnected);
    console.log(globalAdminUserSocket);
    globalAdminUserSocket.on('message', (data) => {
      console.log('y-log\n socket received a message', data);
      console.log(globalAdminUserSocket);
      // window.localStorage.setItem('lsnMsg', true);
      setHasGotNewMsg(false);
      console.log(data);
      handleNewTextMsg(data);
    });
    globalAdminUserSocket.on('getMessages', (data) => {
      // 是在UserListContent點擊進入房間時觸發
      console.log('y-log\n socket received messages', data);
      // window.localStorage.setItem('lsnMsg', true);
      console.log(data);
      const adminNameTemp = getAdminName();
      adminNameTemp.then((adminNameValue) => {
        console.log(adminNameValue);
        loadingPrevMessage(data, adminNameValue);
      });
    });
    globalAdminUserSocket.on('error', (data) => {
      console.log(data, 'Chat Room Content : Send Message Error !');
    });
    // }
  };
  useEffect(() => {
    console.log(textMsgArray);
  }, [textMsgArray]);
  useEffect(() => {
    listenUserSocketMessageEvent();
  }, []);
  // useEffect(() => {
  //   listenUserSocketMessageEvent();
  // }, [globalRoomId]);
  /*** END Socket監聽User Socket訊息事件 ***/

  /*** 清除State中重複訊息 ***/
  const clearRedupulicMsg = () => {
    // console.log( 'textMsgArray', textMsgArray );
    // console.log("typeof textMsgArray", typeof textMsgArray);
    for (let [i, v] of Object.entries(textMsgArray)) {
      // console.log({i, v});
      if (i > 0) {
        const curId = v?.getId || '';
        const preId = textMsgArray[i - 1]?.getId || '';
        // const lineId = v?.lineId || "";
        // console.log({curValue,preValue});
        if (curId === preId) {
          textMsgArray.splice(i, 1);
        }
        // const textMsgArrayLength = textMsgArray?.length || 0;
        // const oriTextMsgArray = JSON.parse(window.localStorage.getItem('textMsgArray'));
        // const oriTextMsgArrayLength = oriTextMsgArray?.length || 0;
        // console.log({textMsgArrayLength,oriTextMsgArrayLength});
        // if( textMsgArrayLength > oriTextMsgArrayLength ) { // 若State中的訊息較新，則存入LocalStorage
        //   window.localStorage.setItem('textMsgArray',JSON.stringify(textMsgArray));
        // }
        // console.log('textMsgArrayClear',textMsgArray);
      }
    }
    // console.log('textMsgArray',textMsgArray);
  };
  useEffect(() => {
    clearRedupulicMsg();
  }, [textMsgArray]);
  /*** END 清除State中重複訊息 ***/

  // /*** 由ChannelType設置訊息樣式 ***/
  // const setMsgStyleFromChannelType = () => {
  //   // setTimeout(() => {
  //     console.log(textMsgArray);
  //     for( let element of textMsgArray ) {
  //       console.log(element);
  //       const id = element?.getId || "";
  //       const channelType = element?.getChannelType || "";
  //       if( !! id && !! channelType ) {
  //         console.log(id);
  //         console.log(channelType);
  //         switch (channelType) {
  //           case 'LINE':
  //             const lineMsgBoxDiv = document.getElementById(id);
  //             const lineMsgBoxArr = lineMsgBoxDiv?.getElementsByClassName('rce-mbox-right')|| [];
  //             const lineMsgBoxNotchArr = lineMsgBoxDiv?.getElementsByClassName('rce-mbox-right-notch')|| [];
  //             for( let el of lineMsgBoxArr ) {
  //               el.style.backgroundColor = "#ccffcc";
  //             }
  //             for( let el of lineMsgBoxNotchArr ) {
  //               el.style.fill = "#ccffcc";
  //             }
  //             break;
  //           case 'FACEBOOK':
  //             const fbMsgBoxDiv = document.getElementById(id);
  //             const fbMsgBoxArr = fbMsgBoxDiv?.getElementsByClassName('rce-mbox-right') || [];
  //             const fbMsgBoxNotchArr = fbMsgBoxDiv?.getElementsByClassName('rce-mbox-right-notch')|| [];
  //             for( let el of fbMsgBoxArr ) {
  //               el.style.backgroundColor = "#d3dcf5";
  //             }
  //             for( let el of fbMsgBoxNotchArr ) {
  //               el.style.fill = "#d3dcf5";
  //             }
  //             break;
  //           case 'EMAIL':
  //             const emailMsgBoxDiv = document.getElementById(id);
  //             const emailMsgBoxArr = emailMsgBoxDiv?.getElementsByClassName('rce-mbox-right')|| [];
  //             const emailMsgBoxNotchArr = emailMsgBoxDiv?.getElementsByClassName('rce-mbox-right-notch')|| [];
  //             for( let el of emailMsgBoxArr ) {
  //               el.style.backgroundColor = "#ffebd4";
  //             }
  //             for( let el of emailMsgBoxNotchArr ) {
  //               el.style.fill = "#ffebd4";
  //             }
  //             break;
  //           default: // CHAT
  //             const chatMsgBoxDiv = document.getElementById(id);
  //             const chatMsgBoxArr = chatMsgBoxDiv?.getElementsByClassName('rce-mbox-right')|| [];
  //             const chatMsgBoxNotchArr = chatMsgBoxDiv?.getElementsByClassName('rce-mbox-right-notch')|| [];
  //             for( let el of chatMsgBoxArr ) {
  //               el.style.backgroundColor = "#fff";
  //             }
  //             for( let el of chatMsgBoxNotchArr ) {
  //               el.style.fill = "#fff";
  //             }
  //             break;
  //         }
  //       }
  //     }
  //   // }, 1000);
  // }
  // useEffect(()=>{
  //   setMsgStyleFromChannelType();
  // } ,[hasGotNewMsg, hasGotPreMsg, textMsgArray])
  // /*** END 由訊息來源設置訊息樣式 ***/

  /*** 下載檔案 ***/
  const downloadFile = (dataUrl, dataName) => {
    // window.open(
    //   dataUrl
    // );
    // fileDownload(dataUrl, dataName);
    // console.log(dataUrl);
    Axios.get(dataUrl, {
      responseType: 'blob',
    }).then((res) => {
      fileDownload(res.data, dataName);
    });
  }; /*** END 下載檔案 ***/

  /*** Scroll to Last Message ***/
  const scrollToLastMsg = () => {
    const rceContainerMboxArr =
      document.getElementsByClassName('rce-container-mbox');
    const rceContainerMboxArrLength = rceContainerMboxArr?.length || 0;
    if (rceContainerMboxArrLength > 0) {
      const lastRceContainerMbox =
        rceContainerMboxArr[rceContainerMboxArr.length - 1];
      console.log(lastRceContainerMbox);
      // lastRceContainerMbox.scrollIntoView({ behavior: "smooth" });
      lastRceContainerMbox.scrollIntoView();
    }
  };
  useEffect(() => {
    scrollToLastMsg();
  }, [
    hasGotNewMsg,
    hasGotPreMsg,
    JSON.stringify(textMsgArray),
    globalRoomId,
    globalJoinRoom,
  ]);
  /*** END Scroll to Last Message ***/

  /*** 文字訊息格式 ***/
  let textArray = [
    {
      position: 'left',
      type: 'text',
      title: 'Emre',
      text: 'left message',
    },
    {
      position: 'right',
      type: 'text',
      title: 'Emre',
      text: 'right message',
    },
  ]; /*** END 文字訊息格式 ***/

  console.log('y-log\n display messages array: \n', textMsgArray);

  return (
    <>
      {/* {textArray.map((v,i)=>{
        console.log('v',v);
        return(
          <MessageList
          className='message-list'
          lockable={true}
          toBottomHeight={'100%'}
          dataSource={textArray}
        
        />
        )
      })} */}
      {/* <div>{JSON.stringify(textMsgArray)}</div> */}
      {(Array.isArray(textMsgArray) &&
        Object.entries(textMsgArray).length > 0) ||
      msgExist ? (
        textMsgArray
          .slice()
          .sort(
            (a, b) =>
              new Date(a.getDate).getTime() - new Date(b.getDate).getTime()
          )
          .map((v, i) => {
            console.log(v);
            // console.log('textMsgArray',textMsgArray);
            /*
            const textMsg = {
                position,
                userData,
                userStatus,
                firstName,
                lastName,
                email,
                phone,
                lineId,
                isLineBind,
                avatar,
                statusMessage,
                getId,
                getUserType,
                getChannelId,
                getChannelType,
                getType,
                getText,
                getDataType,
                getDataName,
                getDataUri,
                getDataUrl,
                getDataThumbnailUrl,
                getDataBuffer
            }              
          */
            /* new
            const textMsg = {
              position,
              userStatus,
              userId,
              lineId,
              userName,
              avatar,
              email,
              statusMessage,
              getId,
              getUserType,
              getChannelId,
              getChannelType,
              getType,
              getText,
              getDataType,
              getDataName,
              getDataUri,
              getDataUrl,
              getDataThumbnailUrl,
              getDataBuffer,
              getDate
            }
            const userInfoData = {
              roomId,
              userId,
              userName,
              channels,
              channelEntries,
              channelChatId,
              channelLineId,
              channelFbId,
              channelMailId,
              lineEntries,
              lineId,
              lineName,
              linePictureUrl,
              lineLanguage,
              lineStatusMessage,
              fbEntries,
              fbId,
              email,
              tags,
            }
            userStatus = "【一般使用者】";
            userId = globalUserInfo?.userId || "";
            lineId = globalUserInfo?.lineId || "";
            userName = globalUserInfo?.userName || globalUserInfo?.lineName || `訪客（ID:${userId}）`;
            avatar = globalUserInfo?.linePictureUrl || "https://www.gravatar.com/avatar/?d=mp";
            email = globalUserInfo?.email || "";   
            statusMessage = globalUserInfo?.lineStatusMessage || "";
          */
            const position = v?.position === 'left' ? 'right' : 'left';
            const pathd =
              position === 'right' ? 'M0 0v20L20 0' : 'M20 0v20L0 0';
            const id = v?.getId || 0;
            /* 預設文字訊息 */
            // const defaultText = defaultMsg?.text || "【請先連線再送出訊息！】";
            /* 文字訊息 */
            const type = v?.getType || 'text';
            const userType = v?.getUserType || '';
            let userId, lineId, userStatus, title, avatar;
            userId = lineId = userStatus = title = avatar = '';
            if (userType === 'CUSTOMER') {
              userId = globalUserInfo?.userId || '';
              lineId = globalUserInfo?.lineId || '';
              userStatus = !userId ? '【訪客】' : `【訪客 ID:${userId}】`;
              title =
                globalUserInfo?.userName ||
                globalUserInfo?.lineName ||
                userStatus ||
                '【未知使用者】';
              avatar =
                globalUserInfo?.linePictureUrl ||
                'https://www.gravatar.com/avatar/?d=mp';
            } else {
              userStatus = v?.userStatus || '【客服人員】';
              title = v?.userName || v?.userStatus || '【客服人員】';
              console.log(v?.userName);
              console.log(v?.userStatus);
              avatar = v?.avatar || '/cs.png';
            }
            // const title = v?.lastName || v?.userStatus || "【未連線使用者】";
            const text = v?.getText || '';
            const statusTitle = v?.statusMessage || '';
            const status = 'sent';
            // const status = statusTitle === "waiting" ? "waiting" :
            //     statusTitle === "succeeded" ? "sent" :
            //     statusTitle === "received" ? "received" :
            //     statusTitle === "read" ? "read" :
            //     "sent";
            const date = v?.getDate || new Date();
            const interval = new Date() - new Date(date);
            const dateString = new Date(date)
              .toString()
              .replace(/^\w+\s+/, '')
              .replace(/\s*GMT\+\d{4}.*$/, '');
            const sendTimeText = !!interval ? dateString : 'just now';
            /* 圖片訊息 */
            let dataType = v?.getDataType || '';
            let dataName = v?.getDataName || '';
            let dataUri = v?.getDataUri || '';
            let dataUrl = v?.getDataUrl || '';
            let dataThumbnailUrl = v?.getDataThumbnailUrl || '';
            // const dataTemp = {position,id,type,title,statusTitle,status,date,dataType,dataName,dataUrl}
            const channelType = v?.getChannelType || '';
            // setMsgStyleFromChannelType(id, channelType);

            // 設定網址格式訊息
            const uriRegex =
              /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // Photo URL Regex
            const uriRegexGI =
              /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b((?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*))/i; // Photo URL Regex GI
            const nameRegexGI = /[^\/]+(?=\/?$)/i; // Photo Name Regex GI (最後一個斜線後的字串)
            const uriExtractArray = text?.match(uriRegexGI) || ''; // uriExtractArray[0]: 完整比對字串(URL); uriExtractArray[1]: 群組一比對字串(Name)
            const uriExtract = uriExtractArray[0] ?? '';
            const nameMatch = uriExtractArray[1]?.match(nameRegexGI);
            const longName = nameMatch ? nameMatch[0] : 'photo-' + new Date();
            const name = longName?.slice(0, 20) || 'photo-' + new Date();
            if (!!type && type === 'text') {
              if (uriRegex.test(text) || !!uriExtract) {
                // 若為URI格式
                if (
                  uriExtract.indexOf('youtu.be') > -1 ||
                  uriExtract.indexOf('youtube.com') > -1
                ) {
                  // Youtube影片格式
                  dataType = 'video';
                  console.log(uriExtract);
                  const videoUrl = new URL(uriExtract);
                  const videoSearch = videoUrl?.search || '';
                  const urlParams = new URLSearchParams(videoSearch);
                  dataName = !!videoSearch ? urlParams.get('v') : longName;
                  dataUrl = 'https://www.youtube.com/embed/' + dataName;
                } else if (uriExtract.indexOf('vimeo.com') > -1) {
                  // Vimeo影片格式
                  dataType = 'video';
                  dataName = longName;
                  dataUrl =
                    'https://player.vimeo.com/video/' +
                    dataName +
                    '?h=fa5c48d950&title=0&byline=0&portrait=0';
                } else {
                  // 圖片格式
                  dataType = 'photo';
                  dataName = name;
                  dataUrl = uriExtract;
                }
              }
            }

            const lastElId = textMsgArray[i - 1]?.getId || '';
            // console.log('text',text);
            // console.log('lastElText',lastElText);
            // console.log('text === lastElText?',text === lastElText);
            // console.log(id);
            // console.log(lastElId);
            if (id !== lastElId) {
              // console.log(dataType);
              // console.log(dataName);
              console.log(dataUrl);
              clearRedupulicMsg();
              // console.log('textMsgArrayClear',textMsgArray);
              // console.log('window.localStorage.textMsgArrayClear',window.localStorage.getItem('textMsgArray'));
              return (
                <>
                  {/* !! defaultMsg ? (
                   <MessageBox
                     position = "left"
                     type = {type}
                     title = {title}
                     text= {defaultText}
                   />
                ) :  */}
                  {!!type && type === 'text' ? (
                    !!dataType && dataType === 'photo' ? (
                      <div id={id} className={`${channelType} ${position}`}>
                        <MessageBox
                          position={position}
                          id={id}
                          type={dataType}
                          avatar={avatar}
                          title={title}
                          data={{
                            uri: dataUrl,
                            width: 240,
                            height: 180,
                          }}
                          statusTitle={statusTitle}
                          status={status}
                          date={date}
                        />
                      </div>
                    ) : !!dataType &&
                      dataType === 'video' &&
                      (text.indexOf('youtube.com') > -1 ||
                        text.indexOf('youtu.be') > -1) ? (
                      // () => {
                      // console.log('youtube');
                      // let urlParams = new URLSearchParams(dataUrl);
                      // const videoName = urlParams.get('v');
                      // const src = "https://www.youtube.com/embed/" + videoName;
                      // return(
                      <div id={id} className={`${channelType} ${position}`}>
                        <div className="rce-container-mbox">
                          <div className={`rce-mbox rce-mbox-${position}`}>
                            <div className="rce-mbox-body">
                              <div className="rce-mbox-title">
                                <span>{title}</span>
                              </div>
                              <div className="rce-mbox-video padding-time">
                                <div
                                  className="rce-mbox-video--img"
                                  style={{ width: '240px', height: '180px' }}
                                >
                                  <iframe
                                    width="240"
                                    height="180"
                                    src={dataUrl}
                                    title="YouTube video player"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              </div>
                              <div
                                class="rce-mbox-time non-copiable"
                                data-text={sendTimeText}
                              >
                                <span class="rce-mbox-status">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div>
                              <svg
                                className={`rce-mbox-${position}-notch`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <defs>
                                  <filter id="filter1" x="0" y="0">
                                    <feOffset
                                      result="offOut"
                                      in="SourceAlpha"
                                      dx="-2"
                                      dy="-5"
                                    ></feOffset>
                                    <feGaussianBlur
                                      result="blurOut"
                                      in="offOut"
                                      stdDeviation="3"
                                    ></feGaussianBlur>
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="blurOut"
                                      mode="normal"
                                    ></feBlend>
                                  </filter>
                                </defs>
                                <path d={pathd} filter="url(#filter1)"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        {/* <iframe 
                      width="240" 
                      height="180" 
                      src = "https://www.youtube.com/embed/MlTgVR0sXz4"
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      allowfullscreen></iframe> */}
                      </div>
                    ) : //    );
                    // }
                    !!dataType &&
                      dataType === 'video' &&
                      uriExtract.indexOf('vimeo.com') > -1 ? (
                      <div id={id} className={`${channelType} ${position}`}>
                        <div className="rce-container-mbox">
                          <div className={`rce-mbox rce-mbox-${position}`}>
                            <div className="rce-mbox-body">
                              <div className="rce-mbox-title">
                                <span>{title}</span>
                              </div>
                              <div className="rce-mbox-video rce-mbox-video--video padding-time">
                                <div
                                  className="rce-mbox-video--img"
                                  style={{ width: '240px', height: '180px' }}
                                >
                                  <iframe
                                    src={dataUrl}
                                    width="240"
                                    height="180"
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                  {/* <p style={{'display': 'none'}}>
                                    <a href="https://vimeo.com/783453584">The Vandal (Interactive)</a> from 
                                    <a href="https://vimeo.com/eddiealcazar">Eddie Alcazar</a> on 
                                    <a href="https://vimeo.com">Vimeo</a>.
                                  </p> */}
                                </div>
                              </div>
                              <div
                                class="rce-mbox-time non-copiable"
                                data-text={sendTimeText}
                              >
                                <span class="rce-mbox-status">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div>
                              <svg
                                className={`rce-mbox-${position}-notch`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <defs>
                                  <filter id="filter1" x="0" y="0">
                                    <feOffset
                                      result="offOut"
                                      in="SourceAlpha"
                                      dx="-2"
                                      dy="-5"
                                    ></feOffset>
                                    <feGaussianBlur
                                      result="blurOut"
                                      in="offOut"
                                      stdDeviation="3"
                                    ></feGaussianBlur>
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="blurOut"
                                      mode="normal"
                                    ></feBlend>
                                  </filter>
                                </defs>
                                <path d={pathd} filter="url(#filter1)"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        id={id}
                        className={`${channelType} ${position} ${
                          v?.isBroadcast ? 'BROADCAST' : ''
                        }`}
                      >
                        {v?.isBroadcast ? (
                          <p className="broadcast-message">
                            <GlobalOutlined />
                            廣播訊息
                          </p>
                        ) : null}

                        <MessageBox
                          position={position}
                          id={id}
                          type={type}
                          title={title}
                          avatar={avatar}
                          text={text}
                          statusTitle={statusTitle}
                          status={status}
                          date={date}
                        />
                      </div>
                    )
                  ) : !!type && type === 'photo' ? (
                    <div id={id} className={`${channelType} ${position}`}>
                      <MessageBox
                        position={position}
                        id={id}
                        type={type}
                        avatar={avatar}
                        title={title}
                        // text={dataName}
                        data={{
                          uri: dataUrl,
                          width: 240,
                          height: 180,
                        }}
                        statusTitle={statusTitle}
                        status={status}
                        date={date}
                      />
                    </div>
                  ) : !!type &&
                    type === 'video' &&
                    (dataType.indexOf('video/') > -1 ||
                      dataName.indexOf('.mp4') > -1 ||
                      dataName.indexOf('.mov') > -1 ||
                      dataName.indexOf('.wmv') > -1 ||
                      dataName.indexOf('.webm') > -1 ||
                      dataName.indexOf('.avi') > -1 ||
                      dataName.indexOf('.flv') > -1 ||
                      dataName.indexOf('.mkv') > -1 ||
                      dataName.indexOf('.mts') > -1) ? (
                    <div id={id} className={`${channelType} ${position}`}>
                      <MessageBox
                        className={style.chatRoomContentMessageBoxVideo}
                        position={position}
                        id={id}
                        type={'video'}
                        avatar={avatar}
                        title={title}
                        data={{
                          videoURL: dataUrl,
                          // videoURL: "https://messanger.kid-pro.com/chat-messages/message-645c44437e285d750034de0182d4714e-cc84-4456-9366-b551e2a84c09.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=aYhuX59nCrg8fwcE%2F20230516%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230516T030745Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=b0102264f9e184394d325ea0ee1a187df51911ef53ca618c297edafec69912ef",
                          width: 240,
                          height: 180,
                          status: {
                            click: true,
                            loading: 0.5,
                            download: true,
                          },
                        }}
                        statusTitle={statusTitle}
                        status={status}
                        date={date}
                      />
                    </div>
                  ) : !!type &&
                    type === 'audio' &&
                    (dataType.indexOf('audio/') > -1 ||
                      dataName.indexOf('.wav') > -1 ||
                      dataName.indexOf('.mp3') > -1 ||
                      dataName.indexOf('.m4a') > -1 ||
                      dataName.indexOf('.flac') > -1 ||
                      dataName.indexOf('.wma') > -1 ||
                      dataName.indexOf('.aac') > -1) ? (
                    <div id={id} className={`${channelType} ${position}`}>
                      <MessageBox
                        className={style.chatRoomContentMessageBoxVideo}
                        position={position}
                        id={id}
                        type={'audio'}
                        avatar={avatar}
                        title={title}
                        data={{
                          audioURL: dataUrl,
                          controlsList: 'nofullscreen',
                        }}
                        statusTitle={statusTitle}
                        status={status}
                        date={date}
                      />
                    </div>
                  ) : !!type && type === 'file' ? (
                    <div id={id} className={`${channelType} ${position}`}>
                      <MessageBox
                        position={position}
                        id={id}
                        type={type}
                        avatar={avatar}
                        title={title}
                        text={dataName}
                        // text={dataUrl}
                        data={{
                          uri: dataUrl,
                          id: dataName,
                          name: dataName,
                          status: {
                            click: false,
                            loading: 0,
                          },
                        }}
                        onDownload={(e) => {
                          downloadFile(dataUrl, dataName);
                        }}
                        statusTitle={statusTitle}
                        status={status}
                        date={date}
                      />
                    </div>
                  ) : (
                    <div id={id} className={`${channelType} ${position}`}></div>
                  )}
                </>
              );
            }
          })
      ) : (
        // <MessageList
        //   className='message-list'
        //   lockable={true}
        //   toBottomHeight={'100%'}
        //   dataSource={textMsgArray}
        // />
        <div>
          <span>請選擇使用者列表中的一位使用者，即可進入聊天室開始聊天。</span>
        </div>
      )}
      {/* <MessageBox
        position={"left"}
        type={"photo"}
        title={"Kursat"}
        text={"TEXT"}
        data={{
            uri: "https://picsum.photos/200/200",
            alt: "test"
        }}
      /> */}
      {/* <RceText></RceText>
      <RcePhoto></RcePhoto>
      <RceVideo></RceVideo> */}
      {/* <MessageBox
       position="left"
       title="Emre"
       type='text'
       text="Click to join the meeting"
       date={new Date()}
      /> */}
      {/* <MessageList
        className='message-list'
        lockable={true}
        toBottomHeight={'100%'}
        dataSource={textArray}
      /> */}
      {/* <div>
        <p>{textArray.text}</p>
      </div> */}
    </>
  );
}

export default ChatRoomContent;
