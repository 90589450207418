import { httpRequest } from '../../../utils/httpRequest';

/* //////////////////////////////////////////////////////////////// */
export const setRichMenuAsDefault = async ({ lineRichMenuId }) => {
  const res = await httpRequest(`/rich-menu/${lineRichMenuId}/set-default`, {
    method: 'POST',
  });

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};
