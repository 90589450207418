/* React */
import { useContext } from 'react';
import { createPortal } from 'react-dom';

/* Store */
import { DialogContext } from '../../../store/DialogContext';
/* Styles */
import style from './Dialog.module.css';
import { Button } from 'antd';

/* //////////////////////////////////////////////////////////////// */
export default function Dialog() {
  const { isDialogOpen, type, title, message, onConfirmCb, onCloseDialog } =
    useContext(DialogContext);

  console.log('Dialog Component', isDialogOpen);

  const handleCancel = () => {
    onCloseDialog();
  };
  const handleConfirm = async () => {
    try {
      await onConfirmCb?.();
    } catch (_) {
      // unused
    }

    onCloseDialog();
  };

  /* JSX ---------------------------------------------------------- */
  if (!isDialogOpen) return null;

  return createPortal(
    <>
      <div className={style['overlay']} onClick={handleCancel} />
      <div className={style['dialog']}>
        <h2
          className={`${style['title']} ${
            type === 'error' ? style['title--error'] : ''
          } ${type === 'confirm' ? style['title--confirm'] : ''}`}
        >
          {title}
        </h2>
        <p className={style['message']}>{message}</p>

        <div className={style['buttons']}>
          {type === 'confirm' ? (
            <>
              <Button onClick={handleCancel}>取消</Button>
              <Button
                className={style['button--warning']}
                onClick={handleConfirm}
              >
                確認
              </Button>
            </>
          ) : null}
          {type === 'error' ? (
            <Button className={style['button--error']} onClick={handleCancel}>
              確認
            </Button>
          ) : null}
        </div>
      </div>
    </>,
    document.body
  );
}
