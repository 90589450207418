import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import style from './styles/LineAudience.module.css';
import '../../styles/LineAnt.css';
import './styles/LineComponentsRce.css';
import './styles/LineComponentsAnt.css';
import {
  Layout,
  Menu,
  Input,
  Space,
  Button,
  InputNumber,
  List,
  Radio,
  Tag,
  Pagination,
} from 'antd';
import {
  EyeOutlined,
  PlusOutlined,
  EditFilled,
  EditOutlined,
  RollbackOutlined,
  DeleteFilled,
  DeleteOutlined,
} from '@ant-design/icons';

function LineAudience() {
  const [lineAudienceCRUD, setLineAudienceCRUD] = useState('LineAudienceRead');
  const [searchString, setSearchString] = useState(''); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(6); // 一頁有幾個受眾數
  const [lineAudienceDataArray, setLineAudienceDataArray] = useState([{}]); // 各受眾資料
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項tag資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState(
    {}
  ); //更新欄位狀態
  const [addFieldsOnChangeState, setAddFieldsOnChangeState] = useState({
    name: '',
    category: '',
    description: '',
    priority: 0,
  }); // 新增受眾欄位onChange狀態
  const lineAudienceNameRef = useRef('');
  const lineAudienceCategoryRef = useRef('');
  const lineAudienceDescriptionRef = useRef('');
  const lineAudiencePriorityRef = useRef('');
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || '';
    setSearchString(val);
  };
  const onSearch = () => {
    console.log(currentPage);
    requestLineAudience();
    setIsEditMode([-1, false]); // 返回非編輯模式
  };

  /*** 查詢LineAudience ***/
  const requestLineAudience = async () => {
    const url =
      apiURL +
      '/chat-tags?' +
      new URLSearchParams({
        filter: searchString,
        // limit: numberPerPage,
        // offset: currentPage-1
      });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`受眾查詢失敗！\n${err}`);
        throw new Error('RequestLineAudience failed');
      });
    console.log(response);
    setLineAudienceDataArray(response);
    // setUpdateFieldsOnChangeState(response);
    // try {
    //   const response = await axios.get({
    //     method: 'get',
    //     url: url,
    //     responseType: 'json',
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  };
  useEffect(() => {
    requestLineAudience();
  }, []);
  useEffect(() => {
    console.log(lineAudienceDataArray);
  }, [lineAudienceDataArray]);

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  const clickLineAudienceMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || '';
    if (key.includes('LineAudienceRead')) {
      setLineAudienceCRUD('LineAudienceRead');
    } else if (key.includes('LineAudienceAdd')) {
      setLineAudienceCRUD('LineAudienceAdd');
    } else if (key.includes('LineAudienceUpdate')) {
      setLineAudienceCRUD('LineAudienceUpdate');
    } else if (key.includes('LineAudienceDelete')) {
      setLineAudienceCRUD('LineAudienceDelete');
    } else {
      setLineAudienceCRUD('');
    }
  };

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 更新欄位onChange ***/
  const updateFieldsOnChange = (e) => {
    e.preventDefault();
    // const value = e?.value || "";
    const name = lineAudienceNameRef?.current?.input?.value || '';
    const category = lineAudienceCategoryRef?.current?.input?.value || '';
    const description = lineAudienceDescriptionRef?.current?.input?.value || '';
    const priority =
      parseInt(lineAudiencePriorityRef?.current?.input?.value) || 0;
    const data = { name, category, description, priority };
    console.log(lineAudienceNameRef);
    console.log(lineAudienceNameRef.current);
    console.log(value);
    console.log(data);
    setUpdateFieldsOnChangeState(data);
  };

  /*** 更新受眾 ***/
  const updateAudience = async (e, id) => {
    e.preventDefault();
    const url = apiURL + '/chat-tags/' + id;
    console.log(url);
    console.log(updateFieldsOnChangeState);
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(updateFieldsOnChangeState),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`受眾更新失敗！\n${err}`);
        throw new Error('Update Audience failed');
      });
    console.log(JSON.stringify(response));
    const responseId = response?._id || '';
    if (!!responseId) {
      if (Object.values(lineAudienceDataArray).length > 0) {
        for (let el of lineAudienceDataArray) {
          if (Object.values(el).includes(id)) {
            el['name'] = (await response?.name) || '';
            el['category'] = (await response?.category) || '';
            el['description'] = (await response?.description) || '';
            el['priority'] = (await response?.priority) || 0;
            alert(`受眾內容已更新！\n( ID：${id} )`);
          }
        }
        setIsEditMode([-1, false]); // 返回非編輯模式
      }
    }
  };

  /*** 新增欄位onChange ***/
  const addFieldsOnChange = (e) => {
    e.preventDefault();
    const fieldId = e?.target?.id || '';
    const value = e?.target?.value || '';
    if (!!fieldId) {
      console.log(fieldId);
      console.log(value);
      switch (fieldId) {
        case 'AudienceName':
          setAddFieldsOnChangeState({
            ...addFieldsOnChangeState,
            ['name']: value,
          });
          break;
        case 'AudienceCategory':
          setAddFieldsOnChangeState({
            ...addFieldsOnChangeState,
            ['category']: value,
          });
          break;
        case 'AudienceDescription':
          setAddFieldsOnChangeState({
            ...addFieldsOnChangeState,
            ['description']: value,
          });
          break;
        case 'AudiencePriority':
          const intValue = parseInt(value);
          setAddFieldsOnChangeState({
            ...addFieldsOnChangeState,
            ['priority']: intValue,
          });
          break;
      }
    }
  };
  useEffect(() => {
    console.log(addFieldsOnChangeState);
  }, [addFieldsOnChangeState]);

  /*** 新增受眾 ***/
  const addAudience = async (e, id, name) => {
    e.preventDefault();
    console.log(id);
    console.log(name);
    // const url = apiURL + "/chat-tags/";
    // console.log(url);
    // console.log(addFieldsOnChangeState);
    // const response = await fetch(
    //   url,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(addFieldsOnChangeState),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //     },
    //   }
    // )
    // .then((res) => res.json())
    // .catch((err) => {
    //   alert(`標籤新增失敗！\n${err}`);
    //   throw new Error("Add Audience failed");
    // });
    // console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // const responseStatus = response?.statusCode || "";
    // if( ! responseId ) {
    //   const responseErrorMsg = response?.message || "";
    //   if( responseStatus === 500 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else if( responseStatus === 400 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else {
    //     alert(`標籤新增失敗！\n${responseErrorMsg}`);
    //   }
    //   return false;
    // } else {
    //   const name = await response?.name || "";
    //   const category = await response?.category || "";
    //   const description = await response?.description || "";
    //   const priority = await response?.priority || 0;
    //   const data = { _id: responseId, name, category, description, priority };
    //   tagsDataArray.push(data);
    //   alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
    //   setAddFieldsOnChangeState({}); // 清空欄位
    //   requestAudience(name); // 查詢已新增的標籤
    //   setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
    // }
  };

  /*** 查詢Audience ***/
  const requestAudience = async (searchString) => {
    const url =
      apiURL +
      '/chat-tags?' +
      new URLSearchParams({
        filter: searchString,
      });
    console.log(url);
    console.log(searchString);
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`標籤查詢失敗！\n${err}`);
        throw new Error('RequestAudience failed');
      });
    console.log(response);
    setLineAudienceDataArray(response);
  };

  /*** 刪除受眾 ***/
  const deleteAudience = async (e, id, name) => {
    e.preventDefault();
    if (window.confirm(`確定要刪除【${name}】受眾？\n( ID：${id} )`)) {
      const url = apiURL + '/chat-tags/' + id;
      console.log(url);
      console.log(updateFieldsOnChangeState);
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: '*/*',
        },
      })
        // .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.ok) {
            for (let [index, el] of lineAudienceDataArray.entries()) {
              if (Object.values(el).includes(id)) {
                lineAudienceDataArray.splice(index, 1);
                alert(`【${el['name']}】受眾已刪除！\n（ID：${id}）`);
              }
            }
            setIsEditMode([-1, false]); // 返回非編輯模式
          } else {
            alert(`受眾刪除失敗！`);
            throw new Error('Delete Audience failed');
          }
        })
        .catch((err) => {
          alert(`受眾刪除失敗！\n${err}`);
          throw new Error('Delete Audience failed');
        });
      // const responseId = response?._id || "";
      // if( !! responseId ) {
      // }
    } else {
      return;
    }
  };

  /*** 分頁顯示設定 ***/
  const data = [
    // 樣板資料
    {
      _id: '001',
      name: '測試受眾1',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '002',
      name: '測試受眾2',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '003',
      name: '測試受眾3',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試受眾4',
      category: '受眾類別',
      description: '受眾描述',
      priority: 0,
      __v: 0,
    },
  ];

  useEffect(() => {
    console.log(isEditMode);
    console.log(isEditMode[0]);
    console.log(isEditMode[1]);
  }, [isEditMode]);
  return (
    <Layout id="lineAudienceLayout" className={style.lineAudienceLayout}>
      {/* <div className={`${ComponentStyle.title}`}><h1>LineAudience</h1></div> */}
      <Content className={style.lineAudienceOuterContent}>
        <Content className={style.lineAudienceContent}>
          <section
            id={'lineAudienceSearchSec'}
            className={style.lineAudienceSearchSec}
          >
            <div className={style.lineAudienceSearchDiv}>
              <Search
                className={`${style.lineAudienceInput} ${style.lineAudienceSearch}`}
                addonBefore={
                  <span className={style.lineAudienceSearchTitle}>
                    受眾查詢
                  </span>
                }
                placeholder="請輸入欲查詢的受眾名稱"
                allowClear
                size="large"
                onChange={(e) => {
                  searchOnChange(e);
                }}
                onSearch={onSearch}
                enterButton
              />
            </div>
            <List
              className={style.lineAudienceList}
              locale={{}}
              pagination={{
                position: 'bottom',
                align: 'center',
                hideOnSinglePage: true,
                showQuickJumper: true,
                // total: 50,
                current: currentPage,
                pageSize: numberPerPage,
                onChange: changePage,
              }}
              dataSource={lineAudienceDataArray}
              renderItem={(item, index) =>
                // Object.keys(lineAudienceDataArray[0]).includes('_id') && isEditMode[0] === index && isEditMode[1] ? (
                // <List.Item>
                //     <List.Item.Meta
                //       avatar={
                //         <div className={style.lineAudienceTagDiv}>
                //           <Tag className={style.lineAudienceTagIcon} color="geekblue">{item.name}</Tag>
                //         </div>
                //       }
                //       title={
                //         <div className={style.lineAudienceDescriptionItem}>
                //           <span className={style.lineAudienceDescriptionID}>{"受眾ID："}</span><span className={style.lineAudienceDescriptionText}>{item._id}</span>
                //           <div className={style.lineAudienceEditAndDelIconDiv}>
                //             <PlusOutlined className={style.lineAudienceAddIcon} onClick={(e) => { addAudience(e, item._id, item.name) }} />
                //             {/* <RollbackOutlined className={style.lineAudienceEditAndDelIcon} onClick={(e) => { setIsEditMode(false) }} />
                //             <DeleteOutlined className={style.lineAudienceEditAndDelIcon} onClick={(e) => { deleteAudience(e, item._id, item.name) }} /> */}
                //             {/* <DeleteFilled className={style.lineAudienceAddIcon} /> */}
                //           </div>
                //         </div>
                //       }
                //       description={
                //         <div className={style.lineAudienceDescriptionDiv} style={{"justifyContent": "center"}}>
                //           <div className={`${style.lineAudienceDescriptionItem} ${style.lineAudienceDescriptionItemName}`}>
                //             <span className={`${style.lineAudienceDescriptionLabel} ${style.lineAudienceDescriptionLabelName}`}>{"受眾名稱："}</span>
                //             <Input className={`${style.lineAudienceDescriptionInput} ${style.lineAudienceDescriptionInputName}`} type="text" defaultValue={item.name} ref={lineAudienceNameRef} maxLength={10} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineAudienceDescriptionItem} ${style.lineAudienceDescriptionItemCategory}`}>
                //             <span className={`${style.lineAudienceDescriptionLabel} ${style.lineAudienceDescriptionLabelCategory}`}>{"受眾類別："}</span>
                //             <Input className={`${style.lineAudienceDescriptionInput} ${style.lineAudienceDescriptionInputCategory}`} type="text" defaultValue={item.category} ref={lineAudienceCategoryRef} maxLength={20} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineAudienceDescriptionItem} ${style.lineAudienceDescriptionItemDescription}`}>
                //             <span className={`${style.lineAudienceDescriptionLabel} ${style.lineAudienceDescriptionLabelDescription}`}>{"受眾描述："}</span>
                //             <Input className={`${style.lineAudienceDescriptionInput} ${style.lineAudienceDescriptionInputDescription}`} type="text" defaultValue={item.description} ref={lineAudienceDescriptionRef} maxLength={40} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineAudienceDescriptionItem} ${style.lineAudienceDescriptionItemPriority}`}>
                //             <span className={`${style.lineAudienceDescriptionLabel} ${style.lineAudienceDescriptionLabelPriority}`}>{"優先權："}</span>
                //             <Input className={`${style.lineAudienceDescriptionInput} ${style.lineAudienceDescriptionInputPriority}`} type="number" defaultValue={item.priority} ref={lineAudiencePriorityRef} maxLength={2} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineAudienceDescriptionItem} ${style.lineAudienceDescriptionItemUpdateBtn}`}>
                //             <Button className={`${style.lineAudienceDescriptionInputUpdateBtn}`} type={"primary"} size={"small"} onClick={(e) => { updateAudience( e, item._id ); }}>更新</Button>
                //           </div>
                //         </div>
                //       }
                //     />
                //   </List.Item>
                // ) :
                Object.keys(lineAudienceDataArray[0]).includes('_id') ? (
                  <List.Item>
                    <List.Item.Meta
                      className={style.lineAudienceListItemMeta}
                      avatar={
                        <Tag
                          className={style.lineAudienceTagIcon}
                          color="geekblue"
                        >
                          {item.name}
                        </Tag>
                      }
                      title={
                        <div
                          className={`${style.lineAudienceDescriptionItem} ${style.lineAudienceDescriptionItemTitle}`}
                        >
                          <span className={style.lineAudienceDescriptionID}>
                            {'受眾ID：'}
                          </span>
                          <span className={style.lineAudienceDescriptionText}>
                            {item._id}
                          </span>
                          <div className={style.lineAudienceEditAndDelIconDiv}>
                            <PlusOutlined
                              className={style.lineAudienceAddIcon}
                              onClick={(e) => {
                                addAudience(e, item._id, item.name);
                              }}
                            />
                            {/* <EditOutlined className={style.lineAudienceEditAndDelIcon} onClick={(e) => { setIsEditMode([index, true]) }} /> */}
                            {/* <EditFilled className={style.lineAudienceAddIcon} /> */}
                            {/* <DeleteOutlined className={style.lineAudienceEditAndDelIcon} onClick={(e) => { deleteAudience(e, item._id, item.name) }} /> */}
                            {/* <DeleteFilled className={style.lineAudienceAddIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div className={style.lineAudienceDescriptionDiv}>
                          <div className={style.lineAudienceDescriptionItem}>
                            <span
                              className={style.lineAudienceDescriptionLabel}
                            >
                              {'受眾名稱：'}
                            </span>
                            <span className={style.lineAudienceDescriptionText}>
                              {item.name}
                            </span>
                          </div>
                          <div className={style.lineAudienceDescriptionItem}>
                            <span
                              className={style.lineAudienceDescriptionLabel}
                            >
                              {'受眾類別：'}
                            </span>
                            <span className={style.lineAudienceDescriptionText}>
                              {item.category}
                            </span>
                          </div>
                          <div className={style.lineAudienceDescriptionItem}>
                            <span
                              className={style.lineAudienceDescriptionLabel}
                            >
                              {'受眾描述：'}
                            </span>
                            <span className={style.lineAudienceDescriptionText}>
                              {item.description}
                            </span>
                          </div>
                          <div className={style.lineAudienceDescriptionItem}>
                            <span
                              className={style.lineAudienceDescriptionLabel}
                            >
                              {'優先權：'}
                            </span>
                            <span className={style.lineAudienceDescriptionText}>
                              {item.priority}
                            </span>
                          </div>
                          {/* <div className={style.lineAudienceDescriptionItem}>
                            <span className={style.lineAudienceDescriptionLabel}>{"其他資訊："}</span><span className={style.lineAudienceDescriptionText}>{item.__v}</span>
                          </div> */}
                        </div>
                      }
                    />
                  </List.Item>
                ) : (
                  <></>
                )
              }
            />
          </section>
        </Content>
      </Content>
    </Layout>
  );
}

export default LineAudience;
