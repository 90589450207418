/* React */
// import { useEffect } from 'react';

/* Components */
import { RichMenuAreasForm, RichMenuImage } from './Components';
/* Styles */
import style from './LineRichMenuEditor.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function LineRichMenuEditor() {
  /* JSX ---------------------------------------------------------- */
  return (
    <section className={style['container']}>
      <div className={style['container__image']}>
        <RichMenuImage />
      </div>
      <div className={style['container__form']}>
        <RichMenuAreasForm />
      </div>
    </section>
  );
}
