import { httpRequest } from '../../../utils/httpRequest';

/* //////////////////////////////////////////////////////////////// */
export const modifyMessage = async ({ reqData, sendMethod, lineMessageId }) => {
  const res = await httpRequest(
    `/send-message/${sendMethod}/${lineMessageId}`,
    {
      method: 'PUT',
      body: JSON.stringify(reqData),
    }
  );

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};
