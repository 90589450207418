import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import style from './styles/LineTags.module.css';
import '../../styles/LineAnt.css';
import './styles/LineComponentsRce.css';
import './styles/LineComponentsAnt.css';
import {
  Layout,
  Menu,
  Input,
  Space,
  Button,
  InputNumber,
  List,
  Radio,
  Tag,
  Pagination,
} from 'antd';
import {
  EyeOutlined,
  PlusOutlined,
  EditFilled,
  EditOutlined,
  RollbackOutlined,
  DeleteFilled,
  DeleteOutlined,
} from '@ant-design/icons';

function LineTags(props) {
  const [lineTagsCRUD, setLineTagsCRUD] = useState('LineTagsRead');
  const [searchString, setSearchString] = useState(''); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(6); // 一頁有幾個標籤數
  const [lineTagsDataArray, setLineTagsDataArray] = useState([{}]); // 各標籤資料
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項tag資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState(
    {}
  ); //更新欄位狀態
  const [addFieldsOnChangeState, setAddFieldsOnChangeState] = useState({
    name: '',
    category: '',
    description: '',
    priority: 0,
  }); // 新增標籤欄位onChange狀態
  const lineTagsNameRef = useRef('');
  const lineTagsCategoryRef = useRef('');
  const lineTagsDescriptionRef = useRef('');
  const lineTagsPriorityRef = useRef('');
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || '';
    setSearchString(val);
  };
  const onSearch = () => {
    console.log(currentPage);
    requestLineTags();
    setIsEditMode([-1, false]); // 返回非編輯模式
  };

  /*** 查詢LineTags ***/
  const requestLineTags = async () => {
    const url =
      apiURL +
      '/chat-tags?' +
      new URLSearchParams({
        filter: searchString,
        // limit: numberPerPage,
        // offset: currentPage-1
      });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`標籤查詢失敗！\n${err}`);
        throw new Error('RequestLineTags failed');
      });
    console.log(response);
    setLineTagsDataArray(response);
    // setUpdateFieldsOnChangeState(response);
    // try {
    //   const response = await axios.get({
    //     method: 'get',
    //     url: url,
    //     responseType: 'json',
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  };
  useEffect(() => {
    requestLineTags();
  }, []);
  useEffect(() => {
    console.log(lineTagsDataArray);
  }, [lineTagsDataArray]);

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  const clickLineTagsMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || '';
    if (key.includes('LineTagsRead')) {
      setLineTagsCRUD('LineTagsRead');
    } else if (key.includes('LineTagsAdd')) {
      setLineTagsCRUD('LineTagsAdd');
    } else if (key.includes('LineTagsUpdate')) {
      setLineTagsCRUD('LineTagsUpdate');
    } else if (key.includes('LineTagsDelete')) {
      setLineTagsCRUD('LineTagsDelete');
    } else {
      setLineTagsCRUD('');
    }
  };

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 更新欄位onChange ***/
  const updateFieldsOnChange = (e) => {
    e.preventDefault();
    // const value = e?.value || "";
    const name = lineTagsNameRef?.current?.input?.value || '';
    const category = lineTagsCategoryRef?.current?.input?.value || '';
    const description = lineTagsDescriptionRef?.current?.input?.value || '';
    const priority = parseInt(lineTagsPriorityRef?.current?.input?.value) || 0;
    const data = { name, category, description, priority };
    console.log(lineTagsNameRef);
    console.log(lineTagsNameRef.current);
    console.log(value);
    console.log(data);
    setUpdateFieldsOnChangeState(data);
  };

  /*** 更新標籤 ***/
  const updateTags = async (e, id) => {
    e.preventDefault();
    const url = apiURL + '/chat-tags/' + id;
    console.log(url);
    console.log(updateFieldsOnChangeState);
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(updateFieldsOnChangeState),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`標籤更新失敗！\n${err}`);
        throw new Error('Update Tags failed');
      });
    console.log(JSON.stringify(response));
    const responseId = response?._id || '';
    if (!!responseId) {
      if (Object.values(lineTagsDataArray).length > 0) {
        for (let el of lineTagsDataArray) {
          if (Object.values(el).includes(id)) {
            el['name'] = (await response?.name) || '';
            el['category'] = (await response?.category) || '';
            el['description'] = (await response?.description) || '';
            el['priority'] = (await response?.priority) || 0;
            alert(`標籤內容已更新！\n( ID：${id} )`);
          }
        }
        setIsEditMode([-1, false]); // 返回非編輯模式
      }
    }
  };

  /*** 新增欄位onChange ***/
  const addFieldsOnChange = (e) => {
    e.preventDefault();
    const fieldId = e?.target?.id || '';
    const value = e?.target?.value || '';
    if (!!fieldId) {
      console.log(fieldId);
      console.log(value);
      switch (fieldId) {
        case 'TagsName':
          setAddFieldsOnChangeState({
            ...addFieldsOnChangeState,
            ['name']: value,
          });
          break;
        case 'TagsCategory':
          setAddFieldsOnChangeState({
            ...addFieldsOnChangeState,
            ['category']: value,
          });
          break;
        case 'TagsDescription':
          setAddFieldsOnChangeState({
            ...addFieldsOnChangeState,
            ['description']: value,
          });
          break;
        case 'TagsPriority':
          const intValue = parseInt(value);
          setAddFieldsOnChangeState({
            ...addFieldsOnChangeState,
            ['priority']: intValue,
          });
          break;
      }
    }
  };
  useEffect(() => {
    console.log(addFieldsOnChangeState);
  }, [addFieldsOnChangeState]);

  /*** 儲存最近新增的標籤 ***/
  const recentTagsStore = (attrObj) => {
    const recentTagsData = window.localStorage.getItem('rctatt') ?? '';
    let recentTagsArray = !!recentTagsData ? JSON.parse(recentTagsData) : [];
    recentTagsArray.push(attrObj);
    const tagsDataJson = JSON.stringify(recentTagsArray);
    window.localStorage.setItem('rctatt', tagsDataJson);
  };

  /*** 新增標籤 ***/
  const addTags = async (e, id, name) => {
    e.preventDefault();
    console.log(id);
    console.log(name);
    const attrObj = {
      key: id,
      label: name,
      // onClick: ({ item, key, keyPath, domEvent }) => {
      //   console.log(key);
      // }
    };
    recentTagsStore(attrObj);
    // const url = apiURL + "/chat-tags/";
    // console.log(url);
    // console.log(addFieldsOnChangeState);
    // const response = await fetch(
    //   url,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(addFieldsOnChangeState),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //     },
    //   }
    // )
    // .then((res) => res.json())
    // .catch((err) => {
    //   alert(`標籤新增失敗！\n${err}`);
    //   throw new Error("Add Tags failed");
    // });
    // console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // const responseStatus = response?.statusCode || "";
    // if( ! responseId ) {
    //   const responseErrorMsg = response?.message || "";
    //   if( responseStatus === 500 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else if( responseStatus === 400 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else {
    //     alert(`標籤新增失敗！\n${responseErrorMsg}`);
    //   }
    //   return false;
    // } else {
    //   const name = await response?.name || "";
    //   const category = await response?.category || "";
    //   const description = await response?.description || "";
    //   const priority = await response?.priority || 0;
    //   const data = { _id: responseId, name, category, description, priority };
    //   tagsDataArray.push(data);
    //   alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
    //   setAddFieldsOnChangeState({}); // 清空欄位
    //   requestTags(name); // 查詢已新增的標籤
    //   setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
    // }
  };

  /*** 查詢Tags ***/
  const requestTags = async (searchString) => {
    const url =
      apiURL +
      '/chat-tags?' +
      new URLSearchParams({
        filter: searchString,
      });
    console.log(url);
    console.log(searchString);
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`標籤查詢失敗！\n${err}`);
        throw new Error('RequestTags failed');
      });
    console.log(response);
    setLineTagsDataArray(response);
  };

  /*** 刪除標籤 ***/
  const deleteTags = async (e, id, name) => {
    e.preventDefault();
    if (window.confirm(`確定要刪除【${name}】標籤？\n( ID：${id} )`)) {
      const url = apiURL + '/chat-tags/' + id;
      console.log(url);
      console.log(updateFieldsOnChangeState);
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: '*/*',
        },
      })
        // .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.ok) {
            for (let [index, el] of lineTagsDataArray.entries()) {
              if (Object.values(el).includes(id)) {
                lineTagsDataArray.splice(index, 1);
                alert(`【${el['name']}】標籤已刪除！\n（ID：${id}）`);
              }
            }
            setIsEditMode([-1, false]); // 返回非編輯模式
          } else {
            alert(`標籤刪除失敗！`);
            throw new Error('Delete Tags failed');
          }
        })
        .catch((err) => {
          alert(`標籤刪除失敗！\n${err}`);
          throw new Error('Delete Tags failed');
        });
      // const responseId = response?._id || "";
      // if( !! responseId ) {
      // }
    } else {
      return;
    }
  };

  /*** 分頁顯示設定 ***/
  const data = [
    // 樣板資料
    {
      _id: '001',
      name: '測試標籤1',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '002',
      name: '測試標籤2',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '003',
      name: '測試標籤3',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
  ];

  useEffect(() => {
    console.log(isEditMode);
    console.log(isEditMode[0]);
    console.log(isEditMode[1]);
  }, [isEditMode]);
  return (
    <Layout id="lineTagsLayout" className={style.lineTagsLayout}>
      {/* <div className={`${ComponentStyle.title}`}><h1>LineTags</h1></div> */}
      <Content className={style.lineTagsOuterContent}>
        <Content className={style.lineTagsContent}>
          <section id={'lineTagsSearchSec'} className={style.lineTagsSearchSec}>
            <div className={style.lineTagsSearchDiv}>
              <Search
                className={`${style.lineTagsInput} ${style.lineTagsSearch}`}
                addonBefore={
                  <span className={style.lineTagsSearchTitle}>標籤查詢</span>
                }
                placeholder="請輸入欲查詢的標籤名稱"
                allowClear
                size="large"
                onChange={(e) => {
                  searchOnChange(e);
                }}
                onSearch={onSearch}
                enterButton
              />
            </div>
            <List
              className={style.lineTagsList}
              locale={{}}
              pagination={{
                position: 'bottom',
                align: 'center',
                hideOnSinglePage: true,
                showQuickJumper: true,
                // total: 50,
                current: currentPage,
                pageSize: numberPerPage,
                onChange: changePage,
              }}
              dataSource={lineTagsDataArray}
              renderItem={(item, index) =>
                // Object.keys(lineTagsDataArray[0]).includes('_id') && isEditMode[0] === index && isEditMode[1] ? (
                // <List.Item>
                //     <List.Item.Meta
                //       avatar={
                //         <div className={style.lineTagsTagDiv}>
                //           <Tag className={style.lineTagsTagIcon} color="geekblue">{item.name}</Tag>
                //         </div>
                //       }
                //       title={
                //         <div className={style.lineTagsDescriptionItem}>
                //           <span className={style.lineTagsDescriptionID}>{"標籤ID："}</span><span className={style.lineTagsDescriptionText}>{item._id}</span>
                //           <div className={style.lineTagsEditAndDelIconDiv}>
                //             <PlusOutlined className={style.lineTagsAddIcon} onClick={(e) => { addTags(e, item._id, item.name) }} />
                //             {/* <RollbackOutlined className={style.lineTagsEditAndDelIcon} onClick={(e) => { setIsEditMode(false) }} />
                //             <DeleteOutlined className={style.lineTagsEditAndDelIcon} onClick={(e) => { deleteTags(e, item._id, item.name) }} /> */}
                //             {/* <DeleteFilled className={style.lineTagsAddIcon} /> */}
                //           </div>
                //         </div>
                //       }
                //       description={
                //         <div className={style.lineTagsDescriptionDiv} style={{"justifyContent": "center"}}>
                //           <div className={`${style.lineTagsDescriptionItem} ${style.lineTagsDescriptionItemName}`}>
                //             <span className={`${style.lineTagsDescriptionLabel} ${style.lineTagsDescriptionLabelName}`}>{"標籤名稱："}</span>
                //             <Input className={`${style.lineTagsDescriptionInput} ${style.lineTagsDescriptionInputName}`} type="text" defaultValue={item.name} ref={lineTagsNameRef} maxLength={10} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineTagsDescriptionItem} ${style.lineTagsDescriptionItemCategory}`}>
                //             <span className={`${style.lineTagsDescriptionLabel} ${style.lineTagsDescriptionLabelCategory}`}>{"標籤類別："}</span>
                //             <Input className={`${style.lineTagsDescriptionInput} ${style.lineTagsDescriptionInputCategory}`} type="text" defaultValue={item.category} ref={lineTagsCategoryRef} maxLength={20} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineTagsDescriptionItem} ${style.lineTagsDescriptionItemDescription}`}>
                //             <span className={`${style.lineTagsDescriptionLabel} ${style.lineTagsDescriptionLabelDescription}`}>{"標籤描述："}</span>
                //             <Input className={`${style.lineTagsDescriptionInput} ${style.lineTagsDescriptionInputDescription}`} type="text" defaultValue={item.description} ref={lineTagsDescriptionRef} maxLength={40} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineTagsDescriptionItem} ${style.lineTagsDescriptionItemPriority}`}>
                //             <span className={`${style.lineTagsDescriptionLabel} ${style.lineTagsDescriptionLabelPriority}`}>{"優先權："}</span>
                //             <Input className={`${style.lineTagsDescriptionInput} ${style.lineTagsDescriptionInputPriority}`} type="number" defaultValue={item.priority} ref={lineTagsPriorityRef} maxLength={2} onChange={updateFieldsOnChange}></Input>
                //           </div>
                //           <div className={`${style.lineTagsDescriptionItem} ${style.lineTagsDescriptionItemUpdateBtn}`}>
                //             <Button className={`${style.lineTagsDescriptionInputUpdateBtn}`} type={"primary"} size={"small"} onClick={(e) => { updateTags( e, item._id ); }}>更新</Button>
                //           </div>
                //         </div>
                //       }
                //     />
                //   </List.Item>
                // ) :
                Object.keys(lineTagsDataArray[0]).includes('_id') ? (
                  <List.Item>
                    <List.Item.Meta
                      className={style.lineTagsListItemMeta}
                      avatar={
                        <Tag className={style.lineTagsTagIcon} color="geekblue">
                          {item.name}
                        </Tag>
                      }
                      title={
                        <div
                          className={`${style.lineTagsDescriptionItem} ${style.lineTagsDescriptionItemTitle}`}
                        >
                          <span className={style.lineTagsDescriptionID}>
                            {'標籤ID：'}
                          </span>
                          <span className={style.lineTagsDescriptionText}>
                            {item._id}
                          </span>
                          <div className={style.lineTagsEditAndDelIconDiv}>
                            <PlusOutlined
                              className={style.lineTagsAddIcon}
                              onClick={(e) => {
                                addTags(e, item._id, item.name);
                              }}
                            />
                            {/* <EditOutlined className={style.lineTagsEditAndDelIcon} onClick={(e) => { setIsEditMode([index, true]) }} /> */}
                            {/* <EditFilled className={style.lineTagsAddIcon} /> */}
                            {/* <DeleteOutlined className={style.lineTagsEditAndDelIcon} onClick={(e) => { deleteTags(e, item._id, item.name) }} /> */}
                            {/* <DeleteFilled className={style.lineTagsAddIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div className={style.lineTagsDescriptionDiv}>
                          <div className={style.lineTagsDescriptionItem}>
                            <span className={style.lineTagsDescriptionLabel}>
                              {'標籤名稱：'}
                            </span>
                            <span className={style.lineTagsDescriptionText}>
                              {item.name}
                            </span>
                          </div>
                          <div className={style.lineTagsDescriptionItem}>
                            <span className={style.lineTagsDescriptionLabel}>
                              {'標籤類別：'}
                            </span>
                            <span className={style.lineTagsDescriptionText}>
                              {item.category}
                            </span>
                          </div>
                          <div className={style.lineTagsDescriptionItem}>
                            <span className={style.lineTagsDescriptionLabel}>
                              {'標籤描述：'}
                            </span>
                            <span className={style.lineTagsDescriptionText}>
                              {item.description}
                            </span>
                          </div>
                          <div className={style.lineTagsDescriptionItem}>
                            <span className={style.lineTagsDescriptionLabel}>
                              {'優先權：'}
                            </span>
                            <span className={style.lineTagsDescriptionText}>
                              {item.priority}
                            </span>
                          </div>
                          {/* <div className={style.lineTagsDescriptionItem}>
                            <span className={style.lineTagsDescriptionLabel}>{"其他資訊："}</span><span className={style.lineTagsDescriptionText}>{item.__v}</span>
                          </div> */}
                        </div>
                      }
                    />
                  </List.Item>
                ) : (
                  <></>
                )
              }
            />
          </section>
        </Content>
      </Content>
    </Layout>
  );
}

export default LineTags;
