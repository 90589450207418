import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import style from './styles/UserListContent.module.css';
import { ChatList, ChatItem, Dropdown, Button } from 'react-chat-elements';
import CryptoJS from 'crypto-js';
import DefaultAvatar from './imgs/default-user-image.png';
import LoadingSpinnerMedium from './OtherComponent/LoadingSpinnerMedium';

/*** Import Socket Component ***/
import { GlobalAdminSocket } from '../../../../../SocketIO/Admin/SocketAdminConnect';
import SocketAdminListening from '../../../../../SocketIO/Admin/SocketAdminListening';
// import SocketAdminListening from '../../../../../SocketIO/Admin/SocketAdminListening';

/*** Import Room ID Context ***/
import { GlobalRoomId } from '../ChatRoom';
import { GlobalJoinRoom } from '../ChatRoom';
import { GlobalHasJoinedRoom } from '../ChatRoom';

function UserListContent(props) {
  const { clickTimes, setClickTimes } = props;
  const [globalAdminSocket, setGlobalAdminSocket] =
    useContext(GlobalAdminSocket);
  const [globalRoomId, setGlobalRoomId] = useContext(GlobalRoomId);
  const [globalJoinRoom, setGlobalJoinRoom] = useContext(GlobalJoinRoom);
  const [globalHasJoinedRoom, setGlobalHasJoinedRoom] =
    useContext(GlobalHasJoinedRoom);

  const [hasGotGlobalRoomId, setHasGotGlobalRoomId] = useState(false);
  useEffect(() => {
    if (!!globalRoomId) {
      setHasGotGlobalRoomId(true);
    } else {
      setHasGotGlobalRoomId(false);
    }
  }, [globalRoomId]);
  const [userListLoading, setUserListLoading] = useState(true);
  const [roomListState, setRoomListState] = useState([]); // 使用者列表預設值
  const [newRoom, setNewRoom] = useState({}); // 設定新傳訊息的使用者房間
  const [info, setInfo] = useState('');
  const [assignTo, setAssignTo] = useState({ 999999: '所有人' });
  // const [userListContentLoading, setUserListContentLoading] = useState(false);
  // useEffect(()=>{setUserListContentLoading(true)},[]);
  // const [textMsgArray, setTextMsgArray] = useState([]);
  // const [msgExist, setMsgExist] = useState(false);

  // let globalAdminUserSocket = null;
  // let globalAdminPanelSocket = null;
  // let globalAdminUserSocketConnected = null;
  // let globalAdminPanelSocketConnected = null;
  const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState(
    globalAdminSocket?.globalAdminUserSocket || {}
  ); // 初始值設定正確才能及時觸發主動連線
  const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState(
    globalAdminSocket?.globalAdminPanelSocket || {}
  );
  // const [globalAdminUserSocketConnected, setGlobalAdminUserSocketConnected] = useState(globalAdminSocket?.globalAdminUserSocket?.connected);
  // const [globalAdminPanelSocketConnected, setGlobalAdminPanelSocketConnected] = useState(globalAdminSocket?.globalAdminPanelSocket?.connected);

  /*** 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/
  const initializeAdminUserAndPanelSocket = async () => {
    console.log(globalAdminSocket);
    // console.log(globalAdminSocket?.globalAdminUserSocket);
    // console.log(globalAdminSocket?.globalAdminPanelSocket);
    // console.log(globalAdminSocket.globalAdminUserSocket.connected);
    // console.log(globalAdminSocket.globalAdminPanelSocket.connected);
    // globalAdminUserSocket = await ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminUserSocket || null : null );
    // globalAdminPanelSocket = await ( typeof globalAdminSocket === 'object' && globalAdminSocket !== null && Object.keys(globalAdminSocket).length > 0 ? globalAdminSocket?.globalAdminPanelSocket || null : null );
    let adminUserSocket = null;
    if (
      typeof globalAdminSocket === 'object' &&
      globalAdminSocket !== null &&
      Object.keys(globalAdminSocket).length > 0
    ) {
      adminUserSocket = globalAdminSocket?.globalAdminUserSocket || null;
      console.log(adminUserSocket);
      setGlobalAdminUserSocket(adminUserSocket); // 最後改動此處，讓GlobalAdminSocket連線後設定GlobalAdminUserSocket
    }
    let adminPanelSocket = null;
    if (
      typeof globalAdminSocket === 'object' &&
      globalAdminSocket !== null &&
      Object.keys(globalAdminSocket).length > 0
    ) {
      adminPanelSocket = globalAdminSocket?.globalAdminPanelSocket || null;
      console.log(adminPanelSocket);
      setGlobalAdminPanelSocket(adminPanelSocket);
    }
    // if( ! globalAdminUserSocket || ! globalAdminPanelSocket ) {
    //   globalAdminUserSocket = assignGlobalAdminUserSocketConnected;
    //   globalAdminPanelSocket = assignGlobalAdminPanelSocketConnected;
    // }
    // await checkAdminUserAndPanelSocketIsConnected(globalAdminUserSocket, globalAdminPanelSocket);
  };
  useEffect(() => {
    initializeAdminUserAndPanelSocket();
  }, []);
  useEffect(() => {
    initializeAdminUserAndPanelSocket();
  }, [globalAdminSocket]);
  useEffect(() => {
    console.log('globalAdminUserSocket', globalAdminUserSocket);
    console.log('globalAdminPanelSocket', globalAdminPanelSocket);
  }, [globalAdminUserSocket, globalAdminPanelSocket]);
  /*** END 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/

  // /*** 確認 User 及 Panel Socket 連線狀態 ***/
  // const checkAdminUserAndPanelSocketIsConnected = async (globalAdminUserSocket, globalAdminPanelSocket) => {
  //   console.log({ globalAdminUserSocket, globalAdminPanelSocket });
  //   console.log(!!globalAdminUserSocket);
  //   console.log(!!globalAdminPanelSocket);
  //   if ( !! globalAdminUserSocket ) {
  //     console.log("GlobalAdminUserSocket Connect");
  //     console.log(typeof globalAdminUserSocket === 'object');
  //     console.log(globalAdminUserSocket !== null);
  //     console.log(Object.keys(globalAdminUserSocket).length > 0);
  //     let adminUserSocketConnected = null;
  //     if ( typeof globalAdminUserSocket === 'object' && globalAdminUserSocket !== null && Object.keys(globalAdminUserSocket).length > 0 ) {
  //       adminUserSocketConnected = globalAdminUserSocket?.connected || null;
  //       console.log(adminUserSocketConnected);
  //     }
  //     console.log(adminUserSocketConnected);
  //     setGlobalAdminUserSocketConnected(adminUserSocketConnected);
  //     console.log(globalAdminUserSocketConnected);
  //   }
  //   if ( !! globalAdminPanelSocket ) {
  //     console.log("GlobalAdminPanelSocket Connect");
  //     console.log(typeof globalAdminPanelSocket === 'object');
  //     console.log(globalAdminPanelSocket !== null);
  //     console.log(Object.keys(globalAdminPanelSocket).length > 0);
  //     let adminPanelSocketConnected = null;
  //     if ( typeof globalAdminPanelSocket === 'object' && globalAdminPanelSocket !== null && Object.keys(globalAdminPanelSocket).length > 0 ) {
  //       adminPanelSocketConnected = globalAdminPanelSocket?.connected || null;
  //       console.log(adminPanelSocketConnected);
  //     }
  //     console.log(adminPanelSocketConnected);
  //     console.log(globalAdminPanelSocketConnected);
  //     setGlobalAdminPanelSocketConnected(adminPanelSocketConnected);
  //     console.log(globalAdminPanelSocketConnected);
  //   }
  //   console.log({ globalAdminUserSocketConnected, globalAdminPanelSocketConnected });
  // };
  // // useEffect(() => {
  // //   checkAdminUserAndPanelSocketIsConnected();
  // // }, [globalAdminUserSocket, globalAdminPanelSocket]);
  // useEffect(() => {
  //   console.log({globalAdminUserSocketConnected, globalAdminPanelSocketConnected});
  // }, [globalAdminUserSocketConnected, globalAdminPanelSocketConnected]);
  // /*** END 確認 User 及 Panel Socket連線狀態 ***/

  /*** 取得加密的使用者資料 ***/
  let defaultUserData = null;
  const lineAppId = process.env.REACT_APP_LINE_APP_ID;
  const cpo = window.localStorage.getItem('cpo')
    ? window.localStorage.getItem('cpo')
    : '';
  const getCipherUserData = () => {
    const accessToken = window.localStorage.getItem(
      'LIFF_STORE:' + lineAppId + ':accessToken'
    )
      ? window.localStorage.getItem('LIFF_STORE:' + lineAppId + ':accessToken')
      : '';
    if (!!accessToken && !!cpo) {
      try {
        const userDataJson = CryptoJS.AES.decrypt(cpo, `${accessToken}`);
        // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
        const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
        const userDataDecode = JSON.parse(userDataUTF8);

        // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
        // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));
        defaultUserData = userDataDecode;
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };
  useEffect(() => {
    getCipherUserData();
  }, [cpo]);
  /*** END 取得加密的使用者資料 ***/

  /*** 請求使用者資料 ***/
  const emitUserInfo = async (id) => {
    setTimeout(
      () => {
        const globalAdminPanelSocketConnected =
          globalAdminPanelSocket?.connected || false;
        console.log(globalAdminPanelSocketConnected);
        console.log(id);
        if (globalAdminPanelSocketConnected && !!id) {
          globalAdminPanelSocket.emit('userInfo', { roomId: id });
          console.log('Emit: Administer Get User Info !');
        } else if (!globalAdminPanelSocketConnected) {
          console.log('GlobalAdminPanelSocket not Connected !');
        } else {
          console.log('GlobalRoomId not Exist!');
        }
      },
      1000,
      id
    );
  }; /*** END 請求使用者資料 ***/

  /*** 請求使用者舊訊息 ***/
  const emitPrevMessages = async () => {
    console.log('listenUserSocketMessageEvent');
    console.log('Has Room ID ?', globalRoomId);
    console.log('Has Joined Room ?', globalHasJoinedRoom);
    const globalAdminUserSocketConnected =
      globalAdminUserSocket?.connected || false;
    if (globalAdminUserSocketConnected) {
      console.log(
        'globalAdminUserSocketConnected is true',
        globalAdminUserSocketConnected
      );
      globalAdminUserSocket.emit('getMessages', {
        cursorD: new Date(),
        limit: 50,
      });
      console.log('Chat Room Content Emit: getMessages');
    }
  }; /*** END 請求使用者舊訊息 ***/

  /*** 處理使用者列表資料 ***/
  const handleUserList = async (data) => {
    console.log({ data });
    /* roomListData = {
        "assigned": [
          {
            "roomId": "string",
            "title": "string",
            "subtitle": "string",
            "date": "2019-08-24T14:15:22Z",
            "unread": 0,
            "avatar": "string"
          }
        ],
        "all": [
          {
            "roomId": "string",
            "title": "string",
            "subtitle": "string",
            "date": "2019-08-24T14:15:22Z",
            "unread": 0,
            "avatar": "string"
          }
        ]
      }      
    */
    /* 
      userData = {
        const userStatus = defaultUserData?.userStatus || "【一般使用者】";
        const firstName = defaultUserData?.firstName || "";
        const lastName = defaultUserData?.lastName || userStatus ||  "【未連線使用者】"; // displayName
        const email = defaultUserData?.email || "";
        const phone = defaultUserData?.phone || "";
        const lineId = defaultUserData?.lineId || ""; // userId
        const isLineBind = defaultUserData?.isLineBind || false;
        const avatar = defaultUserData?.avatar || "";
        const statusMessage = defaultUserData?.statusMessage || "";
        const date = data?.date || new Date();
      }
    */
    let roomList = [];
    const roomListAssign = data?.assigned || [];
    console.log('roomListAssign', roomListAssign);
    const roomListAll = data?.all || [];
    console.log('roomListAll', roomListAll);
    for (let objEl of roomListAssign) {
      const id = objEl?.roomId || '';
      console.log(globalRoomId);
      if (!globalRoomId) {
        // 賦予第一個取得的Room ID為預設值
        setGlobalRoomId(id);
        console.log(id);
        console.log(globalRoomId);
      }
      const avatar = objEl?.avatar || 'https://www.gravatar.com/avatar/?d=mp';
      const onAvatarError = 'https://www.gravatar.com/avatar/?d=mp';
      const alt = 'line_avatar';
      const title = objEl?.title || '';
      const subtitle = objEl?.subtitle || '';
      const date = objEl?.date || new Date();
      const unread = objEl?.unread || 0;
      const room = {
        id,
        avatar,
        onAvatarError,
        alt,
        title,
        subtitle,
        date,
        unread,
      };
      console.log('room', room);
      roomList.push(room);
    }
    setRoomListState(roomList);
    setUserListLoading(false);
    console.log('roomList', roomList);
  };
  useEffect(() => {
    console.log('roomListState', roomListState);
  }, [roomListState]);
  /*** END 處理使用者列表資料 ***/

  /*** 新傳訊息會更新使用者列表 ***/
  const handleUserListNewMsg = (data) => {
    console.log({ data });
    /* roomListData = {
        "assigned": [
          {
            "roomId": "string",
            "title": "string",
            "subtitle": "string",
            "date": "2019-08-24T14:15:22Z",
            "unread": 0,
            "avatar": "string"
          }
        ],
        "all": [
          {
            "roomId": "string",
            "title": "string",
            "subtitle": "string",
            "date": "2019-08-24T14:15:22Z",
            "unread": 0,
            "avatar": "string"
          }
        ]
      }      
    */
    /* 
      userData = {
        const userStatus = defaultUserData?.userStatus || "【一般使用者】";
        const firstName = defaultUserData?.firstName || "";
        const lastName = defaultUserData?.lastName || userStatus ||  "【未連線使用者】"; // displayName
        const email = defaultUserData?.email || "";
        const phone = defaultUserData?.phone || "";
        const lineId = defaultUserData?.lineId || ""; // userId
        const isLineBind = defaultUserData?.isLineBind || false;
        const avatar = defaultUserData?.avatar || "";
        const statusMessage = defaultUserData?.statusMessage || "";
        const date = data?.date || new Date();
      }
    */
    const roomListAssign = data?.assigned[0] || null;
    console.log('roomListAssign', roomListAssign);
    // console.log('roomListAssign[0]', roomListAssign[0]);
    const roomListAll = data?.all[0] || null;
    console.log('roomListAll', roomListAll);
    // console.log('roomListAll[0]', roomListAll[0]);
    // for( let objEl of roomListAssign ) {
    const id = roomListAssign?.roomId || '';
    const avatar =
      roomListAssign?.avatar || 'https://www.gravatar.com/avatar/?d=mp';
    const onAvatarError = 'https://www.gravatar.com/avatar/?d=mp';
    const alt = 'line_avatar';
    const title = roomListAssign?.title || '';
    const subtitle = roomListAssign?.subtitle || '';
    const date = roomListAssign?.date || new Date();
    const unread = roomListAssign?.unread || 0;
    const room = {
      id,
      avatar,
      onAvatarError,
      alt,
      title,
      subtitle,
      date,
      unread,
    };
    console.log('room', room);
    setNewRoom(room);
    // }
    // console.log(roomListState);
    // console.log(roomListState[roomListState.length-1]);
    // console.log(_.isEqual( roomListState[roomListState.length-1], room));
    // console.log( roomListState.length > 0 && ! _.isEqual( roomListState[roomListState.length-1], room) );
    // setRoomListStateFunction(room);
    // if( roomListState.length > 0 && ! _.isEqual( roomListState[roomListState.length-1], room ) ) {
    //   setRoomListState((current => [...current, room]));
    // }
    // clearRedupulicMsg(roomListState);
    // setUserListLoading(false);
    // console.log('roomList', roomList);
  };
  // const setRoomListStateFunction = (room) => {
  //   if( roomListState.length > 0 && ! _.isEqual( roomListState[roomListState.length-1], room ) ) {
  //     setRoomListState((current => [...current, room]));
  //   }
  // }
  useEffect(() => {
    console.log('roomListState', roomListState);
  }, [roomListState]);
  /*** END 新傳訊息會更新使用者列表 ***/

  /*** 更新傳新訊息使用者之Room Data至User List ***/
  const updateNewMsgUserRoom = () => {
    setUserListLoading(true);
    const roomListStateTemp = roomListState.map((item, idx) => {
      const preId = item?.id || '';
      const curId = newRoom?.id || '';
      if (preId === curId) {
        // ID相同取代原元素
        item = newRoom;
      } else if (roomListState.length === idx + 1) {
        // ID比對至最末位仍不相等，插入成為新元素
        roomListState.push(newRoom);
      }
      return item;
    });
    setRoomListState(roomListStateTemp);
    setUserListLoading(false);
  };
  useEffect(() => {
    updateNewMsgUserRoom();
  }, [newRoom]);
  /*** END 更新傳新訊息使用者之Room Data至User List ***/

  /*** Panel Socket 事件監聽 ***/
  const listenPanelSocketEvent = () => {
    const globalAdminPanelSocketConnected =
      globalAdminPanelSocket?.connected || false;
    // console.log("listenPanelSocketEvent, globalAdminPanelSocketConnected", globalAdminPanelSocketConnected);
    if (globalAdminPanelSocketConnected) {
      console.log(
        'listenPanelSocketEvent, globalAdminPanelSocketConnected is true',
        globalAdminPanelSocketConnected
      );
      console.log(globalAdminPanelSocket);
      // globalAdminPanelSocket.on("roomListInit", (data) => {
      //   console.log(data, 'User List On : Listening Room List Initial !');
      // });
      globalAdminPanelSocket.on('roomList', async (data1) => {
        console.log('y-log\n socket received room-list:\n', data1);
        setUserListLoading(true);
        console.log(data1, 'User List On : Listening Room List !');
        await handleUserList(data1);
        await globalAdminPanelSocket.on('newRoomItem', (data2) => {
          console.log(data2, 'User List On : New Room Item !');
          handleUserListNewMsg(data2);
        });
      });
      globalAdminPanelSocket.on('assign', async (data3) => {
        console.log(data3, 'User List On : Listening Admin Assign Result !');
      });
    }
  };
  useEffect(() => {
    listenPanelSocketEvent();
  }, []);
  /*** END Panel Socket 事件監聽 ***/

  /*** 要求Room List(之後放在前台) ***/
  const emitRoomListFromPanelSocket = () => {
    const globalAdminPanelSocketConnected =
      globalAdminPanelSocket?.connected || false;
    console.log(
      'emitRoomListFromPanelSocket, globalAdminPanelSocketConnected',
      globalAdminPanelSocketConnected
    );
    if (globalAdminPanelSocketConnected) {
      console.log(
        'emitRoomListFromPanelSocket, globalAdminPanelSocketConnected is true',
        globalAdminPanelSocketConnected
      );
      console.log(globalAdminPanelSocket);
      // globalAdminPanelSocket.emit("roomListInit");
      globalAdminPanelSocket.emit('roomList', {
        cursorD: new Date(),
        limit: 50,
      });
    }
  };
  useEffect(() => {
    emitRoomListFromPanelSocket();
  }, []);
  /*** END 要求Room List ***/

  /*** 載入後過一段時間未取得User List，顯示提示訊息 ***/
  const setTimeoutAfterSocketConnected = async (sec) => {
    setUserListLoading(true);
    const ms = 1000 * sec;
    setTimeout(() => {
      const globalAdminPanelSocketConnected =
        globalAdminPanelSocket?.connected || false;
      if (!globalAdminPanelSocketConnected) {
        setUserListLoading(false);
        setInfo('無法取得使用者列表，請重新進入聊天室介面！');
      } else if (roomListState.length == 0) {
        setUserListLoading(false);
        setInfo('目前無使用者加入房間！');
      }
    }, ms);
  };
  useEffect(() => {
    setTimeoutAfterSocketConnected(8);
  }, [globalAdminPanelSocket]);
  /*** END 載入後過一段時間未取得User List，顯示提示訊息 ***/

  /*** 管理者加入房間 ***/
  const adminJoinRoom = async (id) => {
    // const roomId = process.env.REACT_APP_ROOMID;
    console.log(globalAdminUserSocket);
    // console.log(roomListState);
    const globalAdminUserSocketConnected =
      globalAdminUserSocket?.connected || false;
    if (globalAdminUserSocketConnected && !!id) {
      // const roomId = roomListState[i] ?. id || "";
      globalAdminUserSocket.emit('joinRoom', { roomId: id });
      setGlobalRoomId(id);
      setGlobalJoinRoom(true);
      console.log('Emit: Administer Join Room !');
    }
  }; /*** END 管理者加入房間 ***/

  /*** 管理者離開房間 ***/
  const adminLeaveRoom = async () => {
    // const roomId = process.env.REACT_APP_ROOMID;
    console.log(globalAdminUserSocket);
    // console.log(roomListState);
    const globalAdminUserSocketConnected =
      globalAdminUserSocket?.connected || false;
    if (globalAdminUserSocketConnected && !!globalRoomId) {
      globalAdminUserSocket.emit('leaveRoom', { roomId: globalRoomId });
      // setGlobalRoomId(globalRoomId);
      setGlobalJoinRoom(false);
      console.log('Emit: Administer Leave Room !');
    }
  }; /*** END 管理者離開房間 ***/

  /*** 剛載入時預設顯示最近傳訊息使用者的詳細資訊 ***/
  const showRecentContactUser = async () => {
    const newRoomId = (await newRoom?.id) || '';
    const roomListStateLength = roomListState?.length || 0;
    // const firstRoom = roomListStateLength > 0 && !! roomListState[roomListStateLength-1] ? roomListState[roomListStateLength-1] : {};
    const firstRoom =
      roomListStateLength > 0 && !!roomListState[0] ? roomListState[0] : {};
    const firstRoomId = (await firstRoom?.id) || '';
    // console.log(roomId);
    console.log(newRoomId);
    console.log(firstRoomId);
    // if( !! newRoomId ) {
    //   await adminLeaveRoom();
    //   await adminJoinRoom(newRoomId);
    //   await emitUserInfo(newRoomId);
    // } else if( !! firstRoomId ) {
    await adminLeaveRoom();
    await adminJoinRoom(firstRoomId);
    await emitUserInfo(firstRoomId);
    // }
  };
  useEffect(() => {
    showRecentContactUser();
  }, [hasGotGlobalRoomId]);
  // useEffect(() => {
  //   showRecentContactUser();
  // }, [roomListState]);
  /*** END 剛載入時預設顯示最近傳訊息使用者的詳細資訊 ***/

  /*** 當管理者分配使用者時，變換assignText ***/
  // let index = "";
  const assignByIndex = (idx) => {
    // console.log(idx);
    // console.log(assignTo);
    // console.log(Object.keys(assignTo).length > 1);
    // console.log(assignTo[idx]);
    // console.log(assignTo[999999]);
    const assignTextTemp =
      Object.keys(assignTo).length > 1 && !!assignTo[idx]
        ? assignTo[idx]
        : assignTo[999999];
    // console.log(assignTextTemp);
    // index = idx;
    // setAssignText(assignTextTemp);
    return assignTextTemp;
  };
  // useEffect(()=>{
  //   assignByIndex();
  // }, [assignIdx]);
  /*** END 當管理者分配使用者時，變換assignText ***/

  /*** 指派給不同權限之管理者 ***/
  const assignToAdmin = (itemIndex) => {
    const globalAdmiPanelSocketConnected =
      globalAdminPanelSocket?.connected || false;
    if (globalAdmiPanelSocketConnected && !!globalRoomId) {
      let assignType = 'close';
      console.log(assignType);
      switch (itemIndex) {
        case 0:
          assignType = 'agent';
          break;
        case 1:
          assignType = 'therapist';
          break;
        case 2:
          assignType = 'engineer';
          break;
        default:
          break;
      }
      globalAdminPanelSocket.emit('assign', { assignType });
      console.log('Emit: Assign To An Admin !');
    }
  };
  /*** END 指派給不同權限之管理者 ***/

  /*** 偵測是否為行動設備 ***/
  const isMobileDevice = () => {
    const mobileDevice = [
      'Android',
      'webOS',
      'iPhone',
      'iPad',
      'iPod',
      'BlackBerry',
      'Windows Phone',
      'X11',
    ];
    let isMobileDevice = mobileDevice.some((e) => navigator.userAgent.match(e));
    // console.log(navigator.userAgent);
    // console.log(isMobileDevice);
    return isMobileDevice;
  }; /*** END 偵測是否為行動設備 ***/

  /*** 各用戶簡易資訊卡 ***/
  let listData = [
    {
      avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
      alt: 'kursat_avatar',
      title: 'Kursat',
      subtitle: "Why don't we go to the No Way Home movie this weekend ?",
      date: new Date(),
      unread: 3,
    },
    {
      avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
      alt: 'kursat_avatar',
      title: 'Kursat',
      subtitle: "Why don't we go to the No Way Home movie this weekend ?",
      date: new Date(),
      unread: 3,
    },
    {
      avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
      alt: 'kursat_avatar',
      title: 'Kursat',
      subtitle: "Why don't we go to the No Way Home movie this weekend ?",
      date: new Date(),
      unread: 3,
    },
    {
      avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
      alt: 'kursat_avatar',
      title: 'Kursat',
      subtitle: "Why don't we go to the No Way Home movie this weekend ?",
      date: new Date(),
      unread: 3,
    },
    {
      avatar: 'https://avatars.githubusercontent.com/u/80540635?v=4',
      alt: 'kursat_avatar',
      title: 'Kursat',
      subtitle: "Why don't we go to the No Way Home movie this weekend ?",
      date: new Date(),
      unread: 3,
    },
  ];

  return (
    <>
      {/* <div className={style.userListContent}>
          <ChatList
            className='chat-list'
            dataSource={listData} />
      </div> */}
      {userListLoading && !info && roomListState.length == 0 ? (
        <LoadingSpinnerMedium></LoadingSpinnerMedium>
      ) : !userListLoading && !!info && roomListState.length == 0 ? (
        <div className={style.infoDiv}>
          <span className={style.infoSpan}>{info}</span>
        </div>
      ) : (
        <div className={style.userListContent}>
          {/* <div>{JSON.stringify(roomListState)} {JSON.stringify(listData)}</div> */}
          {roomListState.length > 0 ? (
            roomListState
              .slice()
              // sort by date
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .map((v, i) => {
                console.log({ v, i });
                const id = v?.id || '';
                const avatar =
                  v?.avatar || 'https://www.gravatar.com/avatar/?d=mp';
                const onAvatarError = 'https://www.gravatar.com/avatar/?d=mp';
                const alt = v?.alt || 'line_avatar';
                const title = v?.title || '';
                const subtitle = v?.subtitle || '';
                const date = v?.date || new Date();
                const unread = v?.unread || 0;
                console.log(assignTo);
                console.log(assignByIndex(i));
                return (
                  <div
                    id="userListDropdown"
                    className={style.userListChatItemDiv}
                  >
                    <ChatItem
                      id={id}
                      avatar={avatar}
                      onAvatarError={onAvatarError}
                      alt={alt}
                      title={title}
                      subtitle={subtitle}
                      date={date}
                      unread={unread}
                      onClick={async (e) => {
                        // console.log(isMobileDevice());
                        // if (e.detail == 1 && !isMobileDevice()) {
                        //   await adminLeaveRoom();
                        //   await adminJoinRoom(id);
                        //   await emitUserInfo(id);
                        //   setClickTimes(1);
                        // } else {
                        //   await adminLeaveRoom();
                        //   await adminJoinRoom(id);
                        //   await emitUserInfo(id);
                        //   await emitPrevMessages();
                        //   setClickTimes(2);
                        // }
                        // await setGlobalRoomId('');
                        await adminLeaveRoom();
                        await adminJoinRoom(id);
                        await emitUserInfo(id);
                        await emitPrevMessages();
                        setGlobalRoomId(id);
                      }}
                    ></ChatItem>
                    <Dropdown
                      animationType="default"
                      animationPosition="norteast"
                      buttonProps={{
                        className: `${style.userListButton}`,
                        text: '指派給\n【' + assignByIndex(i) + '】',
                        backgroundColor: '#1677FE',
                      }}
                      onSelect={(itemIndex) => {
                        switch (itemIndex) {
                          case 0:
                            setAssignTo({ ...assignTo, [i]: '客服' });
                            assignToAdmin(itemIndex);
                            assignByIndex(i);
                            break;
                          case 1:
                            setAssignTo({ ...assignTo, [i]: '治療師' });
                            assignToAdmin(itemIndex);
                            assignByIndex(i);
                            break;
                          case 2:
                            setAssignTo({ ...assignTo, [i]: '工程師' });
                            assignToAdmin(itemIndex);
                            assignByIndex(i);
                            break;
                          default:
                            setAssignTo({ ...assignTo, [i]: '所有人' });
                            assignToAdmin(itemIndex);
                            assignByIndex(i);
                            break;
                        }
                      }}
                      items={[
                        {
                          text: '客服',
                        },
                        {
                          text: '治療師',
                        },
                        {
                          text: '工程師',
                        },
                        {
                          text: '所有人',
                        },
                      ]}
                    />
                  </div>
                );
              })
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}

export default UserListContent;
