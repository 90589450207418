import React, { useState } from 'react';
import axios from 'axios';
import style from './styles/Users.module.css';
import './styles/UsersRce.css';
import {
  Layout,
  Menu,
  Input,
  Space,
  Button,
  InputNumber,
  List,
  Radio,
  Tag,
  Pagination,
} from 'antd';
import {
  EyeOutlined,
  PlusCircleOutlined,
  UpCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';

/*** Import Users Component ***/
import UsersRead from './components/UsersRead';
import UsersAdd from './components/UsersAdd';
// import UsersUpdate from './components/UsersUpdate';
// import UsersDelete from './components/UsersDelete';

function Users() {
  const [usersCRUD, setUsersCRUD] = useState('UsersRead');
  const [searchString, setSearchString] = useState(''); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(8); // 一頁有幾個Tag數
  const [usersDataArray, setUsersDataArray] = useState([{}]); // 各使用者資料
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  /*** 使用者頂部導覽項目 ***/
  // const menuItems = [
  //   getMenuItem('Option 1', '1', <PieChartOutlined />),
  //   getMenuItem('Option 2', '2', <DesktopOutlined />),
  //   getMenuItem('Option 3', '3', <ContainerOutlined />),

  //   getMenuItem('Navigation One', 'sub1', <MailOutlined />, [
  //     getMenuItem('Option 5', '5'),
  //     getMenuItem('Option 6', '6'),
  //     getMenuItem('Option 7', '7'),
  //     getMenuItem('Option 8', '8'),
  //   ]),

  //   getMenuItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
  //     getMenuItem('Option 9', '9'),
  //     getMenuItem('Option 10', '10'),

  //     getMenuItem('Submenu', 'sub3', null, [getMenuItem('Option 11', '11'), getMenuItem('Option 12', '12')]),
  //   ]),
  // ];
  const menuItems = [
    {
      label: '查詢／編輯使用者',
      key: 'UsersRead',
      icon: <EyeOutlined />,
    },
    {
      label: '新增使用者',
      key: 'UsersAdd',
      icon: <PlusCircleOutlined />,
      // disabled: true,
    },
    // {
    //   label: '更新使用者',
    //   key: 'UsersUpdate',
    //   icon: <UpCircleOutlined />,
    //   children: [
    //     {
    //       type: 'group',
    //       label: 'Item 1',
    //       children: [
    //         {
    //           label: 'Option 1',
    //           key: 'setting:1',
    //         },
    //         {
    //           label: 'Option 2',
    //           key: 'setting:2',
    //         },
    //       ],
    //     },
    //     {
    //       type: 'divider',
    //       label: 'Item 2',
    //       children: [
    //         {
    //           label: 'Option 3',
    //           key: 'setting:3',
    //         },
    //         {
    //           label: 'Option 4',
    //           key: 'setting:4',
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   label: '刪除使用者',
    //   key: 'UsersDelete',
    //   icon: <MinusCircleOutlined />,
    //   // disabled: true,
    // }
  ];
  // const getMenuItem = ( label, key, icon, children, type ) => {
  //   return {
  //     key,
  //     icon,
  //     children,
  //     label,
  //     type,
  //   }
  // }

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || '';
    setSearchString(val);
  };
  const onSearch = () => {
    console.log(currentPage);
    requestUsers();
  };

  /*** 查詢Users ***/
  const requestUsers = async () => {
    const url =
      apiURL +
      '/chat-users?' +
      new URLSearchParams({
        filter: searchString,
        limit: numberPerPage,
        offset: currentPage - 1,
      });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        throw new Error('RequestUsers failed');
      });
    console.log(response);
    setUsersDataArray(response);
  };

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
  };
  const clickUsersMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || '';
    if (key.includes('UsersRead')) {
      setUsersCRUD('UsersRead');
    } else if (key.includes('UsersAdd')) {
      setUsersCRUD('UsersAdd');
    } else if (key.includes('UsersUpdate')) {
      setUsersCRUD('UsersUpdate');
    } else if (key.includes('UsersDelete')) {
      setUsersCRUD('UsersDelete');
    } else {
      setUsersCRUD('');
    }
  };

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 分頁顯示設定 ***/
  const data = [
    // 樣板資料
    {
      _id: '001',
      name: '測試使用者1',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '002',
      name: '測試使用者2',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '003',
      name: '測試使用者3',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
  ];

  return (
    <Layout className={style.usersLayout}>
      <Menu
        style={{ borderLeft: '1px solid white' }}
        theme="dark"
        onClick={clickUsersMenuItem}
        selectedKeys={[usersCRUD]}
        mode="horizontal"
        items={menuItems}
      />
      <Content className={style.usersContent}>
        {usersCRUD === 'UsersRead' ? (
          <Content id="UsersRead">
            <UsersRead></UsersRead>
          </Content>
        ) : usersCRUD === 'UsersAdd' ? (
          <Content id="UsersAdd">
            <UsersAdd id="UsersAdd"></UsersAdd>
          </Content>
        ) : (
          <></>
        )}
      </Content>
    </Layout>
  );
}

export default Users;
