/* React */
// import React from 'react';

/* Components */
import RichMenuItem from './RichMenuItem';
/* Styles */
import style from './RichMenuList.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function RichMenuList({ richMenusData, inDeleteMode }) {
  /* JSX ---------------------------------------------------------- */
  return (
    <ul className={style['rich-menu-list__container']}>
      {richMenusData.map((richMenuData) => (
        <RichMenuItem
          key={richMenuData.lineRichMenuId}
          richMenuData={richMenuData}
          inDeleteMode={inDeleteMode}
        />
      ))}
    </ul>
  );
}
