import { httpRequest } from '../../../utils/httpRequest';

/* //////////////////////////////////////////////////////////////// */
export const deleteRichMenu = async ({ lineRichMenuId }) => {
  const res = await httpRequest(`/rich-menu/${lineRichMenuId}`, {
    method: 'DELETE',
  });

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};
