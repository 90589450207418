/* React */
import { useEffect, useMemo, useRef, useState } from 'react';
/* ANTD */
import { Button } from 'antd';
/* Calendar */
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

/* Hooks */
import { useGetMessages } from './hooks/useQueryMessage';
/* Components */
import { LoadingSpinner, Modal } from '../../../../../Component/ui';
import MessageDetailModal from './Components/MessagesTable/MessageDetailModal';
/* Styles */
import './LineMessageCalendar.css';

/* //////////////////////////////////////////////////////////////// */
export default function LineMessageCalendar() {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [currentId, setCurrentId] = useState(undefined);
  const [lastClickTime, setLastClickTime] = useState(new Date());

  const { data, isLoading } = useGetMessages({
    messagePerPage: 100,
    year,
    month,
  });

  const buttonEl = useRef(null);

  // Filter display messages
  const messagesEvent = useMemo(() => {
    if (!data) return [];

    return data.thisPageMessageData.reduce((eventArr, message) => {
      console.log(message);

      if (
        message.isSendImmediately ||
        message.sendingStatus === 'cancel' ||
        message.approvingStatus === 'reject'
      )
        return eventArr;

      eventArr.push({
        _id: message._id,
        title: message.purpose,
        start: message.estimatedSendingTime,
        textColor: 'white',
        className: `event__blue`, // [NOTE] for specify color depends on message sendMethod or is send or not
      });

      return eventArr;
    }, []);
  }, [data]);

  // if id or click time change click button
  useEffect(() => {
    if (!currentId) return;

    buttonEl.current?.click();
  }, [currentId, lastClickTime]);

  // open detail modal
  const handleEventClick = (d) => {
    const clickEventId = d.el.fcSeg.eventRange.def.extendedProps._id;
    setCurrentId(clickEventId);
    setLastClickTime(new Date());
  };
  // Set year month for message query
  const handleDisplayMonthChange = (dateInfo) => {
    const viewType = dateInfo.view.type;
    const curStartMonth = new Date(dateInfo.start).getMonth() + 1;
    const curStartYear = new Date(dateInfo.start).getFullYear();
    const curStartDate = new Date(dateInfo.start).getDate();
    if (viewType !== 'dayGridMonth' || curStartDate === 1) {
      setYear(curStartYear);
      setMonth(curStartMonth);
      return;
    }

    setYear(curStartMonth + 1 > 12 ? curStartYear + 1 : curStartYear);
    setMonth(curStartMonth + 1 > 12 ? 1 : curStartMonth + 1);
  };

  /* JSX ---------------------------------------------------------- */
  if (isLoading) return <LoadingSpinner fullscreen />;

  return (
    <>
      <Modal>
        <div>
          <Modal.Content name={currentId}>
            <MessageDetailModal lineMessageId={currentId} />
          </Modal.Content>
        </div>

        <Modal.OpenButton opens={currentId}>
          <Button ref={buttonEl} style={{ display: 'none' }} />
        </Modal.OpenButton>
      </Modal>

      <section className="line-message__calendar__container">
        <FullCalendar
          height="600px"
          plugins={[dayGridPlugin, timeGridPlugin]}
          // initialView="timeGridWeek" // initial in week view
          datesSet={handleDisplayMonthChange}
          events={messagesEvent}
          eventClick={handleEventClick}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          buttonText={{
            today: '今日',
            month: '月',
            week: '周',
            day: '日',
          }}
          locale="zh-TW"
        />
      </section>
    </>
  );
}
