/* React */
// import React from 'react';
/* ANTD */
import { Button } from 'antd';

/* Hooks */
import usePaginationContext from '../../hooks/usePaginationContext';
import useRichMenuEditorContext from '../../hooks/useRichMenuEditorContext';

/* //////////////////////////////////////////////////////////////// */
export default function GoBackButton() {
  const { onUnEdit } = usePaginationContext();
  const { onResetContext } = useRichMenuEditorContext();

  const handleButtonClick = () => {
    onUnEdit();
    onResetContext();
  };

  /* JSX ---------------------------------------------------------- */
  return <Button onClick={handleButtonClick}>取消</Button>;
}
