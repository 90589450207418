import React, { useEffect, useState, useRef } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import {
  Layout,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Space,
  DatePicker,
  TimePicker,
  Button,
  Dropdown,
  Form,
  List,
  Collapse,
  Upload,
} from 'antd';
import {
  DownOutlined,
  EyeOutlined,
  EditOutlined,
  RollbackOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import style from './styles/WpSetTeachingPlan.module.css';
import '../styles/WordpressAnt.css';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

/*** Import Wordpress Component ***/
// import WordpressCastInterface from "./WordpressComponents/WordpressCastInterface"

function WpSetTeachingPlan() {
  const { Content } = Layout;
  const { Panel } = Collapse;
  const [btnLoadings, setBtnLoadings] = useState([]);
  const [sendingTime, setSendingTime] = useState(0);
  const [showInputNumber, setShowInputNumber] = useState(false);
  const [checkList, setCheckList] = useState([{}]);
  const [dropdownCategoryTextState, setDropdownCategoryTextState] =
    useState('請選擇教案類別'); // 教案類別
  useEffect(() => {
    console.log(dropdownCategoryTextState);
  }, [dropdownCategoryTextState]);
  // const [dropdownItemUnitTextState, setDropdownItemUnitTextState] = useState("請選擇教具單位"); // 教案類別
  const [afterFilterShow, setAfterFilterShow] = useState({ display: 'block' }); // 篩選完顯示區塊
  const [showAdviceReport, setShowAdviceReport] = useState(false);
  const [defaultSecCount, setDefaultSecCount] = useState(8);
  const [addSecCount, setAddSecCount] = useState(0);
  const [addSectionArray, setAddSectionArray] = useState([]);
  const [addAbilitySectionArray, setAddAbilitySectionArray] = useState([]);
  const [addItemSectionArray, setAddItemSectionArray] = useState([]);
  const [addActivitySectionArray, setAddActivitySectionArray] = useState([]);
  const classSecRef = useRef(null);
  const [classDate, setClassDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [activityArray, setActivityArray] = useState([
    { id: '', location: '', startTime: '', endTime: '' },
  ]);
  const [filterFieldsOnChangeState, setFilterFieldsOnChangeState] = useState({
    pid: '',
    name: '',
    courseIntroduction: '',
    price: 0,
    teacher: '',
    maximum: 8,
    formal: 6,
    toExperience: 1,
    compensate: 1,
    locationTime: [],
    flag: 1,
    operator: '',
  }); // 篩選欄位onChange狀態
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState({
    pid: '',
    name: '',
    courseIntroduction: '',
    price: 0,
  }); // 更新欄位onChange狀態
  const [courseDataObj, setCourseDataObj] = useState({});
  const [locationTimeArray, setLocationTimeArray] = useState([]);
  const [courseDataArray, setCourseDataArray] = useState([]); // 教案資料
  const [courseDataArrayUnique, setCourseDataArrayUnique] = useState([]);
  useEffect(() => {
    console.log(courseDataArrayUnique);
  }, [courseDataArrayUnique]);
  const [courseDataArrayUniqueTest, setCourseDataArrayUniqueTest] = useState(
    []
  );
  const [courseSettingSuccess, setCourseSettingSuccess] = useState(false);
  const [courseDataArrayTest, setCourseDataArrayTest] = useState([
    {
      _id: '64911e87466aef5f4a19f998',
      pid: 'string',
      courseIntroduction: '教案介紹:Nestjs-API,MongoDB環境建置',
      formal: 6,
      toExperience: 2,
      compensate: 0,
      memo: '備註:',
      flag: 0,
      createdDate: '2023-06-20T03:35:25.980Z',
      __v: 0,
      student: [],
    },
    {
      _id: '64911eeb4ddde2728f9b4c7b',
      pid: 'course-110-0002',
      name: '教案名稱:Nestjs-API及MongoDB環境建置(第一章)',
      location: '捷運善導寺站-店1',
      startTime: '2023-06-19T09:00:01.481Z',
      endTime: '2023-06-19T09:30:01.481Z',
      flag: 0,
      createdDate: '2023-06-19T01:32:01.481Z',
      student: [],
      __v: 0,
    },
    {
      _id: '64911eeb4ddde2728f9b4c7b',
      pid: 'course-110-0002',
      name: '教案名稱:Nestjs-API及MongoDB環境建置(第一章)',
      location: '捷運善導寺站-店2',
      startTime: '2023-06-20T09:00:01.481Z',
      endTime: '2023-06-20T09:30:01.481Z',
      flag: 0,
      createdDate: '2023-06-19T01:32:01.481Z',
      student: [],
      __v: 0,
    },
    {
      _id: '64911eeb4ddde2728f9b4c7b',
      pid: 'course-110-0002',
      name: '教案名稱:Nestjs-API及MongoDB環境建置(第一章)',
      location: '捷運善導寺站-店3',
      startTime: '2023-06-21T09:00:01.481Z',
      endTime: '2023-06-21T09:30:01.481Z',
      flag: 0,
      createdDate: '2023-06-19T01:32:01.481Z',
      student: [],
      __v: 0,
    },
    {
      _id: '64911f5a466aef5f4a19f99b',
      pid: 'string',
      courseIntroduction: '教案介紹:Nestjs-API,MongoDB環境建置',
      formal: 6,
      toExperience: 2,
      compensate: 0,
      memo: '備註:',
      flag: 0,
      createdDate: '2023-06-20T03:35:25.980Z',
      __v: 0,
      student: [],
    },
  ]); // 教案資料

  /*** 教案類別(測試) ***/
  const teachingPlanCategoryArrayTest = [
    {
      label: '教案類別1',
      key: '教案類別1',
      onClick: ({ item, key, keyPath, domEvent }) => {
        setDropdownCategoryTextState(key);
      },
    },
    {
      label: '教案類別2',
      key: '教案類別2',
      onClick: ({ item, key, keyPath, domEvent }) => {
        setDropdownCategoryTextState(key);
      },
    },
    {
      label: '教案類別3',
      key: '教案類別3',
      onClick: ({ item, key, keyPath, domEvent }) => {
        setDropdownCategoryTextState(key);
      },
    },
    {
      label: '其他',
      key: '其他',
      onClick: ({ item, key, keyPath, domEvent }) => {
        setDropdownCategoryTextState(key);
      },
    },
  ];

  /*** initialize ***/
  const initialize = () => {
    setFilterFieldsOnChangeState({
      pid: '',
      name: '',
      courseIntroduction: '',
      price: 0,
      teacher: '',
      maximum: 8,
      formal: 6,
      toExperience: 1,
      compensate: 1,
      locationTime: [],
      flag: 1,
      operator: '',
    });
    setUpdateFieldsOnChangeState({
      pid: '',
      name: '',
      courseIntroduction: '',
      price: 0,
    });
    setLocationTimeArray([]);
    setCourseDataArrayUnique([]);
    setAddSectionArray([]);
    setClassDate(dayjs().format('YYYY-MM-DD'));
    setActivityArray([{ id: '', location: '', startTime: '', endTime: '' }]);
  };
  /*** CourseData去除有重複pid的obj ***/
  useEffect(() => {
    const makeUniqueArray = (arr, key) => {
      const uniqueObjects = {};
      return arr.reduce((result, obj) => {
        if (obj?.flag > -1) {
          // 非刪除項才做處理
          if (!uniqueObjects[obj[key]]) {
            uniqueObjects[obj[key]] = true;
            result.push(obj);
          }
        }
        return result;
      }, []);
    };
    const uniqueArray = makeUniqueArray(courseDataArray, 'pid');
    // const uniqueArrayTest = makeUniqueArray(courseDataArrayTest, 'pid');
    setCourseDataArrayUnique(uniqueArray);
    // setCourseDataArrayUniqueTest(uniqueArrayTest);
  }, [courseDataArray]);

  /*** 新增一個課堂object array ***/
  // useEffect(()=>{
  //     const targetKeys = ['_id', 'location', 'startTime', 'endTime'];  // 要匹配的特定键名
  //     const filteredObjectsTest = courseDataArrayTest.map(obj => {
  //         let newObj = {};
  //         targetKeys.forEach(key => {
  //             if (obj.hasOwnProperty(key)) {
  //                 newObj[key] = obj[key];
  //             }
  //         });
  //         return newObj;
  //     });
  //     setLocationTimeArray(filteredObjectsTest);
  // },[courseDataArrayTest]);
  useEffect(() => {
    console.log(locationTimeArray);
  }, [locationTimeArray]);
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項資料, 第n項是否可編輯]
  const wpApiURL = process.env.REACT_APP_WPAPIURL; // API URL
  const loginToken = window.localStorage.getItem('loginToken');
  // const [courseDataArray, setCourseDataArray] = useState([
  //     { _id: "", name: "", location: "", startTime: "", endTime: "" },
  // ]); // 教案資料
  useEffect(() => {
    if (
      filterFieldsOnChangeState?.maximum -
        filterFieldsOnChangeState?.toExperience -
        filterFieldsOnChangeState?.compensate <
      0
    ) {
      setFilterFieldsOnChangeState({
        ...filterFieldsOnChangeState,
        ['formal']: 0,
      });
    } else if (
      filterFieldsOnChangeState?.maximum -
        filterFieldsOnChangeState?.formal -
        filterFieldsOnChangeState?.compensate <
      0
    ) {
      setFilterFieldsOnChangeState({
        ...filterFieldsOnChangeState,
        ['toExperience']: 0,
      });
    } else if (
      filterFieldsOnChangeState?.maximum -
        filterFieldsOnChangeState?.formal -
        filterFieldsOnChangeState?.toExperience <
      0
    ) {
      setFilterFieldsOnChangeState({
        ...filterFieldsOnChangeState,
        ['compensate']: 0,
      });
    }
  }, []);

  /*** 查詢課堂 ***/
  const requestClass = async (e, id, name, location, startTime, endTime) => {
    e?.preventDefault();
    let url = '';
    if (!!id) {
      url = wpApiURL + `/kidproCourse/${id}`;
    } else {
      url = wpApiURL + '/kidproCourse/getCourseFindall';
    }
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        // alert(`教案查詢失敗！\n${err}`);
        throw new Error('Request Course failed');
      });
    console.log(response);
    // if( response.code === 200 ) {
    // const responseData = response?.data || [];
    // console.log(responseData);
    setCourseDataArray(response);
    setIsEditMode([-1, false]); // 返回非編輯模式

    const targetKeys = ['pid', 'location', 'startTime', 'endTime']; // 要匹配的特定键名
    const filteredObjects = response.map((obj) => {
      let newObj = {};
      if (obj?.flag > -1) {
        targetKeys.forEach((key) => {
          if (obj.hasOwnProperty(key)) {
            newObj[key] = obj[key];
          }
        });
      }
      return newObj;
    });
    setLocationTimeArray(filteredObjects);

    // }
  };
  useEffect(() => {
    requestClass();
  }, []);
  useEffect(() => {
    requestClass();
  }, [courseSettingSuccess]);

  /*** 新增教案能力 ***/
  // const addAbilities = (e) => {
  //     e?.preventDefault();
  //     setAddAbilitySectionArray([...addAbilitySectionArray, { id: Date.now() }]);
  // }

  /*** 新增教具 ***/
  const addItems = (e) => {
    e?.preventDefault();
    console.log(e);
    setAddItemSectionArray([...addItemSectionArray, { id: Date.now() }]);
  };

  /*** 新增教案活動 ***/
  const addActivities = (e) => {
    e?.preventDefault();
    setAddActivitySectionArray([
      ...addActivitySectionArray,
      { id: Date.now() },
    ]);
  };

  /*** 刪除教案能力 ***/
  // const removeAbility = (e, id) => {
  //     e?.preventDefault();
  //     const activityGroupSections = addAbilitySectionArray.filter((item) => item.id !== id);
  //     setAddAbilitySectionArray(activityGroupSections);
  // }

  /*** 刪除教具 ***/
  const removeItem = (e, id) => {
    e?.preventDefault();
    const activityGroupSections = addItemSectionArray.filter(
      (item) => item.id !== id
    );
    setAddItemSectionArray(activityGroupSections);
  };

  /*** 刪除活動群組 ***/
  const removeActivity = (e, id) => {
    e?.preventDefault();
    const activityGroupSections = addActivitySectionArray.filter(
      (item) => item.id !== id
    );
    setAddActivitySectionArray(activityGroupSections);
  };

  useEffect(() => {
    console.log(addSecCount);
    console.log(addSectionArray);
  }, [addSecCount]);
  useEffect(() => {
    console.log(addSectionArray);
  }, [addSectionArray]);
  useEffect(() => {
    console.log(courseDataObj);
  }, [courseDataObj]);
  useEffect(() => {
    console.log(filterFieldsOnChangeState);
  }, [filterFieldsOnChangeState]);

  /*** 取得管理者資料 ***/
  const getAdminData = async () => {
    const decoded = await jwt_decode(loginToken);
    console.log(decoded);
    const id = (await decoded?.id) || '';
    const email = (await decoded?.email) || '';
    const atIndex = await email.indexOf('@');
    const name = (await atIndex) !== -1 ? email.substring(0, atIndex) : '';
    const data = { id, name, email };
    console.log(name);
    // setAdminData(data);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['operator']: await name,
    });
  };
  useEffect(() => {
    getAdminData();
  }, []);
  useEffect(() => {
    getAdminData();
  }, [loginToken]);
  /*** END 取得管理者資料 ***/

  // const items = [
  //     {
  //         label: 'RM301',
  //         key: 'RM301',
  //         onClick: ({ item, key, keyPath, domEvent }) => {
  //             console.log(key);
  //             setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["location"]:key });
  //             setDropdownTextState(key);
  //         },
  //     },
  //     {
  //         label: 'RM302',
  //         key: 'RM302',
  //         onClick: ({ item, key, keyPath, domEvent }) => {
  //             console.log(key);
  //             setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["location"]:key });
  //             setDropdownTextState(key);
  //         },
  //     },
  // ];

  const enterLoading = (index) => {
    setBtnLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
  };

  const endLoading = (index) => {
    setBtnLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  };

  const fieldsOnChange = (e, idx) => {
    e?.preventDefault();
    const fieldsId = e?.target?.id || '';
    const value = e?.target?.value || '';
    console.log(fieldsId, value);
    switch (fieldsId) {
      case 'teachingPlanCategory':
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['category']: value,
        });
        break;
      case 'teachingPlanName':
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['name']: value,
        });
        break;
      case 'teachingPlanActivity':
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['activity']: value,
        });
        break;
      case 'courseIntro':
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['courseIntroduction']: value,
        });
        break;
      case 'activityContent':
        const activityArrayNew = [...activityArray].map((object) => {
          if (!object.id) {
            // 只有預設空值的Object Element ID，將Object Element賦予id
            return {
              ...object,
              id: idx,
              location: value,
            };
          } else if (object.id === idx) {
            // Object Element ID符合目標，更新Object Element
            return {
              ...object,
              location: value,
            };
          } else return object; // Object Element ID不符合目標，不更動Object Element
        });
        setActivityArray(activityArrayNew);
        let flagObjHasSameId = false;
        for (let object of activityArrayNew) {
          if (object.id === idx) {
            flagObjHasSameId = true;
          }
        }
        if (!flagObjHasSameId) {
          setActivityArray((prevArray) => [
            ...prevArray,
            {
              id: idx,
              location: value,
            },
          ]);
        }
        break;
    }
  };

  const updateFieldsOnChange = (e, defVal) => {
    e?.preventDefault();
    const fieldsId = e?.target?.id || '';
    const value = e?.target?.value || '';
    console.log(fieldsId, value);
    switch (fieldsId) {
      case 'teachingPlanCategory':
        setUpdateFieldsOnChangeState({
          ...updateFieldsOnChangeState,
          ['category']: value,
        });
        break;
      case 'teachingPlanName':
        setUpdateFieldsOnChangeState({
          ...updateFieldsOnChangeState,
          ['name']: value,
        });
        break;
      case 'teachingPlanActivity':
        setUpdateFieldsOnChangeState({
          ...updateFieldsOnChangeState,
          ['activity']: value,
        });
        break;
      case 'courseIntro':
        setUpdateFieldsOnChangeState({
          ...updateFieldsOnChangeState,
          ['courseIntroduction']: value,
        });
        break;
    }
  };
  const inputNumberPriceOnChange = (value) => {
    console.log(value);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['price']: value,
    });
  };
  const inputNumberClassNumberOnChange = (value) => {
    console.log(value);
    // setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["numClasses"]:value});
    setDefaultSecCount(value);
  };
  const inputNumberMaximumOnChange = (value) => {
    console.log(value);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['maximum']: value,
    });
  };
  const inputNumberFormalOnChange = (value) => {
    console.log(value);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['formal']: value,
    });
  };
  const inputNumberExpOnChange = (value) => {
    console.log(value);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['toExperience']: value,
    });
  };
  const inputNumberCompensateOnChange = (value) => {
    console.log(value);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['compensate']: value,
    });
  };
  const radioOnChange = (e) => {
    console.log('radio checked', e.target.value);
    setSendingTime(e.target.value);
  };

  const datePickerOnChange = (date, dateString, id) => {
    console.log(date, dateString, id);
    setClassDate(dateString);
    const timeString = dateString + ' 00:00:00 +0800';
    const dateData = new Date(timeString);
    console.log(dateData);
    const activityArrayNew = [...activityArray].map((object) => {
      if (!object.id) {
        // 只有預設空值的Object Element ID，將Object Element賦予id
        return {
          ...object,
          id: id,
          startTime: dateData,
          endTime: dateData,
        };
      } else if (object.id === id) {
        // Object Element ID符合目標，更新Object Element
        return {
          ...object,
          startTime: dateData,
          endTime: dateData,
        };
      } else return object; // Object Element ID不符合目標，不更動Object Element
    });
    setActivityArray(activityArrayNew);
    let flagObjHasSameId = false;
    for (let object of activityArrayNew) {
      if (object.id === id) {
        flagObjHasSameId = true;
      }
    }
    if (!flagObjHasSameId) {
      setActivityArray((prevArray) => [
        ...prevArray,
        {
          id: id,
          startTime: dateData,
          endTime: dateData,
        },
      ]);
    }
  };
  useEffect(() => {
    console.log(activityArray);
  }, [activityArray]);

  const timePickerOnChange = (timeArray, timeStringArray, id) => {
    console.log(timeArray, timeStringArray, id);
    const startTimeString = `${classDate} ${timeStringArray[0]}:00 +0800`;
    const endTimeString = `${classDate} ${timeStringArray[1]}:00 +0800`;
    console.log(startTimeString, endTimeString);
    const startTimeDate = new Date(startTimeString);
    const endTimeDate = new Date(endTimeString);
    console.log(startTimeDate, endTimeDate);
    const activityArrayNew = [...activityArray].map((object) => {
      if (!object.id) {
        // 只有預設空值的Object Element ID，將Object Element賦予id
        return {
          ...object,
          id: id,
          startTime: startTimeDate,
          endTime: endTimeDate,
        };
      } else if (object.id === id) {
        // Object Element ID符合目標，更新Object Element
        return {
          ...object,
          startTime: startTimeDate,
          endTime: endTimeDate,
        };
      } else return object; // Object Element ID不符合目標，不更動Object Element
    });
    setActivityArray(activityArrayNew);
    let flagObjHasSameId = false;
    for (let object of activityArrayNew) {
      console.log(object.id);
      console.log(id);
      if (object.id === id) {
        flagObjHasSameId = true;
      }
    }
    if (!flagObjHasSameId) {
      setActivityArray((prevArray) => [
        ...prevArray,
        {
          id: id,
          startTime: startTimeDate,
          endTime: endTimeDate,
        },
      ]);
    }
  };
  useEffect(() => {
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['locationTime']: activityArray,
    });
  }, [activityArray]);

  const checkboxOnChange = (event) => {
    const id = event?.target?.id || '';
    const checked = event?.target?.checked || false;
    const checkElement = { id, checked };
    if (Object.values(checkList[0]).length > 0) {
      // CheckList 有值，取代或新增check資料
      let checkListArray = new Array();
      let hasSameId = false;
      checkListArray = checkList.map((obj) => {
        const objId = obj?.id || '';
        if (objId === id) {
          obj.checked = checked; // 取代check資料
          hasSameId = true;
        }
        return obj;
      });
      if (!hasSameId) {
        // 未比對到同ID的物件，新增check資料
        setCheckList((current) => [...current, checkElement]);
      } else {
        // 有比對到同ID的物件，取代原CheckList
        setCheckList(checkListArray);
      }
    } else {
      // 無值則新增check資料
      let checkListArray = new Array();
      checkListArray.push(checkElement);
      setCheckList(checkListArray);
    }
    let msgLimitIsChecked = false;
    for (let el of checkList) {
      // msgLimit前次設定的值
      if (Object.values(el).includes('msgLimit')) {
        msgLimitIsChecked = el?.checked || false;
      }
    }
    if ((id === 'msgLimit' && checked) || msgLimitIsChecked) {
      // msgLimit事件觸發設定的值或前次設定的值
      setShowInputNumber(true);
    } else {
      setShowInputNumber(false);
    }
  };
  useEffect(() => {
    console.log(checkList);
  }, [checkList]);

  /*** 設定Course ***/
  const setCourse = async (e) => {
    e?.preventDefault();
    // setCourseDataObj(filterFieldsOnChangeState);
    // setLocationTimeArray(filterFieldsOnChangeState?.locationTime||[]);
    // console.log(filterFieldsOnChangeState["name"])
    const url = wpApiURL + '/kidproCourse/addCourse';
    console.log(url);
    // console.log(searchString);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(filterFieldsOnChangeState),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      // .then((res) => res.json())
      .catch((err) => {
        alert(`教案新增失敗！\n${err}`);
        throw new Error('Add Course failed');
      });
    console.log(response);
    if (response.status === 201) {
      alert('教案設定成功！');
      // setCourseSettingSuccess(!courseSettingSuccess);
      setAfterFilterShow({ display: 'block' }); // 顯示設定完成後教案
    } else {
      // let responseMsg = response?.message || "";
      // responseMsg = responseMsg.replace(/[\[\]"]/g, '');
      alert(
        `教案設定失敗！\nError ${response.statusCode}：${
          response.error
        } (${JSON.stringify(response.message).replace(/[\[\]"]/g, ' ')})`
      );
      console.log(response);
    }
    requestClass();
    initialize();
  };
  /*** 更新Course ***/
  const updateCourse = async (e, pid) => {
    e?.preventDefault();
    const url = wpApiURL + '/kidproCourse/updateManyCourseNameByPid/' + pid;
    console.log(url);
    // console.log(searchString);
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(updateFieldsOnChangeState),
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`教案更新失敗！\n( Product ID : ${pid} )\n${err}`);
        throw new Error('Update Course failed');
      });
    console.log(response);
    if (response.code === 200) {
      alert(`教案更新成功！\n( Product ID : ${pid} )`);
      // setCourseSettingSuccess(!courseSettingSuccess);
      // setAfterFilterShow({"display":"block"});  // 顯示篩選後標籤
    } else {
      // let responseMsg = response?.message || "";
      // responseMsg = responseMsg.replace(/[\[\]"]/g, '');
      alert(
        `教案更新失敗！\n( Product ID : ${pid} )\nError ${response.code}：${response.error} (${response.message})`
      );
      console.log(response);
    }
    requestClass();
    initialize();
  };
  /*** 刪除Course ***/
  const deleteCourse = async (e, pid, name) => {
    e?.preventDefault();
    if (window.confirm(`確定要刪除教案【${name}】？ ( 商品ID：${pid} )`)) {
      const url = wpApiURL + '/kidproCourse/delManyCourseFlagByPid/' + pid;
      console.log(url);
      const data = { flag: -1 };
      // console.log(searchString);
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .catch((err) => {
          alert(`教案刪除失敗！\n( Product ID : ${pid} )\n${err}`);
          throw new Error('Delete Course failed');
        });
      console.log(response);
      if (response.code === 200) {
        alert(`教案刪除成功！\n( Product ID : ${pid} )`);
        // setCourseSettingSuccess(!courseSettingSuccess);
        // setAfterFilterShow({"display":"block"});  // 顯示篩選後標籤
      } else {
        // let responseMsg = response?.message || "";
        // responseMsg = responseMsg.replace(/[\[\]"]/g, '');
        alert(
          `教案刪除失敗！\n( Product ID : ${pid} )\nError ${response.statusCode}：${response.error} ( ${response.message} )`
        );
        console.log(response);
      }
    } else {
      return;
    }
    requestClass();
    initialize();
  };
  /*** 檢視教案 ***/
  const seeCourses = async (e, id, name) => {
    e.preventDefault();
    console.log(id);
    console.log(name);
    const attrObj = {
      key: id,
      label: name,
      // onClick: ({ item, key, keyPath, domEvent }) => {
      //   console.log(key);
      // }
    };
    // recentMessagesStore(attrObj);
    // const url = apiURL + "/chat-tags/";
    // console.log(url);
    // console.log(addFieldsOnChangeState);
    // const response = await fetch(
    //   url,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(addFieldsOnChangeState),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //     },
    //   }
    // )
    // .then((res) => res.json())
    // .catch((err) => {
    //   alert(`標籤新增失敗！\n${err}`);
    //   throw new Error("Add Messages failed");
    // });
    // console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // const responseStatus = response?.statusCode || "";
    // if( ! responseId ) {
    //   const responseErrorMsg = response?.message || "";
    //   if( responseStatus === 500 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else if( responseStatus === 400 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else {
    //     alert(`標籤新增失敗！\n${responseErrorMsg}`);
    //   }
    //   return false;
    // } else {
    //   const name = await response?.name || "";
    //   const category = await response?.category || "";
    //   const description = await response?.description || "";
    //   const priority = await response?.priority || 0;
    //   const data = { _id: responseId, name, category, description, priority };
    //   tagsDataArray.push(data);
    //   alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
    //   setAddFieldsOnChangeState({}); // 清空欄位
    //   requestMessages(name); // 查詢已新增的標籤
    //   setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
    // }
  };
  const courseDataObjTest = {
    pid: 'pcc-003',
    name: 'CourseName003',
    courseIntroduction: 'CourseIntro003',
    price: 1900,
    teacher: '',
    maximum: 8,
    formal: 5,
    toExperience: 1,
    compensate: 2,
    locationTime: [
      {
        id: 1687146193223,
        location: 'RM002',
        startTime: '2023-06-28T03:00:00.000Z',
        endTime: '2023-06-28T03:30:00.000Z',
      },
      {
        id: 1687146222771,
        location: 'RM003',
        startTime: '2023-06-29T05:00:00.000Z',
        endTime: '2023-06-29T05:30:00.000Z',
      },
    ],
    flag: 1,
    operator: 'maple.snow462',
  };
  const locationTimeArrayTest = [
    {
      id: 1687146193223,
      location: 'RM002',
      startTime: '2023-06-28T03:00:00.000Z',
      endTime: '2023-06-28T03:30:00.000Z',
    },
    {
      id: 1687146222771,
      location: 'RM003',
      startTime: '2023-06-29T05:00:00.000Z',
      endTime: '2023-06-29T05:30:00.000Z',
    },
  ];

  const text = `
        A dog is a type of domesticated animal.
        Known for its loyalty and faithfulness,
        it can be found as a welcome guest in many households across the world.
        `;

  const items2 = [
    {
      key: '1',
      header: 'This is panel header 1',
      content: <p>{text}</p>,
    },
    {
      key: '2',
      header: 'This is panel header 2',
      content: <p>{text}</p>,
    },
    {
      key: '3',
      header: 'This is panel header 3',
      content: <p>{text}</p>,
    },
  ];
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <Layout id={'WpSetTeachingPlanLayout'}>
      <Form
        id={'WpSetTeachingPlanForm'}
        className={style.wpSetTeachingPlanForm}
        onFinish={() => {}}
      >
        <Content className={`${style.wpSetTeachingPlanContent}`}>
          <section
            id="WpSetTeachingPlanSection"
            className={`${style.wpSetTeachingPlanSection} ${style.wpSetTeachingPlanSectionTitle}`}
          >
            <h4 className={style.wpSetTeachingPlanH4}>教案設定</h4>
          </section>
          <section
            id="WpSetTeachingPlanSection"
            className={`${style.wpSetTeachingPlanSection} ${style.wpSetTeachingPlanSectionCourseIdName}`}
          >
            <Form.Item
              className={style.wpSetTeachingPlanFormItem}
              name="teachingPlanCategory"
            >
              <div
                className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputCategory}`}
              >
                <Space
                  className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputCategory}`}
                >
                  <h6
                    className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                  >
                    教案類別
                  </h6>
                  <Dropdown
                    id="teachingPlanCategory"
                    className={`${style.wpSetTeachingPlanSendBtn} ${style.wpSetTeachingPlanFlex2}  ${style.wpSetTeachingPlanDropdownCategory}`}
                    menu={{ items: teachingPlanCategoryArrayTest }}
                    placement="bottom"
                    // onOpenChange={(open)=>console.log(open)}
                  >
                    <Button size="middle">
                      <Space
                        className={`${style.wpSetTeachingPlanDropdownBtnSpace}`}
                      >
                        {!!dropdownCategoryTextState
                          ? dropdownCategoryTextState
                          : '請選擇教案類別'}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Space>
                {dropdownCategoryTextState === '其他' ? (
                  <Space className={`${style.wpSetTeachingPlanSpaceInput}`}>
                    <h6
                      className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                    >
                      　　　　
                    </h6>
                    <Input
                      id="teachingPlanCategoryOther"
                      className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputCategory} ${style.wpSetTeachingPlanFlex2}`}
                      placeholder="請輸入新的教案類別名稱"
                      allowClear
                      size="middle"
                      onChange={(e) => fieldsOnChange(e)}
                    />
                  </Space>
                ) : (
                  <></>
                )}
              </div>
            </Form.Item>
            {/* <Form.Item className={style.wpSetTeachingPlanFormItem} name="teachingPlanCategoryAdvice">
                            <Button className={style.lineBroadcastSendBtn} size="small" type="primary" 
                                onClick={(e)=>{
                                }}>
                                <Space className={style.wpSetTeachingPlanSpaceUpdateBtnText}>
                                    設定此教案類別之報告建議
                                </Space>
                            </Button>   
                        </Form.Item>                            */}
            <Form.Item
              className={style.wpSetTeachingPlanFormItem}
              name="teachingPlanName"
            >
              <Space className={`${style.wpSetTeachingPlanSpaceInput}`}>
                <h6
                  className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                >
                  教案名稱
                </h6>
                <div
                  className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputName}`}
                >
                  <Input
                    id="teachingPlanName"
                    className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputId} ${style.wpSetTeachingPlanFlex2}`}
                    placeholder="請輸入教案名稱"
                    allowClear
                    size="middle"
                    onChange={(e) => fieldsOnChange(e)}
                  />
                </div>
              </Space>
            </Form.Item>
            <Form.Item
              className={style.wpSetTeachingPlanFormItem}
              name="teachingPlanKidCount"
            >
              <Space className={`${style.wpSetTeachingPlanSpaceInput}`}>
                <h6
                  className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                >
                  學生數量
                </h6>
                <div
                  className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputName}`}
                >
                  <InputNumber
                    id="teachingPlanKidCount"
                    className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputKidCount} ${style.wpSetTeachingPlanFlex2}`}
                    // placeholder="0"
                    allowClear
                    size="small"
                    onChange={(e) => fieldsOnChange(e)}
                  />
                </div>
              </Space>
            </Form.Item>
            <Form.Item
              className={style.wpSetTeachingPlanFormItem}
              name="teachingPlanPeriod"
            >
              <Space
                className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputPeriod}`}
              >
                <h6
                  className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                >
                  教案時長
                </h6>
                <div
                  className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputName}`}
                >
                  <InputNumber
                    id="teachingPlanPeriod"
                    className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputPeriod} ${style.wpSetTeachingPlanFlex2}`}
                    // placeholder="0"
                    allowClear
                    size="small"
                    onChange={(e) => fieldsOnChange(e)}
                    addonAfter={<span>分鐘</span>}
                  />
                </div>
              </Space>
            </Form.Item>
          </section>
          {/* <section id="WpSetTeachingPlanOuterSectionAddAbility" className={`${style.wpSetTeachingPlanOuterSection} ${style.wpSetTeachingPlanOuterSectionAddAbility}`}>
                    { addAbilitySectionArray.map((idItem, i) => {
                        return(
                            <section key={idItem.id} id="WpSetTeachingPlanSectionAddAbility" className={`${style.wpSetTeachingPlanSection} ${style.wpSetTeachingPlanSectionAddAbility}`}>
                                <div id={`class-${idItem.id}`} className={`${style.wpSetTeachingPlanDivAbility}`}>
                                    <div className={style.wpSetTeachingPlanDivDeleteOutlined}>
                                        <DeleteOutlined className={style.wpSetTeachingPlanDelIcon} onClick={(e)=>{
                                                                                                            removeAbility(e, idItem.id);
                                                                                                        }}/>
                                    </div>
                                    <Form.Item className={style.wpSetTeachingPlanFormItem} name="teachingPlanAbility">
                                        <Space className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputName}`}>
                                            <h6 className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}>重點能力</h6>
                                            <div className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputPlace}`}>
                                                <Input
                                                    id={`teachingPlanAbility`}
                                                    className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputPlace} ${style.wpSetTeachingPlanFlex2}`} 
                                                    placeholder="請輸入本教案要培養的重點能力" 
                                                    allowClear size="middle" 
                                                    onChange={(e)=>fieldsOnChange(e, idItem.id)}
                                                />
                                            </div>
                                        </Space>
                                    </Form.Item>
                                </div>                  
                            </section>
                        ) 
                    })}
                    </section> */}
          <section
            id="WpSetTeachingPlanOuterSectionAddItem"
            className={`${style.wpSetTeachingPlanOuterSection} ${style.wpSetTeachingPlanOuterSectionAddItem}`}
          >
            {addItemSectionArray.map((idItem, i) => {
              return (
                <section
                  key={idItem.id}
                  id="WpSetTeachingPlanSectionAddItem"
                  className={`${style.wpSetTeachingPlanSection} ${style.wpSetTeachingPlanSectionAddItem}`}
                >
                  <div
                    id={`class-${idItem.id}`}
                    className={`${style.wpSetTeachingPlanDivItem}`}
                  >
                    <div className={style.wpSetTeachingPlanDivDeleteOutlined}>
                      <DeleteOutlined
                        className={style.wpSetTeachingPlanDelIcon}
                        onClick={(e) => {
                          removeItem(e, idItem.id);
                        }}
                      />
                    </div>
                    <Form.Item
                      className={`${style.wpSetTeachingPlanFormItem}`}
                      name="teachingPlanItemName"
                    >
                      <Space
                        className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputItem}`}
                      >
                        <h6
                          className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                        >
                          教具名稱
                        </h6>
                        <div
                          className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputItemName}`}
                        >
                          <Input
                            id={`teachingPlanItemName`}
                            className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputItem} ${style.wpSetTeachingPlanFlex2}`}
                            placeholder="請輸入教具名稱"
                            allowClear
                            size="middle"
                            onChange={(e) => fieldsOnChange(e, idItem.id)}
                          />
                        </div>
                      </Space>
                    </Form.Item>
                    <Form.Item
                      className={style.wpSetTeachingPlanFormItem}
                      name="teachingPlanItemCount"
                    >
                      <Space
                        className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputItem}`}
                      >
                        <h6
                          className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                        >
                          教具數量
                        </h6>
                        <div
                          className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputItemCount}`}
                        >
                          <Input
                            id={`teachingPlanItemCount`}
                            className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputItem} ${style.wpSetTeachingPlanFlex2}`}
                            placeholder="請輸入教具數量"
                            allowClear
                            size="middle"
                            onChange={(e) => fieldsOnChange(e, idItem.id)}
                          />
                        </div>
                      </Space>
                    </Form.Item>
                    <Form.Item
                      className={style.wpSetTeachingPlanFormItem}
                      name="teachingPlanItemUnit"
                    >
                      <Space
                        className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputItem}`}
                      >
                        <h6
                          className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                        >
                          單位名稱
                        </h6>
                        <div
                          className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputItemUnit}`}
                        >
                          <Input
                            id={`teachingPlanItemUnit`}
                            className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputItem} ${style.wpSetTeachingPlanFlex2}`}
                            placeholder="請輸入教具單位"
                            allowClear
                            size="middle"
                            onChange={(e) => fieldsOnChange(e, idItem.id)}
                          />
                        </div>
                      </Space>
                    </Form.Item>
                    <Form.Item
                      className={style.wpSetTeachingPlanFormItem}
                      name="teachingPlanItemImage"
                    >
                      <Space
                        className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputItem}`}
                      >
                        <h6
                          className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                        >
                          教具圖片
                        </h6>
                        <div
                          className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputItemImage}`}
                        >
                          <Input
                            id={`teachingPlanItemImage`}
                            className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputItem} ${style.wpSetTeachingPlanFlex2}`}
                            placeholder="請輸入教具圖片URL"
                            allowClear
                            size="middle"
                            onChange={(e) => fieldsOnChange(e, idItem.id)}
                          />
                        </div>
                      </Space>
                    </Form.Item>
                    <Form.Item
                      className={style.wpSetTeachingPlanFormItem}
                      name="teachingPlanItemNote"
                    >
                      <Space
                        className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputItem}`}
                      >
                        <h6
                          className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                        >
                          注意事項
                        </h6>
                        <div
                          className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputItemNote}`}
                        >
                          <Input
                            id={`teachingPlanItemNote`}
                            className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputItem} ${style.wpSetTeachingPlanFlex2}`}
                            placeholder="請輸入注意事項"
                            allowClear
                            size="middle"
                            onChange={(e) => fieldsOnChange(e, idItem.id)}
                          />
                        </div>
                      </Space>
                    </Form.Item>
                  </div>
                </section>
              );
            })}
          </section>
          <section
            id="WpSetTeachingPlanOuterSectionAddActivity"
            className={`${style.wpSetTeachingPlanOuterSection} ${style.wpSetTeachingPlanOuterSectionAddActivity}`}
          >
            {addActivitySectionArray.map((idItem, i) => {
              return (
                <section
                  key={idItem.id}
                  id="WpSetTeachingPlanSectionAddActivity"
                  className={`${style.wpSetTeachingPlanSection} ${style.wpSetTeachingPlanSectionAddActivity}`}
                >
                  <div
                    id={`class-${idItem.id}`}
                    className={`${style.wpSetTeachingPlanDivActivity}`}
                  >
                    <div className={style.wpSetTeachingPlanDivDeleteOutlined}>
                      <DeleteOutlined
                        className={style.wpSetTeachingPlanDelIcon}
                        onClick={(e) => {
                          removeActivity(e, idItem.id);
                        }}
                      />
                    </div>
                    <Form.Item
                      className={style.wpSetTeachingPlanFormItem}
                      name="teachingPlanActivity"
                    >
                      <Space
                        className={`${style.wpSetTeachingPlanSpaceInput} ${style.wpSetTeachingPlanSpaceInputActivity}`}
                      >
                        <h6
                          className={`${style.wpSetTeachingPlanH6} ${style.wpSetTeachingPlanFlex1}`}
                        >
                          活動內容
                        </h6>
                        <div
                          className={`${style.wpSetTeachingPlanDivInput} ${style.wpSetTeachingPlanDivInputActivity}`}
                        >
                          <Input.TextArea
                            id={`teachingPlanActivity`}
                            className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputActivity} ${style.wpSetTeachingPlanFlex2}`}
                            placeholder="請輸入教案活動內容"
                            allowClear
                            size="middle"
                            onChange={(e) => fieldsOnChange(e, idItem.id)}
                          />
                        </div>
                      </Space>
                    </Form.Item>
                  </div>
                </section>
              );
            })}
          </section>
          <section
            id="WpSetTeachingPlanSection"
            className={`${style.wpSetTeachingPlanSection} ${style.wpSetTeachingPlanBtnSection}`}
          >
            {/* <Form.Item className={`${style.wpSetTeachingPlanFormItem} ${style.wpSetTeachingPlanFormItemSendBtn}`} name="addAbilities">
                            <Space className={style.wpSetTeachingPlanSpaceSendBtn} >
                                <Button className={style.lineBroadcastSendBtn} type="primary" 
                                    // loading={btnLoadings[0]} 
                                    onClick={(e) => addAbilities(e)}>
                                    <Space>
                                        新增重點能力
                                    </Space>
                                </Button>    
                            </Space>
                        </Form.Item>     */}
            <Form.Item
              className={`${style.wpSetTeachingPlanFormItem} ${style.wpSetTeachingPlanFormItemSendBtn}`}
              name="addItems"
            >
              <Space className={style.wpSetTeachingPlanSpaceSendBtn}>
                <Button
                  className={style.lineBroadcastSendBtn}
                  type="primary"
                  // loading={btnLoadings[0]}
                  onClick={(e) => addItems(e)}
                >
                  <Space>新增教具</Space>
                </Button>
              </Space>
            </Form.Item>
            <Form.Item
              className={`${style.wpSetTeachingPlanFormItem} ${style.wpSetTeachingPlanFormItemSendBtn}`}
              name="addActivities"
            >
              <Space className={style.wpSetTeachingPlanSpaceSendBtn}>
                <Button
                  className={style.lineBroadcastSendBtn}
                  type="primary"
                  // loading={btnLoadings[0]}
                  onClick={(e) => addActivities(e)}
                >
                  <Space>新增教案活動</Space>
                </Button>
              </Space>
            </Form.Item>
            <Form.Item
              className={`${style.wpSetTeachingPlanFormItem} ${style.wpSetTeachingPlanFormItemSendBtn}`}
              name="setAdviceReport"
            >
              <Space className={style.wpSetTeachingPlanSpaceSendBtn}>
                <Button
                  className={style.lineBroadcastSendBtn}
                  type="primary"
                  // loading={btnLoadings[0]}
                  onClick={(e) => {
                    setShowAdviceReport(true);
                  }}
                >
                  <Space>設定建議報告</Space>
                </Button>
              </Space>
            </Form.Item>
            <Form.Item
              className={`${style.wpSetTeachingPlanFormItem} ${style.wpSetTeachingPlanFormItemSendBtn}`}
              name="setTeachingPlan"
            >
              <Space className={style.wpSetTeachingPlanSpaceSendBtn}>
                <Button
                  className={style.lineBroadcastSendBtn}
                  type="primary"
                  // loading={btnLoadings[1]}
                  onClick={(e) => setCourse(e)}
                >
                  <Space>完成設定</Space>
                </Button>
              </Space>
            </Form.Item>
          </section>
        </Content>
      </Form>
      <Content
        className={`${style.wpSetTeachingPlanFilterConsultContent} ${style.wpSetTeachingPlanFilterConsultContentQuery}`}
        style={afterFilterShow}
      >
        <section
          id={'WpSetTeachingPlanFilterConsultSearchSec'}
          className={style.wpSetTeachingPlanFilterConsultSearchSec}
        >
          <div className={style.wpSetTeachingPlanFilterConsultTitle}>
            <h5>已新增教案：</h5>
          </div>
          <List
            className={style.wpSetTeachingPlanFilterConsultList}
            locale={{}}
            dataSource={courseDataArrayUnique}
            renderItem={(item, index) =>
              item?.flag > -1 &&
              Object.keys(courseDataArray[0]).includes('_id') &&
              isEditMode[0] === index &&
              isEditMode[1] ? (
                <List.Item>
                  <List.Item.Meta
                    className={style.wpSetTeachingPlanFilterConsultListItemMeta}
                    title={
                      <>
                        <div
                          className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleDiv} ${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleDivUpdate}`}
                        >
                          <div
                            className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDiv} ${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDivUpdate}`}
                          >
                            <div
                              className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionItem} ${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitle}`}
                            >
                              <span
                                className={
                                  style.wpSetTeachingPlanFilterConsultCourseDescriptionName
                                }
                              >
                                {'教案類別：'}
                              </span>
                              <span
                                className={
                                  style.wpSetTeachingPlanFilterConsultCourseDescriptionText
                                }
                              >
                                {item?.pid}
                              </span>
                            </div>
                            <div
                              className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionItem} ${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitle}`}
                            >
                              <span
                                className={
                                  style.wpSetTeachingPlanFilterConsultCourseDescriptionName
                                }
                              >
                                {'教案名稱：'}
                              </span>
                              <Input
                                id={`courseName`}
                                className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputName} ${style.wpSetTeachingPlanFlex2}`}
                                placeholder="請輸入教案名稱"
                                defaultValue={item?.name}
                                allowClear
                                size="middle"
                                onChange={(e) =>
                                  updateFieldsOnChange(e, item?.name)
                                }
                              />
                              <Space
                                className={`${style.wpSetTeachingPlanSpaceUpdateBtn}`}
                                direction="vertical"
                              >
                                <Button
                                  className={style.lineBroadcastSendBtn}
                                  size="small"
                                  type="primary"
                                  onClick={(e) => {
                                    updateCourse(e, item?.pid);
                                  }}
                                >
                                  <Space
                                    className={
                                      style.wpSetTeachingPlanSpaceUpdateBtnText
                                    }
                                  >
                                    儲存更新
                                  </Space>
                                </Button>
                              </Space>
                            </div>
                          </div>
                          <div
                            className={
                              style.wpSetTeachingPlanFilterConsultIconDiv
                            }
                          >
                            <RollbackOutlined
                              className={
                                style.wpSetTeachingPlanFilterConsultIcon
                              }
                              onClick={(e) => {
                                setIsEditMode([-1, false]);
                              }}
                            />
                            <DeleteOutlined
                              className={
                                style.wpSetTeachingPlanFilterConsultIcon
                              }
                              onClick={(e) => {
                                deleteCourse(e, item?.pid, item?.name);
                                setIsEditMode([-1, false]);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                    description={
                      <Collapse onChange={onChange}>
                        <Panel
                          className={
                            style.wpSetTeachingPlanFilterConsultCourseDescriptionPanel
                          }
                          header={'活動'}
                          key={item?._id}
                        >
                          {locationTimeArray.map((item2) => {
                            if (item?.pid === item2?.pid) {
                              return (
                                <div
                                  className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionDiv} ${style.wpSetTeachingPlanFilterConsultCourseDescriptionQueryDiv}`}
                                >
                                  <div
                                    className={
                                      style.wpSetTeachingPlanFilterConsultCourseDescriptionItem
                                    }
                                  >
                                    <span
                                      className={
                                        style.wpSetTeachingPlanFilterConsultCourseDescriptionLabel
                                      }
                                    >
                                      {'活動內容：'}
                                    </span>
                                    <span
                                      className={
                                        style.wpSetTeachingPlanFilterConsultCourseDescriptionText
                                      }
                                    >
                                      {item2?.location}
                                    </span>
                                  </div>
                                  {/* <div className={style.wpSetTeachingPlanFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetTeachingPlanFilterConsultCourseDescriptionLabel}>{"開始時間："}</span>
                                                                        <span className={style.wpSetTeachingPlanFilterConsultCourseDescriptionText}>{dayjs(item2?.startTime).format("YYYY-MM-DD HH:mm")}</span>
                                                                    </div>
                                                                    <div className={style.wpSetTeachingPlanFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetTeachingPlanFilterConsultCourseDescriptionLabel}>{"結束時間："}</span>
                                                                        <span className={style.wpSetTeachingPlanFilterConsultCourseDescriptionText}>{dayjs(item2?.endTime).format("YYYY-MM-DD HH:mm")}</span>
                                                                    </div> */}
                                </div>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </Panel>
                      </Collapse>
                    }
                  />
                </List.Item>
              ) : item?.flag > -1 &&
                Object.keys(courseDataArray[0]).includes('_id') ? (
                <List.Item>
                  <List.Item.Meta
                    className={style.wpSetTeachingPlanFilterConsultListItemMeta}
                    title={
                      <>
                        <div
                          className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleDiv}`}
                        >
                          <div
                            className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDiv}`}
                          >
                            <div
                              className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionItem} ${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitle}`}
                            >
                              <span
                                className={
                                  style.wpSetTeachingPlanFilterConsultCourseDescriptionName
                                }
                              >
                                {'教案類別：'}
                              </span>
                              <span
                                className={
                                  style.wpSetTeachingPlanFilterConsultCourseDescriptionText
                                }
                              >
                                {item?.pid}
                              </span>
                            </div>
                            <div
                              className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionItem} ${style.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitle}`}
                            >
                              <span
                                className={
                                  style.wpSetTeachingPlanFilterConsultCourseDescriptionName
                                }
                              >
                                {'教案名稱：'}
                              </span>
                              <span
                                className={
                                  style.wpSetTeachingPlanFilterConsultCourseDescriptionText
                                }
                              >
                                {item?.name}
                              </span>
                            </div>
                          </div>
                          <div
                            className={
                              style.wpSetTeachingPlanFilterConsultIconDiv
                            }
                          >
                            <EditOutlined
                              className={
                                style.wpSetTeachingPlanFilterConsultIcon
                              }
                              onClick={(e) => {
                                setIsEditMode([index, true]);
                              }}
                            />
                            <DeleteOutlined
                              className={
                                style.wpSetTeachingPlanFilterConsultIcon
                              }
                              onClick={(e) => {
                                deleteCourse(e, item?.pid, item?.name);
                                setIsEditMode([-1, false]);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                    description={
                      <Collapse onChange={onChange}>
                        <Panel
                          className={
                            style.wpSetTeachingPlanFilterConsultCourseDescriptionPanel
                          }
                          header={'活動'}
                          key={item?._id}
                        >
                          {locationTimeArray.map((item2) => {
                            if (item?.pid === item2?.pid) {
                              return (
                                <div
                                  className={`${style.wpSetTeachingPlanFilterConsultCourseDescriptionDiv} ${style.wpSetTeachingPlanFilterConsultCourseDescriptionQueryDiv}`}
                                >
                                  <div
                                    className={
                                      style.wpSetTeachingPlanFilterConsultCourseDescriptionItem
                                    }
                                  >
                                    <span
                                      className={
                                        style.wpSetTeachingPlanFilterConsultCourseDescriptionLabel
                                      }
                                    >
                                      {'活動內容：'}
                                    </span>
                                    <span
                                      className={
                                        style.wpSetTeachingPlanFilterConsultCourseDescriptionText
                                      }
                                    >
                                      {item2?.location}
                                    </span>
                                  </div>
                                  {/* <div className={style.wpSetTeachingPlanFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetTeachingPlanFilterConsultCourseDescriptionLabel}>{"開始時間："}</span>
                                                                        <span className={style.wpSetTeachingPlanFilterConsultCourseDescriptionText}>{dayjs(item2?.startTime).format("YYYY-MM-DD HH:mm")}</span>
                                                                    </div>
                                                                    <div className={style.wpSetTeachingPlanFilterConsultCourseDescriptionItem}>
                                                                        <span className={style.wpSetTeachingPlanFilterConsultCourseDescriptionLabel}>{"結束時間："}</span>
                                                                        <span className={style.wpSetTeachingPlanFilterConsultCourseDescriptionText}>{dayjs(item2?.endTime).format("YYYY-MM-DD HH:mm")}</span>
                                                                    </div> */}
                                </div>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </Panel>
                      </Collapse>
                    }
                  />
                </List.Item>
              ) : (
                <></>
              )
            }
          />
        </section>
      </Content>
    </Layout>
  );
}

export default WpSetTeachingPlan;
