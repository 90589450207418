/* React */
// import React from 'react';
/* ANTD */
import { Descriptions } from 'antd';

/* Styles */
import style from './MessageListItem.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function MessageListItem({ message, index }) {
  const handleCopyFlexMessageContent = (message) => {
    navigator.clipboard.writeText(message);
  };

  /* JSX ---------------------------------------------------------- */
  if (message.type === 'text')
    return (
      <div className={style['message-item']}>
        <p>訊息{index + 1}</p>
        <Descriptions bordered size="small" column={1}>
          <Descriptions.Item label="類型">{message.type}</Descriptions.Item>
        </Descriptions>
        <div className={style['message-item__content']}>{message.text}</div>
      </div>
    );

  if (message.type === 'flex')
    return (
      <div className={style['message-item']}>
        <p>訊息{index + 1}</p>
        <Descriptions bordered size="small" column={1}>
          <Descriptions.Item label="類型">{message.type}</Descriptions.Item>
          <Descriptions.Item label="altText">
            {message.altText}
          </Descriptions.Item>
        </Descriptions>
        <div className={style['message-item__content']}>
          <pre>
            {JSON.stringify(message.contents, null, 2)}

            <button
              className={style['message-item__flex-message__btn']}
              onClick={() =>
                handleCopyFlexMessageContent(
                  JSON.stringify(message.contents, null, 2)
                )
              }
            >
              copy
            </button>
          </pre>
        </div>
      </div>
    );

  return null;
}
