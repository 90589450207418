/* React */
// import React from 'react';

/* Hooks */
import useRichMenuEditorContext from '../../hooks/useRichMenuEditorContext';
/* Style */
import style from './AreasPreview.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function AreasPreview() {
  const { areas, uploadImageDimensions, renderFigureSize } =
    useRichMenuEditorContext();

  console.log(areas);

  const heightCompressionRate =
    renderFigureSize.height / uploadImageDimensions.height;
  const widthCompressionRate =
    renderFigureSize.width / uploadImageDimensions.width;

  /* JSX ---------------------------------------------------------- */
  return (
    <div className={style['container']}>
      {areas.map((area, index) => {
        const { x, y, height, width } = area.bounds || {};

        if (
          x === undefined ||
          y === undefined ||
          height === undefined ||
          width === undefined
        )
          return null;

        return (
          <div
            className={style['preview-area']}
            style={{
              position: 'absolute',
              top: y * heightCompressionRate,
              left: x * widthCompressionRate,
              height: height * heightCompressionRate,
              width: width * widthCompressionRate,
            }}
          >
            <span className={style['preview-area__serial-number']}>
              {index + 1}
            </span>
          </div>
        );
      })}
    </div>
  );
}
