/* React */
// import React from 'react';
/* Query */
import { useQuery } from '@tanstack/react-query';

/* Services */
import { getTags } from '../services/tag';

/* //////////////////////////////////////////////////////////////// */
export function useGetTags() {
  const { data } = useQuery({ queryFn: getTags, queryKey: ['tag'] });

  return { data };
}
