import { httpRequest } from '../../../utils/httpRequest';

/* //////////////////////////////////////////////////////////////// */
export const getMessageById = async (lineMessageId) => {
  if (!lineMessageId) return null;

  const res = await httpRequest(`/send-message/${lineMessageId}`);

  const data = await res.json();

  if (!res.ok) throw data;

  const sendMessagesData = {
    purpose: data.message.purpose,
    sendMessages: data.message.sendMessages.map((sendMessage) => {
      if (sendMessage.type !== 'flex') return sendMessage;

      return {
        ...sendMessage,
        contents: JSON.stringify(sendMessage.contents, null, 2),
      };
    }),
    isSendImmediately: data.message.isSendImmediately,
    estimatedSendingTime: data.message.estimatedSendingTime || undefined,
  };

  const formattedData = { ...data, sendMessagesData };

  return formattedData;
};
