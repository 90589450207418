/* React */
// import React from 'react';
/* ANTD */
import { Form, InputNumber, Space } from 'antd';

/* //////////////////////////////////////////////////////////////// */
export default function InputBound({ field }) {
  /* JSX ---------------------------------------------------------- */
  return (
    <Space>
      <Form.Item
        name={[field.name, 'bounds', 'x']}
        rules={[{ required: true, message: 'x 不可為空' }]}
      >
        <InputNumber placeholder="x" />
      </Form.Item>
      <Form.Item
        name={[field.name, 'bounds', 'y']}
        rules={[{ required: true, message: 'y 不可為空' }]}
      >
        <InputNumber placeholder="y" />
      </Form.Item>
      <Form.Item
        name={[field.name, 'bounds', 'height']}
        rules={[{ required: true, message: '高度不可為空' }]}
      >
        <InputNumber placeholder="高度" />
      </Form.Item>
      <Form.Item
        name={[field.name, 'bounds', 'width']}
        rules={[{ required: true, message: '寬度不可為空' }]}
      >
        <InputNumber placeholder="寬度" />
      </Form.Item>
    </Space>
  );
}
