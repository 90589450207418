/* React */
import { useContext } from 'react';

/* Store */
import { RichMenuEditorContext } from '../store/RichMenuEditorContext';

/* //////////////////////////////////////////////////////////////// */
export default function useRichMenuEditorContext() {
  const RichMenuEditorCtx = useContext(RichMenuEditorContext);

  return RichMenuEditorCtx;
}
