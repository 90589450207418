/* React */
// import React from 'react';
/* ANTD */
import { Button, Form, Input, Radio } from 'antd';

/* Hooks */
import usePaginationContext from '../../hooks/usePaginationContext.js';
import useRichMenuEditorContext from '../../hooks/useRichMenuEditorContext.js';
import { useCreateRichMenu } from '../../hooks/useQueryRichMenu.js';
/* Components */
import { Label } from '../ui';
import RichMenuAreasFormItem from './RichMenuAreasFormItem';
import GoBackButton from './GoBackButton';
/* Styles */
import style from './RichMenuAreasForm.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function RichMenuAreasForm() {
  const { uploadImageFile, uploadImageDimensions, onAreasChange } =
    useRichMenuEditorContext();
  const { curEditingId, onUnEdit } = usePaginationContext();

  const [form] = Form.useForm();
  const { getFieldsValue } = form;

  const { onCreateRichMenu } = useCreateRichMenu(onUnEdit); // onUnEdit after create or edit navigation to console page

  const handleFormChange = () => {
    const { areas } = getFieldsValue();
    onAreasChange(areas || []);
  };
  const handleFormSubmit = (formData) => {
    console.log(formData);
    const uploadTemplateReqData = {
      size: {
        height: uploadImageDimensions.height,
        width: uploadImageDimensions.width,
      },
      name: formData.name,
      chatBarText: formData.chatBarText,
      selected: formData.selected,
      areas: formData.areas.map((area) => ({
        ...area,
        bounds: {
          x: Number(area.bounds.x),
          y: Number(area.bounds.y),
          height: Number(area.bounds.height),
          width: Number(area.bounds.width),
        },
      })),
    };
    console.log('uploadTemplateReqData: \n', uploadTemplateReqData);

    if (!curEditingId)
      onCreateRichMenu({
        createTemplateReq: uploadTemplateReqData,
        imageFile: uploadImageFile,
      });
  };

  /* JSX ---------------------------------------------------------- */
  if (!uploadImageFile) return <GoBackButton />;

  return (
    <Form
      className={style['form__container']}
      form={form}
      onFinish={handleFormSubmit}
      onChange={handleFormChange}
    >
      <div className={style['input-texts']}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: '名稱不可為空' }]}
        >
          <Input placeholder="Rich Menu 名稱" maxLength={300} />
        </Form.Item>

        <Form.Item
          name="chatBarText"
          rules={[{ required: true, message: '選單列文字不可為空' }]}
        >
          <Input placeholder="選單列文字(最長14字)" maxLength={14} />
        </Form.Item>
      </div>

      <div className={style['input__radio']}>
        <Label>預設開啟 Rich Menu</Label>
        <Form.Item name="selected" initialValue={true}>
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <Form.Item>
        <Form.List
          name="areas"
          initialValue={[{ action: { type: 'message' } }]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <RichMenuAreasFormItem
                  key={index}
                  fields={fields}
                  field={field}
                  remove={remove}
                  index={index}
                />
              ))}

              {fields.length < 20 ? (
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '100%' }}
                  >
                    新增區塊
                  </Button>
                  {/* <Form.ErrorList errors={errors} /> */}
                </Form.Item>
              ) : null}
            </>
          )}
        </Form.List>
      </Form.Item>

      <GoBackButton />
      <Button
        className={style['form__submit-button']}
        htmlType="submit"
        type="primary"
      >
        SUBMIT
      </Button>
    </Form>
  );
}
