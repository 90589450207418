import React, { useEffect, useState, useRef } from 'react';
import {
  Layout,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Space,
  DatePicker,
  TimePicker,
  Button,
  Dropdown,
  Form,
  List,
} from 'antd';
import {
  DownOutlined,
  EyeOutlined,
  EditOutlined,
  RollbackOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import style from './styles/WpReadParentChildCourse.module.css';
import '../styles/WordpressAnt.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

/*** Import Wordpress Component ***/
import WpReadParentChildCourseChildrenList from './WpCourseComponents/WpReadParentChildCourseChildrenList';

function WpReadParentChildCourse(props) {
  const { wpReadParentChildCoursePage, setWpReadParentChildCoursePage } = props;
  const { Content } = Layout;
  const [btnLoadings, setBtnLoadings] = useState([]);
  const [sendingTime, setSendingTime] = useState(0);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [showInputNumber, setShowInputNumber] = useState(false);
  const [checkList, setCheckList] = useState([{}]);
  const [locationArray, setLocationArray] = useState([]);
  useEffect(() => {
    console.log(locationArray);
    console.log(locationArray.length);
  }, [locationArray]);
  const [dropdownTextState, setDropdownTextState] = useState('');
  const [afterFilterShow, setAfterFilterShow] = useState({ display: 'block' }); // 篩選完顯示區塊
  const [wpReadParentChildClass, setWpReadParentChildClass] = useState({});
  useEffect(() => {
    console.log(wpReadParentChildClass);
  }, [wpReadParentChildClass]);
  // const [wpReadParentChildCoursePage, setWpReadParentChildCoursePage] = useState("Search");
  const [courseDataArray, setCourseDataArray] = useState([]); // 課程資料
  const [courseDataArrayTest, setCourseDataArrayTest] = useState([
    {
      _id: '64911e87466aef5f4a19f998',
      pid: 'string',
      name: '測試課程一',
      courseIntroduction: '課程介紹:Nestjs-API,MongoDB環境建置',
      formal: 6,
      toExperience: 2,
      compensate: 0,
      memo: '備註:',
      flag: 0,
      createdDate: '2023-06-20T03:35:25.980Z',
      __v: 0,
      student: [],
    },
    {
      _id: '64911eeb4ddde2728f9b4c7b',
      pid: 'course-110-0002',
      name: '測試課程二',
      location: '捷運善導寺站-店1',
      startTime: '2023-06-20T09:00:00.980Z',
      endTime: '2023-06-20T09:30:00.980Z',
      flag: 0,
      createdDate: '2023-06-19T01:32:01.481Z',
      student: [],
      __v: 0,
    },
    {
      _id: '64911f5a466aef5f4a19f99b',
      pid: 'course-003',
      name: '測試課程三',
      formal: 6,
      toExperience: 2,
      compensate: 0,
      memo: '備註:',
      flag: 0,
      createdDate: '2023-06-20T03:35:25.980Z',
      __v: 0,
      student: [],
    },
  ]); // 課程資料
  useEffect(() => {
    console.log(courseDataArray);
  }, [courseDataArray]);
  // const [filterFieldsOnChangeState, setFilterFieldsOnChangeState] = useState({ pid: "", name: "", location: "", startTime: "", endTime: "" }); // 篩選欄位onChange狀態
  const [filterFieldsOnChangeState, setFilterFieldsOnChangeState] = useState(
    {}
  ); // 篩選欄位onChange狀態
  useEffect(() => {
    console.log(filterFieldsOnChangeState);
  }, [filterFieldsOnChangeState]);
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState(
    {}
  ); //更新欄位狀態
  useEffect(() => {
    console.log(updateFieldsOnChangeState);
  }, [updateFieldsOnChangeState]);
  const [updateFieldsOnChangeStateTest, setUpdateFieldsOnChangeStateTest] =
    useState([]); //更新顯示欄位狀態(測試)
  useEffect(() => {
    console.log(updateFieldsOnChangeStateTest);
  }, [updateFieldsOnChangeStateTest]);
  const [classDate, setClassDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [classTime, setClassTime] = useState([]);
  const [updateClassDate, setUpdateClassDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [updateClassTime, setUpdateClassTime] = useState([]);
  const [classArray, setClassArray] = useState([
    { id: '', location: '', startTime: '', endTime: '' },
  ]);
  useEffect(() => {
    console.log(classArray);
  }, [classArray]);
  const [classObj, setClassObj] = useState({
    _id: '',
    location: '',
    startTime: '',
    endTime: '',
  });
  useEffect(() => {
    console.log(classObj);
  }, [classObj]);
  // const classNameRef = useRef("");
  // const classCategoryRef = useRef("");
  // const classDescriptionRef = useRef("");
  // const classPriorityRef = useRef("");
  const wpApiURL = process.env.REACT_APP_WPAPIURL; // API URL
  dayjs.extend(utc);
  const items = [
    {
      label: 'RM301',
      key: 'RM301',
      onClick: ({ item, key, keyPath, domEvent }) => {
        console.log(key);
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['location']: key,
        });
        setDropdownTextState(key);
      },
    },
    {
      label: 'RM302',
      key: 'RM302',
      onClick: ({ item, key, keyPath, domEvent }) => {
        console.log(key);
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['location']: key,
        });
        setDropdownTextState(key);
      },
    },
  ];
  const enterLoading = (index) => {
    setBtnLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
  };

  const endLoading = (index) => {
    setBtnLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  };

  const radioOnChange = (e) => {
    console.log('radio checked', e.target.value);
    setSendingTime(e.target.value);
  };
  const inputOnChange = (e) => {
    console.log(e);
    e?.preventDefault();
    const fieldId = e?.target?.id || '';
    const value = e?.target?.value || '';
    switch (fieldId) {
      case 'courseID':
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['pid']: value,
        });
        break;
      case 'courseName':
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['name']: value,
        });
        break;
      // setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["location"]:key });
    }
  };
  const datePickerOnChange = (date, dateString) => {
    console.log(date, dateString);
    // setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["date"]:timeString});
    // setSendingTime(1);
    let startTimeString, endTimeString;
    startTimeString = endTimeString = '';
    if (!dateString || dateString === 'Invalid Date') {
      // const defaultDate = dayjs(dayjs(new Date()).format("YYYY-MM-DD") + "00:00:00 +0800").format("YYYY-MM-DD");
      // console.log(defaultDate);
      // setClassDate(defaultDate);
      // startTimeString = defaultDate + " 00:00:00 +0800";
      // endTimeString = defaultDate + " 23:59:59 +0800";
      startTimeString = '2022-01-01 00:00:00 +0800';
      endTimeString = '2122-01-01 23:59:59 +0800';
    } else {
      setClassDate(dateString);
      if (Array.isArray(classTime) && classTime?.length > 0) {
        startTimeString = `${dateString} ${classTime[0]}:00 +0800`;
        endTimeString = `${dateString} ${classTime[1]}:00 +0800`;
      } else {
        startTimeString = dateString + ' 00:00:00 +0800';
        endTimeString = dateString + ' 23:59:59 +0800';
      }
    }
    console.log('startTimeString', startTimeString);
    console.log('endTimeString', endTimeString);
    const startTimeDate = dayjs.utc(startTimeString).format();
    const endTimeDate = dayjs.utc(endTimeString).format();
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['startTime']: startTimeDate,
      ['endTime']: endTimeDate,
    });
  };

  const timePickerOnChange = (timeArray, timeStringArray) => {
    console.log(timeStringArray);
    console.log(timeStringArray[0], timeStringArray[1]);
    // setSendingTime(1);
    let startTimeString, endTimeString;
    startTimeString = endTimeString = '';
    if (
      !timeStringArray[0] ||
      !timeStringArray[1] ||
      timeStringArray[0] === 'Invalid Date' ||
      timeStringArray[1] === 'Invalid Date'
    ) {
      setClassTime(['00:00', '23:59']);
      if (classDate) {
        startTimeString = `${classDate} 00:00:00 +0800`;
        endTimeString = `${classDate} 23:59:59 +0800`;
      } else {
        startTimeString = `${dayjs(new Date()).format(
          'YYYY-MM-DD'
        )} 00:00:00 +0800`;
        endTimeString = `${dayjs(new Date()).format(
          'YYYY-MM-DD'
        )} 23:59:59 +0800`;
      }
    } else {
      if (classDate) {
        setClassTime(timeStringArray);
        startTimeString = `${classDate} ${timeStringArray[0]}:00 +0800`;
        endTimeString = `${classDate} ${timeStringArray[1]}:00 +0800`;
      } else {
        setClassTime(['00:00', '00:00']);
        startTimeString = `2022-01-01 00:00:00 +0800`;
        endTimeString = `2122-01-01 00:00:00 +0800`;
      }
    }
    console.log(startTimeString, endTimeString);
    const startTimeDate = dayjs.utc(startTimeString).format();
    const endTimeDate = dayjs.utc(endTimeString).format();
    console.log(startTimeDate, endTimeDate);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['startTime']: startTimeDate,
      ['endTime']: endTimeDate,
    });
  };

  const checkboxOnChange = (event) => {
    const id = event?.target?.id || '';
    const checked = event?.target?.checked || false;
    const checkElement = { id, checked };
    if (Object.values(checkList[0]).length > 0) {
      // CheckList 有值，取代或新增check資料
      let checkListArray = new Array();
      let hasSameId = false;
      checkListArray = checkList.map((obj) => {
        const objId = obj?.id || '';
        if (objId === id) {
          obj.checked = checked; // 取代check資料
          hasSameId = true;
        }
        return obj;
      });
      if (!hasSameId) {
        // 未比對到同ID的物件，新增check資料
        setCheckList((current) => [...current, checkElement]);
      } else {
        // 有比對到同ID的物件，取代原CheckList
        setCheckList(checkListArray);
      }
    } else {
      // 無值則新增check資料
      let checkListArray = new Array();
      checkListArray.push(checkElement);
      setCheckList(checkListArray);
    }
    let msgLimitIsChecked = false;
    for (let el of checkList) {
      // msgLimit前次設定的值
      if (Object.values(el).includes('msgLimit')) {
        msgLimitIsChecked = el?.checked || false;
      }
    }
    if ((id === 'msgLimit' && checked) || msgLimitIsChecked) {
      // msgLimit事件觸發設定的值或前次設定的值
      setShowInputNumber(true);
    } else {
      setShowInputNumber(false);
    }
  };
  useEffect(() => {
    console.log(checkList);
  }, [checkList]);

  /*** 篩選欄位onChange ***/
  // const filterFieldsOnChange = (e) => {
  //     e.preventDefault();
  //     const fieldId = e?.target?.id || "";
  //     const value = e?.target?.value || "";
  //     if( !! fieldId ) {
  //     console.log(fieldId);
  //     console.log(value);
  //     switch(fieldId) {
  //         case "CourseName":
  //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['name']: value});
  //             break;
  //         case "CourseCategory":
  //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['location']: value});
  //             break;
  //         case "CourseDescription":
  //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['date']: value});
  //             break;
  //         case "CoursePriority":
  //             const intValue = parseInt(value);
  //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['time']: intValue});
  //             break;
  //     }
  //     }
  // }
  // useEffect(() => {
  //     console.log(filterFieldsOnChangeState);
  // }, [filterFieldsOnChangeState]);

  const { Search } = Input;
  /*** 搜尋欄位onChange ***/
  const searchOnChange = (event) => {
    const val = event?.target?.value || '';
    // setSearchString(val);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['name']: val,
    });
  };

  /*** 搜尋按鈕 ***/
  const onSearch = () => {
    requestClass();
  };

  /*** 查詢Course ***/
  const requestClass = async (e) => {
    e?.preventDefault();
    // console.log(filterFieldsOnChangeState["name"])
    // const url = wpApiURL + "/course?" + new URLSearchParams({
    //     filter: filterFieldsOnChangeState,
    // });
    console.log(filterFieldsOnChangeState);
    let url = '';
    let response = [];
    // if( !!pid || !!name || !!location || !!startTime || !!endTime ) {
    // url = wpApiURL + `/kidproCourse/${pid}`;
    const pid = filterFieldsOnChangeState?.pid || '';
    const name = filterFieldsOnChangeState?.name || '';
    const location = filterFieldsOnChangeState?.location || '';
    const startTime = filterFieldsOnChangeState?.startTime || '';
    const endTime = filterFieldsOnChangeState?.endTime || '';
    console.log('startTime', startTime);
    console.log('endTime', endTime);
    let paramsObj = {};
    if (pid) {
      paramsObj['pid'] = pid;
    }
    if (name) {
      paramsObj['name'] = name;
    }
    if (location) {
      paramsObj['location'] = location;
    }
    // else {
    if (e && !startTime) {
      // 手動觸發且為空值，查今日課程；非手動觸發為空值或無效值，查全部課程
      paramsObj['startTime'] = dayjs
        .utc(dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00 +0800')
        .format();
      paramsObj['endTime'] = dayjs
        .utc(dayjs(new Date()).format('YYYY-MM-DD') + ' 23:59:59 +0800')
        .format();
    } else {
      paramsObj['startTime'] =
        startTime && startTime !== 'Invalid Date'
          ? startTime
          : dayjs.utc('2022-01-01 00:00:00 +0800').format();
      paramsObj['endTime'] =
        endTime && endTime !== 'Invalid Date'
          ? endTime
          : (paramsObj['endTime'] = dayjs
              .utc('2122-01-01 00:00:00 +0800')
              .format());
    }
    // paramsObj['startTime'] = startTime && startTime!=="Invalid Date" ? startTime : dayjs.utc(dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00 +0800").format();
    // paramsObj['endTime'] = endTime && endTime!=="Invalid Date" ? endTime : dayjs.utc(dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59 +0800").format();

    // }
    // else {
    //     if(!startTime || startTime==="Invalid Date") {
    //         // console.log(dayjs.utc(new Date()).format("YYYY-MM-DD") + " 00:00:00 +0800");
    //         paramsObj['startTime'] = dayjs.utc(dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00 +0800").format();
    //     }
    //     if(!endTime || endTime==="Invalid Date") {
    //         paramsObj['endTime'] = dayjs.utc(dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59 +0800").format();
    //     }
    // }
    console.log(paramsObj);
    const urlParams = new URLSearchParams(paramsObj);
    url =
      wpApiURL +
      `/kidproCourse/findCourseFromCoursesByPidNameLocSETime?${urlParams}`;
    response = await fetch(url, {
      method: 'GET',
      // body: JSON.stringify(filterFieldsOnChangeState),
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        // alert(`課程查詢失敗！\n${err}`);
        console.log(`課程查詢失敗！\n${err}`);
        throw new Error('Request Course failed');
      });
    // } else {
    //     url = wpApiURL + "/kidproCourse/getCourseFindall";
    //     response = await fetch(
    //         url,
    //         {
    //             headers: {
    //                 "Accept": "application/json",
    //             },
    //         }
    //     )
    //     .then((res) => res.json())
    //     .catch((err) => {
    //         // alert(`課程查詢失敗！\n${err}`);
    //         throw new Error("Request Course failed");
    //     });
    // }
    console.log(url);
    console.log(response);
    console.log(response?.code === 200);
    console.log(response?.data);
    // console.log(searchString);

    // if( Array.isArray(response) && response.length > 0 ) {
    //     console.log(response);
    //     setCourseDataArray(response);
    //     setAfterFilterShow({"display":"block"});  // 顯示篩選後課程
    // }

    // if( response.code === 200 ) {
    // const responseData = response?.data || [];
    // console.log(responseData);
    // let responseDataArray = [];
    // for( let el of responseData ) {
    // const _id = el?._id || "";
    // const pid = el?.pid || "";
    // const price = el?.price || "";
    // const name = el?.name || "";
    // const course_introduction = el?.course_introduction || "";
    // const memo = el?.memo || "";
    // const location = el?.location || "";
    // let date, startTime, endTime;
    // date = startTime = endTime = {};
    // const opening_time = el?.opening_time || "";
    //     console.log(opening_time);
    //     const timeRegex = /(\d{12})/gi;
    //     const timeArray = opening_time.match(timeRegex);
    //     console.log(timeArray);
    //     if( !! timeArray ){
    //         for( let [idx, tEl] of timeArray.entries() ) {
    //             const timeElRegex = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})$/gi;
    //             console.log(tEl);
    //             const tElArray = timeElRegex.exec(tEl);
    //             console.log(tElArray);
    //             if (tElArray !== null) {
    //                 const year = tElArray[1];
    //                 const month = tElArray[2];
    //                 const day = tElArray[3];
    //                 const hour = tElArray[4];
    //                 const minute = tElArray[5];
    //                 date = new Date(`${year}-${month}-${day} 00:00:00 +0800`);
    //                 console.log(new Date(`2000-01-01 00:00:00 +0800`));
    //                 if( idx === 0 ) {
    //                     startTime = new Date(`${year}-${month}-${day} ${hour}:${minute}:00 +0800`);
    //                     console.log(startTime);
    //                 } else {
    //                     endTime = new Date(`${year}-${month}-${day} ${hour}:${minute}:00 +0800`);
    //                 }

    //             } else {
    //                 console.log("Invalid start time/end time format.");
    //             }
    //         }
    //     } else {
    //         console.log("Invalid time format.");
    //     }
    // const num_classes = el?.num_classes || 0;
    // const formal = el?.formal || 0;
    // const to_experience = el?.to_experience || 0;
    // const compensate = el?.compensate || 0;
    // const student = el?.student || [];
    // const teacher = el?.teacher || "";
    // const responseDataObj = { _id, pid, price, name, course_introduction, memo, location, date, startTime, endTime, num_classes, formal, to_experience, compensate, student, teacher };
    // responseDataArray.push(responseDataObj);
    // }
    // console.log(responseDataArray);
    // console.log(paramsObj?.hasOwnProperty("pid"));
    if (Array.isArray(response)) {
      // const reponseData = response?.data || [];
      console.log(response);
      setCourseDataArray(response);
    } else if (
      paramsObj?.hasOwnProperty('pid') ||
      paramsObj?.hasOwnProperty('name') ||
      paramsObj?.hasOwnProperty('location')
    ) {
      console.log(Object.keys(paramsObj)?.hasOwnProperty('pid'));
      alert(
        `無法搜尋到任何課程！\n( Error: ${response.error}, ${response.message} )`
      );
    }
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  useEffect(() => {
    requestClass();
  }, []);
  useEffect(() => {
    // 查詢後設定location 選擇列表
    if (
      locationArray.length === 0 ||
      (locationArray.length === 1 &&
        locationArray[0]?.label === '請選擇教室編號')
    ) {
      const locationItem = courseDataArray.map((item) => item.location); // Array中Object指定Key對應之值轉為Iterable Array
      const uniqueLocationItem = [...new Set(locationItem)]; // 去除重複值
      const saniArray = uniqueLocationItem.filter((item) => {
        // 去除空值
        return (
          item !== undefined &&
          item !== null &&
          !(typeof item === 'object' && Object.keys(item).length === 0) &&
          !(Array.isArray(item) && item.length === 0) &&
          !(typeof item === 'string' && item.trim() === '')
        );
      });
      let locationDropdown = saniArray.map((el) => {
        // 設為Dropdown Item物件格式
        return {
          label: el,
          key: el,
          onClick: ({ item, key, keyPath, domEvent }) => {
            // setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["location"]:key });
            setDropdownTextState(key);
          },
        };
      });
      // console.log(JSON.stringify(locationDropdown));
      console.log(locationDropdown);
      locationDropdown.unshift({
        label: `請選擇教室編號`,
        key: '',
        onClick: ({ item, key, keyPath, domEvent }) => {
          setDropdownTextState(key);
        },
      });
      console.log(locationDropdown);
      setLocationArray(locationDropdown);
    }
  }, [courseDataArray]);
  useEffect(() => {
    // if( !!dropdownTextState ) {
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['location']: dropdownTextState,
    });
    // }
  }, [dropdownTextState]);

  /*** 刪除課堂 ***/
  const deleteClass = async (e, _id, name, startTime, endTime) => {
    e?.preventDefault();
    console.log({ _id, name, startTime, endTime });
    const dateFormat = dayjs(startTime).format('YYYY-MM-DD');
    const startTimeFormat = dayjs(startTime).format('HH:mm');
    const endTimeFormat = dayjs(endTime).format('HH:mm');
    const url = wpApiURL + `/kidproCourse/delOneCourseFlagByClassId/${_id}`;
    const data = { flag: -1 };
    if (
      window.confirm(
        `確定要刪除課堂【${name}】${dateFormat} ${startTimeFormat}~${endTimeFormat}？`
      )
    ) {
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .catch((err) => {
          alert(
            `課堂【${name}】${dateFormat} ${startTimeFormat}~${endTimeFormat} 刪除失敗！\n${err}`
          );
          console.log(`課堂刪除失敗！\n( Class ID : ${_id} )\n${err}`);
          throw new Error('Delete class failed');
        });
      console.log(response);
      if (response?.code === 200) {
        alert(
          `課堂【${name}】${dateFormat} ${startTimeFormat}~${endTimeFormat} 刪除成功！\n( Class ID : ${_id} )`
        );
      } else {
        alert(
          `課堂【${name}】${dateFormat} ${startTimeFormat}~${endTimeFormat} 刪除失敗！\n( Class ID : ${_id} )\nError : ${response?.statusCode}. ${response?.error}, ${response?.message} !`
        );
      }
      requestClass();
    } else {
      return;
    }
  };

  /*** 更新欄位onChange ***/
  const locationUpdateOnChange = (e, _id, oriLocation) => {
    e?.preventDefault();
    const value = e?.target?.value || '';
    console.log(_id, value);
    setClassObj({ ...classObj, ['_id']: _id, ['location']: value });
  };
  const datePickerUpdateOnChange = (date, dateString, _id, oriTimeArray) => {
    console.log(date, dateString, _id, oriTimeArray, updateClassTime);
    setUpdateClassDate(dateString);
    const startTimeString = !updateClassTime[0]
      ? dateString + ' ' + oriTimeArray[0] + ':00 +0800'
      : dateString + ' ' + updateClassTime[0] + ':00 +0800';
    const endTimeString = !updateClassTime[1]
      ? dateString + ' ' + oriTimeArray[1] + ':00 +0800'
      : dateString + ' ' + updateClassTime[1] + ':00 +0800';
    const startTimeDate = dayjs.utc(startTimeString).format();
    const endTimeDate = dayjs.utc(endTimeString).format();
    console.log(startTimeDate, endTimeDate);
    setClassObj({
      ...classObj,
      ['_id']: _id,
      ['startTime']: startTimeDate,
      ['endTime']: endTimeDate,
    });
  };
  const timePickerUpdateOnChange = (
    timeArray,
    timeStringArray,
    _id,
    oriDate
  ) => {
    console.log(timeArray, timeStringArray, _id);
    setUpdateClassTime(timeStringArray);
    const startTimeString = !updateClassDate
      ? `${oriDate} ${timeStringArray[0]}:00 +0800`
      : `${updateClassDate} ${timeStringArray[0]}:00 +0800`;
    const endTimeString = !updateClassDate
      ? `${oriDate} ${timeStringArray[1]}:00 +0800`
      : `${updateClassDate} ${timeStringArray[1]}:00 +0800`;
    console.log(startTimeString, endTimeString);
    const startTimeDate = dayjs.utc(startTimeString).format();
    const endTimeDate = dayjs.utc(endTimeString).format();
    console.log(startTimeDate, endTimeDate);
    setClassObj({
      ...classObj,
      ['_id']: _id,
      ['startTime']: startTimeDate,
      ['endTime']: endTimeDate,
    });
  };
  useEffect(() => {
    setUpdateFieldsOnChangeState({
      ...updateFieldsOnChangeState,
      ['locationTime']: classArray,
    });
  }, [classArray]);
  // const updateFieldsOnChange = (e) => {
  //     e.preventDefault();
  //     const name = classNameRef?.current?.input?.value || "";
  //     const category = classCategoryRef?.current?.input?.value || "";
  //     const description = classDescriptionRef?.current?.input?.value || "";
  //     const priority = parseInt(classPriorityRef?.current?.input?.value) || 0;
  //     const data = { name, category, description, priority };
  //     console.log(classNameRef);
  //     console.log(classNameRef.current);
  //     console.log(data);
  //     setUpdateFieldsOnChangeState(data);
  // }

  /*** 更新課程 ***/
  const updateClass = async (e, id, name) => {
    e?.preventDefault();
    console.log(id);
    console.log(name);
    const url =
      wpApiURL + '/kidproCourse/updateOneCourseLoSEndTimeByClassId/' + id;
    console.log(url);
    console.log(updateFieldsOnChangeState);
    console.log(classObj);
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(classObj),
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`課堂更新失敗！\n( Class ID : ${id} )\n${err}`);
        throw new Error('Update Tag failed');
      });
    console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // if( !! responseId ) {
    // if( Object.values(courseDataArray).length > 0 ) {
    if (response?.code === 200) {
      // for( let el of courseDataArray ) {
      //     if( Object.values(el).includes(id) ) {
      //         el['name'] = await response?.name || "";
      //         el['category'] = await response?.category || "";
      //         el['description'] = await response?.description || "";
      //         el['priority'] = await response?.priority || 0;
      //         alert(`課堂資訊已更新！\n( ID：${id} )`);
      //     }
      // }
      alert(`課堂資料已更新！\n( Class ID : ${id} )`);
    } else {
      alert(`課堂資料更新失敗！\n( Class ID : ${id} )`);
    }
    requestClass();
    setIsEditMode([-1, false]); // 返回非編輯模式
    // }
  };

  /*** 檢視課程 ***/
  const seeCourses = async (e, id, name) => {
    e.preventDefault();
    console.log(id);
    console.log(name);
    const attrObj = {
      key: id,
      label: name,
      // onClick: ({ item, key, keyPath, domEvent }) => {
      //   console.log(key);
      // }
    };
    // const selectedCourse = courseDataArrayTest.map((v, i) => {
    //     const _id = v?._id || "";
    //     if( id === _id ) {
    //         setWpReadParentChildClass(v);
    //     }
    // });
    for (let el of courseDataArray) {
      const _id = el?._id || '';
      if (id === _id) {
        setWpReadParentChildClass(el);
      }
    }

    setWpReadParentChildCoursePage('ChildrenList');
    // recentMessagesStore(attrObj);
    // const url = apiURL + "/chat-course/";
    // console.log(url);
    // console.log(addFieldsOnChangeState);
    // const response = await fetch(
    //   url,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(addFieldsOnChangeState),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //     },
    //   }
    // )
    // .then((res) => res.json())
    // .catch((err) => {
    //   alert(`標籤新增失敗！\n${err}`);
    //   throw new Error("Add Messages failed");
    // });
    // console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // const responseStatus = response?.statusCode || "";
    // if( ! responseId ) {
    //   const responseErrorMsg = response?.message || "";
    //   if( responseStatus === 500 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else if( responseStatus === 400 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else {
    //     alert(`標籤新增失敗！\n${responseErrorMsg}`);
    //   }
    //   return false;
    // } else {
    //   const name = await response?.name || "";
    //   const category = await response?.category || "";
    //   const description = await response?.description || "";
    //   const priority = await response?.priority || 0;
    //   const data = { _id: responseId, name, category, description, priority };
    //   courseDataArray.push(data);
    //   alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
    //   setAddFieldsOnChangeState({}); // 清空欄位
    //   requestMessages(name); // 查詢已新增的標籤
    //   setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
    // }
  };

  return (
    <>
      {wpReadParentChildCoursePage === 'ChildrenList' ? (
        <WpReadParentChildCourseChildrenList
          setWpReadParentChildCoursePage={setWpReadParentChildCoursePage}
          wpReadParentChildClass={wpReadParentChildClass}
        ></WpReadParentChildCourseChildrenList>
      ) : (
        <Layout className={style.wpReadParentChildCourseLayout}>
          <Form
            id={'WpReadParentChildCourse'}
            className={style.wpReadParentChildCourseForm}
            onFinish={() => {}}
          >
            <h4 className={style.wpReadParentChildCourseH4}>
              搜尋及編輯【親子課程】課堂
            </h4>
            <Form.Item
              className={style.wpReadParentChildCourseFormItem}
              name="button"
            >
              <Space className={style.wpReadParentChildCourseSpaceDropdown}>
                <h6
                  className={`${style.wpReadParentChildCourseH6} ${style.wpReadParentChildCourseFlex1}`}
                >
                  課程商品ID
                </h6>
                {/* <Search 
                                    className={`${style.wpReadParentChildCourseInput} ${style.wpReadParentChildCourseSearch}`} 
                                    placeholder="請輸入欲查詢的課程名稱" 
                                    allowClear size="middle" 
                                    onChange={(e) => {searchOnChange(e)}} 
                                    onSearch={onSearch} 
                                    enterButton 
                                /> */}
                <Input
                  id="courseID"
                  className={`${style.wpReadParentChildCourseInput} ${style.wpReadParentChildCourseSearch}`}
                  placeholder="請輸入欲查詢的課程ID"
                  allowClear
                  size="middle"
                  onChange={(e) => {
                    inputOnChange(e);
                  }}
                ></Input>
              </Space>
            </Form.Item>
            <Form.Item
              className={style.wpReadParentChildCourseFormItem}
              name="button"
            >
              <Space className={style.wpReadParentChildCourseSpaceDropdown}>
                <h6
                  className={`${style.wpReadParentChildCourseH6} ${style.wpReadParentChildCourseFlex1}`}
                >
                  課程名稱
                </h6>
                <Input
                  id="courseName"
                  className={`${style.wpReadParentChildCourseInput} ${style.wpReadParentChildCourseSearch}`}
                  placeholder="請輸入欲查詢的課程名稱"
                  allowClear
                  size="middle"
                  onChange={(e) => {
                    inputOnChange(e);
                  }}
                ></Input>
              </Space>
            </Form.Item>
            <Form.Item
              className={style.wpReadParentChildCourseFormItem}
              name="button"
            >
              <Space
                id="wpReadParentChildCourseSpaceDropdown"
                className={style.wpReadParentChildCourseSpaceDropdown}
              >
                <h6
                  className={`${style.wpReadParentChildCourseH6} ${style.wpReadParentChildCourseFlex1}`}
                >
                  上課地點
                </h6>
                <Dropdown
                  id="courseLocationTime"
                  className={`${style.wpReadParentChildCourseSendBtn} ${style.wpReadParentChildCourseFlex2}`}
                  menu={{ items: locationArray }}
                  placement="bottom"
                  // onOpenChange={(open)=>console.log(open)}
                >
                  <Button
                  // id="CoursePlace"
                  // onClick={(e) => {
                  //     enterLoading(1);
                  //     console.log(e.target)
                  // }}
                  >
                    <Space>
                      {!!dropdownTextState
                        ? dropdownTextState
                        : '請選擇教室編號'}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Form.Item>
            <Form.Item
              className={style.wpReadParentChildCourseFormItem}
              name="sendingTime"
              rules={[{ required: true, message: `【傳送時間】為必填欄位！` }]}
            >
              <Space className={style.wpReadParentChildCourseSpaceTime}>
                <h6
                  className={`${style.wpReadParentChildCourseH6} ${style.wpReadParentChildCourseFlex1}`}
                >
                  上課日期
                </h6>
                <div
                  className={`${style.wpReadParentChildCourseDivTimePicker}`}
                >
                  <DatePicker
                    className={`${style.wpReadParentChildCourseDatePicker} ${style.wpReadParentChildCourseFlex2}`}
                    defaultValue={dayjs.utc(new Date())}
                    placeholder={'請選擇課程日期'}
                    onChange={datePickerOnChange}
                    suffixIcon={null}
                  />
                </div>
              </Space>
            </Form.Item>
            <Form.Item
              className={style.wpReadParentChildCourseFormItem}
              name="sendingTime"
              rules={[{ required: true, message: `【傳送時間】為必填欄位！` }]}
            >
              <Space className={style.wpReadParentChildCourseSpaceTime}>
                <h6
                  className={`${style.wpReadParentChildCourseH6} ${style.wpReadParentChildCourseFlex1}`}
                >
                  上課時間
                </h6>
                <div
                  className={`${style.wpReadParentChildCourseDivTimePicker}`}
                >
                  <TimePicker.RangePicker
                    className={`${style.wpReadParentChildCourseTimePicker}`}
                    placeholder={['課程開始時間', '課程結束時間']}
                    onChange={timePickerOnChange}
                    format={'HH:mm'}
                    minuteStep={5}
                    suffixIcon={null}
                  />
                </div>
              </Space>
            </Form.Item>
            <Form.Item
              className={`${style.wpReadParentChildCourseFormItem} ${style.wpReadParentChildCourseFormItemBtn}`}
              name="advance"
            >
              <Space
                className={style.wpReadParentChildCourseSpaceSendBtn}
                direction="vertical"
              >
                <Button
                  htmlType="submit"
                  className={style.wpReadParentChildCourseSendBtn}
                  type="primary"
                  // loading={btnLoadings[0]}
                  onClick={(e) => requestClass(e)}
                >
                  <Space>搜尋課堂</Space>
                </Button>
              </Space>
            </Form.Item>
            {/* <Form.Item className={`${style.wpReadParentChildCourseFormItem} ${style.wpReadParentChildCourseFormItemLineCastInterface}`} name="interface" rules={[{ required: true, message: `【訊息輸入欄位】為必填欄位！` }]}>
                            <Space className={style.wpReadParentChildCourseSpaceLineCastInterface} >
                                <LineCastInterface></LineCastInterface>
                            </Space>
                        </Form.Item> */}
          </Form>
          <Content
            className={`${style.wpReadParentChildCourseFilterConsultContent} ${style.wpReadParentChildCourseFilterConsultContentQuery}`}
            style={afterFilterShow}
          >
            <section
              id={'wpReadParentChildCourseFilterConsultSearchSec'}
              className={style.wpReadParentChildCourseFilterConsultSearchSec}
            >
              <div className={style.wpReadParentChildCourseFilterConsultTitle}>
                <h5>課堂列表：</h5>
              </div>
              <List
                className={style.wpReadParentChildCourseFilterConsultList}
                locale={{}}
                dataSource={courseDataArray}
                renderItem={(item, index) =>
                  item?.flag > -1 &&
                  Object.keys(courseDataArray[0]).includes('_id') &&
                  isEditMode[0] === index &&
                  isEditMode[1] ? ( // 編輯模式
                    <List.Item>
                      <List.Item.Meta
                        className={
                          style.wpReadParentChildCourseFilterConsultListItemMeta
                        }
                        // avatar={
                        //     // <Tag className={style.wpReadParentChildCourseFilterConsultTagIcon} color="geekblue">{item.name}</Tag>
                        //     <span className={style.wpReadParentChildCourseFilterConsultCourseDescriptionText}>{item.name}</span>
                        // }
                        title={
                          <div
                            className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleDiv}`}
                          >
                            <div
                              className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv}`}
                            >
                              <div
                                className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitle} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleID}`}
                              >
                                <span
                                  className={
                                    style.wpReadParentChildCourseFilterConsultCourseDescriptionName
                                  }
                                >
                                  {'課程商品ID：'}
                                </span>
                                <span
                                  className={
                                    style.wpReadParentChildCourseFilterConsultCourseDescriptionText
                                  }
                                >
                                  {item?.pid}
                                </span>
                              </div>
                              <div
                                className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitle} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleName}`}
                              >
                                <span
                                  className={
                                    style.wpReadParentChildCourseFilterConsultCourseDescriptionName
                                  }
                                >
                                  {'課程名稱：'}
                                </span>
                                <span
                                  className={
                                    style.wpReadParentChildCourseFilterConsultCourseDescriptionText
                                  }
                                >
                                  {item?.name}
                                </span>
                              </div>
                            </div>
                            <div
                              className={
                                style.wpReadParentChildCourseFilterConsultIconDiv
                              }
                            >
                              <RollbackOutlined
                                className={
                                  style.wpReadParentChildCourseFilterConsultIcon
                                }
                                onClick={(e) => {
                                  setIsEditMode(false);
                                }}
                              />
                              <DeleteOutlined
                                className={
                                  style.wpReadParentChildCourseFilterConsultIcon
                                }
                                onClick={(e) => {
                                  deleteClass(e, item._id, item.name);
                                }}
                              />
                              <EyeOutlined
                                className={
                                  style.wpReadParentChildCourseFilterConsultIcon
                                }
                                onClick={(e) => {
                                  seeCourses(e, item?._id, item?.name);
                                }}
                              />
                            </div>
                          </div>
                        }
                        description={
                          <div
                            className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionDiv} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionQueryDiv}`}
                          >
                            <div
                              className={
                                style.wpReadParentChildCourseFilterConsultCourseDescriptionItem
                              }
                            >
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionLabel
                                }
                              >
                                {'地點：'}
                              </span>
                              {/* <span className={style.wpReadParentChildCourseFilterConsultCourseDescriptionText}>{item?.location}</span> */}
                              <Input
                                id={`classLocation`}
                                className={`${style.wpReadParentChildCourseInput} ${style.wpReadParentChildCourseInputPlace} ${style.wpReadParentChildCourseFlex2}`}
                                placeholder="請輸入上課地點"
                                defaultValue={item?.location}
                                allowClear
                                size="middle"
                                onChange={(e) =>
                                  locationUpdateOnChange(
                                    e,
                                    item?._id,
                                    item?.location
                                  )
                                }
                              />
                            </div>
                            <div
                              className={
                                style.wpReadParentChildCourseFilterConsultCourseDescriptionItem
                              }
                            >
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionLabel
                                }
                              >
                                {'日期：'}
                              </span>
                              {/* <span className={style.wpReadParentChildCourseFilterConsultCourseDescriptionText}>{dayjs(item?.startTime).format("YYYY-MM-DD HH:mm")}</span> */}
                              <DatePicker
                                id={`classDate`}
                                className={`${style.wpReadParentChildCourseDatePicker} ${style.wpReadParentChildCourseFlex2}`}
                                placeholder={'請選擇上課日期'}
                                defaultValue={
                                  !!item?.startTime
                                    ? dayjs(
                                        dayjs(item?.startTime).format(
                                          'YYYY-MM-DD'
                                        )
                                      )
                                    : undefined
                                }
                                onChange={(date, dateString) =>
                                  datePickerUpdateOnChange(
                                    date,
                                    dateString,
                                    item?._id,
                                    [
                                      dayjs(item?.startTime).format('HH:mm'),
                                      dayjs(item?.endTime).format('HH:mm'),
                                    ]
                                  )
                                }
                                suffixIcon={null}
                              />
                            </div>
                            <div
                              className={
                                style.wpReadParentChildCourseFilterConsultCourseDescriptionItem
                              }
                            >
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionLabel
                                }
                              >
                                {'時間：'}
                              </span>
                              {/* <span className={style.wpReadParentChildCourseFilterConsultCourseDescriptionText}>{dayjs(item?.endTime).format("YYYY-MM-DD HH:mm")}</span> */}
                              <TimePicker.RangePicker
                                id={`classTime`}
                                className={`${style.wpReadParentChildCourseTimePicker}`}
                                placeholder={['課堂開始時間', '課堂結束時間']}
                                defaultValue={[
                                  !!item?.startTime
                                    ? dayjs(item?.startTime)
                                    : undefined,
                                  !!item?.startTime
                                    ? dayjs(item?.endTime)
                                    : undefined,
                                ]}
                                onChange={(timeArray, timeStringArray) =>
                                  timePickerUpdateOnChange(
                                    timeArray,
                                    timeStringArray,
                                    item?._id,
                                    dayjs(item?.startTime).format('YYYY-MM-DD')
                                  )
                                }
                                format={'HH:mm'}
                                minuteStep={5}
                                suffixIcon={null}
                                needsConfirmation={false}
                              />
                            </div>
                            {/* <div className={style.wpReadParentChildCourseFilterConsultCourseDescriptionItem}>
                                                            <span className={style.wpReadParentChildCourseFilterConsultCourseDescriptionLabel}>{"人數上限："}</span>
                                                            <span className={style.wpReadParentChildCourseFilterConsultCourseDescriptionText}>{item.maximum}</span>
                                                        </div> */}
                            <div
                              id="updateClass"
                              className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseFilterConsultCourseDivUpdateBtn}`}
                            >
                              <Button
                                className={`${style.wpReadParentChildCourseFilterConsultCourseUpdateBtn}`}
                                type={'primary'}
                                size={'small'}
                                onClick={(e) => {
                                  updateClass(e, item._id);
                                }}
                              >
                                更新
                              </Button>
                            </div>
                          </div>
                        }
                      />
                    </List.Item>
                  ) : item?.flag > -1 &&
                    Object.keys(courseDataArray[0]).includes('_id') ? ( // 非編輯模式
                    <List.Item>
                      <List.Item.Meta
                        className={
                          style.wpReadParentChildCourseFilterConsultListItemMeta
                        }
                        title={
                          <div
                            className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleDiv}`}
                          >
                            <div
                              className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv}`}
                            >
                              <div
                                className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitle} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleID}`}
                              >
                                <span
                                  className={
                                    style.wpReadParentChildCourseFilterConsultCourseDescriptionName
                                  }
                                >
                                  {'課程商品ID：'}
                                </span>
                                <span
                                  className={
                                    style.wpReadParentChildCourseFilterConsultCourseDescriptionText
                                  }
                                >
                                  {item?.pid}
                                </span>
                              </div>
                              <div
                                className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitle} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleName}`}
                              >
                                <span
                                  className={
                                    style.wpReadParentChildCourseFilterConsultCourseDescriptionName
                                  }
                                >
                                  {'課程名稱：'}
                                </span>
                                <span
                                  className={
                                    style.wpReadParentChildCourseFilterConsultCourseDescriptionText
                                  }
                                >
                                  {item?.name}
                                </span>
                              </div>
                            </div>
                            <div
                              className={
                                style.wpReadParentChildCourseFilterConsultIconDiv
                              }
                            >
                              <EditOutlined
                                className={
                                  style.wpReadParentChildCourseFilterConsultIcon
                                }
                                onClick={(e) => {
                                  setIsEditMode([index, true]);
                                  setClassObj({
                                    ...classObj,
                                    ['_id']: item?._id,
                                    ['location']: item?.location,
                                    ['startTime']: new Date(item?.startTime),
                                    ['endTime']: new Date(item?.endTime),
                                  });
                                }}
                              />
                              <DeleteOutlined
                                className={
                                  style.wpReadParentChildCourseFilterConsultIcon
                                }
                                onClick={(e) => {
                                  deleteClass(
                                    e,
                                    item._id,
                                    item.name,
                                    item?.startTime,
                                    item?.endTime
                                  );
                                }}
                              />
                              <EyeOutlined
                                className={
                                  style.wpReadParentChildCourseFilterConsultIcon
                                }
                                onClick={(e) => {
                                  seeCourses(e, item?._id, item?.name);
                                }}
                              />
                            </div>
                          </div>
                        }
                        description={
                          <div
                            className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionDiv} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionQueryDiv}`}
                          >
                            <div
                              className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitle} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleLocation}`}
                            >
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionLabel
                                }
                              >
                                {'地點：'}
                              </span>
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionText
                                }
                              >
                                {item?.location}
                              </span>
                            </div>
                            <div
                              className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitle} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleStartTime}`}
                            >
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionLabel
                                }
                              >
                                {'開始時間：'}
                              </span>
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionText
                                }
                              >
                                {!!item?.startTime
                                  ? dayjs(item?.startTime).format(
                                      'YYYY-MM-DD HH:mm'
                                    )
                                  : undefined}
                              </span>
                            </div>
                            <div
                              className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitle} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleEndTime}`}
                            >
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionLabel
                                }
                              >
                                {'結束時間：'}
                              </span>
                              <span
                                className={
                                  style.wpReadParentChildCourseFilterConsultCourseDescriptionText
                                }
                              >
                                {!!item?.endTime
                                  ? dayjs(item?.endTime).format(
                                      'YYYY-MM-DD HH:mm'
                                    )
                                  : undefined}
                              </span>
                            </div>
                            {/* <div className={`${style.wpReadParentChildCourseFilterConsultCourseDescriptionItem}>
                                                            <span className={style.wpReadParentChildCourseFilterConsultCourseDescriptionLabel}>{"人數上限："}</span>
                                                            <span className={style.wpReadParentChildCourseFilterConsultCourseDescriptionText}>{item.maximum}</span>
                                                        </div> */}
                          </div>
                        }
                      />
                    </List.Item>
                  ) : (
                    <></>
                  )
                }
              />
            </section>
          </Content>
        </Layout>
      )}
    </>
  );
}

export default WpReadParentChildCourse;
