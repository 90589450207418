import { createContext, useCallback, useState } from 'react';

const DialogContext = createContext({
  isDialogOpen: false,
  type: 'error',
  title: '',
  message: '',
  onConfirmCb: () => {},
  onOpenDialog: ({ type, title, message, onConfirmCb }) => {},
  onCloseDialog: () => {},
});

function DialogContextProvider({ children }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [type, setType] = useState('error');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [onConfirmCb, setOnConfirmCb] = useState(() => () => {});

  const onOpenDialog = useCallback(({ type, title, message, onConfirmCb }) => {
    setIsDialogOpen(true);
    setType(type);
    setTitle(title);
    setMessage(message);
    setOnConfirmCb(() => onConfirmCb);
  }, []);

  const onCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setOnConfirmCb(() => () => {});
  }, []);

  return (
    <DialogContext.Provider
      value={{
        isDialogOpen,
        type,
        title,
        message,
        onConfirmCb,
        onOpenDialog,
        onCloseDialog,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
}

export { DialogContext, DialogContextProvider };
