/* React */
import React from 'react';

/* Components */
import { MessageForm } from './Components';

/* //////////////////////////////////////////////////////////////// */
export default function LineCreateMessageForm() {
  /* JSX ---------------------------------------------------------- */
  return <MessageForm />;
}
