/* React */
// import React from 'react';
/* ANTD */
import { Input, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';

/* Styles */
import style from './MessageListItem.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function MessageListItemFlex({ field }) {
  /* JSX ---------------------------------------------------------- */
  return (
    <>
      <div className={style['item__input']}>
        <Form.Item
          name={[field.name, 'altText']}
          rules={[
            {
              required: true,
              message: 'altText 不可為空',
            },
          ]}
        >
          <Input placeholder="altText" />
        </Form.Item>
      </div>
      <div className={style['item__input']}>
        <Form.Item
          name={[field.name, 'contents']}
          rules={[
            {
              validator: (_, data) => {
                try {
                  if (data.trim().length === 0) throw new Error();
                  JSON.parse(data);
                  return Promise.resolve();
                } catch (err) {
                  return Promise.reject();
                }
              },
              message: '無效的 json 格式',
            },
          ]}
        >
          <TextArea
            className={style['item__text-area']}
            rows={6}
            placeholder="flex message json"
            style={{ 'white-space': 'pre-line' }} // preserve json format
          />
        </Form.Item>
      </div>
    </>
  );
}
