import React, { useState, useEffect, useContext } from 'react'
import style from './styles/CustomerLiffChatRoom.module.css'
import './styles/RceStyle.css'
import io from 'socket.io-client';
import liff from '@line/liff';
import CryptoJS  from 'crypto-js';
import jwt_decode from "jwt-decode";

/*** Import Socket Component ***/
import { GlobalSocketFromChatRoom } from '../../../SocketIO/Customer/SocketCustomerConnectChatRoom';

/*** Customer Chat Room Component ***/
import CustomerChatRoom from './CustomerChatRoom';

/* ANT UI */
import { DisconnectOutlined, LinkOutlined, LogoutOutlined } from '@ant-design/icons';

/*** BootStrap ***/
import { Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css'

// /***  UTM ***/
// import { useCookies } from 'react-cookie'; // 引入useCookies hook
// import ReactGA from "react-ga4"; // GA4
// import ReactPixel from 'react-facebook-pixel'; // FB Pixel

export const GlobalUserData = React.createContext({});

function CustomerLiffChatRoom() {
    let globalUserData = null;
    const [isFromCustomer, setIsFromCustomer] = useState(true);
    const [userData, setUserData] = useState({});
    const [showLiffIcon, setShowLiffIcon] = useState(true);
    const loginToken = !! window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : '';
    useEffect(() => { // 未登入導回登入頁面
        if( ! loginToken ) {
            window.location.href = '/ChatRoomLogin';
        }
    } ,[])
    const lineBind = window.localStorage.getItem("lineBind") ?? false;
    const frontEndURL = process.env.REACT_APP_FRONTENDURL; // 首頁URL
    const backEndURL = process.env.REACT_APP_BACKENDURL;
    const lineAppId = process.env.REACT_APP_LINE_APP_ID;
    const lineid = process.env.REACT_APP_LINE_APP_ID; // 官網LINE Developer ID
    const accessToken = window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) ? window.localStorage.getItem( "LIFF_STORE:" + lineAppId + ":accessToken" ) : "";
    let cpo = window.localStorage.getItem( "cpo" ) ? window.localStorage.getItem( "cpo" ) : "";
    const screenHeight = window?.innerHeight||0;
    // const customerLiffPopupStyleModalHeaderHeight = document?.getElementById("customerLiffPopupStyleModalHeader")?.offsetHeight || 0;
    // const customerChatRoomSendSecHeight = document?.getElementById("customerChatRoomSendSec")?.offsetHeight || 0;
    // const customerChatRoomContentSecHeight = screenHeight - customerLiffPopupStyleModalHeaderHeight - customerChatRoomSendSecHeight;
    useEffect(() => {
        console.log(screenHeight);
    },[screenHeight]);
    const thisStyle = `
        .modal-dialog{
            margin: 0;
            width: 100%!important;
            max-width: 100%;
            height: 100%;
            position: initial!important;
        }
        .modal-content{
            margin-bottom: -1px;
            width: 100%!important;
            height: 100%;
            max-height: 100%;
            overflow-y: scroll;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            overflow: -moz-scrollbars-none; /* Old Firefox */
            scrollbar-width: none;  /* Firefox */
            border-radius: 0;
        }
        .modal-content::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
        #customerChatRoomContentSec{
            min-height: ${screenHeight*0.83}px;
        }
        #customerChatRoomSendSec{
            border-radius: 0;
        }
    `
    // 之前的設定
    // .modal-content{
    //     margin-bottom: -1px;
    //     width: 100%!important;
    //     height: 100%;
    //     max-height: ${screenHeight}px;
    //     overflow-y: scroll;
    //     -ms-overflow-style: none;  /* Internet Explorer 10+ */
    //     overflow: -moz-scrollbars-none; /* Old Firefox */
    //     scrollbar-width: none;  /* Firefox */
    //     border-radius: 0;
    // }
    // #customerChatRoomContentSec{
    //     min-height: ${0.75*screenHeight}px;
    // }

    /*** Theme Selector ***/
    // const themes = {
    //     Customer: 'public/themes/CustomerLiffPopup.css',
    //     CustomerChatRoomLogin: 'public/themes/CustomerLiffPopupChatRoomLogin.css'
    // };

    /*** 取得登入者資料 ***/
    const getJwtData= (loginToken) => {
      const decoded = jwt_decode(loginToken);
      // // // // // // // console.log(decoded);
      const uid = decoded?.id || "";
      const email = decoded?.email || "";
      const atIndex = email.indexOf('@');
      const adminUsername = atIndex !== -1 ? email.substring(0, atIndex) : "";
      // // // // // // // // console.log(adminUsername);
      // setUserId(uid);
      return {uid, email};
    }
    // useEffect(() => {
    //   getJwtData();
    // }, []);
    // useEffect(() => {
    //   getJwtData();
    // }, [loginToken]);
    /*** END 取得使用者資料 ***/

    /*** 清除錯誤的cpo ***/
    useEffect(() => {
        const cpoLength = cpo?.length || 0;
        if( cpoLength < 45 ) {
            window.localStorage.setItem( "cpo", "" );
        }
    }, [cpo]);

    /* 取得SocketAdminConnect的連線狀態 */
    let globalSocketVar = null;
    const globalSocketContext = useContext(GlobalSocketFromChatRoom);
    const [globalSocket, setGlobalSocket] = useState(null);
    const [isGlobalSocketConnected, setIsGlobalSocketConnected] = useState(false);
    const [leaveRoom, setLeaveRoom] = useState(false);
    const liffId = process.env.REACT_APP_LINE_APP_ID;
    // const roomId = process.env.REACT_APP_ROOMID;
    const host = process.env.REACT_APP_HOST;
    const isWss = process.env.REACT_APP_ISWSS;
    const [show, setShow] = useState(false); // 設定是否顯示Login Popup
    const [NOuaabindLine, setNOuaabindLine] = useState(true); // 設定登入頁面line是否綁定uaa，預設true為未綁定

    useEffect(() => {
        // const queryParameters = new URLSearchParams( window.location.search );
        // const lineBind = queryParameters.get( "line_bind" ) ?? "";
        window.localStorage.setItem( 'isFromCustomerChatRoomLogin', true );
        window.localStorage.removeItem( 'isFromCustomer' );
        window.localStorage.removeItem( 'isFromAdmin' );
        if( lineBind ) {
            setNOuaabindLine(false);
        } else {
            setNOuaabindLine(true);
        }
    }, []);    
    useEffect(() => {
        if( lineBind ) {
            setNOuaabindLine(false);
        } else {
            setNOuaabindLine(true);
        }
    }, [lineBind]);    
    
    /*** 取得加密的使用者資料 ***/
    const getCipherUserData = () => {
        const cpo = window.localStorage.getItem("cpo") ? window.localStorage.getItem("cpo") : ""; 
        if( !! accessToken && !! cpo ) {
            try {
                const userDataJson  = CryptoJS.AES.decrypt( cpo, `${accessToken}` );
                // console.log(userDataJson);
                const userDataUTF8 = userDataJson?.toString( CryptoJS.enc.Utf8 );
                // console.log(userDataUTF8);
                // const userDataUTF8 = CryptoJS.enc.Utf8.parse(userDataJson);
                const userDataDecode = JSON.parse( userDataUTF8 );
                // console.log(userDataDecode);
                // const userDataJson  = CryptoJS.AES.decrypt(cpo, accessToken);
                // const userDataDecode = JSON.parse(userDataJson?.toString(CryptoJS.enc.Utf8));
                setUserData( userData => ({...userData, userDataDecode}));
                globalUserData = userDataDecode;
            } catch (e) {
                console.log(e);
                return false;
            }                
        }   
    }
    useEffect(() => {
        getCipherUserData();
    }, [cpo, accessToken])
    /*** END 取得加密的使用者資料 ***/    

    /*** 確認Socket是否已連線 ***/
    const checkSocketIsConnected = async() => {
        setInterval(() => {
            let globalSocketDefault = Object.keys(globalSocketContext).length !== 0 ? globalSocketContext : null;
            setGlobalSocket(globalSocketDefault);
            console.log({globalSocketContext});
            console.log({globalSocketDefault});
            console.log({globalSocket});
            if( !! globalSocketDefault ) {
                const globalSocketConnected = globalSocketDefault?.connected || false;
                if( globalSocketConnected ) {
                    setIsGlobalSocketConnected(true);
                    // console.log('setIsGlobalSocketConnected(true)');
                } else {
                    setIsGlobalSocketConnected(false);
                    // console.log('setIsGlobalSocketConnected(false)');
                }
                globalSocketDefault.on("connect", () => {
                    setIsGlobalSocketConnected(true);
                    // console.log('setIsGlobalSocketConnected(true)');
                });  
            }
        }, 10000);
    }
    useEffect(() => {
        checkSocketIsConnected();
    }, []);    

    /*** 重設Socket連線Active ***/
    const resetSocketConnectActive = async(e) => {
        e?.preventDefault();
        globalSocketVar = globalSocketContext;
        let globalSocketDefault = globalSocketVar ?? null;
        setGlobalSocket(globalSocketDefault);
        console.log({globalSocketDefault});
        let isGlobalSocketConnectedTemp = globalSocketVar?.connected || false;
        setIsGlobalSocketConnected(isGlobalSocketConnectedTemp);
        console.log({isGlobalSocketConnectedTemp});
        await socketConnectActive();
        let resetSocketConnectActiveInterval = "";
        // if( ! isGlobalSocketConnectedTemp ) {
        //     resetSocketConnectActiveInterval = setInterval(() => {
        //         resetSocketConnectActive();
        //     }, 1000);
        // } else if( !! resetSocketConnectActiveInterval ) {
        //     clearInterval(resetSocketConnectActiveInterval);
        // }
    }

    /*** 登入後，Socket才進行連線 ***/
    /*** LIFF登入後，Socket才進行連線 ***/
    const socketConnectActive = async (e) => {
        e?.preventDefault();
        const init = await liff.init({
            liffId: liffId,
            permissions: ["profile"]
        })
        .then(async() => {
            const isLoggedIn = liff.isLoggedIn();
            if(isLoggedIn) {
                const accessToken = liff.getAccessToken();
                // console.log('accessToken', accessToken);
                if( !! accessToken ) {
                    /* Socket連線 */
                    const socket = io.connect(`${isWss ? 'wss' : 'ws'}://${host}/ws/line-chat`, {
                        transports: ['websocket'],
                        auth: {
                            LineAccessToken: accessToken,
                            Authorization: `Bearer ${loginToken}`,
                        },
                    }); /* END Socket連線 */
                    console.log(await socket);
                    setGlobalSocket(await socket);
                    socket.on("connect", async() => {
                        // console.log(socket);
                        // console.log(socket.connected);
                        let isGlobalSocketTemp = socket?.connected || false;
                        setIsGlobalSocketConnected(isGlobalSocketTemp);
                        console.log({isGlobalSocketTemp});
                        await userLeaveRoom(await socket);
                        await userJoinRoom(await socket);
                    });                    
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });
    } 
    // useEffect(() => {
    //     socketConnectActive();
    // }, [accessToken, roomId, host, isWss, liffId]);
    /*** END LIFF登入後，Socket才進行連線 ***/        

    /*** 使用者加入房間 ***/
    const userJoinRoom = async(socket) => {
        console.log({accessToken});
        // console.log({isGlobalSocketConnected});
        console.log( !! accessToken );
        console.log({socket});
        // socket.on("connect", () => {
        if( !! accessToken ) {
            console.log('userData', userData);
            const messageId = userData?.userId || "";
            console.log({messageId});
            socket.emit('joinRoom', {
                'lineAccessToken': accessToken, 
                // Begin reading the message ID, and if it is not defined, read the last message
                // messageId
            });
            setLeaveRoom(false);
            console.log({leaveRoom});
        }
        // });
    }/*** END 使用者加入房間 ***/ 

    /*** 使用者離開房間 ***/
    const userLeaveRoom = async(socket) => {
        console.log({accessToken});
        // console.log({isGlobalSocketConnected});
        console.log( !! accessToken );
        console.log({socket});
        // socket.on("connect", () => {
        if( !! accessToken ) {
            console.log('userData', userData);
            const messageId = userData?.userId || "";
            console.log({messageId});
            socket.emit('leaveRoom', {
                'lineAccessToken': accessToken, 
                // Begin reading the message ID, and if it is not defined, read the last message
                // messageId
            });
            setLeaveRoom(true);
            console.log({leaveRoom});
        }
        // });
    }/*** END 使用者離開房間 ***/    
    

    return (
        <>
            <style jsx>{thisStyle}</style>
            <section
                id="Customer"
                // backdrop="static"
                keyboard={false}
                dialogClassName={style.modalContainer}
                centered
            >
                <div className={style.modal}>
                {/* <div className={customerLiffPopupStyle.modalClose} onClick={(event) => {handleCloseCustomerLiffPopup();}}>x</div> */}
                    <div id={"customerLiffPopupStyleModalHeader"} className={style.modalHeader} >
                        {/* <Modal.Title className={customerLiffPopupStyle.modalTitle}></Modal.Title> */}
                        {/* <div className={customerLiffPopupStyle.modalBanner}>
                            <img src={PopupBanner}></img>
                        </div> */}
                        <div className={style.modalHeaderText}>
                            <h5>聯絡客服</h5>
                        </div>
                        <div style={{'display':'none'}}>
                        {/* <div style={{'color':'#FFF'}}> */}
                            {JSON.stringify(isGlobalSocketConnected)} {JSON.stringify( ! leaveRoom )} {JSON.stringify(isGlobalSocketConnected && ! leaveRoom)}
                        </div>
                        {/* 20230921 前端不顯示已連線及登出按鈕 */}
                        {/* { isGlobalSocketConnected && ! leaveRoom ? (
                            <div className={style.btnDiv}>
                                <Button 
                                    className={style.btnLink}
                                    text = {"已連線"} 
                                    onClick = {async(e) => {
                                        if( !! accessToken ) {
                                            // stopRunningUseEffect(e);
                                            // resetSocketConnectActive(e);
                                            await userLeaveRoom(globalSocket);
                                            await userJoinRoom(globalSocket);
                                            // setIsGlobalUserSocket(false);
                                            // setIsGlobalPanelSocket(false);
                                        } else {
                                            alert("連線參數錯誤，請確認是否已登入！");
                                        }
                                    }}
                                    backgroundColor = {'#61c56d'} 
                                    title ="重新連線" 
                                    icon = {{
                                        float:'left',
                                        size:15,
                                        component:<LinkOutlined />
                                    }}
                                />
                                <Button 
                                    className={style.btnLogout}
                                    text = {"登出"} 
                                    onClick = {(e) => {
                                        if( !! accessToken ) {
                                            logout(e);
                                        } else {
                                            alert("您已登出聊天室！");
                                        }
                                    }}
                                    backgroundColor = {'#61c56d'} 
                                    title ="登出聊天室" 
                                    icon = {{
                                        float:'left',
                                        size:15,
                                        component:<LogoutOutlined />
                                    }}
                                />
                            </div>
                        ) : (
                            <div className={style.btnDiv}>
                                <Button
                                    className={style.btnUnlink}
                                    text = {"未連線"} 
                                    onClick = {async(e) => {
                                        if( !! accessToken && !! host && !! isWss ) {
                                            // startRunningUseEffect(e);
                                            // resetSocketConnectActive(e);
                                            await userLeaveRoom(globalSocket);
                                            await userJoinRoom(globalSocket);
                                            // adminUserSocketConnectActive(e);
                                            // adminPanelSocketConnectActive(e);
                                        } else {
                                            alert("連線參數錯誤，請確認是否已登入！");
                                        }
                                    }}
                                    backgroundColor = {'rgb(200 100 100)'} 
                                    title ="重新連線" 
                                    icon = {{
                                        float:'left',
                                        size:15,
                                        component:<DisconnectOutlined />
                                    }}
                                />
                                <Button 
                                    className={style.btnLogout}
                                    text = {"登出"} 
                                    onClick = {(e) => {
                                        if( !! accessToken ) {
                                            logout(e);
                                        } else {
                                            alert("您已登出聊天室！");
                                        }
                                    }}
                                    backgroundColor = {'#61c56d'} 
                                    title ="登出聊天室" 
                                    icon = {{
                                        float:'left',
                                        size:15,
                                        component:<LogoutOutlined />
                                    }}
                                />
                            </div>
                        )}                             */}
                    </div>
                    <div className={style.modalBody} >
                        <CustomerChatRoom globalSocket={globalSocket}></CustomerChatRoom>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CustomerLiffChatRoom
