/* React */
import React from 'react';
/* ANTD */
import { Layout, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

/* Hooks */
import usePaginationContext from './usePaginationContext';
/* Store */
import { PaginationContextProvider } from './PaginationContext';
import { PaginationContextProvider as RichPaginationContextProvider } from './richmenu/store/PaginationContext';
/* Components */
import {
  LineCreateMessageForm,
  LineMessageCalendar,
  LineMessageTable,
} from './message';
import { IndexPage as LineRichMenuIndexPage } from './richmenu';
import LineNarrowcast from './components/LineNarrowcast';
import LineCastByTags from './components/LineCastByTags';
import LineTemplate from './components/LineTemplate';
import LineMessages from './components/LineMessages';
import LineFlexMessage from './components/LineFlexMessage';
/* Styles */
import style from './styles/Line.module.css';

function LINE_CONTENT() {
  const { curPage, navigate } = usePaginationContext();

  const { Content } = Layout;

  // const [castType, navigate] = useState('MessageTable');

  const menuItems = [
    {
      label: '傳送訊息',
      key: 'Message',
      icon: <GlobalOutlined />,
      children: [
        {
          label: '建立訊息組',
          key: 'MessageForm',
        },
        {
          label: '訊息列表',
          key: 'MessageTable',
        },
        {
          label: '查看預約',
          key: 'MessageCalendar',
        },
      ],
    },
    {
      label: 'Rich Menu',
      key: 'RichMenu',
    },
    {
      label: 'Flex Message',
      key: 'FlexMessage',
    },
  ];

  /*** 點選Menu Item ***/
  const clickCastTypeMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || '';
    if (key.includes('MessageForm')) {
      navigate('MessageForm');
    } else if (key.includes('MessageTable')) {
      navigate('MessageTable');
    } else if (key.includes('MessageCalendar')) {
      navigate('MessageCalendar');
    } else if (key.includes('Narrowcast')) {
      navigate('Narrowcast');
    } else if (key.includes('CastByTags')) {
      navigate('CastByTags');
    } else if (
      key.includes('Template') &&
      !key.includes('RichMenuCreateTemplate')
    ) {
      navigate('Template');
    } else if (key.includes('Messages')) {
      navigate('Messages');
    } else if (key.includes('RichMenu')) {
      navigate('RichMenu');
    } else if (key.includes('FlexMessage')) {
      navigate('FlexMessage');
    } else {
      navigate('');
    }
  };

  return (
    <Layout className={style.lineLayout}>
      <Menu
        style={{ borderLeft: '1px solid white' }}
        theme="dark"
        onClick={clickCastTypeMenuItem}
        selectedKeys={[curPage]}
        mode="horizontal"
        items={menuItems}
      />
      <Content className={style.lineContent}>
        {curPage === 'MessageForm' ? (
          <Content id="MessageForm">
            <LineCreateMessageForm id="MessageForm"></LineCreateMessageForm>
          </Content>
        ) : curPage === 'MessageTable' ? (
          <Content id="MessageTable">
            <LineMessageTable id="MessageTable"></LineMessageTable>
          </Content>
        ) : curPage === 'MessageCalendar' ? (
          <Content id="MessageCalendar">
            <LineMessageCalendar id="MessageCalendar"></LineMessageCalendar>
          </Content>
        ) : curPage === 'Narrowcast' ? (
          <Content id="Narrowcast">
            <LineNarrowcast id="LineNarrowcast"></LineNarrowcast>
          </Content>
        ) : curPage === 'CastByTags' ? (
          <Content id="CastByTags">
            <LineCastByTags id="LineCastByTags"></LineCastByTags>
          </Content>
        ) : curPage === 'Template' ? (
          <Content id="Template">
            <LineTemplate id="LineTemplate"></LineTemplate>
          </Content>
        ) : curPage === 'Messages' ? (
          <Content id="Messages">
            <LineMessages id="LineMessages"></LineMessages>
          </Content>
        ) : curPage === 'RichMenu' ? (
          <Content>
            <RichPaginationContextProvider>
              <LineRichMenuIndexPage id="RichMenu"></LineRichMenuIndexPage>
            </RichPaginationContextProvider>
          </Content>
        ) : curPage === 'FlexMessage' ? (
          <Content>
            <LineFlexMessage id="FlexMessage"></LineFlexMessage>
          </Content>
        ) : (
          <></>
        )}
      </Content>
    </Layout>
  );
}

export default function LINE() {
  return (
    <PaginationContextProvider>
      <LINE_CONTENT />
    </PaginationContextProvider>
  );
}
