/* React */
// import React from 'react';
/* ANTD */
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';

/* //////////////////////////////////////////////////////////////// */
export default function MessageListItemText({ field }) {
  /* JSX ---------------------------------------------------------- */
  return (
    <Form.Item
      name={[field.name, 'text']}
      rules={[
        { required: true, message: 'message 不可為空' },
        { max: 300, message: '訊息不可超過300字' },
      ]}
    >
      <TextArea rows={6} placeholder="message" />
    </Form.Item>
  );
}
