/* React */
// import React from 'react';
/* ANTD */
import { Button } from 'antd';

/* Hooks */
import usePaginationContext from '../../hooks/usePaginationContext';
/* Styles */
// import style from './CreateRichMenuButton.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function CreateRichMenuButton() {
  const { onNavigate } = usePaginationContext();

  const handleButtonClick = () => {
    onNavigate('create');
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <Button onClick={handleButtonClick} type="primary">
      新增圖文選單
    </Button>
  );
}
