import React from 'react'
import { useState, useContext, useRef } from 'react'
import LoginIndex from './LoginIndex'
import NoLineDataLogin from './NoLineDataLogin'
// import spinner from '../../Components/LoadingSpinner'
import spinner from '../../../Component/Spinner/Spinner'
import Register from './Register'
import RegisterPhone from './RegisterPhone'
import RegisterPassword from './RegisterPassword'
import RegisterEmail from './RegisterEmail'
import RegisterSuccess from './RegisterSuccess'
import AccountExist from './AccountExist'
import AuthContext from '../login/components/AuthContext'
import { Form } from 'react-bootstrap'
import FormPhoneUnlink from './FormPhoneUnlink'

function LoginParent(props) {
  const { email,authorized, setAuth, auth,setEmail } = useContext(AuthContext);
  //此props NOuaabindLine from UaaLogin line_liff_login api uaa 與 line帳號未綁定
  const {isFromCustomer,NOuaabindLine,setNOuaabindLine,setClearData,ClearData,LoginToken} = props;
  let LIFF_STOREregister = window.localStorage.getItem('LIFF_STOREregister')

  const [Sheet, setSheet] = useState('LoginIndex'); // 設定進入哪張表單
  const [smsveristatus, setsmsveristatus] = useState(false); // 檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕
  // const [lineRegister, setLineRegister] = useState(false); // 使用者是否是以LINE註冊

  return (
    <>
      {
         ( () => {
            if( Sheet==='LoginIndex' && ! NOuaabindLine && email===null ) {
                return (
                    <>
                        {/* 預設sheet LoginIndex 為登入表單 */}
                        <LoginIndex isFromCustomer={isFromCustomer} setSheet={setSheet} spinner={spinner}></LoginIndex>
                    </>
                )
            } else if ( Sheet==='NOuaabindLine' || window.localStorage.getItem("lineBind") === "false" ) {
                return (
                    <>
                      {/* 登入表單，進行line登入後 liff_loginapi 回傳無uaa & line帳號未綁定 */}
                      <NoLineDataLogin isFromCustomer={isFromCustomer} setSheet={setSheet} Sheet={Sheet} setNOuaabindLine={setNOuaabindLine} ></NoLineDataLogin>
                    </>
                )
            } else if ( Sheet==='register' ) {
              return(
                <> 
                  {/* 註冊頁面 */}
                  <Register setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></Register>
                </>

              )
            } else if ( Sheet==='registerPhone' ) {
              return(
                <> 
                  {/* 手機註冊頁面 */}
                  <RegisterPhone setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterPhone>
                </>

              )
            } else if ( Sheet==='registerPassword' ) {
              return(
                <> 
                  {/* 設定密碼頁面 */}
                  <RegisterPassword setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterPassword>
                </>

              )
            } else if ( Sheet==='registerEmail' ) {
              return(
                <> 
                  {/* 設定Email頁面 */}
                  <RegisterEmail setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterEmail>
                </>

              )
            } else if ( Sheet==='registerSuccess' ) {
              return(
                <> 
                  {/* 設定Email頁面 */}
                  <RegisterSuccess setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterSuccess>
                </>

              )
            } else if ( email!==null  && LIFF_STOREregister && Sheet!=='register' ) {
              return(
                <> 
                  {/* 登入表單，進行line登入後 liff_login api 回傳200，代表老會員進入帳號已存在  */}
                  <AccountExist isFromCustomer={isFromCustomer} setSheet={setSheet} setClearData={setClearData} LoginToken={LoginToken}></AccountExist>
                </>
              )
            } 
            // else if ( Sheet==='formPhoneUnlink' ) {
            //   <>
            //     {/* 進入解除手機綁定表單 */}
            //     <FormPhoneUnlink setSheet={setSheet} LoginToken={LoginToken}></FormPhoneUnlink>
            //   </>
            // }
         })()
      }
    </>
  )
}

export default LoginParent