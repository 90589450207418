/* React */
import { useMemo, useRef } from 'react';
/* ANTD */
import { UploadOutlined } from '@ant-design/icons';

/* Hooks */
import useRichMenuEditorContext from '../../hooks/useRichMenuEditorContext.js';
/* Components */
import AreasPreview from './AreasPreview.jsx';
/* Styles */
import style from './RichMenuImage.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function RichMenuImage() {
  const {
    uploadImageFile,
    uploadImageDimensions,
    onUploadImage,
    onPreviewImageLoaded,
  } = useRichMenuEditorContext();

  const imagePreviewUrl = useMemo(() => {
    if (!uploadImageFile) return null;
    return URL.createObjectURL(uploadImageFile);
  }, [uploadImageFile]);

  const figureRef = useRef();

  /**
   * Get upload image file and image file actual height and width
   */
  const handleImageUpload = (e) => {
    const uploadImage = e.target.files[0];

    if (!uploadImage) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const { height, width } = img;
        onUploadImage(uploadImage, { height, width });
      };
    };
    reader.readAsDataURL(uploadImage);
  };

  /**
   * Get this figure element display size on browser
   */
  const handleGetFigureRenderSize = () => {
    const figureEl = figureRef.current;
    if (!figureEl) return;
    const { height, width } = figureEl.getBoundingClientRect();
    onPreviewImageLoaded({ height, width });
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <div className={style['container']}>
      <div>
        {uploadImageDimensions.height !== 0 &&
        uploadImageDimensions.width !== 0 ? (
          <div className={style['image-detail']}>
            <span>您上傳的圖片尺寸為</span>
            <span>
              {uploadImageDimensions.width} * {uploadImageDimensions.height}
            </span>
          </div>
        ) : null}

        <label className={style['image-uploader']} htmlFor="image">
          <UploadOutlined />
          <span>上傳圖片</span>
        </label>

        <input
          id="image"
          type="file"
          accept="image/png, image/jpg"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </div>

      {imagePreviewUrl ? (
        <figure
          ref={figureRef}
          style={{ position: 'relative' }}
          className={style['preview-image__container']}
        >
          <img
            src={imagePreviewUrl}
            alt="upload preview"
            className={style['preview-image']}
            onLoad={handleGetFigureRenderSize}
          />

          <AreasPreview />
        </figure>
      ) : null}
    </div>
  );
}
