/* React */
import { useState } from 'react';
/* ANTD */
import { Input, Pagination, Select, Spin } from 'antd';

/* Hooks */
import { useGetMessages } from './hooks/useQueryMessage';
/* Components */
import { MessagesTable } from './Components';
/* Styles */
import style from './LineMessageTable.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function LineMessageTable() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [method, setMethod] = useState('all');

  const { data, isLoading } = useGetMessages({
    page,
    messagePerPage: 6,
    search,
    method,
  });

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const handleMethodChange = (method) => {
    setMethod(method);
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <section className={style['container']}>
      <div className={style['search-bar']}>
        <div className={style['search-bar__block']}>
          <label>標題篩選</label>
          <Input
            onChange={handleSearchChange}
            placeholder="輸入標題(最長20字)"
            maxLength={20}
          />
        </div>
        <div className={style['search-bar__block']}>
          <label>發送方式篩選</label>
          <Select
            style={{ width: 150 }}
            value={method}
            options={[
              { value: 'all', label: '全部' },
              { value: 'broadcast', label: 'broadcast' },
              { value: 'multicast', label: 'multicast' },
            ]}
            onChange={handleMethodChange}
          />
        </div>
      </div>

      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          <MessagesTable messagesData={data?.thisPageMessageData || []} />

          <Pagination
            className={style['pagination']}
            current={page}
            onChange={handlePageChange}
            pageSize={1}
            total={data?.totalPages}
          />
        </>
      )}
    </section>
  );
}
