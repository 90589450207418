import React, {useEffect, useState} from 'react'
import style from './styles/Wordpress.module.css'
import ComponentStyle from '../styles/Components.module.css'
import { Layout, Menu, Button } from 'antd';
import {
  MenuOutlined,
  GlobalOutlined,
  WifiOutlined,
  AimOutlined,
  LayoutOutlined,
  BarsOutlined,
  ShoppingOutlined,
  SettingOutlined,
  FileSearchOutlined,
  BookOutlined,
  Icon
} from '@ant-design/icons';
/*** 自訂SVG ICON ***/
import { ReactComponent as TeacherIcon } from '../../imgs/teacher.svg'; 
import { ReactComponent as ParentChildIcon } from '../../imgs/ParentChild.svg'; 
import { ReactComponent as TrainingIcon } from '../../imgs/training.svg'; 
import { ReactComponent as OnlineCourseIcon } from '../../imgs/OnlineCourse.svg'; 

/*** Import Wordpress Component ***/
import WpSetParentChildCourse from './components/WpSetParentChildCourse';
import WpReadParentChildCourse from './components/WpReadParentChildCourse';
import WpTrainingCourse from './components/WpTrainingCourse';
import WpOnlineCourse from './components/WpOnlineCourse';
import WpEntity from './components/WpEntity';
import WpSetAbility from './components/WpSetAbility';
import WpSetTeachingPlan from './components/WpSetTeachingPlan';

function Wordpress() {
  const {Content} = Layout;
  const [courseType, setCourseType] = useState('ReadParentChildCourse');
  const rootSubmenuKeys = ['Course']; // 設定phone submenu 根選單選項
  const [openKeys, setOpenKeys] = useState(['']); // 自動縮合不必要的phone submenu
  const [showPhoneMenu, setShowPhoneMenu] = useState(false); // 點選phone menu icon會顯示菜單
  const [wpReadParentChildCoursePage, setWpReadParentChildCoursePage] = useState("Search");
  const menuItems = [
    {
      label: '課程商品',
      key: 'Course',
      icon: <TeacherIcon style={{marginRight:"2px"}} width={"14px"} fill={"#DDD"} />,
      popupOffset: [-6, 3],
      children: [
        {
          label: '親子課程',
          key: 'ParentChildCourse',
          icon: <ParentChildIcon style={{marginRight:"2px"}} height={"14px"} fill={"#DDD"} />,
          popupOffset: [7, -4],
          children: [
            {
              label: '搜尋及編輯課堂／小孩資料／下載簽到表／請假補課單',
              key: 'ReadParentChildCourse',
              icon: <FileSearchOutlined />
            },
            {
              label: '設定課程',
              key: 'SetParentChildCourse',
              icon: <SettingOutlined />
            }
          ]
        },
        {
          label: '培訓課程',
          key: 'TrainingCourse',
          icon: <TrainingIcon style={{marginRight:"2px"}} height={"14px"} fill={"#DDD"} />
        },
        {
          label: '線上課程',
          key: 'OnlineCourse',
          icon: <OnlineCourseIcon style={{marginRight:"2px"}} width={"14px"} fill={"#DDD"} />
        },
        {
          label: '教案',
          key: 'TeachingPlan',
          icon: <BookOutlined style={{marginRight:"2px"}} width={"14px"} fill={"#DDD"} />,
          popupOffset: [7, -4],
          children: [
            {
              label: '重點能力設定',
              key: 'SetAbility',
              icon: <FileSearchOutlined />
            },
            {
              label: '設定教案',
              key: 'SetTeachingPlan',
              icon: <SettingOutlined />
            }
          ]
        },
      ],
    },
    {
      label: '實體商品',
      key: 'Entity',
      icon: <ShoppingOutlined />,
    },
  ];

  /*** 點選Menu Item ***/
  const clickCourseTypeMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || "";
    const childrenKey = e?.key || "";
    if (key.includes("ReadParentChildCourse")) {
      setCourseType("ReadParentChildCourse");
      setWpReadParentChildCoursePage("Search")
    } else if (key.includes("SetParentChildCourse")) {
      setCourseType("SetParentChildCourse");
    } else if (key.includes("TrainingCourse")) {
      setCourseType("TrainingCourse");
    } else if (key.includes("OnlineCourse")) {
      setCourseType("OnlineCourse");
    } else if (key.includes("Entity")) {
      setCourseType("Entity");
    } else if (key.includes("SetAbility")) {
      setCourseType("SetAbility");
    } else if (key.includes("SetTeachingPlan")) {
      setCourseType("SetTeachingPlan");
    } else {
      setCourseType("");
    }
  } 

  const onClick = (e) => {
    console.log('click ', e);
    setCourseType(e.key);
  }

  /*** 自動縮合未選中根選單 ***/
  const onOpenChange = (keys) => { // keys為本次要開啟的submenu
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1); // 本次要開啟的submenu是否在前次開啟的submenu列表中，並回傳本次要開啟的submenu key
    console.log("latestOpenKey",latestOpenKey)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) { // 本次要開啟的submenu key不是root submenu
      setOpenKeys(keys); // 將本次要開啟的sub submenu實際打開
    } else {  // 本次要開啟的submenu是root submenu
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []); // 本次要開啟的root submenu存在(要打開)，便讓其打開(每次操作只會有一個submenu被打開，故openKeys Array只有一個元素，前次開啟的submenu元素會被覆蓋)；若本次要開啟的root submenu不存在(要關閉)，則關閉所有submenu
    }
  }
  const closePhoneMenu = () => {
    const wpContentDom = document.getElementById("WordpressContent");
    wpContentDom.addEventListener("click", (e) => {
      e.preventDefault();
      setShowPhoneMenu(false);
    });
  }
  useEffect(() => {closePhoneMenu()}, []);

  return (
    <Layout id={"WordpressLayout"} className={style.wordpressLayout}>
      <Menu className={style.wordpressPCMenu} style={{borderLeft:'1px solid white'}} theme="dark" mode="horizontal" onClick={({ item, key, keyPath, domEvent }) => { clickCourseTypeMenuItem({ item, key, keyPath, domEvent })} } selectedKeys={[courseType]} items={menuItems} />
      <div className={style.wordpressPhoneMenu}>
        <div id="WordpressPhoneMenu" style={{ width: '100%', height: '45px', backgroundColor: '#001529', marginBottom: 1}}>
          <Button style={{ margin: 6 }} type="primary" onClick={(e)=>{
              setShowPhoneMenu(!showPhoneMenu);
            }}>
            <MenuOutlined />
          </Button>
        </div>
        {showPhoneMenu ? (
          <Menu
            // style={{ width: 220 }}
            theme="dark"
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            onClick={({ item, key, keyPath, domEvent }) => { clickCourseTypeMenuItem({ item, key, keyPath, domEvent })} } 
            selectedKeys={[courseType]}
            items={menuItems}
          />
        ):(
          <></>
        )}
      </div>
      <Content id={"WordpressContent"} className={style.wordpressContent}>
        {  courseType === "ReadParentChildCourse" ? (
          <Content id="ReadParentChildCourse">
            <WpReadParentChildCourse id="ReadParentChildCourse" wpReadParentChildCoursePage={wpReadParentChildCoursePage} setWpReadParentChildCoursePage={setWpReadParentChildCoursePage}></WpReadParentChildCourse>
          </Content>
        ) : courseType === "SetParentChildCourse" ? (
          <Content id="SetParentChildCourse">
            <WpSetParentChildCourse id="WpSetParentChildCourse"></WpSetParentChildCourse>
          </Content>
        ) : courseType === "TrainingCourse" ? (
          <Content id="TrainingCourse">
            <WpTrainingCourse id="WpTrainingCourse"></WpTrainingCourse>
          </Content>
        ) : courseType === "OnlineCourse" ? (
          <Content id="OnlineCourse">
            <WpOnlineCourse id="WpOnlineCourse"></WpOnlineCourse>
          </Content>
        ) : courseType === "Entity" ? (
          <Content id="Entity">
            <WpEntity id="WpEntity"></WpEntity>
          </Content>
        ) : courseType === "SetAbility" ? (
          <Content id="setAbility">
            <WpSetAbility id="WpSetAbility"></WpSetAbility>
          </Content>
        ) : courseType === "SetTeachingPlan" ? (
          <Content id="SetTeachingPlan">
            <WpSetTeachingPlan id="WpSetTeachingPlan"></WpSetTeachingPlan>
          </Content>
        ) : (
          <></>
        )}
      </Content>
    </Layout>
  )
}

export default Wordpress