/* React */
// import React from 'react';

/* Styles */
import style from './Label.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function Label({ children }) {
  /* JSX ---------------------------------------------------------- */
  return <label className={style['label']}>{children}</label>;
}
