/* React */
import { useState } from 'react';
/* ANTD */
import { Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';

/* Styles */
import style from './inputAction.module.css';

const selectOptions = [
  { value: 'message', label: '傳送訊息' },
  { value: 'uri', label: '連結' },
];

/* //////////////////////////////////////////////////////////////// */
export default function InputAction({ field }) {
  const [type, setType] = useState('message');

  const handleSelectChange = (type) => {
    setType(type);
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <div className={style['input-action__container']}>
      <Form.Item name={[field.name, 'action', 'type']} initialValue={type}>
        <Select
          style={{ width: 120 }}
          options={selectOptions}
          onChange={handleSelectChange}
        />
      </Form.Item>

      <div className={style['input-action__label-input']}>
        <Form.Item
          name={[field.name, 'action', 'label']}
          rules={[{ required: true, message: '標籤名稱不可為空' }]}
        >
          <Input placeholder="標籤名稱" maxLength={300} />
        </Form.Item>
      </div>

      <div className={style['input-action__content']}>
        {type === 'message' ? (
          <Form.Item
            name={[field.name, 'action', 'text']}
            rules={[{ required: true, message: '訊息不可為空' }]}
          >
            <TextArea placeholder="請輸入要傳送的訊息" maxLength={300} />
          </Form.Item>
        ) : null}

        {type === 'uri' ? (
          <Form.Item
            name={[field.name, 'action', 'uri']}
            rules={[{ required: true, message: '連結不可為空' }]}
          >
            <Input placeholder="請輸入連結" maxLength={1000} />
          </Form.Item>
        ) : null}
      </div>
    </div>
  );
}
