/* React */
// import React from 'react';
/* ANTD */
import { Button, Form } from 'antd';

/* Hooks */
import useRichMenuEditorContext from '../../hooks/useRichMenuEditorContext';
/* Components */
import InputAction from './InputAction';
import InputBound from './InputBound';
/* Styles */
import style from './RichMenuAreasFormItem.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function RichMenuAreasFormItem({
  fields,
  field,
  remove,
  index,
}) {
  const { onRemoveAreaRow } = useRichMenuEditorContext();

  const handleRemoveBlock = () => {
    remove(field.name);
    onRemoveAreaRow(index);
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <Form.Item>
      <div className={style['form-item__container']}>
        <div>
          <label className={style['label']}>區塊 {index + 1}</label>
          <div className={style['form-item__row--1']}>
            <InputBound field={field} />
            {fields.length > 1 ? (
              <Button onClick={handleRemoveBlock}>刪除區塊</Button>
            ) : null}
          </div>
        </div>

        <InputAction field={field} />
      </div>
    </Form.Item>
  );
}
