import React, { useEffect, useState } from 'react';
import {
  Layout,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Space,
  DatePicker,
  TimePicker,
  Button,
  Dropdown,
  Form,
  List,
  Modal,
  Tag,
} from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import style from './styles/WpTrainingCourse.module.css';
import '../styles/WordpressAnt.css';
import dayjs from 'dayjs';

/*** Import Wordpress Components ***/
// import WpTrainingCourseComponents from './WordpressComponents/WpTrainingCourseComponents';
// import WordpressCastInterface from "./WordpressComponents/WordpressCastInterface"

function WpTrainingCourse() {
  const { Content } = Layout;
  const { CheckableTag } = Tag;
  const [btnLoadings, setBtnLoadings] = useState([]);
  const [sendingTime, setSendingTime] = useState(0);
  const [showInputNumber, setShowInputNumber] = useState(false);
  const [checkList, setCheckList] = useState([{}]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [templateData, setTemplateData] = useState([
    { _id: '001', name: '測試模板1' },
    { _id: '002', name: '測試模板2' },
    { _id: '003', name: '測試模板3' },
  ]); // 模板資訊
  const [recentTemplate, setRecentTemplate] = useState([]);

  /*** 載入常用模板 ***/
  const recentTemplateLoad = () => {
    const recentTemplateString = window.localStorage.getItem('rctatt');
    const recentTemplateArray = !!recentTemplateString
      ? JSON.parse(recentTemplateString)
      : [];
    setRecentTemplate(recentTemplateArray);
  };
  useEffect(() => {
    console.log(recentTemplate);
  }, [recentTemplate]);
  useEffect(() => {
    recentTemplateLoad();
  }, []);
  useEffect(() => {
    recentTemplateLoad();
  }, [templateData]); /*** END 載入常用模板 ***/
  const handleTemplateModalOk = () => {
    setOpenTemplateModal(false);
  };
  const handleTemplateModalCancel = () => {
    setOpenTemplateModal(false);
  };

  const items = [
    {
      label: '草稿1',
      key: '1',
    },
    {
      label: '草稿2',
      key: '2',
    },
    {
      label: '草稿3',
      key: '3',
    },
    {
      label: '草稿4',
      key: '4',
    },
  ];
  const recentAttrTest = [
    {
      key: '647593c28b71e19c5dac639d',
      label: '模板2',
    },
    {
      key: '647593d08b71e19c5dac63a0',
      label: '模板3',
    },
    {
      key: '647593e18b71e19c5dac63a3',
      label: '模板4',
    },
    {
      key: '647593e18b71e19c5dac63a3',
      label: '模板4',
    },
  ];

  const enterLoading = (index) => {
    setBtnLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setBtnLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const radioOnChange = (e) => {
    console.log('radio checked', e.target.value);
    setSendingTime(e.target.value);
  };

  const datePickerOnChange = (time, timeString) => {
    console.log(time, timeString);
    setSendingTime(1);
  };

  const timePickerOnChange = (time, timeString) => {
    console.log(time, timeString);
    setSendingTime(1);
  };

  const checkboxOnChange = (event) => {
    const id = event?.target?.id || '';
    const checked = event?.target?.checked || false;
    const checkElement = { id, checked };
    if (Object.values(checkList[0]).length > 0) {
      // CheckList 有值，取代或新增check資料
      let checkListArray = new Array();
      let hasSameId = false;
      checkListArray = checkList.map((obj) => {
        const objId = obj?.id || '';
        if (objId === id) {
          obj.checked = checked; // 取代check資料
          hasSameId = true;
        }
        return obj;
      });
      if (!hasSameId) {
        // 未比對到同ID的物件，新增check資料
        setCheckList((current) => [...current, checkElement]);
      } else {
        // 有比對到同ID的物件，取代原CheckList
        setCheckList(checkListArray);
      }
    } else {
      // 無值則新增check資料
      let checkListArray = new Array();
      checkListArray.push(checkElement);
      setCheckList(checkListArray);
    }
    // const santiCheckList = [...new Set(checkList.map(itm => JSON.stringify(itm)))].map(i => JSON.parse(i));
    // console.log(santiCheckList);
    let msgLimitIsChecked = false;
    for (let el of checkList) {
      // msgLimit前次設定的值
      if (Object.values(el).includes('msgLimit')) {
        msgLimitIsChecked = el?.checked || false;
      }
    }
    if ((id === 'msgLimit' && checked) || msgLimitIsChecked) {
      // msgLimit事件觸發設定的值或前次設定的值
      setShowInputNumber(true);
    } else {
      setShowInputNumber(false);
    }
  };
  useEffect(() => {
    console.log(checkList);
  }, [checkList]);

  const msgLimitOnChange = (value) => {
    console.log(value);
  };

  /*** 取得模板設定列表 ***/
  const getTemplateSettingList = (e, urlArg) => {
    if (!!e) {
      e.preventDefault();
    }
    console.log('getTemplateSettingList');
    console.log(urlArg);
    const apiUrl = process.env.REACT_APP_APIURL;
    const url = !!urlArg
      ? urlArg
      : apiUrl + '/chat-tags' + '?limit=50&offset=0';
    console.log(url);
    // fetch(url, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    // })
    // .then((r) => r.json())
    // .then((result) => {
    //     console.log(result);
    //     /*
    //         [
    //             {
    //                 "_id": "64547fd77af19c5afde1c548",
    //                 "name": "string",
    //                 "category": "default",
    //                 "description": " ",
    //                 "priority": 0,
    //                 "__v": 0
    //             }
    //         ]
    //     */
    //     if( Array.isArray(result) && result.length > 0 ) {
    //         let resultExist = false;
    //         for( let v of result ) {
    //         const settingTagId = v?._id || "";
    //         if( !!settingTagId ) {
    //             resultExist = true;
    //             break;
    //         }
    //         }
    //         console.log(resultExist);
    //         if( resultExist ) {
    //         setAudienceData(result);
    //         }
    //     }
    // })
    // .catch((error) => {
    //     console.log('error',error);
    // });
  };
  useEffect(() => {
    getTemplateSettingList();
  }, []);
  useEffect(() => {
    console.log(templateData);
  }, [templateData]);
  /*** END 取得模板設定列表 ***/

  /*** 新增模板 ***/
  const addTemplate = (triggerTagId, checked) => {
    console.log(triggerTagId);
    console.log(checked);
    // const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    // if( globalAdminPanelSocketConnected ) {
    // globalAdminPanelSocket.emit('addAudience', {
    //     tagIds: [triggerTagId],
    // });
    // handleAddTag(triggerTagId, checked); // 目前無法判斷是否真的新增成功(無回傳值)
    // console.log('User Detail : AddUserTags Emit !');
    // } else {
    // console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    // }
  }; /*** END 新增模板 ***/

  /*** 刪除模板 ***/
  const removeTemplate = (triggerTagId) => {
    console.log('removeTemplate', triggerTagId);
    // const globalAdminPanelSocketConnected = globalAdminPanelSocket?.connected || false;
    // if( globalAdminPanelSocketConnected ) {
    // globalAdminPanelSocket.emit('removeAudience', {
    //     tagId: triggerTagId,
    // });
    // console.log('User Detail : removeAudience Emit !');
    // handleCloseTag(triggerTagId); // 目前無法判斷是否真的刪成功(無回傳值)
    // } else {
    // console.log('User Detail : GlobalAdmin【Panel】SocketConnected not Connected !');
    // alert("刪除失敗（與伺服器連線中斷）！")
    // }
  };
  /*** END 刪除模板 ***/

  return (
    <>
      <Form
        id={'WpTrainingCourseForm'}
        className={style.wpTrainingCourseForm}
        onFinish={() => {}}
      >
        <h4 className={style.wpTrainingCourseH4}>培訓課程</h4>
        {/* <Form.Item className={`${style.wpTrainingCourseFormItem} ${style.wpTrainingCourseFormItemBtn}`} name="button">
            <Space className={style.wpTrainingCourseSpaceSendBtn}>
                <Dropdown.Button
                    className={style.wpTrainingCourseSendBtn}
                    type="primary"
                    icon={<DownOutlined/>}
                    // loading={btnLoadings[0]}
                    menu={{ items }}
                    placement="bottom"
                    onClick={() => enterLoading(0)}
                >
                    儲存草稿
                </Dropdown.Button>                    
                <Button className={style.wpTrainingCourseSendBtn} type="primary" // loading={btnLoadings[1]} onClick={() => enterLoading(1)}>
                    傳送測試訊息
                </Button>
            </Space>
        </Form.Item>
        <Form.Item className={style.wpTrainingCourseFormItem} name="template" showCount>
          <div className={style.wpTrainingCourseDivAudience} id="WpTrainingCourse">
            <h6 className={style.wpTrainingCourseH6}>模板設定</h6>
            <Content className={`${style.wpTrainingCourseContent} ${style.wpTrainingCourseSpace}`}>
                <section id="WpTrainingCourseSection">
                    <Space className={`${style.wpTrainingCourseSpace} ${style.wpTrainingCourseSpaceTemplate}`} direction="vertical">
                        <div className={style.wpTrainingCourseDivFilterByTemplate}>
                            <Button className={style.wpTrainingCourseSendBtn} type="primary" // loading={btnLoadings[3]} onClick={() => { enterLoading(3); setOpenTemplateModal(true); }}  icon={<SearchOutlined />}>
                                篩選模板
                            </Button>
                            <Dropdown.Button
                                className={`${style.wpTrainingCourseSendBtn} ${style.wpTrainingCourseDropdown}`}
                                type="primary"
                                icon={<DownOutlined/>}
                                // loading={btnLoadings[4]}
                                menu={{ items:recentTemplate }}
                                placement="bottom"
                                onClick={() => enterLoading(4)}
                            >
                                常用模板
                            </Dropdown.Button>
                        </div>    
                        <Modal
                            getContainer={document.getElementById("WpTrainingCourseSection")}
                            className={style.wpTrainingCourseeModalTemplate}
                            open={openTemplateModal}
                            footer={null}
                            onOk={handleTemplateModalOk}
                            onCancel={handleTemplateModalCancel}
                        >
                            <WpTrainingCourseComponents></WpTrainingCourseComponents>
                        </Modal>    
                        <div className={`${style.wpTrainingCourseTemplateContainer}`}>
                            { templateData.length > 0 ? (
                                templateData.map((v, i) => {
                                    let templateId = v?._id || "";
                                    let templateName = v?.name || "";
                                    return (
                                    <>
                                        <TweenOneGroup
                                        enter={{
                                            scale: 0.8,
                                            opacity: 0,
                                            type: 'from',
                                            duration: 100,
                                        }}
                                        onEnd={(e) => {
                                            if (e.type === 'appear' || e.type === 'enter') {
                                            e.target.style = 'display: inline-block';
                                            }
                                        }}
                                        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                        appear={false}
                                        >
                                            <span  style={{ display: 'inline-block' }}>
                                                <Tag
                                                    className={`${style.wpTrainingCourseTags} ${style.wpTrainingCourseTemplateTags}`}
                                                    color="geekblue"
                                                    closable
                                                    onClose={(e) => {
                                                        e.preventDefault();
                                                        removeTemplate(templateId);
                                                    }}
                                                    >
                                                    {templateName}
                                                </Tag>
                                            </span>  
                                        </TweenOneGroup>
                                    </>
                                    )
                                })
                            ) : (
                                <div className={`${style.wpTrainingCourseTag} ${style.wpTrainingCourseNoTags}`}>
                                    <div className={`${style.wpTrainingCourseSubTitle}`}><span>此使用者未設定任何模板</span></div>
                                </div>
                            )}
                        </div>
                    </Space>  
                </section>
              </Content>                  
          </div>
        </Form.Item>             */}
      </Form>
    </>
  );
}

export default WpTrainingCourse;
