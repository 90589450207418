import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import style from './styles/TagsRead.module.css';
import '../styles/TagsAnt.css';
import './styles/TagsComponentsRce.css';
import './styles/TagsComponentsAnt.css';
import {
  Layout,
  Menu,
  Input,
  Space,
  Button,
  InputNumber,
  List,
  Radio,
  Tag,
  Pagination,
} from 'antd';
import {
  EyeOutlined,
  EditFilled,
  EditOutlined,
  RollbackOutlined,
  DeleteFilled,
  DeleteOutlined,
} from '@ant-design/icons';

function Tags() {
  const [tagsCRUD, setTagsCRUD] = useState('TagsRead');
  const [searchString, setSearchString] = useState(''); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(6); // 一頁有幾個Tag數
  const [tagsDataArray, setTagsDataArray] = useState([{}]); // 各標籤資料
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項tag資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState(
    {}
  ); //更新欄位狀態
  const tagsNameRef = useRef('');
  const tagsCategoryRef = useRef('');
  const tagsDescriptionRef = useRef('');
  const tagsPriorityRef = useRef('');
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || '';
    setSearchString(val);
  };
  const onSearch = () => {
    console.log(currentPage);
    requestTags();
    setIsEditMode([-1, false]); // 返回非編輯模式
  };

  /*** 查詢Tags ***/
  const requestTags = async () => {
    const url =
      apiURL +
      '/chat-tags?' +
      new URLSearchParams({
        filter: searchString,
        // limit: numberPerPage,
        // offset: currentPage-1
      });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`標籤查詢失敗！\n${err}`);
        throw new Error('RequestTags failed');
      });
    console.log(response);
    setTagsDataArray(response);
    // setUpdateFieldsOnChangeState(response);
    // try {
    //   const response = await axios.get({
    //     method: 'get',
    //     url: url,
    //     responseType: 'json',
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  };
  useEffect(() => {
    requestTags();
  }, []);
  useEffect(() => {
    console.log(tagsDataArray);
  }, [tagsDataArray]);

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  const clickTagsMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || '';
    if (key.includes('TagsRead')) {
      setTagsCRUD('TagsRead');
    } else if (key.includes('TagsAdd')) {
      setTagsCRUD('TagsAdd');
    } else if (key.includes('TagsUpdate')) {
      setTagsCRUD('TagsUpdate');
    } else if (key.includes('TagsDelete')) {
      setTagsCRUD('TagsDelete');
    } else {
      setTagsCRUD('');
    }
  };

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 更新欄位onChange ***/
  const updateFieldsOnChange = (e) => {
    e.preventDefault();
    // const value = e?.value || "";
    const name = tagsNameRef?.current?.input?.value || '';
    const category = tagsCategoryRef?.current?.input?.value || '';
    const description = tagsDescriptionRef?.current?.input?.value || '';
    const priority = parseInt(tagsPriorityRef?.current?.input?.value) || 0;
    const data = { name, category, description, priority };
    console.log(tagsNameRef);
    console.log(tagsNameRef.current);
    console.log(value);
    console.log(data);
    setUpdateFieldsOnChangeState(data);
  };

  /*** 更新標籤 ***/
  const updateTag = async (e, id) => {
    e.preventDefault();
    const url = apiURL + '/chat-tags/' + id;
    console.log(url);
    console.log(updateFieldsOnChangeState);
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(updateFieldsOnChangeState),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`標籤更新失敗！\n${err}`);
        throw new Error('Update Tag failed');
      });
    console.log(JSON.stringify(response));
    const responseId = response?._id || '';
    if (!!responseId) {
      if (Object.values(tagsDataArray).length > 0) {
        for (let el of tagsDataArray) {
          if (Object.values(el).includes(id)) {
            el['name'] = (await response?.name) || '';
            el['category'] = (await response?.category) || '';
            el['description'] = (await response?.description) || '';
            el['priority'] = (await response?.priority) || 0;
            alert(`標籤內容已更新！\n( ID：${id} )`);
          }
        }
        setIsEditMode([-1, false]); // 返回非編輯模式
      }
    }
  };

  /*** 刪除標籤 ***/
  const deleteTag = async (e, id, name) => {
    e.preventDefault();
    if (window.confirm(`確定要刪除【${name}】標籤？\n( ID：${id} )`)) {
      const url = apiURL + '/chat-tags/' + id;
      console.log(url);
      console.log(updateFieldsOnChangeState);
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: '*/*',
        },
      })
        // .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.ok) {
            for (let [index, el] of tagsDataArray.entries()) {
              if (Object.values(el).includes(id)) {
                tagsDataArray.splice(index, 1);
                alert(`【${el['name']}】標籤已刪除！\n（ID：${id}）`);
              }
            }
            setIsEditMode([-1, false]); // 返回非編輯模式
          } else {
            alert(`標籤刪除失敗！`);
            throw new Error('Delete Tag failed');
          }
        })
        .catch((err) => {
          alert(`標籤刪除失敗！\n${err}`);
          throw new Error('Delete Tag failed');
        });
      // const responseId = response?._id || "";
      // if( !! responseId ) {
      // }
    } else {
      return;
    }
  };

  /*** 分頁顯示設定 ***/
  const data = [
    // 樣板資料
    {
      _id: '001',
      name: '測試標籤1',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '002',
      name: '測試標籤2',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '003',
      name: '測試標籤3',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試標籤4',
      category: '標籤類別',
      description: '標籤描述',
      priority: 0,
      __v: 0,
    },
  ];
  // <Tag color="magenta">magenta</Tag>
  // <Tag color="red">red</Tag>
  // <Tag color="volcano">volcano</Tag>
  // <Tag color="orange">orange</Tag>
  // <Tag color="gold">gold</Tag>
  // <Tag color="lime">lime</Tag>
  // <Tag color="green">green</Tag>
  // <Tag color="cyan">cyan</Tag>
  // <Tag color="blue">blue</Tag>
  // <Tag color="geekblue">geekblue</Tag>
  // <Tag color="purple">purple</Tag>

  useEffect(() => {
    console.log(isEditMode);
    console.log(isEditMode[0]);
    console.log(isEditMode[1]);
  }, [isEditMode]);
  return (
    <Layout id="tagsReadLayout" className={style.tagsReadLayout}>
      {/* <div className={`${ComponentStyle.title}`}><h1>Tags</h1></div> */}
      <Content className={style.tagsReadOuterContent}>
        <Content className={style.tagsReadContent}>
          <section id={'tagsSearchSec'} className={style.tagsReadSearchSec}>
            <div className={style.tagsReadSearchDiv}>
              <Search
                className={`${style.tagsReadInput} ${style.tagsReadSearch}`}
                addonBefore={
                  <span className={style.tagsReadSearchTitle}>標籤查詢</span>
                }
                placeholder="請輸入欲查詢的標籤名稱"
                allowClear
                size="large"
                onChange={(e) => {
                  searchOnChange(e);
                }}
                onSearch={onSearch}
                enterButton
              />
            </div>
            <List
              className={style.tagsReadList}
              locale={{}}
              pagination={{
                position: 'bottom',
                align: 'center',
                hideOnSinglePage: true,
                showQuickJumper: true,
                // total: 50,
                current: currentPage,
                pageSize: numberPerPage,
                onChange: changePage,
              }}
              dataSource={tagsDataArray}
              renderItem={(item, index) =>
                Object.keys(tagsDataArray[0]).includes('_id') &&
                isEditMode[0] === index &&
                isEditMode[1] ? (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <div className={style.tagsReadTagIconDiv}>
                          <Tag
                            className={style.tagsReadTagIcon}
                            color="geekblue"
                          >
                            {item.name}
                          </Tag>
                        </div>
                      }
                      title={
                        <div className={style.tagsReadTagDescriptionItem}>
                          <span className={style.tagsReadTagDescriptionID}>
                            {'標籤ID：'}
                          </span>
                          <span className={style.tagsReadTagDescriptionText}>
                            {item._id}
                          </span>
                          <div className={style.tagsReadTagEditAndDelIconDiv}>
                            <RollbackOutlined
                              className={style.tagsReadTagEditAndDelIcon}
                              onClick={(e) => {
                                setIsEditMode(false);
                              }}
                            />
                            <DeleteOutlined
                              className={style.tagsReadTagEditAndDelIcon}
                              onClick={(e) => {
                                deleteTag(e, item._id, item.name);
                              }}
                            />
                            {/* <DeleteFilled className={style.tagsReadTagIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div
                          className={style.tagsReadTagDescriptionDiv}
                          style={{ justifyContent: 'center' }}
                        >
                          <div
                            className={`${style.tagsReadTagDescriptionItem} ${style.tagsReadTagDescriptionItemName}`}
                          >
                            <span
                              className={`${style.tagsReadTagDescriptionLabel} ${style.tagsReadTagDescriptionLabelName}`}
                            >
                              {'標籤名稱：'}
                            </span>
                            <Input
                              className={`${style.tagsReadTagDescriptionInput} ${style.tagsReadTagDescriptionInputName}`}
                              type="text"
                              defaultValue={item.name}
                              ref={tagsNameRef}
                              maxLength={10}
                              onChange={updateFieldsOnChange}
                            ></Input>
                          </div>
                          <div
                            className={`${style.tagsReadTagDescriptionItem} ${style.tagsReadTagDescriptionItemCategory}`}
                          >
                            <span
                              className={`${style.tagsReadTagDescriptionLabel} ${style.tagsReadTagDescriptionLabelCategory}`}
                            >
                              {'標籤類別：'}
                            </span>
                            <Input
                              className={`${style.tagsReadTagDescriptionInput} ${style.tagsReadTagDescriptionInputCategory}`}
                              type="text"
                              defaultValue={item.category}
                              ref={tagsCategoryRef}
                              maxLength={20}
                              onChange={updateFieldsOnChange}
                            ></Input>
                          </div>
                          <div
                            className={`${style.tagsReadTagDescriptionItem} ${style.tagsReadTagDescriptionItemDescription}`}
                          >
                            <span
                              className={`${style.tagsReadTagDescriptionLabel} ${style.tagsReadTagDescriptionLabelDescription}`}
                            >
                              {'標籤描述：'}
                            </span>
                            <Input
                              className={`${style.tagsReadTagDescriptionInput} ${style.tagsReadTagDescriptionInputDescription}`}
                              type="text"
                              defaultValue={item.description}
                              ref={tagsDescriptionRef}
                              maxLength={40}
                              onChange={updateFieldsOnChange}
                            ></Input>
                          </div>
                          <div
                            className={`${style.tagsReadTagDescriptionItem} ${style.tagsReadTagDescriptionItemPriority}`}
                          >
                            <span
                              className={`${style.tagsReadTagDescriptionLabel} ${style.tagsReadTagDescriptionLabelPriority}`}
                            >
                              {'優先權：'}
                            </span>
                            <Input
                              className={`${style.tagsReadTagDescriptionInput} ${style.tagsReadTagDescriptionInputPriority}`}
                              type="number"
                              defaultValue={item.priority}
                              ref={tagsPriorityRef}
                              maxLength={2}
                              onChange={updateFieldsOnChange}
                            ></Input>
                          </div>
                          <div
                            className={`${style.tagsReadTagDescriptionItem} ${style.tagsReadTagDescriptionItemUpdateBtn}`}
                          >
                            <Button
                              className={`${style.tagsReadTagDescriptionInputUpdateBtn}`}
                              type={'primary'}
                              size={'small'}
                              onClick={(e) => {
                                updateTag(e, item._id);
                              }}
                            >
                              更新
                            </Button>
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                ) : Object.keys(tagsDataArray[0]).includes('_id') ? (
                  <List.Item>
                    <List.Item.Meta
                      className={style.tagsReadListItemMeta}
                      avatar={
                        <Tag className={style.tagsReadTagIcon} color="geekblue">
                          {item.name}
                        </Tag>
                      }
                      title={
                        <div
                          className={`${style.tagsReadTagDescriptionItem} ${style.tagsReadTagDescriptionItemTitle}`}
                        >
                          <span className={style.tagsReadTagDescriptionID}>
                            {'標籤ID：'}
                          </span>
                          <span className={style.tagsReadTagDescriptionText}>
                            {item._id}
                          </span>
                          <div className={style.tagsReadTagEditAndDelIconDiv}>
                            <EditOutlined
                              className={style.tagsReadTagEditAndDelIcon}
                              onClick={(e) => {
                                setIsEditMode([index, true]);
                              }}
                            />
                            {/* <EditFilled className={style.tagsReadTagIcon} /> */}
                            <DeleteOutlined
                              className={style.tagsReadTagEditAndDelIcon}
                              onClick={(e) => {
                                deleteTag(e, item._id, item.name);
                              }}
                            />
                            {/* <DeleteFilled className={style.tagsReadTagIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div className={style.tagsReadTagDescriptionDiv}>
                          <div className={style.tagsReadTagDescriptionItem}>
                            <span className={style.tagsReadTagDescriptionLabel}>
                              {'標籤名稱：'}
                            </span>
                            <span className={style.tagsReadTagDescriptionText}>
                              {item.name}
                            </span>
                          </div>
                          <div className={style.tagsReadTagDescriptionItem}>
                            <span className={style.tagsReadTagDescriptionLabel}>
                              {'標籤類別：'}
                            </span>
                            <span className={style.tagsReadTagDescriptionText}>
                              {item.category}
                            </span>
                          </div>
                          <div className={style.tagsReadTagDescriptionItem}>
                            <span className={style.tagsReadTagDescriptionLabel}>
                              {'標籤描述：'}
                            </span>
                            <span className={style.tagsReadTagDescriptionText}>
                              {item.description}
                            </span>
                          </div>
                          <div className={style.tagsReadTagDescriptionItem}>
                            <span className={style.tagsReadTagDescriptionLabel}>
                              {'優先權：'}
                            </span>
                            <span className={style.tagsReadTagDescriptionText}>
                              {item.priority}
                            </span>
                          </div>
                          {/* <div className={style.tagsReadTagDescriptionItem}>
                            <span className={style.tagsReadTagDescriptionLabel}>{"其他資訊："}</span><span className={style.tagsReadTagDescriptionText}>{item.__v}</span>
                          </div> */}
                        </div>
                      }
                    />
                  </List.Item>
                ) : (
                  <></>
                )
              }
            />
          </section>
        </Content>
      </Content>
    </Layout>
  );
}

export default Tags;
