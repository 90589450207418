/* React */
import { useState } from 'react';

/* Hooks */
import { useGetRichMenus } from './hooks/useQueryRichMenu';
/* Components */
import {
  CreateRichMenButton,
  RichMenuList,
  ToggleDeleteButton,
} from './Components';

/* Styles */
import style from './LineRichMenuConsole.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function LineRichMenuConsole() {
  const [inDeleteMode, setInDeleteMode] = useState(false);

  const { data, isLoading } = useGetRichMenus({});

  const { thisPageRichMenuData } = data || {};

  const handleToggleDeleteMode = () => {
    setInDeleteMode((inDeleteMode) => !inDeleteMode);
  };

  /* JSX ---------------------------------------------------------- */
  if (isLoading) return null; // [TEMP] use loading spinner latter

  if (thisPageRichMenuData === undefined && !isLoading) return null;

  return (
    <section className={style['console__container']}>
      <div className={style['console__buttons']}>
        <CreateRichMenButton />
        <ToggleDeleteButton
          inDeleteMode={inDeleteMode}
          onToggleDeleteMode={handleToggleDeleteMode}
        />
      </div>

      <RichMenuList
        richMenusData={thisPageRichMenuData}
        inDeleteMode={inDeleteMode}
      />
    </section>
  );
}
