/* React */
// import React from 'react';
/* ANTD */
import { CloseOutlined } from '@ant-design/icons';

/* Hooks */
import useDialog from '../../../../../../../hooks/useDialog';
import { useDeleteRichMenu } from '../../hooks/useQueryRichMenu';
/* Styles */
import style from './DeleteRichMenuButton.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function DeleteRichMenuButton({ lineRichMenuId }) {
  const { onDeleteRichMenu } = useDeleteRichMenu();

  const dialog = useDialog();

  const handleClickDeleteButton = () => {
    dialog.confirm({
      message: '是否要刪除此圖文選單',
      onConfirmCb: () => onDeleteRichMenu({ lineRichMenuId }),
    });
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <button onClick={handleClickDeleteButton} className={style['button']}>
      <CloseOutlined />
    </button>
  );
}
