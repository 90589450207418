import { httpRequest } from '../../../utils/httpRequest';

/* //////////////////////////////////////////////////////////////// */
const RICH_MENUS_PER_PAGE = 6;
export const getRichMenus = async ({ page, offset, search }) => {
  const urlParams = new URLSearchParams();
  if (page) urlParams.set('limit', RICH_MENUS_PER_PAGE);
  if (page) urlParams.set('offset', (page - 1) * RICH_MENUS_PER_PAGE);
  if (search) urlParams.set('limit', search);

  const res = await httpRequest(`/rich-menu?${urlParams.toString()}`);

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};
