/* React */
// import React from 'react';
import { createPortal } from 'react-dom';
/* ANTD */
import { Spin } from 'antd';

/* Styles */
import style from './LoadingSpinner.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function LoadingSpinner({ fullscreen }) {
  /* JSX ---------------------------------------------------------- */
  if (!fullscreen) return <Spin />;

  return createPortal(
    <div className={style['backdrop']}>
      <Spin size="large" />
    </div>,
    document.body
  );
}
