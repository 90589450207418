/* React */
import { useState } from 'react';
/* Day */
import dayjs from 'dayjs';
/* ANTD */
import { Button, Descriptions, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';

/* Hooks */
import useDialog from '../../../../../../../hooks/useDialog';
import {
  useGetMessageById,
  useConfirmMessage,
  useCancelMessage,
} from '../../hooks/useQueryMessage';
/* Components */
import { Modal } from '../../../../../../../Component/ui';
import { MessageForm } from '../MessageForm';
import MessageListItem from './MessageListItem';
/* Styles */
import style from './MessageDetailModal.module.css';

import { sendMethodMap, approvingStatusMap } from './MessagesTable';

/* //////////////////////////////////////////////////////////////// */
export default function MessageDetailModal({
  onCloseModal, // [NOTE] Modal component provide props
  lineMessageId,
}) {
  const [confirmedComment, setConfirmedComment] = useState('');
  const [cancelComment, setCancelComment] = useState('');

  const dialog = useDialog();

  const { onCancelMessage } = useCancelMessage(onCloseModal);
  const { onConfirmMessage } = useConfirmMessage(onCloseModal);
  const { data } = useGetMessageById(lineMessageId);
  const { isSupervisor, message } = data || {};
  const {
    _id,
    purpose, // one row
    sendMethod, // one row
    approvingStatus, // one row with blow
    isSendImmediately,
    estimatedSendingTime,
    publishUserId, // one row with blow
    createdAt,
    sentAt,
    sendingStatus, //
    canceledAt,
    cancelComment: getCancelComment,
    cancelSendingUser,
    confirmedAt,
    confirmedComment: getConfirmedCommit,
    confirmedUserId,
    // lastModifiedUserId, //
    // modifiedAt,
    sendMessages, // table
  } = message || {};

  const isShowConfirmForm = isSupervisor && approvingStatus === 'wait';
  const isShowCancelForm =
    !isSupervisor &&
    sendingStatus !== 'cancel' &&
    approvingStatus === 'agree' &&
    isSendImmediately;
  const isShowEditButton =
    sendingStatus !== 'cancel' && approvingStatus === 'wait';
  const isConfirmed = confirmedAt && getConfirmedCommit && confirmedUserId;
  const isCanceled = canceledAt && getCancelComment && cancelSendingUser;
  const isSended = sendingStatus === 'sent' && sentAt;

  const handleConfirmedCommentChange = (e) => {
    setConfirmedComment(e.target.value);
  };
  const handleCancelCommentChange = (e) => {
    setCancelComment(e.target.value);
  };
  const handleAllowSend = () => {
    dialog.confirm({
      message: '同意發送訊息？',
      onConfirmCb: () =>
        onConfirmMessage({
          reqData: {
            agreeToSend: true,
            confirmedComment,
          },
          lineMessageId,
        }),
    });
  };
  const handleRejectSend = () => {
    dialog.confirm({
      message: '拒絕發送訊息？',
      onConfirmCb: () =>
        onConfirmMessage({
          reqData: {
            agreeToSend: false,
            confirmedComment,
          },
          lineMessageId,
        }),
    });
  };
  const handleCancelSend = () => {
    dialog.confirm({
      message: '取消發送預約？',
      onConfirmCb: () =>
        onCancelMessage({
          reqData: {
            cancelComment,
          },
          lineMessageId,
        }),
    });
  };

  /* JSX ---------------------------------------------------------- */
  if (!data)
    return (
      <div
        className={`${style['container']} ${style['loading-spinner__container']}`}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <div className={style['container']}>
      <div style={{ marginBottom: '1rem' }}>
        {isShowEditButton ? (
          <Modal>
            <Modal.Content>
              <MessageForm asModal lineMessageId={_id} />
            </Modal.Content>

            <div className={style['mutation-form__buttons']}>
              <Modal.OpenButton>
                <Button>編輯訊息</Button>
              </Modal.OpenButton>
            </div>
          </Modal>
        ) : null}

        {isShowConfirmForm ? (
          <div className={style['mutation-form']}>
            <p style={{ margin: 0 }}>審核</p>
            <TextArea
              rows={3}
              placeholder="回饋訊息(最長200字)"
              value={confirmedComment}
              onChange={handleConfirmedCommentChange}
              maxLength={200}
            />
            <div className={style['mutation-form__buttons']}>
              <Button onClick={handleAllowSend}>同意發送</Button>
              <Button onClick={handleRejectSend}>拒絕請求</Button>
            </div>
          </div>
        ) : null}

        {isShowCancelForm ? (
          <div className={style['mutation-form']}>
            <p style={{ margin: 0 }}>取消</p>
            <TextArea
              rows={3}
              placeholder="取消原因"
              value={cancelComment}
              onChange={handleCancelCommentChange}
            />
            <div className={style['mutation-form__buttons']}>
              <Button onClick={handleCancelSend}>取消發送</Button>
            </div>
          </div>
        ) : null}
      </div>

      {isCanceled ? (
        <>
          <p
            className={`${style['message-state']} ${style['message-state--canceled']}`}
          >
            此訊息已於&nbsp;{dayjs(canceledAt).format('YY-MM-DD HH:mm:ss')}
            &nbsp;由&nbsp;
            {cancelSendingUser}&nbsp;取消預約
          </p>
          <p className={style['message-state__comment']}>
            取消原因：{getCancelComment}
          </p>
        </>
      ) : null}

      {isSended ? (
        <p
          className={`${style['message-state']} ${style['message-state--sended']}`}
        >
          此訊息已於&nbsp;{dayjs(sentAt).format('YY-MM-DD HH:mm:ss')}
          &nbsp;發送
        </p>
      ) : null}

      {/* Form */}
      <Descriptions bordered column={{ xs: 1, sm: 2, md: 2 }}>
        <Descriptions.Item label="標題" span={2}>
          {purpose}
        </Descriptions.Item>
        <Descriptions.Item label="發送方式" span={2}>
          {sendMethodMap[sendMethod]}
        </Descriptions.Item>
        <Descriptions.Item label="建立時間">
          <DateAndTime time={createdAt} />
        </Descriptions.Item>
        <Descriptions.Item label="預約時間">
          {isSendImmediately ? (
            '立即發送'
          ) : (
            <DateAndTime time={estimatedSendingTime} />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="審核狀態" span={confirmedUserId ? 1 : 2}>
          {approvingStatusMap[approvingStatus]}
        </Descriptions.Item>
        {confirmedUserId ? (
          <Descriptions.Item label="審核人員">
            {confirmedUserId}
          </Descriptions.Item>
        ) : null}
        {isConfirmed ? (
          <Descriptions.Item label="審核訊息" span={2}>
            {getConfirmedCommit}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label="建立者" style={{ width: 'max-content' }}>
          {publishUserId}
        </Descriptions.Item>
      </Descriptions>

      {/* Messages */}
      {sendMessages?.map((message, index) => (
        <MessageListItem key={index} message={message} index={index} />
      ))}
    </div>
  );
}

/* //////////////////////////////////////////////////////////////// */
function DateAndTime({ time }) {
  /* JSX ---------------------------------------------------------- */
  return (
    <div style={{ width: 'max-content', textAlign: 'center' }}>
      <span>{dayjs(time).format('YYYY-MM-DD')}</span>
      <br />
      <span>{dayjs(time).format('HH:mm:ss')} (GMT+8)</span>
    </div>
  );
}
