/* React */
// import React from 'react';
/* ANTD */
import { Button } from 'antd';

/* Styles */
import style from './ToggleDeleteButton.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function ToggleDeleteButton({
  inDeleteMode,
  onToggleDeleteMode,
}) {
  /* JSX ---------------------------------------------------------- */
  return (
    <Button className={style['button']} onClick={onToggleDeleteMode}>
      {inDeleteMode ? '離開刪除模式' : '刪除圖文選單'}
    </Button>
  );
}
