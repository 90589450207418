/* React */
// import { useState } from 'react';
/* ANTD */
import { Switch } from 'antd';
/* Dayjs */
import dayjs from 'dayjs';

/* Hooks */
import { useSetRichMenuAsDefault } from '../../hooks/useQueryRichMenu';
/* Components */
import DeleteRichMenuButton from './DeleteRichMenuButton';
/* Styles */
import style from './RichMenuItem.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function RichMenuItem({ richMenuData, inDeleteMode }) {
  const {
    name,
    lineRichMenuId,
    isDefault: getIsDefault,
    createdAt,
  } = richMenuData;

  const { onSetRichMenuAsDefault } = useSetRichMenuAsDefault();

  const handleToggleIsDefault = async (checked) => {
    onSetRichMenuAsDefault({ lineRichMenuId });
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <li className={style['rich-menu-item']}>
      {inDeleteMode && !getIsDefault ? (
        <DeleteRichMenuButton lineRichMenuId={lineRichMenuId} />
      ) : null}

      <div className={style['rich-menu-item__info']}>
        <h4 className={style['rich-menu-item__name']}>{name}</h4>

        <p className={style['rich-menu-item__create-at']}>
          建立於 {dayjs(createdAt).format('YYYY-MM-DD HH:mm')}
        </p>

        <div className={style['rich-menu-item__control']}>
          <span>預設</span>
          <Switch
            id={`rich-menu-switch-${lineRichMenuId}`}
            size="small"
            onChange={handleToggleIsDefault}
            checked={getIsDefault}
            disabled={getIsDefault}
          />
        </div>
      </div>
    </li>

    // <li className={style['rich-menu-item']}>
    //   {inDeleteMode && !getIsDefault ? (
    //     <DeleteRichMenuButton lineRichMenuId={lineRichMenuId} />
    //   ) : null}

    //   {/* [TODO] click image to go edit page */}
    //   <figure>
    //   </figure>

    //   <div className={style['rich-menu-item__info']}>
    //     <div className={style['rich-menu-item__control']}>
    //       <h4 className={style['rich-menu-item__name']}>{name}</h4>
    //       <Switch
    //         id={`rich-menu-switch-${lineRichMenuId}`}
    //         size="small"
    //         onChange={handleToggleIsDefault}
    //         checked={getIsDefault}
    //         disabled={getIsDefault}
    //       />
    //     </div>
    //     <p className={style['rich-menu-item__create-at']}>
    //       建立於 {dayjs(createdAt).format('YYYY-MM-DD HH:mm')}
    //     </p>
    //   </div>
    // </li>
  );
}
