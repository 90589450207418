/* React */
import { useCallback, useContext } from 'react';

/* Store */
import { DialogContext } from '../store/DialogContext';

/* //////////////////////////////////////////////////////////////// */
export default function useDialog() {
  const { onOpenDialog } = useContext(DialogContext);

  const error = useCallback(
    ({ message }) => {
      console.log('useDialog error');
      onOpenDialog({ type: 'error', title: '錯誤', message });
    },
    [onOpenDialog]
  );

  const confirm = useCallback(
    ({ message, onConfirmCb }) => {
      onOpenDialog({ type: 'confirm', title: '確認', message, onConfirmCb });
    },
    [onOpenDialog]
  );

  const dialog = {
    error,
    confirm,
  };

  return dialog;
}
