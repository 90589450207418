/* React */
// import React from 'react';
/* ANTD */
import { Button, Form } from 'antd';

/* Component */
import MessageListItem from './MessageListItem';

/* //////////////////////////////////////////////////////////////// */
export default function MessageList({ sendMessagesData }) {
  /* JSX ---------------------------------------------------------- */
  return (
    <Form.Item>
      <Form.List
        name="sendMessages"
        rules={[
          {
            validator: (_, sendMessages) => {
              if (sendMessages.length === 0) return Promise.reject();
              return Promise.resolve();
            },
            message: '請輸入至少一則訊息',
          },
        ]}
        initialValue={sendMessagesData?.sendMessages}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <MessageListItem
                key={index}
                fields={fields}
                field={field}
                add={add}
                remove={remove}
                index={index}
                defaultType={sendMessagesData?.sendMessages?.[index]?.type}
              />
            ))}

            {fields.length < 5 ? (
              <Form.Item style={{ marginTop: '0.5rem' }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '100%' }}
                >
                  新增單一訊息
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            ) : null}
          </>
        )}
      </Form.List>
    </Form.Item>
  );
}
