import React, { useEffect, useState, useRef } from 'react';
import {
  Layout,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Space,
  DatePicker,
  TimePicker,
  Button,
  Dropdown,
  Form,
  List,
  Modal,
} from 'antd';
import {
  DownOutlined,
  EyeOutlined,
  EditOutlined,
  RollbackOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import style from './styles/WpReadParentChildCourseChildrenList.module.css';
import wpReadParentChildCourseStyle from '../styles/WpReadParentChildCourse.module.css';
import '../../styles/WordpressAnt.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import arraySupport from 'dayjs/plugin/arraySupport';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import ReactExport from 'react-export-excel';

/*** Import Wordpress Component ***/
import WpApplyAbsenceModal from './WpApplyAbsenceModal';

function WpReadParentChildCourseChildrenList(props) {
  const { setWpReadParentChildCoursePage, wpReadParentChildClass } = props;
  dayjs.extend(relativeTime);
  const { Content } = Layout;
  const [btnLoadings, setBtnLoadings] = useState([]);
  const [sendingTime, setSendingTime] = useState(0);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [showInputNumber, setShowInputNumber] = useState(false);
  const [checkList, setCheckList] = useState([{}]);
  const [dropdownTextState, setDropdownTextState] = useState('請選擇教室編號');
  const [afterFilterShow, setAfterFilterShow] = useState({ display: 'block' }); // 篩選完顯示區塊
  const [showApplyAbsenceModal, setShowApplyAbsenceModal] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState('');
  const [selectedChildName, setSelectedChildName] = useState('');
  useEffect(() => {
    console.log(selectedChildId, selectedChildName);
  }, [selectedChildId, selectedChildName]);
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState(
    {}
  ); //更新欄位狀態
  useEffect(() => {
    console.log(updateFieldsOnChangeState);
  }, [updateFieldsOnChangeState]);
  const [sidHasAppliedAbsence, setSidHasAppliedAbsence] = useState([]); // 學生ID是否完成請假補課
  useEffect(() => {
    console.log(sidHasAppliedAbsence);
  }, []);
  const [triggerStuIdChange, setTriggerStuIdChange] = useState(false);
  const nameRef = useRef('');
  const birthdayRef = useRef('');
  const emailRef = useRef('');
  const telRef = useRef('');
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const wpApiURL = process.env.REACT_APP_WPAPIURL; // API URL
  // const [courseDataArray, setCourseDataArray] = useState([
  //     { _id: "001", name: "預設課程名稱1", location: "預設教室名稱1", date: new Date(2020, 1, 13), startTime: new Date(0, 0, 0, 10, 30), endTime: new Date(0, 0, 0, 11, 0), maximum: "1" },
  //     { _id: "002", name: "預設課程名稱2", location: "預設教室名稱2", date: new Date(2021, 2, 1), startTime: "預設開始時間2", endTime: "預設結束時間2", maximum: "2" },
  //     { _id: "003", name: "預設課程名稱3", location: "預設教室名稱3", date: new Date(2022, 2, 1), startTime: "預設開始時間3", endTime: "預設結束時間3", maximum: "3" },
  //     { _id: "003", name: "預設課程名稱3", location: "預設教室名稱3", date: new Date(2020, 4, 1), startTime: "預設開始時間3", endTime: "預設結束時間3", maximum: "3" },
  //     { _id: "003", name: "預設課程名稱3", location: "預設教室名稱3", date: new Date(2021, 5, 1), startTime: "預設開始時間3", endTime: "預設結束時間3", maximum: "3" },
  //     { _id: "003", name: "預設課程名稱3", location: "預設教室名稱3", date: new Date(2022, 6, 1), startTime: "預設開始時間3", endTime: "預設結束時間3", maximum: "3" },
  //     { _id: "003", name: "預設課程名稱3", location: "預設教室名稱3", date: new Date(2021, 7, 1), startTime: "預設開始時間3", endTime: "預設結束時間3", maximum: "3" },
  //     { _id: "003", name: "預設課程名稱3", location: "預設教室名稱3", date: new Date(2020, 8, 1), startTime: "預設開始時間3", endTime: "預設結束時間3", maximum: "3" }
  // ]); // 課程資料
  const [kidsDataArray, setKidsDataArray] = useState([]);
  useEffect(() => {
    console.log(kidsDataArray);
  }, [kidsDataArray]);
  const [kidsDataArrayTest, setKidsDataArrayTest] = useState([
    {
      _id: '001',
      studentId: 's001',
      name: '陳XX',
      birthday: dayjs.utc(new Date(2020, 1, 11)).format(),
      age: 3,
      email: 'oooo@gmail.com',
      tel: '0912xxxxxx',
      report: 'https://google.com',
      consult: 'https://msn.com',
    },
    {
      _id: '002',
      studentId: 's002',
      name: '林XX',
      birthday: dayjs.utc(new Date(2021, 2, 6)).format(),
      age: 2,
      email: 'oooo@gmail.com',
      tel: '0921xxxxxx',
      report: 'https://google.com',
      consult: 'https://msn.com',
    },
    {
      _id: '004',
      studentId: 's003',
      name: '黃XX',
      birthday: dayjs.utc(new Date(2022, 3, 8)).format(),
      age: 1,
      email: 'oooo@gmail.com',
      tel: '0933xxxxxx',
      report: 'https://google.com',
      consult: 'https://msn.com',
    },
    {
      _id: '005',
      studentId: 's004',
      name: '李XX',
      birthday: dayjs.utc(new Date(2021, 4, 12)).format(),
      age: 2,
      email: 'oooo@gmail.com',
      tel: '0943xxxxxx',
      report: 'https://google.com',
      consult: 'https://msn.com',
    },
    {
      _id: '006',
      studentId: 's005',
      name: '張XX',
      birthday: dayjs.utc(new Date(2020, 5, 21)).format(),
      age: 3,
      email: 'oooo@gmail.com',
      tel: '0944xxxxxx',
      report: 'https://google.com',
      consult: 'https://msn.com',
    },
    {
      _id: '007',
      studentId: 's006',
      name: '吳XX',
      birthday: dayjs.utc(new Date(2020, 6, 12)).format(),
      age: 3,
      email: 'oooo@gmail.com',
      tel: '0967xxxxxx',
      report: 'https://google.com',
      consult: 'https://msn.com',
    },
    {
      _id: '008',
      studentId: 's007',
      name: '朱XX',
      birthday: dayjs.utc(new Date(2022, 7, 31)).format(),
      age: 1,
      email: 'oooo@gmail.com',
      tel: '0954xxxxxx',
      report: 'https://google.com',
      consult: 'https://msn.com',
    },
    {
      _id: '009',
      studentId: 's008',
      name: '王XX',
      birthday: dayjs.utc(new Date(2021, 8, 23)).format(),
      age: 2,
      email: 'oooo@gmail.com',
      tel: '0921xxxxxx',
      report: 'https://google.com',
      consult: 'https://msn.com',
    },
  ]); // 小孩資料
  useEffect(() => {
    console.log(kidsDataArray);
  }, []);
  useEffect(() => {
    console.log(kidsDataArrayTest);
  }, []);
  // useEffect(()=>{console.log(dayjs('1999-01-01').toNow())},[]);
  // useEffect(()=>{console.log(dayjs('1999-01-01').toNow(true))},[]);
  // useEffect(()=>{console.log(dayjs().diff(dayjs(kidsDataArrayTest[0]?.birthday), "year"))},[]);
  // useEffect(()=>{console.log(courseDataArray)},[courseDataArray]);
  useEffect(() => {
    console.log(wpReadParentChildClass);
  }, [wpReadParentChildClass]);
  const [filterFieldsOnChangeState, setFilterFieldsOnChangeState] = useState({
    name: '',
    location: '',
    date: '',
    startTime: '',
    endTime: '',
  }); // 篩選標籤欄位onChange狀態
  useEffect(() => {
    const location = wpReadParentChildClass[0]?.location || '';
    setFilterFieldsOnChangeState(wpReadParentChildClass[0]);
    setDropdownTextState(location);
  }, []);
  useEffect(() => {
    const location = wpReadParentChildClass[0]?.location || '';
    setFilterFieldsOnChangeState(wpReadParentChildClass[0]);
    setDropdownTextState(location);
  }, [wpReadParentChildClass]);
  useEffect(() => {
    console.log(filterFieldsOnChangeState);
  }, [filterFieldsOnChangeState]);
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  const items = [
    {
      label: 'RM301',
      key: 'RM301',
      onClick: ({ item, key, keyPath, domEvent }) => {
        console.log(key);
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['location']: key,
        });
        setDropdownTextState(key);
      },
    },
    {
      label: 'RM302',
      key: 'RM302',
      onClick: ({ item, key, keyPath, domEvent }) => {
        console.log(key);
        setFilterFieldsOnChangeState({
          ...filterFieldsOnChangeState,
          ['location']: key,
        });
        setDropdownTextState(key);
      },
    },
  ];

  /*** 查詢修課學生列表 ***/
  const requestStudents = async (e, _id) => {
    e?.preventDefault();
    if (e && !_id) {
      alert('小孩資料列表查詢失敗！\n（Error：_id dose not exist）');
      return false;
    }
    const urlClass = `${wpApiURL}/kidproCourse/getStudentIdFromCoursesByClassId/${_id}`;
    const responseClass = await fetch(urlClass, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(`課堂資料查詢失敗！\nFrom requestStudents(), ${err}`);
        throw new Error('Request class data failed');
      });
    // const studentIds = await responseClass?.students || [];
    // let studentDataArray = [];
    // let studentDataArrayLength = 0;
    const studentDetails = (await responseClass?.studentDetails) || {};
    let studentDataArray = Object.values(studentDetails);
    for (let el of studentDataArray) {
      el['age'] = dayjs().diff(dayjs(el?.birthday), 'year');
    }
    // for( let studentId of studentIds ) {
    //     const urlStudent = `${wpApiURL}/kidproCourse/getStudentByStuId/${studentId}`;
    //     const responseStudent = await fetch(
    //         urlStudent,
    //         {
    //             method: 'GET',
    //             headers: {
    //                 "Accept": "application/json",
    //             },
    //         }
    //     )
    //     .then((res) => res.json())
    //     .catch((err) => {
    //         // alert(`小孩資料查詢失敗！\n${err}`);
    //         console.log(`小孩資料查詢失敗！\nStudent ID：${studentId}, ${err}`);
    //         throw new Error("Request kid data failed");
    //     });
    //     responseStudent['age'] = dayjs().diff(dayjs(responseStudent?.birthday), "year");
    //     studentDataArrayLength = studentDataArray.push(await responseStudent);
    // }
    // console.log(studentDataArrayLength);
    if (studentDataArray?.length > 0 && !!studentDataArray[0]?.studentId) {
      console.log(studentDataArray);
      setKidsDataArray(studentDataArray);
    } else {
      console.log(
        `此堂課無任何小孩資料！\n( Error：${responseClass.error}, ${responseClass.message} )`
      );
    }
    // if( Array.isArray(responseClass) ) {
    //     // const reponseData = response?.data || [];
    //     console.log(responseClass);
    //     setKidsDataArray(responseClass);
    // } else {
    //     console.log(`此堂課無任何小孩資料！\n( Error：${responseClass.error}, ${responseClass.message} )`)
    // }
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  useEffect(() => {
    requestStudents(null, wpReadParentChildClass?._id);
  }, []);

  const enterLoading = (index) => {
    setBtnLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
  };

  const endLoading = (index) => {
    setBtnLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  };

  /*** 小孩資料更新欄位onChange ***/
  const kidsUpdateOnChange = async (e, defClsId, defStuId, defStuName) => {
    e?.preventDefault();
    const fieldId = e?.target?.id || '';
    const value = e?.target?.value || '';
    console.log(fieldId, value, defClsId, defStuId, defStuName);
    switch (fieldId) {
      case 'studentName':
        setUpdateFieldsOnChangeState({
          ...updateFieldsOnChangeState,
          ['studentName']: value,
        });
        break;
      case 'studentBirthday':
        setUpdateFieldsOnChangeState({
          ...updateFieldsOnChangeState,
          ['birthday']: dayjs.utc(value + ' 00:00').format(),
        });
        break;
      case 'studentEmail':
        setUpdateFieldsOnChangeState({
          ...updateFieldsOnChangeState,
          ['email']: value,
        });
        break;
      case 'studentTel':
        setUpdateFieldsOnChangeState({
          ...updateFieldsOnChangeState,
          ['tel']: value,
        });
        break;
    }
  };

  const radioOnChange = (e) => {
    console.log('radio checked', e.target.value);
    setSendingTime(e.target.value);
  };

  const datePickerOnChange = (time, timeString) => {
    console.log(time, timeString);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['date']: timeString,
    });
    // setSendingTime(1);
  };

  const timePickerOnChange = (timeArray, timeStringArray) => {
    console.log(timeStringArray);
    console.log(timeStringArray[0], timeStringArray[1]);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['startTime']: timeStringArray[0],
      ['endTime']: timeStringArray[1],
    });
    // setSendingTime(1);
  };

  const checkboxOnChange = (event) => {
    const id = event?.target?.id || '';
    const checked = event?.target?.checked || false;
    const checkElement = { id, checked };
    if (Object.values(checkList[0]).length > 0) {
      // CheckList 有值，取代或新增check資料
      let checkListArray = new Array();
      let hasSameId = false;
      checkListArray = checkList.map((obj) => {
        const objId = obj?.id || '';
        if (objId === id) {
          obj.checked = checked; // 取代check資料
          hasSameId = true;
        }
        return obj;
      });
      if (!hasSameId) {
        // 未比對到同ID的物件，新增check資料
        setCheckList((current) => [...current, checkElement]);
      } else {
        // 有比對到同ID的物件，取代原CheckList
        setCheckList(checkListArray);
      }
    } else {
      // 無值則新增check資料
      let checkListArray = new Array();
      checkListArray.push(checkElement);
      setCheckList(checkListArray);
    }
    let msgLimitIsChecked = false;
    for (let el of checkList) {
      // msgLimit前次設定的值
      if (Object.values(el).includes('msgLimit')) {
        msgLimitIsChecked = el?.checked || false;
      }
    }
    if ((id === 'msgLimit' && checked) || msgLimitIsChecked) {
      // msgLimit事件觸發設定的值或前次設定的值
      setShowInputNumber(true);
    } else {
      setShowInputNumber(false);
    }
  };
  useEffect(() => {
    console.log(checkList);
  }, [checkList]);

  /*** 篩選欄位onChange ***/
  // const filterFieldsOnChange = (e) => {
  //     e.preventDefault();
  //     const fieldId = e?.target?.id || "";
  //     const value = e?.target?.value || "";
  //     if( !! fieldId ) {
  //     console.log(fieldId);
  //     console.log(value);
  //     switch(fieldId) {
  //         case "CourseName":
  //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['name']: value});
  //             break;
  //         case "CourseCategory":
  //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['location']: value});
  //             break;
  //         case "CourseDescription":
  //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['date']: value});
  //             break;
  //         case "CoursePriority":
  //             const intValue = parseInt(value);
  //             setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ['time']: intValue});
  //             break;
  //     }
  //     }
  // }
  // useEffect(() => {
  //     console.log(filterFieldsOnChangeState);
  // }, [filterFieldsOnChangeState]);

  const { Search } = Input;
  /*** 搜尋欄位onChange ***/
  const searchOnChange = (event) => {
    const val = event?.target?.value || '';
    // setSearchString(val);
    setFilterFieldsOnChangeState({
      ...filterFieldsOnChangeState,
      ['name']: val,
    });
  };

  /*** 搜尋按鈕 ***/
  // const onSearch = () => {
  //     requestCourse();
  // }

  /*** 查詢Course ***/
  // const requestCourse = async(e) => {
  //     e?.preventDefault();
  //     // console.log(filterFieldsOnChangeState["name"])
  //     const url = apiURL + "/course?" + new URLSearchParams({
  //         filter: filterFieldsOnChangeState,
  //     });
  //     console.log(url);
  //     // console.log(searchString);
  //     const response = await fetch(
  //         url,
  //         {
  //             headers: {
  //             Accept: "application/json",
  //             },
  //         }
  //     )
  //     .then((res) => res.json())
  //     .catch((err) => {
  //         // alert(`標籤查詢失敗！\n${err}`);
  //         throw new Error("RequestCourse failed");
  //     });
  //     if( Array.isArray(response) && response.length > 0 ) {
  //         console.log(response);
  //         setCourseDataArray(response);
  //         setAfterFilterShow({"display":"block"});  // 顯示篩選後標籤
  //     }
  // }

  /*** 日期格式驗證 ***/
  const dateValidationCheck = async (str) => {
    let re = new RegExp('^([0-9]{4})[.-]{1}([0-9]{1,2})[.-]{1}([0-9]{1,2})$');
    let strDataValue;
    let infoValidation = true;
    if ((strDataValue = re.exec(str)) != null) {
      let i;
      i = parseFloat(strDataValue[1]);
      if (i <= 0 || i > 9999) {
        infoValidation = false;
      }
      i = parseFloat(strDataValue[2]);
      if (i <= 0 || i > 12) {
        infoValidation = false;
      }
      i = parseFloat(strDataValue[3]);
      if (i <= 0 || i > 31) {
        infoValidation = false;
      }
    } else {
      infoValidation = false;
    }
    if (!infoValidation) {
      alert('請輸入 YYYY-MM-DD 日期格式');
    }
    return infoValidation;
  };

  /*** 更新小孩資料 ***/
  const updateKid = async (
    e,
    _id,
    studentId,
    studentName,
    birthday,
    email,
    tel
  ) => {
    console.log({ _id, studentId, studentName, birthday, email, tel });
    e?.preventDefault();
    if (!(await _id)) {
      alert('課堂ID不存在！');
      return false;
    }
    if (!(await studentId)) {
      alert('小孩ID不存在！');
      return false;
    }
    // console.log(updateFieldsOnChangeState);
    // const uptName = updateFieldsOnChangeState?.name ?? name;
    // const uptBirthday = updateFieldsOnChangeState?.birthday ?? birthday;
    // const uptEmail = updateFieldsOnChangeState?.email ?? email;
    // const uptTel = updateFieldsOnChangeState?.tel ?? tel;
    // const uptObj = { uptName, uptBirthday, uptEmail, uptTel };
    const uptName = nameRef?.current?.input?.value || '';
    const uptBirthday = birthdayRef?.current?.input?.value || '';
    const uptEmail = emailRef?.current?.input?.value || '';
    const uptTel = telRef?.current?.input?.value || '';
    const uptObj = {
      studentName: uptName,
      birthday: uptBirthday,
      email: uptEmail,
      tel: uptTel,
    };
    console.log(uptObj);
    if (!(await dateValidationCheck(dayjs(uptBirthday).format('YYYY-MM-DD')))) {
      return false;
    }
    const url =
      wpApiURL + '/kidproCourse/updateOneStudentByStudentId/' + studentId;
    console.log(url);
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(uptObj),
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`小孩資料更新失敗！\n${err}`);
        throw new Error('Update Tag failed');
      });
    console.log(response);
    // const responseId = response?._id || "";
    // const responseStuId = response?.studentId || "";
    // if( !! responseId && !! responseStuId ) {
    //     if( Object.values(kidsDataArray).length > 0 ) {
    //         for( let el of kidsDataArray ) {
    //             if( Object.values(el).includes(responseId) ) {
    //                 el['name'] = await response?.name || "";
    //                 el['birthday'] = await response?.birthday || "";
    //                 el['tel'] = await response?.tel || "";
    //                 el['email'] = await response?.email || "";
    //                 alert(`小孩資料已更新！\n( ClassID：${responseId}, StudentID：${responseStuId} )`);
    //             }
    //         }
    //         setIsEditMode([-1, false]); // 返回非編輯模式
    //     }
    // }
    if (response?.code === 200) {
      alert(`小孩資料已更新！\n( ClassID：${_id}, StudentID：${studentId} )`);
    } else {
      alert(
        `小孩資料更新失敗！\nError：${response?.error}, ${response?.message}`
      );
    }
    // requestStudents(null, _id);
    requestStudents(null, wpReadParentChildClass?._id);
    setIsEditMode([-1, false]); // 返回非編輯模式
  };

  /*** 檢視課程 ***/
  const seeCourses = async (e, id, name) => {
    e.preventDefault();
    console.log(id);
    console.log(name);
    const attrObj = {
      key: id,
      label: name,
      // onClick: ({ item, key, keyPath, domEvent }) => {
      //   console.log(key);
      // }
    };
    // recentMessagesStore(attrObj);
    // const url = apiURL + "/chat-tags/";
    // console.log(url);
    // console.log(addFieldsOnChangeState);
    // const response = await fetch(
    //   url,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(addFieldsOnChangeState),
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //     },
    //   }
    // )
    // .then((res) => res.json())
    // .catch((err) => {
    //   alert(`標籤新增失敗！\n${err}`);
    //   throw new Error("Add Messages failed");
    // });
    // console.log(JSON.stringify(response));
    // const responseId = response?._id || "";
    // const responseStatus = response?.statusCode || "";
    // if( ! responseId ) {
    //   const responseErrorMsg = response?.message || "";
    //   if( responseStatus === 500 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else if( responseStatus === 400 ) {
    //     alert(`標籤新增失敗！\n( 標籤名稱已存在或標籤內容未輸入完整 )`);
    //   } else {
    //     alert(`標籤新增失敗！\n${responseErrorMsg}`);
    //   }
    //   return false;
    // } else {
    //   const name = await response?.name || "";
    //   const category = await response?.category || "";
    //   const description = await response?.description || "";
    //   const priority = await response?.priority || 0;
    //   const data = { _id: responseId, name, category, description, priority };
    //   tagsDataArray.push(data);
    //   alert(`【${name}】標籤已新增！\n( ID：${responseId} )`);
    //   setAddFieldsOnChangeState({}); // 清空欄位
    //   requestMessages(name); // 查詢已新增的標籤
    //   setAfterAddShow({"display":"block"});  // 顯示已新增的標籤
    // }
  };

  /*** 請假/補課 ***/
  const applyAbsence = (e, studentId, studentName) => {
    e?.preventDefault();
    console.log(studentId, studentName);
    setShowApplyAbsenceModal(true);
    setSelectedChildId(studentId);
    setSelectedChildName(studentName);
    console.log(triggerStuIdChange);
    setTriggerStuIdChange(!triggerStuIdChange);
  };

  /*** 下載檔案 ***/
  const downloadFile = (dataUrl, dataName) => {
    // window.open(
    //   dataUrl
    // );
    // fileDownload(dataUrl, dataName);
    // console.log(dataUrl);
    Axios.get(dataUrl, {
      responseType: 'blob',
    }).then((res) => {
      fileDownload(res.data, dataName);
    });
  }; /*** END 下載檔案 ***/
  // useEffect(()=>{
  //     const courseDataArrayDate = courseDataArray[0]?.date || "";
  //     console.log(dayjs(courseDataArray[0]?.date));
  //     console.log(courseDataArrayDate);
  //     console.log(!!courseDataArrayDate);
  // }, []);
  return (
    <Layout className={style.wpReadParentChildCourseChildrenListLayout}>
      <div className={style.wpReadParentChildCourseChildrenListTitleDiv}>
        <h4
          className={style.wpReadParentChildCourseChildrenListH4}
        >{`【親子課程】-【${wpReadParentChildClass.name}】${
          !!wpReadParentChildClass.startTime
            ? dayjs(wpReadParentChildClass.startTime).format('YYYY-MM-DD HH:mm')
            : 'undefined'
        }～${
          !!wpReadParentChildClass.endTime
            ? dayjs(wpReadParentChildClass.endTime).format('HH:mm')
            : 'undefined'
        }`}</h4>
      </div>
      <Content
        className={`${style.wpReadParentChildCourseChildrenListFilterConsultContent} ${style.wpReadParentChildCourseChildrenListFilterConsultContentQuery}`}
        style={afterFilterShow}
      >
        <section
          id={'wpReadParentChildCourseChildrenListFilterConsultSearchSec'}
          className={
            style.wpReadParentChildCourseChildrenListFilterConsultSearchSec
          }
        >
          <div
            className={
              style.wpReadParentChildCourseChildrenListFilterConsultTitle
            }
          >
            <h5>小孩資料表：</h5>
          </div>
          <List
            className={
              style.wpReadParentChildCourseChildrenListFilterConsultList
            }
            locale={{}}
            loadMore={
              <Space
                className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
              >
                <ExcelFile
                  filename={`${wpReadParentChildClass.name}( ${
                    !!wpReadParentChildClass.startTime
                      ? dayjs(wpReadParentChildClass.startTime).format(
                          'YYYY-MM-DD HH:mm'
                        )
                      : 'undefined'
                  }～${
                    !!wpReadParentChildClass.endTime
                      ? dayjs(wpReadParentChildClass.endTime).format('HH:mm')
                      : 'undefined'
                  } )-小孩資料表`}
                  element={
                    <Button
                      className={
                        style.wpReadParentChildCourseChildrenListSendBtn
                      }
                      type="primary"
                      // loading={btnLoadings[0]}
                      // onClick={(e) =>
                      //     downloadFile("/cs.png", "customer-icon.png")
                      // }
                    >
                      <Space>下載小孩資料表</Space>
                    </Button>
                  }
                >
                  <ExcelSheet data={kidsDataArray} name="小孩資料表">
                    <ExcelColumn label="ID" value="studentId" />
                    <ExcelColumn label="姓名" value="name" />
                    <ExcelColumn label="生日" value="birthday" />
                    <ExcelColumn label="年齡" value="age" />
                    <ExcelColumn label="電話" value="tel" />
                    <ExcelColumn label="課後報告" value="reportUrl" />
                    <ExcelColumn label="問卷" value="consult" />
                    {/* <ExcelColumn label="Marital Status"
                                                    value={(col) => col.is_married ? "Married" : "Single"}/> */}
                  </ExcelSheet>
                </ExcelFile>
                <ExcelFile
                  filename={`${wpReadParentChildClass.name}( ${
                    !!wpReadParentChildClass.startTime
                      ? dayjs(wpReadParentChildClass.startTime).format(
                          'YYYY-MM-DD HH:mm'
                        )
                      : 'undefined'
                  }～${
                    !!wpReadParentChildClass.endTime
                      ? dayjs(wpReadParentChildClass.endTime).format('HH:mm')
                      : 'undefined'
                  } )-簽到表`}
                  element={
                    <Button
                      className={
                        style.wpReadParentChildCourseChildrenListSendBtn
                      }
                      type="primary"
                      // loading={btnLoadings[1]}
                    >
                      <Space>下載簽到表</Space>
                    </Button>
                  }
                >
                  <ExcelSheet data={kidsDataArray} name="課堂小孩資料">
                    <ExcelColumn label="姓名" value="name" />
                    <ExcelColumn label="生日" value="birthday" />
                    <ExcelColumn label="年齡" value="age" />
                    <ExcelColumn label="簽到" />
                  </ExcelSheet>
                </ExcelFile>
                <Button
                  className={style.wpReadParentChildCourseChildrenListSendBtn}
                  type="primary"
                  // loading={btnLoadings[2}
                  onClick={(e) => setWpReadParentChildCoursePage('Search')}
                >
                  <Space>返回課堂搜尋</Space>
                </Button>
              </Space>
            }
            dataSource={kidsDataArray}
            renderItem={(item, index) =>
              Object.keys(kidsDataArray[0]).includes('studentId') &&
              isEditMode[0] === index &&
              isEditMode[1] &&
              window.innerWidth > 766 ? (
                <List.Item
                  className={`${style.wpReadParentChildCourseChildrenListItemAction}`}
                  actions={[
                    <>
                      {item?.status === 'Normal' ? (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            loading={
                              btnLoadings[
                                parseInt(item?.studentId?.replace(/\D/g, ''))
                              ]
                            }
                            onClick={(e) => {
                              applyAbsence(e, item?.studentId, item?.name);
                            }}
                          >
                            <Space>請假／補課</Space>
                          </Button>
                        </div>
                      ) : item?.status === 'Leave' ? (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                            // onClick={(e) => {
                            //     applyAbsence(e, item?.studentId, item?.name);
                            // }}
                          >
                            <Space>已完成請假補課</Space>
                          </Button>
                        </div>
                      ) : item?.status === 'MakeUp' ? (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                            // onClick={(e) => {
                            //     applyAbsence(e, item?.studentId, item?.name);
                            // }}
                          >
                            <Space>補課生</Space>
                          </Button>
                        </div>
                      ) : (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                          >
                            <Space>未確認狀態</Space>
                          </Button>
                        </div>
                      )}
                    </>,
                  ]}
                >
                  <List.Item.Meta
                    className={
                      style.wpReadParentChildCourseChildrenListFilterConsultListItemMeta
                    }
                    // avatar={
                    //     // <Tag className={style.wpReadParentChildCourseChildrenListFilterConsultTagIcon} color="geekblue">{item.name}</Tag>
                    //     <span className={style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText}>{item.name}</span>
                    // }
                    // title={
                    //     <div className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemTitle}`}>
                    //         <span className={style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionName}>{"姓名："}</span><span className={style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText}>{item.name}</span>
                    //         <div className={style.wpReadParentChildCourseChildrenListFilterConsultIconDiv}>
                    //             {/* <EyeOutlined className={style.wpReadParentChildCourseChildrenListFilterConsultIcon} onClick={(e) => { seeCourses(e, item._id, item.name) }} /> */}
                    //         </div>
                    //     </div>
                    // }
                    description={
                      <div
                        className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionDiv} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionQueryDiv}`}
                      >
                        <Space
                          className={
                            style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpace
                          }
                        >
                          <Space
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpaceSpace
                            }
                          >
                            <Space
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpaceKiddata
                              }
                            >
                              <div
                                className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseFilterConsultCourseDescriptionDiv} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFilterConsultCourseDescriptionQueryDiv} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionDivUpadate}`}
                              >
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'姓名：'}
                                  </span>
                                  {/* <span className={style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText}>{item.name}</span> */}
                                  <Input
                                    id={`studentName`}
                                    className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseInput} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFlex2} ${style.wpReadParentChildCourseChildrenListInputName}`}
                                    placeholder="請輸入小孩姓名"
                                    defaultValue={item?.name}
                                    ref={nameRef}
                                    allowClear
                                    size="middle"
                                    onChange={(e) =>
                                      kidsUpdateOnChange(
                                        e,
                                        item?._id,
                                        item?.studentId,
                                        item?.name
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'生日：'}
                                  </span>
                                  {/* <span className={style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText}>{dayjs(item.birthday).locale('zh-tw').format(`YYYY-MM-DD`)}</span> */}
                                  <Input
                                    id={`studentBirthday`}
                                    className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseInput} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFlex2} ${style.wpReadParentChildCourseChildrenListInputBirthday}`}
                                    placeholder="YYYY-MM-DD"
                                    defaultValue={dayjs(item?.birthday).format(
                                      'YYYY-MM-DD'
                                    )}
                                    ref={birthdayRef}
                                    allowClear
                                    size="middle"
                                    onChange={(e) =>
                                      kidsUpdateOnChange(
                                        e,
                                        item?._id,
                                        item?.studentId,
                                        item?.birthday
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'Email：'}
                                  </span>
                                  <Input
                                    id={`studentEmail`}
                                    className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseInput} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFlex2} ${style.wpReadParentChildCourseChildrenListInputEmail}`}
                                    placeholder="請輸入Email"
                                    defaultValue={item?.email}
                                    ref={emailRef}
                                    allowClear
                                    size="middle"
                                    onChange={(e) =>
                                      kidsUpdateOnChange(
                                        e,
                                        item?._id,
                                        item?.studentId,
                                        item?.email
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'電話：'}
                                  </span>
                                  {/* <span className={style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText}>{item.tel}</span> */}
                                  <Input
                                    id={`studentTel`}
                                    className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseInput} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFlex2} ${style.wpReadParentChildCourseChildrenListInputTel}`}
                                    placeholder="請輸入電話號碼"
                                    defaultValue={item?.tel}
                                    ref={telRef}
                                    allowClear
                                    size="middle"
                                    onChange={(e) =>
                                      kidsUpdateOnChange(
                                        e,
                                        item?._id,
                                        item?.studentId,
                                        item?.tel
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'課後報告：'}
                                  </span>
                                  <a
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                                    }
                                    href={item?.reportUrl}
                                    onClick={(e) => {
                                      window.open(item?.reportUrl);
                                    }}
                                  >
                                    {item?.reportUrl}
                                  </a>
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'問卷：'}
                                  </span>
                                  <a
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                                    }
                                    href={item?.consult}
                                    onClick={(e) => {
                                      window.open(item?.consult);
                                    }}
                                  >
                                    {item?.consult}
                                  </a>
                                </div>
                              </div>
                            </Space>
                            <Space
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpaceUpdate
                              }
                            >
                              <div
                                className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn} ${style.wpReadParentChildCourseChildrenListSpaceUpdateBtn}`}
                                direction="horizontal"
                              >
                                <Button
                                  type="primary"
                                  size="small"
                                  // loading={btnLoadings[4]}
                                  onClick={(e) => {
                                    updateKid(
                                      e,
                                      item?._id,
                                      item?.studentId,
                                      item?.name,
                                      item?.birthday,
                                      item?.email,
                                      item?.tel
                                    );
                                  }}
                                >
                                  更新
                                </Button>
                              </div>
                            </Space>
                          </Space>
                          {/* <div className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseFilterConsultCourseDescriptionDiv} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFilterConsultCourseDescriptionQueryDiv} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionDivUpadate}`}>

                                                    </div> */}
                        </Space>
                        <Space
                          className={
                            style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpace
                          }
                        >
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListIconDiv
                            }
                          >
                            <RollbackOutlined
                              className={
                                style.wpReadParentChildCourseChildrenListIcon
                              }
                              onClick={(e) => {
                                setIsEditMode(false);
                              }}
                            />
                          </div>
                        </Space>
                      </div>
                    }
                  />
                </List.Item>
              ) : Object.keys(kidsDataArray[0]).includes('studentId') &&
                isEditMode[0] === index &&
                isEditMode[1] &&
                window.innerWidth <= 766 ? (
                <List.Item
                  className={`${style.wpReadParentChildCourseChildrenListItemAction}`}
                  actions={[
                    <div
                      id="WpApplyAbsenceModalSpace"
                      className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                      direction="horizontal"
                    >
                      <Button
                        className={
                          style.wpReadParentChildCourseChildrenListItemSendBtn
                        }
                        type="primary"
                        size="small"
                        // loading={btnLoadings[4]}
                        onClick={(e) => {
                          updateKid(
                            e,
                            item?._id,
                            item?.studentId,
                            item?.name,
                            item?.birthday,
                            item?.email,
                            item?.tel
                          );
                        }}
                      >
                        <Space>更新</Space>
                      </Button>
                      {item?.status === 'Normal' ? (
                        <Button
                          className={style.lineBroadcastSendBtn}
                          type="primary"
                          size="small"
                          loading={
                            btnLoadings[
                              parseInt(item?.studentId?.replace(/\D/g, ''))
                            ]
                          }
                          onClick={(e) => {
                            applyAbsence(e, item?.studentId, item?.name);
                          }}
                        >
                          <Space>請假／補課</Space>
                        </Button>
                      ) : item?.status === 'Leave' ? (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                            // onClick={(e) => {
                            //     applyAbsence(e, item?.studentId, item?.name);
                            // }}
                          >
                            <Space>已完成請假補課</Space>
                          </Button>
                        </div>
                      ) : item?.status === 'MakeUp' ? (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                            // onClick={(e) => {
                            //     applyAbsence(e, item?.studentId, item?.name);
                            // }}
                          >
                            <Space>補課生</Space>
                          </Button>
                        </div>
                      ) : (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                          >
                            <Space>未確認狀態</Space>
                          </Button>
                        </div>
                      )}
                      {/* <WpApplyAbsenceModal showApplyAbsenceModal={showApplyAbsenceModal} setShowApplyAbsenceModal={setShowApplyAbsenceModal} wpReadParentChildClass={wpReadParentChildClass} selectedChildId={selectedChildId}></WpApplyAbsenceModal> */}
                    </div>,
                  ]}
                >
                  <List.Item.Meta
                    className={
                      style.wpReadParentChildCourseChildrenListFilterConsultListItemMeta
                    }
                    description={
                      <div
                        className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionDiv} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionQueryDiv}`}
                      >
                        <Space
                          className={
                            style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpace
                          }
                        >
                          <Space
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpaceSpace
                            }
                          >
                            <Space
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpaceKiddata
                              }
                            >
                              <div
                                className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseFilterConsultCourseDescriptionDiv} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFilterConsultCourseDescriptionQueryDiv} ${style.wpReadParentChildCourseFilterConsultCourseDescriptionDivUpadate}`}
                              >
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'姓名：'}
                                  </span>
                                  {/* <span className={style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText}>{item.name}</span> */}
                                  <Input
                                    id={`studentName`}
                                    className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseInput} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFlex2} ${style.wpReadParentChildCourseChildrenListInputName}`}
                                    placeholder="請輸入小孩姓名"
                                    defaultValue={item?.name}
                                    ref={nameRef}
                                    allowClear
                                    size="middle"
                                    onChange={(e) =>
                                      kidsUpdateOnChange(
                                        e,
                                        item?._id,
                                        item?.studentId,
                                        item?.name
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'生日：'}
                                  </span>
                                  {/* <span className={style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText}>{dayjs(item.birthday).locale('zh-tw').format(`YYYY-MM-DD`)}</span> */}
                                  <Input
                                    id={`studentBirthday`}
                                    className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseInput} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFlex2} ${style.wpReadParentChildCourseChildrenListInputBirthday}`}
                                    placeholder="YYYY-MM-DD"
                                    defaultValue={dayjs(item?.birthday).format(
                                      'YYYY-MM-DD'
                                    )}
                                    ref={birthdayRef}
                                    allowClear
                                    size="middle"
                                    onChange={(e) =>
                                      kidsUpdateOnChange(
                                        e,
                                        item?._id,
                                        item?.studentId,
                                        item?.birthday
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'Email：'}
                                  </span>
                                  <Input
                                    id={`studentEmail`}
                                    className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseInput} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFlex2} ${style.wpReadParentChildCourseChildrenListInputEmail}`}
                                    placeholder="請輸入Email"
                                    defaultValue={item?.email}
                                    ref={emailRef}
                                    allowClear
                                    size="middle"
                                    onChange={(e) =>
                                      kidsUpdateOnChange(
                                        e,
                                        item?._id,
                                        item?.studentId,
                                        item?.email
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'電話：'}
                                  </span>
                                  <Input
                                    id={`studentTel`}
                                    className={`${wpReadParentChildCourseStyle.wpReadParentChildCourseInput} ${wpReadParentChildCourseStyle.wpReadParentChildCourseFlex2} ${style.wpReadParentChildCourseChildrenListInputTel}`}
                                    placeholder="請輸入電話號碼"
                                    defaultValue={item?.tel}
                                    ref={telRef}
                                    allowClear
                                    size="middle"
                                    onChange={(e) =>
                                      kidsUpdateOnChange(
                                        e,
                                        item?._id,
                                        item?.studentId,
                                        item?.tel
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'課後報告：'}
                                  </span>
                                  <a
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                                    }
                                    href={item?.reportUrl}
                                    onClick={(e) => {
                                      window.open(item?.reportUrl);
                                    }}
                                  >
                                    {item?.reportUrl}
                                  </a>
                                </div>
                                <div
                                  className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate}`}
                                >
                                  <span
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                                    }
                                  >
                                    {'問卷：'}
                                  </span>
                                  <a
                                    className={
                                      style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                                    }
                                    href={item?.consult}
                                    onClick={(e) => {
                                      window.open(item?.consult);
                                    }}
                                  >
                                    {item?.consult}
                                  </a>
                                </div>
                              </div>
                            </Space>
                          </Space>
                        </Space>
                        <Space
                          className={
                            style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpace
                          }
                        >
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListIconDiv
                            }
                          >
                            <RollbackOutlined
                              className={
                                style.wpReadParentChildCourseChildrenListIcon
                              }
                              onClick={(e) => {
                                setIsEditMode(false);
                              }}
                            />
                          </div>
                        </Space>
                      </div>
                    }
                  />
                </List.Item>
              ) : Object.keys(kidsDataArray[0]).includes('studentId') ? (
                <List.Item
                  className={`${style.wpReadParentChildCourseChildrenListItemAction}`}
                  actions={[
                    <>
                      {/* {!sidHasAppliedAbsence?.includes(item?.studentId) ? ( */}
                      {item?.status === 'Normal' ? (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            loading={
                              btnLoadings[
                                parseInt(item?.studentId?.replace(/\D/g, ''))
                              ]
                            }
                            onClick={(e) => {
                              applyAbsence(e, item?.studentId, item?.name);
                            }}
                          >
                            <Space>請假／補課</Space>
                          </Button>
                        </div>
                      ) : item?.status === 'Leave' ? (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                            // onClick={(e) => {
                            //     applyAbsence(e, item?.studentId, item?.name);
                            // }}
                          >
                            <Space>已完成請假補課</Space>
                          </Button>
                        </div>
                      ) : item?.status === 'MakeUp' ? (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                            // onClick={(e) => {
                            //     applyAbsence(e, item?.studentId, item?.name);
                            // }}
                          >
                            <Space>補課生</Space>
                          </Button>
                        </div>
                      ) : (
                        <div
                          id="WpApplyAbsenceModalSpace"
                          className={`${style.wpReadParentChildCourseChildrenListSpaceSendBtn}`}
                          direction="horizontal"
                        >
                          <Button
                            className={style.lineBroadcastSendBtn}
                            type="primary"
                            size="small"
                            disabled
                          >
                            <Space>未確認狀態</Space>
                          </Button>
                        </div>
                      )}
                    </>,
                  ]}
                >
                  <List.Item.Meta
                    className={
                      style.wpReadParentChildCourseChildrenListFilterConsultListItemMeta
                    }
                    description={
                      <div
                        className={`${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionDiv} ${style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionQueryDiv}`}
                      >
                        <Space
                          className={
                            style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpace
                          }
                        >
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem
                            }
                          >
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                              }
                            >
                              {'姓名：'}
                            </span>
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                              }
                            >
                              {item?.name}
                            </span>
                          </div>
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem
                            }
                          >
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                              }
                            >
                              {'生日：'}
                            </span>
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                              }
                            >
                              {dayjs(item?.birthday)
                                .locale('zh-tw')
                                .format(`YYYY-MM-DD`)}
                            </span>
                          </div>
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem
                            }
                          >
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                              }
                            >
                              {'年齡：'}
                            </span>
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                              }
                            >
                              {dayjs().diff(dayjs(item?.birthday), 'year')}
                            </span>
                          </div>
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem
                            }
                          >
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                              }
                            >
                              {'Email：'}
                            </span>
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                              }
                            >
                              {item?.email}
                            </span>
                          </div>
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem
                            }
                          >
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                              }
                            >
                              {'電話：'}
                            </span>
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                              }
                            >
                              {item?.tel}
                            </span>
                          </div>
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem
                            }
                          >
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                              }
                            >
                              {'課後報告：'}
                            </span>
                            <a
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                              }
                              href={item?.reportUrl}
                              onClick={(e) => {
                                window.open(item?.reportUrl);
                              }}
                            >
                              {item?.reportUrl}
                            </a>
                          </div>
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem
                            }
                          >
                            <span
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel
                              }
                            >
                              {'問卷：'}
                            </span>
                            <a
                              className={
                                style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText
                              }
                              href={item?.consult}
                              onClick={(e) => {
                                window.open(item?.consult);
                              }}
                            >
                              {item?.consult}
                            </a>
                          </div>
                        </Space>
                        <Space
                          className={
                            style.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpace
                          }
                        >
                          <div
                            className={
                              style.wpReadParentChildCourseChildrenListIconDiv
                            }
                          >
                            <EditOutlined
                              className={
                                style.wpReadParentChildCourseChildrenListIcon
                              }
                              onClick={(e) => {
                                setIsEditMode([index, true]);
                              }}
                            />
                          </div>
                        </Space>
                      </div>
                    }
                  />
                </List.Item>
              ) : (
                <></>
              )
            }
          />
        </section>
      </Content>
      <WpApplyAbsenceModal
        showApplyAbsenceModal={showApplyAbsenceModal}
        setShowApplyAbsenceModal={setShowApplyAbsenceModal}
        wpReadParentChildClass={wpReadParentChildClass}
        selectedChildId={selectedChildId}
        selectedChildName={selectedChildName}
        sidHasAppliedAbsence={sidHasAppliedAbsence}
        setSidHasAppliedAbsence={setSidHasAppliedAbsence}
        setBtnLoadings={setBtnLoadings}
        triggerStuIdChange={triggerStuIdChange}
        requestStudents={requestStudents}
      ></WpApplyAbsenceModal>
    </Layout>
  );
}

export default WpReadParentChildCourseChildrenList;
