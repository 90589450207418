/* React */
// import React from 'react';

/* Store */
import { RichMenuEditorContextProvider } from './store/RichMenuEditorContext';
/* Hooks */
import usePaginationContext from './hooks/usePaginationContext';
/* Components */
import LineRichMenuConsole from './LineRichMenuConsole';
import LineRichMenuEditor from './LineRichMenuEditor';

/* //////////////////////////////////////////////////////////////// */
export default function IndexPage() {
  const { curPage, curEditingId } = usePaginationContext();

  console.log(curPage);

  /* JSX ---------------------------------------------------------- */
  return (
    <>
      <RichMenuEditorContextProvider>
        {curPage === 'console' ? <LineRichMenuConsole /> : null}
        {curPage === 'create' ? <LineRichMenuEditor /> : null}
        {curPage === 'edit' ? (
          <LineRichMenuEditor richMenuId={curEditingId} />
        ) : null}
      </RichMenuEditorContextProvider>
    </>
  );
}
