import React, { useState, useContext, useEffect, useRef } from 'react';
import { Input, Button, Dropdown } from 'react-chat-elements';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button as AntButton, Upload, Form, Modal } from 'antd';
import CryptoJS from 'crypto-js';
import Compressor from 'compressorjs';
import jwt_decode from 'jwt-decode';
// import data from '@emoji-mart/data'
// import Picker from '@emoji-mart/react'
import Emoji from '../../../../../Component/Emoji/Emoji';

/*** CSS ***/
import style from './styles/ChatRoomSend.module.css';
import './styles/ChatRoomAntComponent.css';

/*** Import Socket Component ***/
import SocketAdminSendMessage from '../../../../../SocketIO/Admin/SocketAdminSendMessage';
import SocketAdminListening from '../../../../../SocketIO/Admin/SocketAdminListening';

/*** Import Context Parameters ***/
import { GlobalAdminSocket } from '../../../../../SocketIO/Admin/SocketAdminConnect';
import { GlobalRoomId } from '../ChatRoom';
import { GlobalJoinRoom } from '../ChatRoom';
import { GlobalHasJoinedRoom } from '../ChatRoom';
import { GlobalChannelType } from '../ChatRoom';

function ChatRoomSend(props) {
  const [form] = Form.useForm();
  const [typingText, settypingText] = useState([]);

  const [globalAdminSocket, setGlobalAdminSocket] =
    useContext(GlobalAdminSocket);
  const [globalRoomId, setGlobalRoomId] = useContext(GlobalRoomId);
  const [globalJoinRoom, setGlobalJoinRoom] = useContext(GlobalJoinRoom);
  const [globalHasJoinedRoom, setGlobalHasJoinedRoom] =
    useContext(GlobalHasJoinedRoom);
  const [globalChannelType, setGlobalChannelType] =
    useContext(GlobalChannelType);
  // useEffect(()=>{console.log(globalChannelType)}, [globalChannelType]);

  const [globalAdminUserSocket, setGlobalAdminUserSocket] = useState(
    globalAdminSocket?.globalAdminUserSocket
  ); // 初始值設定正確才能及時觸發主動連線
  const [globalAdminPanelSocket, setGlobalAdminPanelSocket] = useState(
    globalAdminSocket?.globalAdminPanelSocket
  );
  const { Dragger } = Upload; // Ant拖拉上傳機制
  const [userData, setUserData] = useState({});
  const [textMsg, setTextMsg] = useState({});
  const [uploadFileData, setUploadFileData] = useState({});
  // const [fileListState, setFileListState] = useState([{}]);
  const [formStyle, setFormStyle] = useState({ marginTop: '-30px' });
  const [uploadBtnStyle, setUploadBtnStyle] = useState({ fontSize: '20px' });
  const [reloadUploadCount, setReloadUploadCount] = useState(0);
  // const [uploadDivFlex, setUploadDivFlex] = useState({"flex":"1"});
  const [channelTypeState, setChannelTypeState] = useState('聊天室');
  const [adminData, setAdminData] = useState({});
  const [showEmojiModal, setShowEmojiModal] = useState(false);
  const mountId = 'chatRoomContainerSendSec'; // emoji要掛載的位置ID

  const uploadComponentRef = useRef(null);

  let defaultUserData = null;
  const loginToken = window.localStorage.getItem('loginToken');
  const lineAppId = process.env.REACT_APP_LINE_APP_ID;
  const cpo = window.localStorage.getItem('cpo')
    ? window.localStorage.getItem('cpo')
    : '';
  const accessToken = window.localStorage.getItem(
    'LIFF_STORE:' + lineAppId + ':accessToken'
  )
    ? window.localStorage.getItem('LIFF_STORE:' + lineAppId + ':accessToken')
    : '';
  const allowedPhotoTypes = ['image/png', 'image/jpeg', 'image/gif']; // 允許上傳的圖片格式
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  useEffect(() => {
    switch (globalChannelType) {
      case 'LINE':
        setChannelTypeState('LINE');
        break;
      case 'FACEBOOK':
        setChannelTypeState('Facebook');
        break;
      case 'EMAIL':
        setChannelTypeState('Email');
        break;
      default:
        setChannelTypeState('聊天室');
        break;
    }
  }, []);
  useEffect(() => {
    switch (globalChannelType) {
      case 'LINE':
        setChannelTypeState('LINE');
        break;
      case 'FACEBOOK':
        setChannelTypeState('Facebook');
        break;
      case 'EMAIL':
        setChannelTypeState('Email');
        break;
      case 'MEMO':
        setChannelTypeState('MEMO');
        break;
      default:
        setChannelTypeState('聊天室');
        break;
    }
  }, [globalChannelType]);

  /*** 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/
  const initializeAdminUserAndPanelSocket = async () => {
    let adminUserSocket = null;
    if (
      typeof globalAdminSocket === 'object' &&
      globalAdminSocket !== null &&
      Object.keys(globalAdminSocket).length > 0
    ) {
      adminUserSocket = globalAdminSocket?.globalAdminUserSocket || null;
      setGlobalAdminUserSocket(adminUserSocket); // 最後改動此處，讓GlobalAdminSocket連線後設定GlobalAdminUserSocket
    }
    let adminPanelSocket = null;
    if (
      typeof globalAdminSocket === 'object' &&
      globalAdminSocket !== null &&
      Object.keys(globalAdminSocket).length > 0
    ) {
      adminPanelSocket = globalAdminSocket?.globalAdminPanelSocket || null;
      setGlobalAdminPanelSocket(adminPanelSocket);
    }
  };
  useEffect(() => {
    initializeAdminUserAndPanelSocket();
  }, []);
  useEffect(() => {
    initializeAdminUserAndPanelSocket();
  }, [globalAdminSocket, globalRoomId]);
  /*** END 初始化/重置 globalAdminUserSocket 及 globalAdminPanelSocket ***/

  /*** 取得加密的使用者資料 ***/
  const getCipherUserData = () => {
    if (!!accessToken && !!cpo) {
      try {
        const userDataJson = CryptoJS.AES.decrypt(cpo, `${accessToken}`);
        const userDataUTF8 = userDataJson?.toString(CryptoJS.enc.Utf8);
        const userDataDecode = JSON.parse(userDataUTF8);
        setUserData((userData) => ({ ...userData, userDataDecode }));
        defaultUserData = userDataDecode;
        return userDataDecode;
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };
  useEffect(() => {
    getCipherUserData();
  }, [cpo, globalRoomId]);
  /*** END 取得加密的使用者資料 ***/

  /*** 取得管理者資料 ***/
  const getAdminData = async () => {
    const decoded = jwt_decode(loginToken);
    console.log(decoded);
    const id = decoded?.id || '';
    const email = decoded?.email || '';
    const atIndex = email.indexOf('@');
    const name = atIndex !== -1 ? email.substring(0, atIndex) : '';
    const data = { id, name, email };
    setAdminData(data);
  };
  useEffect(() => {
    getAdminData();
  }, []);
  useEffect(() => {
    getAdminData();
  }, [loginToken]);
  /*** END 取得管理者資料 ***/

  /*** 使用者在訊息欄輸入訊息，將訊息存入State ***/
  const changeFields = (event, emojiText) => {
    event?.preventDefault();
    const val = event?.target?.value || '';
    console.log({ val });
    /* 取得的使用者資料預設格式
        {
            userId: profile.userId,
            userStatus: "【一般使用者】",
            firstName: "",
            lastName: profile.displayName,
            email: "",
            phone: "",
            lineId: profile.userId,
            isLineBind: false,
            avatar: profile.pictureUrl,
            statusMessage: profile.statusMessage
        }
    */
    if (typeof val === 'string') {
      // console.log("string");
      // const uriRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // Photo URL Regex
      // const uriRegexGI = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b((?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*))/i;
      // const nameRegexGI = /[^\/]+(?=\/?$)/i;
      // const uriExtractArray = val?.match(uriRegexGI) || "";
      // const uriExtract = uriExtractArray[0] ?? "";
      // const nameMatch = uriExtractArray[1] ?. match(nameRegexGI);
      // const name = nameMatch ? nameMatch[0] : "photo-" + new Date();
      // console.log(uriRegex.test(val));
      // console.log({uriRegex});
      // console.log({uriExtract});
      // console.log({name});
      // if( uriRegex.test(val) || !! uriExtract ) { // 若為URI格式
      //   if( uriExtract.indexOf('youtube.com') > -1 || uriExtract.indexOf('vimeo.com') > -1 || uriExtract.indexOf('pratikc.wistia.com') > -1 ) { // 影片格式
      //       setTextMsg({
      //           ...textMsg,
      //           type: "text",
      //           text: val,
      //           dataType: "video",
      //           dataName: name,
      //           dataUri: uriExtract,
      //       });
      //       // setTextMsg({
      //       //     ...textMsg,
      //       //     type: "text",
      //       //     text: "videoUrl:" + uriExtract,
      //       // });
      //   } else { // 圖片格式
      //       setTextMsg({
      //           ...textMsg,
      //           type: "text",
      //           text: val,
      //           dataType: "photo",
      //           dataName: name,
      //           dataUri: uriExtract,
      //       });
      //     //   setTextMsg({
      //     //     ...textMsg,
      //     //     type: "text",
      //     //     text: "photoUrl:" + uriExtract,
      //     // });
      //   }
      // } else {
      //   setTextMsg({
      //       ...textMsg,
      //       type: "text",
      //       text: val,
      //   });
      // }
      if (!emojiText) {
        setTextMsg({
          ...textMsg,
          type: 'text',
          text: val,
        });
        console.log(textMsg);
      } else {
        setTextMsg({
          ...textMsg,
          type: 'text',
          text: emojiText,
        });
        // console.log(textMsg);
      }
    }
  }; /*** END 管理者在訊息欄輸入訊息，將訊息存入State ***/

  /*** emoji 選擇後 ***/
  const emojiOnChange = (emoji) => {
    const emojiText = emoji?.native || '';
    // setTextMsg({
    //   ...textMsg,
    //   type: "text",
    //   text: emojiText,
    // });
    const formDom = document.getElementById('chatRoomSendForm');
    const inputDom = formDom?.getElementsByClassName('rce-input')[0];
    inputDom.value += emojiText;
    console.log(formDom);
    console.log(inputDom);
    console.log(inputDom.value);
    changeFields(undefined, inputDom.value);
    setShowEmojiModal(false);
  }; /*** END emoji 選擇後 ***/

  useEffect(() => {
    console.log({ textMsg });
  }, [textMsg]);

  /*** 管理者點選送出訊息，將訊息以Socket Emit傳出 ***/
  const adminSendMessage = async (e) => {
    // const userData = getCipherUserData();
    e.preventDefault();
    const channelType = globalChannelType ?? 'CHAT'; // 在ChatRoomAssign做設定
    const sendText = textMsg?.text || '';
    const type = textMsg?.type || 'text';
    const dataType = textMsg?.dataType || '';
    const dataName = textMsg?.dataName || '';
    const dataUri = textMsg?.dataUri || '';
    const dataBuffer = textMsg?.dataBuffer || {};
    console.log(dataBuffer);
    const data = {
      channelType: channelType,
      type: type,
      text: sendText,
      data: {
        type: dataType,
        name: dataName,
        uri: dataUri,
        // buffer: dataBuffer
      },
    };
    console.log('Admin Emit Message !');
    console.log(data);
    // console.log(globalAdminUserSocket);
    // console.log(globalRoomId);
    // console.log(globalJoinRoom);
    // console.log(globalHasJoinedRoom);
    const globalAdminUserSocketConnected =
      globalAdminUserSocket?.connected || false;
    if (globalAdminUserSocketConnected && !!globalRoomId && type === 'text') {
      console.log('y-log\n socket emit message data: \n', data);
      globalAdminUserSocket.emit('message', data);
      globalAdminUserSocket.emit('roomList', {
        cursorD: new Date(),
        limit: 50,
      });
    } else if (Object.keys(dataBuffer).length > 0 && !!channelType && !!type) {
      syncCompressAndUpload(dataBuffer, channelType, type);
    }

    // setReloadUploadCount( reloadUploadCount + 1 );

    // 觸發 onRemove
    // const uploadComponent = uploadComponentRef.current;
    // if (uploadComponent) {
    //   uploadComponent.querySelector(".anticon-delete").click();
    //   console.log("onRemove event triggered!");
    // }

    // const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
    // if( globalAdminUserSocketConnected && !! globalRoomId ) {
    //     await globalAdminUserSocket.emit('joinRoom', {'roomId': globalRoomId});
    //     await globalAdminUserSocket.on("joinRoom", (res) => {
    //         console.log(res, 'cs');
    //         console.log('Administer join room!');
    //         globalAdminUserSocket.emit('message', data );
    //     });
    // }

    // } else {
    //     const defaultMsg = {
    //         type: 'text',
    //         text: "【請先連線再送出訊息！】",
    //     }
    //     const defaultMsgJson = JSON.stringify(defaultMsg);
    //     window.localStorage.setItem('defaultMsg', defaultMsgJson);
    // }

    clearInput(); // 完成送出訊息後，清空輸入欄位，重設訊息欄樣式
  };
  const clearInput = () => {
    setTextMsg({});
    document.getElementById('chatRoomSendForm').reset();
    // document.getElementById("chatRoomSendForm").getElementsByTagName("input")[0].value = "";
    // document.getElementById("chatRoomSendForm").getElementsByTagName("input")[1].value = "";
    // 完成送出訊息後，重設訊息欄樣式
    setFormStyle({ marginTop: '-30px' });
    setUploadBtnStyle({ fontSize: '20px' });
  }; /*** END 管理者點選送出訊息 ***/

  // useEffect(() => {
  //   console.log(reloadUploadCount);
  // }, [reloadUploadCount]);

  // /*** textMsg State改變時重新渲染 ***/
  // useEffect(() => {
  //   return
  // }, [textMsg]);
  // /*** END textMsg State改變時重新渲染 ***/

  // const changeFields = (event) => {
  //     event.preventDefault();
  //     const id = event.target.id;
  //     const val = event.target.value;
  //     console.log({ typingText });
  //     settypingText({
  //         ...typingText,
  //         position:"right",
  //         type:"text",
  //         title:"Emre",
  //         text:val,

  //     });
  // }

  // const whensubmit = ()=>{
  //     textArray.push(typingText);
  //     console.log('textArray',textArray);
  // };

  // /*** Drag Upload Attributes ***/
  // const dragAttr = {
  //   multiple: false,
  //   customRequest: async ({ onSuccess, onError, file, onProgress }) => {
  //     onProgress({ percent: 0 });

  //     const img = await compressImg(file);
  //     uploadFile(img, "CHAT", "photo").catch((err) => {
  //       onError();
  //     });

  //     onProgress({ percent: 100 });
  //     onSuccess();
  //   },
  // }; /*** END Drag Upload Attributes ***/

  /*** Normalizing File ***/
  const normFile = (e) => {
    // console.log(e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }; /*** END Normalizing File ***/

  /*** 驗證上傳檔案格式是否正確 ***/
  const beforeUploadFile = (file, fileList) => {
    console.log('beforeUploadFile');
    console.log(file);
    console.log(fileList);
    const fileEl = fileList[0] ?? {};
    console.log(fileEl);
    if (Object.keys(fileEl).length > 0) {
      const fileName = fileEl?.name || '未設定檔名';
      const fileType = fileEl?.type || '未設定檔案類別';
      console.log(fileName);
      console.log(fileType);
      if (
        fileType.indexOf('image/') > -1 &&
        allowedPhotoTypes.includes(fileType)
      ) {
        new Promise((resolve, reject) => {
          console.log('return resolve(fileList[0])');
          // return resolve(fileList[0]);
          return true;
        });
      } else if (fileType.indexOf('image/') > -1) {
        // setFormStyle({"marginTop": "-65px"});
        // setUploadBtnStyle({"display": "none"});
        clearInput();
        alert('不支援的圖片格式（僅支援jpg、png、gif格式）!');
        console.log('Upload.LIST_IGNORE');
        // return Upload.LIST_IGNORE;
        return false;
      } else {
        new Promise((resolve, reject) => {
          console.log('return resolve(fileList[0]');
          // return resolve(fileList[0]);
          return true;
        });
      }
    }
  }; /*** END 驗證上傳檔案格式是否正確 ***/

  /*** 移除上傳後預覽圖示 ***/
  const removeFile = (file) => {
    console.log(file);
    const fileType = file?.type || '未知的檔案類型';
    if (
      fileType.indexOf('image/') > -1 &&
      !allowedPhotoTypes.includes(fileType)
    ) {
      setFormStyle({ marginTop: '-30px' });
      setUploadBtnStyle({ fontSize: '20px' });
      return true; // 返回true表示已经从文件列表中删除了这个文件
    }
    return false; // 返回false表示不删除这个文件
  }; /*** END 移除上傳後預覽圖示 ***/

  /*** onChange時設定上傳檔案Data ***/
  const changeUploadFile = (fileItem) => {
    document
      .getElementById('chatRoomSendForm')
      .getElementsByTagName('input')[1].value = '';
    console.log(fileItem);
    const fileList = fileItem?.fileList || [];
    const file = fileList[0]?.originFileObj || {};
    console.log(file);
    // setFileListState(fileList);
    // const hasVerified = beforeUploadFile(file, fileList);
    // if( ! hasVerified ) {
    //   return;
    // }
    // const globalAdminUserSocketConnected = globalAdminUserSocket?.connected || false;
    // if( globalAdminUserSocketConnected ) {
    // const fileList = fileItem?.fileList || [];
    // const file = fileList.length > 0 ? fileList[0] : {};
    console.log(Object.keys(file).length);
    if (Object.keys(file).length > 0) {
      // const uploadData = {
      //   type: 'photo',
      //   text: 'iphoto',
      //   data: {
      //     type: file.type,
      //     name: file.name,
      //     buffer: file
      //   }
      // }

      const fileName = file?.name || '未設定檔名';
      const fileType = file?.type || '未知的檔案類型';
      let type = '';
      if (
        fileType.indexOf('image/') > -1 &&
        allowedPhotoTypes.includes(fileType)
      ) {
        type = 'photo';
        setTextMsg({
          ...textMsg,
          type: type,
          text: fileName,
          dataType: fileType,
          dataName: fileName,
          dataBuffer: file,
        });
        setFormStyle({ marginTop: '-65px' });
        setUploadBtnStyle({ display: 'none' });
      } else if (fileType.indexOf('image/') > -1) {
        // setFormStyle({"marginTop": "-65px"});
        // setUploadBtnStyle({"display": "none"});
        clearInput();
        alert('不支援的圖片格式（僅支援jpg、png、gif格式）!');
        setTextMsg({});
        fileList.splice(fileList.indexOf(file), 1);
        // setFileListState([{}]);
        // console.log(Upload);
        // Upload.fileList = [];
        // } else if( fileType.indexOf("video/mp4") > -1 ) {
        //   type = "video";
        //   setTextMsg({
        //     ...textMsg,
        //     type: type,
        //     text: fileName,
        //     dataType: fileType,
        //     dataName: fileName,
        //     dataBuffer: file
        //   });
        //   setFormStyle({"marginTop": "-65px"});
        //   setUploadBtnStyle({"display": "none"});
        // } else if( fileType.indexOf("video/") > -1 ) {
        //   alert("不支援的影片格式（僅支援mp4格式）!");
        //   setTextMsg({});
        //   fileList.splice(fileList.indexOf(file), 1);
        //   setFormStyle({"marginTop": "-30px"});
        //   setUploadBtnStyle({"fontSize": "20px"});
      } else if (fileType.indexOf('video/') > -1) {
        type = 'video';
        setTextMsg({
          ...textMsg,
          type: type,
          text: fileName,
          dataType: fileType,
          dataName: fileName,
          dataBuffer: file,
        });
        setFormStyle({ marginTop: '-65px' });
        setUploadBtnStyle({ display: 'none' });
      } else if (fileType.indexOf('audio/') > -1) {
        type = 'audio';
        setTextMsg({
          ...textMsg,
          type: type,
          text: fileName,
          dataType: fileType,
          dataName: fileName,
          dataBuffer: file,
        });
        setFormStyle({ marginTop: '-65px' });
        setUploadBtnStyle({ display: 'none' });
      } else {
        type = 'file';
        setTextMsg({
          ...textMsg,
          type: type,
          text: fileName,
          dataType: fileType,
          dataName: fileName,
          dataBuffer: file,
        });
        setFormStyle({ marginTop: '-65px' });
        setUploadBtnStyle({ display: 'none' });
      }
      // setUploadFileData(uploadData);
      // setUploadDivFlex({"flex":"0"});
    }
    // globalAdminUserSocket.emit("message", {
    //     type: 'photo',
    //     text: 'iphoto',
    //     data: {
    //         buffer: file,
    //         name: file.name,
    //         type: file.type
    //     }
    // }, (status) => {
    //     console.log(status);
    // });
    // fileList = [];
    // }
  }; /*** END onChange時設定上傳檔案Data ***/

  /*** 壓縮上傳檔案 ***/
  const compressImg = async (file) => {
    const fileType = file?.type || '未知的檔案類型';
    if (
      fileType.indexOf('image/') > -1 &&
      allowedPhotoTypes.includes(fileType)
    ) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          success: (compressedResult) => {
            resolve(compressedResult);
          },
          error: (err) => {
            alert('錯誤，檔案壓縮失敗！');
            setTextMsg({});
            console.log(err.message);
            reject(false);
          },
        });
      });
    }
    return false;
  }; /*** END 壓縮上傳檔案 ***/

  /*** 檔案上傳 ***/
  const uploadFile = async (file, channelType, messageType) => {
    console.log(file);
    console.log(channelType);
    console.log(messageType);
    const fileName = file?.name || '未設定檔名';
    const fileType = file?.type || '';
    const fileSize = file?.size || 0;
    console.log(fileName);
    const presignedPolicy = await fetch(
      apiURL +
        '/upload-chat/presigned-policy?' +
        new URLSearchParams({
          name: fileName,
          fileSize,
          fileType,
          channelType,
          messageType,
        }),
      {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        // setFormStyle({"marginTop": "-65px"});
        // setUploadBtnStyle({"display": "none"});
        clearInput();
        alert('不支援的檔案名稱或檔案無法於此頻道上傳！');
        setTextMsg({});
        console.log(err);
        throw new Error(err);
      });
    const formData = new FormData();
    const policyFormData = presignedPolicy?.formData || {};
    Object.entries(policyFormData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);
    console.log(formData);
    console.log(presignedPolicy);
    const policyPostURL = presignedPolicy?.postURL || '';
    console.log(policyPostURL);
    fetch(policyPostURL, {
      method: 'post',
      body: formData,
    })
      .then((res) => {
        console.log(res);
        // res.xml().then((xml) => console.log(xml));
        if (!res.ok) {
          setTextMsg({});
          // setFormStyle({"marginTop": "-30px"});
          // setUploadBtnStyle({"fontSize": "20px"});
          clearInput();
          console.log('不支援的檔案格式或大小超過限制（10MB）!', res);
          throw new Error(res);
        } else {
          // setFormStyle({"marginTop": "-30px"});
          // setUploadBtnStyle({"fontSize": "20px"});
          clearInput();
          // 訊息已送出，重置欄位樣式
        }
      })
      .catch((err) => {
        // setFormStyle({"marginTop": "-65px"});
        // setUploadBtnStyle({"display": "none"});
        clearInput();
        alert('不支援的檔案格式或大小超過限制（10MB）!');
        setTextMsg({});
        console.log(err);
        throw new Error(err);
      });
  }; /*** END 檔案上傳 ***/

  /*** 同步壓縮並上傳 ***/
  const syncCompressAndUpload = async (file, channelType, msgType) => {
    let fileTemp = file;
    const compImg = await compressImg(file);
    console.log(compImg);
    fileTemp = !!compImg ? compImg : file;
    console.log(fileTemp);
    console.log(channelType);
    console.log(msgType);
    uploadFile(fileTemp, channelType, msgType).catch((err) => {
      // setFormStyle({"marginTop": "-65px"});
      // setUploadBtnStyle({"display": "none"});
      clearInput();
      alert('不支援的檔案格式或大小超過限制（10MB）!');
      setTextMsg({});
      console.log(err);
      throw new Error(err);
    });
  }; /*** END 同步壓縮並上傳 ***/

  /*** 滑至最下層 ***/
  const setChatRoomScrollPx = (event) => {
    event.preventDefault();
    console.log('setChatRoomScrollPx');
    const chatRoomDom = document.getElementById('ChatRoom');
    chatRoomDom.scrollTo({
      bottom: 0,
      behavior: 'smooth',
    });
  };

  const fileList = [
    {
      uid: '0',
      name: 'xxx.png',
      status: 'uploading',
      percent: 33,
    },
    {
      uid: '-1',
      name: 'yyy.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      thumbUrl:
        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: '-2',
      name: 'zzz.png',
      status: 'error',
    },
  ];

  let textArray = [
    {
      position: 'left',
      type: 'text',
      title: 'Emre',
      text: 'left message',
    },
    {
      position: 'right',
      type: 'text',
      title: 'Emre',
      text: 'right message',
    },
  ];

  return (
    <div className={style.chatRoomSendDiv}>
      <div className={style.chatRoomSendEmojiDropdownDiv}>
        {/* <div className={style.chatRoomSendShowEmojiModalBtn} onClick={() => setShowEmojiModal(true)}></div>
        <Modal
          width="fit-content"
          centered={true}
          className={style.chatRoomSendEmojiModal}
          closable={false}
          // maskClosable={true}
          onOk={() => setShowEmojiModal(false)}
          onCancel={() => setShowEmojiModal(false)}
          footer={null} 
          open={showEmojiModal}
        >
          <Picker data={data} onEmojiSelect={(emoji)=>{emojiOnChange(emoji)}} />
        </Modal> */}
        <Dropdown
          // title="Dropdown Title"
          className={style.chatRoomSendDropdown}
          animationType="default"
          animationPosition="southeast"
          buttonProps={{
            text: '傳送至【' + channelTypeState + '】',
          }}
          onSelect={(e) => {
            switch (e) {
              case 1:
                setGlobalChannelType('LINE');
                break;
              case 2:
                setGlobalChannelType('MEMO');
                break;
              // case 3:
              //   setGlobalChannelType('EMAIL');
              //   break;
              default: // case 0
                setGlobalChannelType('CHAT');
                break;
            }
          }}
          items={[
            {
              text: '聊天室',
            },
            {
              text: 'LINE',
            },
            {
              text: 'MEMO',
            },
            // {
            //   text: "FACEBOOK",
            // },
            // {
            //   text: "Email",
            // },
          ]}
        />
      </div>
      {/* <Input
        className={style.chatRoomSendBtn}
        placeholder="請在此輸入訊息..."
        multiline={true}
        name="typing"
        onChange={changeFields}
        value={typingText.typingText}
        rightButtons={<Button text="送出訊息" title="Send" type="submit" onClick={whensubmit} />} //className={style.sendButton}
        /> */}
      <Form
        id="chatRoomSendForm"
        className={style.chatRoomSendForm}
        form={form}
        style={formStyle}
        onFinish={() => {}}
      >
        <Form.Item
          name="chatRoomSendFormUpload"
          valuePropName="list"
          getValueFromEvent={normFile}
          // rules={[
          //     {
          //         required: true,
          //         message: '請先上傳正確格式的檔案（jpg、png、gif）!',
          //     },
          // ]}
        >
          <Upload
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            // fileList={fileListState}
            beforeUpload={() => false}
            action={
              window.location.protocol +
              '//' +
              window.location.hostname +
              ':' +
              window.location.port +
              '/upload'
            }
            // action={adminSendMessage}
            listType="picture"
            maxCount={1}
            // beforeUpload={(file, fileList) => { beforeUploadFile(file, fileList); }}
            onChange={(fileItem) => {
              changeUploadFile(fileItem);
            }}
            onRemove={() => {
              // 完成送出訊息後，清空輸入欄位
              setTextMsg({});
              document.getElementById('chatRoomSendForm').reset();
              setFormStyle({ marginTop: '-30px' });
              setUploadBtnStyle({ fontSize: '20px' });
              // console.log('onRemove');
              // setUploadDivFlex({"flex":"1"});
            }}
            // onRemove={(file) => { removeFile(file); }}
            // ref={uploadComponentRef}
            // defaultFileList={[...fileList]}
          >
            <UploadOutlined style={uploadBtnStyle} />
            {/* <AntButton className={style.chatRoomSendUploadButton} icon={<UploadOutlined />}>上傳檔案</AntButton> */}
          </Upload>
        </Form.Item>
        <Form.Item name="chatRoomSendFormInput">
          <Input
            id="sendMsg"
            name="sendMsg"
            className={style.chatRoomSendInput}
            placeholder="請在此輸入訊息..."
            // multiline={true}
            onChange={changeFields}
            value={textMsg.text}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                console.log(textMsg?.dataBuffer || '');
                adminSendMessage(e);
              }
            }}
            rightButtons={
              <div className={style.chatRoomSendButtonDiv}>
                {/* <label id="uploadFileLabel" className={style.chatRoomUploadLabel} for="uploadFileInput">
                  <span className={style.chatRoomUploadText}>上傳檔案</span>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/gif, image/jpeg"
                    id="uploadFileInput"
                    name="uploadFileInput"
                    className={style.chatRoomUploadInput}
                    placeholder="上傳檔案"
                    // multiline={true}
                    onChange={changeUploadFile(this.files)}
                    // value={textMsg.text}
                    // onKeyDown={(e) => {
                    //     if (e.key === 'Enter') {
                    //         adminSendMessage(e);
                    //     }
                    // }}
                  />
                </label> */}
                <Emoji
                  emojiClassName={style.chatRoomSendEmojiModal}
                  mountId={mountId}
                  showEmojiModal={showEmojiModal}
                  setShowEmojiModal={setShowEmojiModal}
                  textMsg={textMsg}
                  setTextMsg={setTextMsg}
                  changeFields={changeFields}
                ></Emoji>
                <Button
                  className={style.chatRoomSendButton}
                  backgroundColor={'#1677FE'}
                  text="送出訊息"
                  title="送出訊息"
                  htmlType="submit"
                  onClick={(e) => {
                    adminSendMessage(e);
                    // form.resetFields();
                    // document.getElementById("chatRoomSendForm").reset();
                    // form.resetFields(["chatRoomSendFormInput"]);
                  }}
                />
              </div>
            }
          />
        </Form.Item>
      </Form>
      {/* <Dragger {...dragAttr}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from uploading
          company data or other banned files.
        </p>
      </Dragger> */}
    </div>
  );
}

export default ChatRoomSend;
