import React, { useEffect, useState, useRef } from 'react';
import { Layout, Input, InputNumber, Radio, Checkbox, Space, DatePicker, TimePicker, Button, Dropdown, Form, List, Collapse, Upload, Slider, Select } from 'antd';
import { DownOutlined, EyeOutlined, EditOutlined, RollbackOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import style from './styles/WpSetAbility.module.css';
import '../styles/WordpressAnt.css';
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";



function WpSetAbility() {
    const { Content } = Layout;
    const [btnLoadings, setBtnLoadings] = useState([]);
    const [dropdownTopicTextState, setDropdownTopicTextState] = useState("請選擇重點能力的主要類別"); // 能力主要類別select text
    const [dropdownProjectTextState, setDropdownProjectTextState] = useState("請選擇重點能力的次要類別"); // 能力次要類別select text
    const [dropdownTypeTextState, setDropdownTypeTextState] = useState("請選擇重點能力的代稱"); // 能力代稱select text
    const [dropdownTopicTextStateUpdate, setDropdownTopicTextStateUpdate] = useState(""); // 更新欄位能力主要類別select text
    const [dropdownProjectTextStateUpdate, setDropdownProjectTextStateUpdate] = useState(""); // 更新能力次要類別select text
    const [dropdownTypeTextStateUpdate, setDropdownTypeTextStateUpdate] = useState(""); // 更新能力代稱select text
    useEffect(()=>{
        console.log(dropdownTypeTextState);
    },[dropdownTypeTextState]);
    // const [dropdownItemUnitTextState, setDropdownItemUnitTextState] = useState("請選擇教具單位"); // 教案類別
    const [afterFilterShow, setAfterFilterShow] = useState({"display": "block"}); // 篩選完顯示區塊
    const [activityArray, setActivityArray] = useState([{id: "", location: "", startTime: "", endTime: ""}]);
    const [filterFieldsOnChangeState, setFilterFieldsOnChangeState] = useState({ topic: "", project: "", type: "", description: "", monthAge: 0, lowerMonthAge: 0, upperMonthAge: 72 }); // 篩選欄位onChange狀態
    useEffect(()=>{console.log(filterFieldsOnChangeState)},[filterFieldsOnChangeState]);
    const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState({ topic: "", project: "", type: "", description: "", monthAge: 0, lowerMonthAge: 0, upperMonthAge: 72 }); // 更新欄位onChange狀態
    useEffect(()=>{console.log(updateFieldsOnChangeState)},[updateFieldsOnChangeState]);
    const [abilityArray, setAbilityArray] = useState([]); // 能力資料
    useEffect(()=>{console.log(abilityArray)},[abilityArray]);
    const [abilityTopicArray, setAbilityTopicArray] = useState([]); // 能力主分類顯示資料
    const [abilityProjectArray, setAbilityProjectArray] = useState([]); // 能力次分類顯示資料
    const [abilityTypeArray, setAbilityTypeArray] = useState([]); // 能力代稱顯示資料
    const [abilityTopicArrayUpdate, setAbilityTopicArrayUpdate] = useState([]); // 更新能力主分類顯示資料
    const [abilityProjectArrayUpdate, setAbilityProjectArrayUpdate] = useState([]); // 更新能力次分類顯示資料
    const [abilityTypeArrayUpdate, setAbilityTypeArrayUpdate] = useState([]); // 更新能力代稱顯示資料
    const [abilityTypeTranslation ,setAbilityTypeTranslation] = useState({}); // 能力翻譯對照表
    const [courseDataArrayUnique, setCourseDataArrayUnique] = useState([]); 
    useEffect(()=>{console.log(courseDataArrayUnique)},[courseDataArrayUnique]);
    const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項資料, 第n項是否可編輯]
    const abilityDescriptionUpdateRef = useRef("");
    const abilityMonthUpdateRef = useRef(0);
    const abilityMonthRangeUpdateRef = useRef([]);
    useEffect(()=>{console.log(abilityDescriptionUpdateRef)}, [])
    useEffect(()=>{console.log(abilityDescriptionUpdateRef)}, [abilityDescriptionUpdateRef])
    useEffect(()=>{console.log(abilityDescriptionUpdateRef.current)}, [abilityDescriptionUpdateRef.current])
    // useEffect(()=>{console.log(abilityMonthRangeUpdateRef?.current?.blur?.getValue())}, [abilityMonthRangeUpdateRef?.current])
    const wpApiURL = process.env.REACT_APP_WPAPIURL; // API URL
    const loginToken = window.localStorage.getItem("loginToken");


    /*** 取得管理者資料 ***/
    const getAdminData = async() => {
        const decoded = await jwt_decode(loginToken);
        console.log(decoded);
        const id = await decoded?.id || "";
        const email = await decoded?.email || "";
        const atIndex = await email.indexOf('@');
        const name = await atIndex !== -1 ? email.substring(0, atIndex) : "";
        const data = {id, name, email};
        console.log(name);
        // setAdminData(data);
        setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["operator"]:await name });
    }
    useEffect(() => {
        getAdminData();
    }, []);
    useEffect(() => {
        getAdminData();
    }, [loginToken]);
    /*** END 取得管理者資料 ***/    

    // /*** 取得ANT FORM ***/
    // const [WpSetAbilityForm] = Form.useForm();

    /*** 完成設定後初始化 ***/
    const initialize = () => {
        setFilterFieldsOnChangeState({ topic: "", project: "", type: "", description: "", monthAge: 0, lowerMonthAge: 0, upperMonthAge: 72 }); // 能力設定欄位State資料
        setUpdateFieldsOnChangeState({ topic: "", project: "", type: "", description: "", monthAge: 0, lowerMonthAge: 0, upperMonthAge: 72 }); // 能力編輯欄位State資料
        // setAbilityArray([]); // 能力Request資料
        // setAbilityTopicArray([]); // 能力主分類顯示資料
        // setAbilityProjectArray([]); // 能力次分類顯示資料
        // setAbilityTypeArray([]); // 能力代稱顯示資料
        // setAbilityTopicArrayUpdate([]); // 更新能力主分類顯示資料
        // setAbilityProjectArrayUpdate([]); // 更新能力次分類顯示資料
        // setAbilityTypeArrayUpdate([]); // 更新能力代稱顯示資料
        setDropdownTopicTextState("請選擇重點能力的主要類別"); // 能力主要類別select text
        setDropdownProjectTextState("請選擇重點能力的次要類別"); // 能力次要類別select text
        setDropdownTypeTextState("請選擇重點能力的代稱"); // 能力代稱select text
        setDropdownTopicTextStateUpdate(""); // 更新欄位能力主要類別select text
        setDropdownProjectTextStateUpdate(""); // 更新能力次要類別select text
        setDropdownTypeTextStateUpdate(""); // 更新能力代稱select text
    } 


    /*** CourseData去除有重複pid的obj ***/
    // useEffect(()=>{
    //     const makeUniqueArray = (arr, key) => {
    //         const uniqueObjects = {};
    //         return arr.reduce((result, obj) => {
    //             if( obj?.flag > -1 ) { // 非刪除項才做處理
    //                 if( ! uniqueObjects[obj[key]] ) {
    //                     uniqueObjects[obj[key]] = true;
    //                     result.push(obj);
    //                 }
    //             } 
    //             return result;
    //         }, []);
    //     };
    //     const uniqueArray = makeUniqueArray(courseDataArray, 'pid');
    //     // const uniqueArrayTest = makeUniqueArray(courseDataArrayTest, 'pid');
    //     setCourseDataArrayUnique(uniqueArray);
    //     // setCourseDataArrayUniqueTest(uniqueArrayTest);
    // },[courseDataArray]);
        

    useEffect(() => {
        if( filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.toExperience - filterFieldsOnChangeState?.compensate < 0 ) {
            setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["formal"]: 0 });
        } else if( filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.formal - filterFieldsOnChangeState?.compensate < 0 ) {
            setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["toExperience"]: 0 });
        } else if( filterFieldsOnChangeState?.maximum - filterFieldsOnChangeState?.formal - filterFieldsOnChangeState?.toExperience < 0 ) {
            setFilterFieldsOnChangeState({ ...filterFieldsOnChangeState, ["compensate"]: 0 });
        }
    }, []);

    /*** 查詢能力對照表 ***/
    const requestAbilityTypeTranslation = async(e) => {
        e?.preventDefault();
        const url = wpApiURL + "/setting/typeTranslation";
        const response = await fetch(
            url,
            {
                headers: {
                    "Accept": "*/*",
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            // alert(`能力代稱查詢失敗！\n${err}`);
            throw new Error("Request Ability Type failed");
        });
        // console.log(response);
        setAbilityTypeTranslation(response);
        return response;
    }

    /*** 查詢能力主分類 ***/
    const requestTopicAbility = async(e) => {
        e?.preventDefault();
        const url = wpApiURL + "/setting/allType";
        const response = await fetch(
            url,
            {
                headers: {
                    "Accept": "application/json",
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            // alert(`能力代稱查詢失敗！\n${err}`);
            throw new Error("Request Ability Topic failed");
        });
        console.log(response);

        const abilityTopicArrayTemp = Object.keys(response);

        console.log(abilityTopicArrayTemp);

        // // setAbilityTopicArray(abilityTopicArrayTemp);
        // const abilityTypeTranslationObj = await requestAbilityTypeTranslation();
        // const abilityTopicTranslatedObject = abilityTopicArrayTemp.reduce((result, key) => { // 將代稱Array轉換為 {代稱：譯文} Object
        //     result[key] = abilityTypeTranslationObj[key];
        //     return result;
        // }, {});
        // console.log(abilityTopicTranslatedObject);
        // const abilityTopicEntries = Object.entries(abilityTopicTranslatedObject);

        const abilityTopicEntries = await ability2AbilityTranslation(abilityTopicArrayTemp);
        console.log(abilityTopicEntries);

        // const abilityTopicArray = [
        //     {
        //         label: '教案類別1',
        //         key: '教案類別1',
        //         onClick: ({ item, key, keyPath, domEvent })=>{
        //             setDropdownTypeTextState(key);
        //             setDropdownTopicTextState(key);
        //             setDropdownProjectTextState(key);
        //         }
        //     }
        // ]
        const abilityTopicDropdownItem = abilityTopicEntries.map((val, idx) => {
            return {
                label: `${val[0]}：${val[1]}`,
                key: `${val[0]}：${val[1]}`,
                onClick: ({ item, key, keyPath, domEvent })=>{
                    setDropdownTopicTextState(key);
                    setDropdownProjectTextState("");
                    setDropdownTypeTextState("");
                    setAbilityProjectArray([]); // 能力次分類顯示資料
                    setAbilityTypeArray([]); // 能力代稱顯示資料
                    setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["topic"]:val[0], ["project"]:"", ["type"]:""});
                    requestProjectAbility(val[0], response);
                }
            }
        });     
        setAbilityTopicArray(abilityTopicDropdownItem);
    }
    useEffect(() => {
        requestTopicAbility();
    }, []);

    /*** 查詢能力主分類(更新欄位) ***/
    const requestTopicAbilityUpdate = async(e, topic, project, type, description, monthAge, lowerMonthAge, upperMonthAge) => {
        console.log(topic, project, type, description, monthAge, lowerMonthAge, upperMonthAge);
        e?.preventDefault();
        const topicNew = topic ?? "";
        const projectNew = project ?? "";
        const typeNew = type ?? "";
        // const descriptionNew = !! abilityDescriptionUpdateRef?.current?.input?.value ? abilityDescriptionUpdateRef?.current?.input?.value : description ? description : "";
        const descriptionNew = description ?? "";
        const monthAgeNew = monthAge ?? 0;
        const lowerMonthAgeNew = lowerMonthAge ?? 0;
        const upperMonthAgeNew = upperMonthAge ?? 72;
        setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["topic"]:topicNew, ["project"]:projectNew, ["type"]:typeNew, ["description"]:descriptionNew, ["monthAge"]:monthAgeNew, ["lowerMonthAge"]:lowerMonthAgeNew, ["upperMonthAge"]:upperMonthAgeNew});
        // setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["topic"]:topicNew, ["project"]:projectNew, ["type"]:typeNew, ["description"]:descriptionNew, ["monthAge"]:monthAgeNew});
        const url = wpApiURL + "/setting/allType";
        const response = await fetch(
            url,
            {
                headers: {
                    "Accept": "application/json",
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            // alert(`能力代稱查詢失敗！\n${err}`);
            throw new Error("Request Ability Topic failed");
        });
        console.log(response);

        const abilityTopicArrayTemp = Object.keys(response);

        console.log(abilityTopicArrayTemp);


        const abilityTopicEntries = await ability2AbilityTranslation(abilityTopicArrayTemp);
        console.log(abilityTopicEntries);

        // const abilityTopicArray = [
        //     {
        //         label: '教案類別1',
        //         key: '教案類別1',
        //         onClick: ({ item, key, keyPath, domEvent })=>{
        //             setDropdownTypeTextState(key);
        //             setDropdownTopicTextState(key);
        //             setDropdownProjectTextState(key);
        //         }
        //     }
        // ]

        const abilityTopicDropdownItemUpdate = abilityTopicEntries.map((val, idx) => {
            return {
                label: `${val[0]}：${val[1]}`,
                key: `${val[0]}：${val[1]}`,
                onClick: ({ item, key, keyPath, domEvent })=>{
                    // requestTopicAbilityUpdate(null, topicNew, projectNew, typeNew, descriptionNew, monthAgeNew, lowerMonthAgeNew, upperMonthAgeNew)
                    setDropdownTopicTextStateUpdate(key);
                    setDropdownProjectTextStateUpdate("");
                    setDropdownTypeTextStateUpdate("");
                    setAbilityProjectArrayUpdate([]); // 能力次分類顯示資料
                    setAbilityTypeArrayUpdate([]); // 能力代稱顯示資料
                    // let updateFieldsOnChangeStateTemp = updateFieldsOnChangeState;
                    // console.log(updateFieldsOnChangeStateTemp);
                    // updateFieldsOnChangeStateTemp["topic"] = val[0];
                    // updateFieldsOnChangeStateTemp["project"] = "";
                    // updateFieldsOnChangeStateTemp["type"] = "";
                    // console.log(updateFieldsOnChangeStateTemp);
                    // setUpdateFieldsOnChangeState(updateFieldsOnChangeStateTemp);
                    setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["topic"]:val[0], ["project"]:"", ["type"]:"", ["description"]:abilityDescriptionUpdateRef?.current?.input?.value, ["monthAge"]:abilityMonthUpdateRef?.current?.value, ["lowerMonthAge"]:lowerMonthAgeNew, ["upperMonthAge"]:upperMonthAgeNew});
                    // setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["topic"]:val[0], ["project"]:"", ["type"]:"", ["description"]:abilityDescriptionUpdateRef?.current?.input?.value, ["monthAge"]:abilityMonthUpdateRef?.current?.value});
                    requestProjectAbilityUpdate( response, val[0], description, monthAge, lowerMonthAge, upperMonthAge );
                }
            }
        });        
        setAbilityTopicArrayUpdate(abilityTopicDropdownItemUpdate);
    }    

    /*** 查詢能力次分類 ***/
    const requestProjectAbility = async(topicVal, response) => {
        // console.log(key, response);
        // const project = response[key]['project']['E'];
        let abilityProjectArrayTemp = [];
        let projectsArray = [];
        if ( Object.prototype.hasOwnProperty.call(response, topicVal) ) {
            projectsArray = Object.values(response[topicVal]?.projects);
            console.log(projectsArray);
            projectsArray.forEach(projectObj => {
                console.log(Object.keys(projectObj)[0]);
                const projectKey = Object.keys(projectObj)[0];
                abilityProjectArrayTemp.push(projectKey);
            });
        }
        console.log(abilityProjectArrayTemp);

        // const abilityTypeTranslationObj = await requestAbilityTypeTranslation();
        // const abilityProjectTranslatedObject = abilityProjectArrayTemp.reduce((result, key) => { // 將代稱Array轉換為 {代稱：譯文} Object
        //     result[key] = abilityTypeTranslationObj[key];
        //     return result;
        // }, {});
        // console.log(abilityProjectTranslatedObject);
        // const abilityProjectEntries = Object.entries(abilityProjectTranslatedObject);

        const abilityProjectEntries = await ability2AbilityTranslation(abilityProjectArrayTemp);
        console.log(abilityProjectEntries);

        const abilityProjectDropdownItem = abilityProjectEntries.map((val, idx) => {
            return {
                label: `${val[0]}：${val[1]}`,
                key: `${val[0]}：${val[1]}`,
                onClick: ({ item, key, keyPath, domEvent })=>{
                    setDropdownProjectTextState(key);
                    setDropdownTypeTextState("");
                    setAbilityTypeArray([]); // 能力代稱顯示資料
                    setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["topic"]:topicVal, ["project"]:val[0], ["type"]:""});
                    requestTypeAbility(val[0], projectsArray, topicVal);
                }
            }
        });
        setAbilityProjectArray(abilityProjectDropdownItem);
    }

        /*** 查詢能力次分類(更新欄位) ***/
        const requestProjectAbilityUpdate = async(response, topic, description, monthAge, lowerMonthAge, upperMonthAge) => {
            console.log(response, topic);
            const topicNew = topic ?? "";
            const descriptionNew = description ?? "";
            const monthAgeNew = monthAge ?? 0;
            const lowerMonthAgeNew = lowerMonthAge ?? 0;
            const upperMonthAgeNew = upperMonthAge ?? 72;            
            let abilityProjectArrayTemp = [];
            let projectsArray = [];
            if ( Object.prototype.hasOwnProperty.call(response, topicNew) ) {
                projectsArray = Object.values(response[topicNew]?.projects);
                console.log(projectsArray);
                projectsArray.forEach(projectObj => {
                    console.log(Object.keys(projectObj)[0]);
                    const projectKey = Object.keys(projectObj)[0];
                    abilityProjectArrayTemp.push(projectKey);
                });
            }
            console.log(abilityProjectArrayTemp);
    
            const abilityProjectEntries = await ability2AbilityTranslation(abilityProjectArrayTemp);
            console.log(abilityProjectEntries);
    
            const abilityProjectDropdownItemUpdate = abilityProjectEntries.map((val, idx) => {
                return {
                    label: `${val[0]}：${val[1]}`,
                    key: `${val[0]}：${val[1]}`,
                    onClick: ({ item, key, keyPath, domEvent })=>{
                        setDropdownProjectTextStateUpdate(key);
                        setDropdownTypeTextStateUpdate("");
                        setAbilityTypeArrayUpdate([]); // 能力代稱顯示資料
                        setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["topic"]:topicNew, ["project"]:val[0], ["type"]:"", ["description"]:abilityDescriptionUpdateRef?.current?.input?.value, ["monthAge"]:abilityMonthUpdateRef?.current?.value, ["lowerMonthAge"]:lowerMonthAgeNew, ["upperMonthAge"]:upperMonthAgeNew});
                        requestTypeAbilityUpdate( projectsArray, topicNew, val[0], description, monthAge, lowerMonthAge, upperMonthAge);
                    }
                }
            });
            setAbilityProjectArrayUpdate(abilityProjectDropdownItemUpdate);
        }

    /*** 查詢能力代稱 ***/
    const requestTypeAbility = async(projectVal, projectsArray, topicVal) => {
        console.log(projectVal, projectsArray);
        // const project = response[key]['project']['E'];
        let abilityTypeArrayTemp = [];
        // if ( Object.prototype.hasOwnProperty.call(projectsArray, key) ) {
            // const projectsArray = Object.values(projectsArray[key]?.projects);
            // console.log(projectsArray);
            projectsArray.forEach(projectObj => {
                console.log(Object.keys(projectObj)[0]);
                const projectKey = Object.keys(projectObj)[0];
                if( projectVal === projectKey ) {
                    const abilityArray = projectObj[projectVal]?.abilities;
                    abilityArray.forEach(abilityObj => {
                        console.log(Object.keys(abilityObj)[0]);
                        const abilityKey = Object.keys(abilityObj)[0];
                        abilityTypeArrayTemp.push(abilityKey);
                    })
                }
            });
        // }
        console.log(abilityTypeArrayTemp);
        
        // const abilityTypeTranslationObj = await requestAbilityTypeTranslation();
        // const abilityTypeTranslatedObject = abilityTypeArrayTemp.reduce((result, key) => { // 將代稱Array轉換為 {代稱：譯文} Object
        //     result[key] = abilityTypeTranslationObj[key];
        //     return result;
        // }, {});
        // console.log(abilityTypeTranslatedObject);
        // const abilityTypeEntries = Object.entries(abilityTypeTranslatedObject);

        const abilityTypeEntries = await ability2AbilityTranslation(abilityTypeArrayTemp);
        console.log(abilityTypeEntries);

        const abilityTypeDropdownItem = abilityTypeEntries.map((val, idx) => {
            return {
                label: `${val[0]}：${val[1]}`,
                key: `${val[0]}：${val[1]}`,
                onClick: ({ item, key, keyPath, domEvent })=>{
                    setDropdownTypeTextState(key);
                    setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["topic"]:topicVal, ["project"]:projectVal, ["type"]:val[0]});
                }
            }
        });
        setAbilityTypeArray(abilityTypeDropdownItem);
    }    

    /*** 查詢能力代稱(更新欄位) ***/
    const requestTypeAbilityUpdate = async( projectsArray, topic, project, description, monthAge, lowerMonthAge, upperMonthAge ) => {
        console.log( projectsArray, topic, project, description, monthAge, lowerMonthAge, upperMonthAge );
        const topicNew = topic ?? "";
        const projectNew = project ?? "";
        const descriptionNew = description ?? "";
        const monthAgeNew = monthAge ?? 0;
        const lowerMonthAgeNew = lowerMonthAge ?? 0;
        const upperMonthAgeNew = upperMonthAge ?? 72;
        // const project = response[key]['project']['E'];
        let abilityTypeArrayTemp = [];
        // if ( Object.prototype.hasOwnProperty.call(projectsArray, key) ) {
            // const projectsArray = Object.values(projectsArray[key]?.projects);
            // console.log(projectsArray);
            projectsArray.forEach(projectObj => {
                console.log(Object.keys(projectObj)[0]);
                const projectKey = Object.keys(projectObj)[0];
                if( projectNew === projectKey ) {
                    const abilityArray = projectObj[projectNew]?.abilities;
                    abilityArray.forEach(abilityObj => {
                        console.log(Object.keys(abilityObj)[0]);
                        const abilityKey = Object.keys(abilityObj)[0];
                        abilityTypeArrayTemp.push(abilityKey);
                    })
                }
            });
        // }
        console.log(abilityTypeArrayTemp);
        
        // const abilityTypeTranslationObj = await requestAbilityTypeTranslation();
        // const abilityTypeTranslatedObject = abilityTypeArrayTemp.reduce((result, key) => { // 將代稱Array轉換為 {代稱：譯文} Object
        //     result[key] = abilityTypeTranslationObj[key];
        //     return result;
        // }, {});
        // console.log(abilityTypeTranslatedObject);
        // const abilityTypeEntries = Object.entries(abilityTypeTranslatedObject);

        const abilityTypeEntries = await ability2AbilityTranslation(abilityTypeArrayTemp);
        console.log(abilityTypeEntries);

        const abilityTypeDropdownItemUpdate = abilityTypeEntries.map((val, idx) => {
            return {
                label: `${val[0]}：${val[1]}`,
                key: `${val[0]}：${val[1]}`,
                onClick: ({ item, key, keyPath, domEvent })=>{
                    setDropdownTypeTextStateUpdate(key);
                    // let updateFieldsOnChangeStateTemp = updateFieldsOnChangeState;
                    // updateFieldsOnChangeStateTemp["topic"] = topicVal;
                    // updateFieldsOnChangeStateTemp["project"] = projectVal;
                    // updateFieldsOnChangeStateTemp["type"] = val[0];
                    // console.log(updateFieldsOnChangeStateTemp);
                    // setUpdateFieldsOnChangeState(updateFieldsOnChangeStateTemp);
                    setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["topic"]:topicNew, ["project"]:projectNew, ["type"]:val[0], ["description"]:abilityDescriptionUpdateRef?.current?.input?.value, ["monthAge"]:abilityMonthUpdateRef?.current?.value, ["lowerMonthAge"]:lowerMonthAgeNew, ["upperMonthAge"]:upperMonthAgeNew});
                }
            }
        });
        setAbilityTypeArrayUpdate(abilityTypeDropdownItemUpdate);
    }    

    /*** 將能力代稱Array轉為[能力代稱,譯文]Array ***/
    const ability2AbilityTranslation = async(abilityTypeArrayTemp) => {
        const abilityTypeTranslationObj = await requestAbilityTypeTranslation();
        const abilityTypeTranslatedObject = abilityTypeArrayTemp.reduce((result, key) => { // 將代稱Array轉換為 {代稱：譯文} Object
            result[key] = abilityTypeTranslationObj[key];
            return result;
        }, {});
        console.log(abilityTypeTranslatedObject);
        const abilityTypeEntries = Object.entries(abilityTypeTranslatedObject); // 轉化為[[代稱，譯文], ...]
        console.log(abilityTypeEntries);
        return abilityTypeEntries;
    }

    /*** 查詢能力 ***/
    const requestAbility = async(e) => {
        e?.preventDefault();
        const url = wpApiURL + "/setting/ability";
        const response = await fetch(
            url,
            {
                headers: {
                    "Accept": "application/json",
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            // alert(`能力查詢失敗！\n${err}`);
            throw new Error("Request Ability failed");
        });
        console.log(response);
        // if( response.code === 200 ) {
        // const responseData = response?.data || [];
        // console.log(responseData);
        setAbilityArray(response); 
        setIsEditMode([-1, false]); // 返回非編輯模式
        // }
    }
    useEffect(() => {
        requestAbility();
    }, []);

    /*** 查詢能力Ability ID ***/
    const requestAbilityByID = async(e, aid) => {
        e?.preventDefault();
        const url = wpApiURL + "/setting/ability/" + aid;
        const response = await fetch(
            url,
            {
                headers: {
                    "Accept": "application/json",
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            // alert(`能力查詢失敗！\n${err}`);
            throw new Error("Request Ability failed");
        });
        console.log(response);
        // if( response.code === 200 ) {
        // const responseData = response?.data || [];
        // console.log(responseData);
        setAbilityArray(response); 
        setIsEditMode([-1, false]); // 返回非編輯模式
        // }
    }

    const enterLoading = (index) => {
        setBtnLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = true;
          return newLoadings;
        });
    };

    const endLoading = (index) => {
        setBtnLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
        });
    }

    const fieldsOnChange = (e, idx) => {
        e?.preventDefault();
        const fieldsId = e?.target?.id || "";
        const value = e?.target?.value || "";
        console.log(fieldsId, value);
        switch(fieldsId) {
            case "abilityDescription":  
                setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["description"]:value});
                break;
        }
    }

 
    const inputNumberMonthOnChange = (value) => {
        console.log(value);
        setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["monthAge"]:value}); 
    }
    const inputNumberMonthRangeOnChange = (value) => {
        console.log(value);
        // setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["numClasses"]:value}); 
        setFilterFieldsOnChangeState({...filterFieldsOnChangeState, ["lowerMonthAge"]:value[0], ["upperMonthAge"]:value[1] }); 
    }

    const updateFieldsOnChange = (e, defVal) => {
        e?.preventDefault();
        const fieldsId = e?.target?.id || "";
        const value = e?.target?.value || "";
        console.log(fieldsId, value);
        switch(fieldsId) {
            case "abilityDescriptionUpdate":  
                setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["description"]:value});
                break;
        }
    }

    const inputNumberMonthOnChangeUpdate = (value) => {
        console.log(value);
        setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["monthAge"]:value}); 
    }
    const inputNumberMonthRangeOnChangeUpdate = (value) => {
        console.log(value);
        setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["lowerMonthAge"]:value[0], ["upperMonthAge"]:value[1] }); 
    }
    // const showAbilityMonthRangeUpdateRef = (e) => {
    //     const { value } = e?.target || [0, 72];
    //     console.log("showAbilityMonthRangeUpdateRef", e)
    //     console.log("showAbilityMonthRangeUpdateRef", e.target)
    //     console.log("showAbilityMonthRangeUpdateRef", value)
    //     setUpdateFieldsOnChangeState({...updateFieldsOnChangeState, ["lowerMonthAge"]:value[0], ["upperMonthAge"]:value[1] }); 
    // }


    const [mark, setMark] = useState({
        // 0: {
        //   style: {
        //     color: '#f26419',
        //   },
        //   label: <strong>0個月</strong>
        // },
        // 72: {
        //     style: {
        //       color: '#1677FF',
        //     },
        //     label: <strong>72個月</strong>
        // }
    });
    useEffect(()=>{
        const monthAge = parseInt( filterFieldsOnChangeState?.monthAge || 0 );
        const lowerMonthAge = parseInt( filterFieldsOnChangeState?.lowerMonthAge || 0 );
        const upperMonthAge = parseInt( filterFieldsOnChangeState?.upperMonthAge || 0 );
        let markObj = {};
        markObj[lowerMonthAge] = {
            style: {
                color: '#1677FF',
            },
            label: <strong>{ filterFieldsOnChangeState?.lowerMonthAge || 0 }個月</strong>,
        };
        markObj[upperMonthAge] = {
            style: {
                color: '#1677FF',
            },
            label: <strong>{ filterFieldsOnChangeState?.upperMonthAge || 0 }個月</strong>,
        };
        markObj[monthAge] = {
            style: {
                color: '#f26419',
                marginTop: '-32px'
            },
            label: <strong>{ filterFieldsOnChangeState?.monthAge || 0 }個月</strong>,
        };
        setMark(markObj);
    }, [filterFieldsOnChangeState]); 

    const [markUpdate, setMarkUpdate] = useState({});
    useEffect(()=>{
        const monthAge = parseInt( updateFieldsOnChangeState?.monthAge || 0 );
        const lowerMonthAge = parseInt( updateFieldsOnChangeState?.lowerMonthAge || 0 );
        const upperMonthAge = parseInt( updateFieldsOnChangeState?.upperMonthAge || 0 );
        let markObj = {};
        markObj[lowerMonthAge] = {
            style: {
                color: '#1677FF',
            },
            label: <strong>{ updateFieldsOnChangeState?.lowerMonthAge || 0 }個月</strong>,
        };
        markObj[upperMonthAge] = {
            style: {
                color: '#1677FF',
            },
            label: <strong>{ updateFieldsOnChangeState?.upperMonthAge || 0 }個月</strong>,
        };
        markObj[monthAge] = {
            style: {
                color: '#f26419',
                marginTop: '-32px'
            },
            label: <strong>{ updateFieldsOnChangeState?.monthAge || 0 }個月</strong>,
        };
        setMarkUpdate(markObj);
    }, [updateFieldsOnChangeState]);        

    /*** 設定重點能力 ***/
    const setAbility = async(e) => {
        e?.preventDefault();
        const url = wpApiURL + "/setting/ability";
        console.log(url);
        // console.log(searchString);
        const response = await fetch(
            url,
            {
                method: 'POST',
                body: JSON.stringify(filterFieldsOnChangeState),
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/json" 
                },
            }
        )
        // .then((res) => res.json())
        .catch((err) => {
            alert(`重點能力【${dropdownTopicTextState}－${dropdownProjectTextState}－${dropdownTypeTextState}】設定失敗！\n${err}`);
            throw new Error("Add Ability failed");
        });
        console.log(response);
        if( response.status === 201 ) {
            alert(`重點能力【${dropdownTopicTextState}－${dropdownProjectTextState}－${dropdownTypeTextState}】設定成功！`);
            setAfterFilterShow({"display":"block"});  // 顯示設定完成後能力
        } else {
            alert(`重點能力【${dropdownTopicTextState}－${dropdownProjectTextState}－${dropdownTypeTextState}】設定失敗！\nError ${response?.statusCode}：${response?.error} (${JSON.stringify((response?.message)).replace(/[\[\]"]/g, ' ')})`);
            console.log(response);
        }
        requestAbility();
        initialize();
    }

    /*** 更新重點能力 ***/
    const updateAbility = async(e, aid) => {
        e?.preventDefault();
        const url = wpApiURL + "/setting/ability/" + aid;
        console.log(url);
        // console.log(searchString);
        const response = await fetch(
            url,
            {
                method: 'PUT',
                body: JSON.stringify(updateFieldsOnChangeState),
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json" 
                },
            }
        )
        .then((res) => res.json())
        .catch((err) => {
            alert(`重點能力【${updateFieldsOnChangeState?.topic}－${updateFieldsOnChangeState?.project}－${updateFieldsOnChangeState?.type}】更新失敗！\n( Ability ID : ${aid} )\n${err}`);
            throw new Error("Update Ability failed");
        });
        console.log(response);
        // if( response.code === 200 ) {
        if( !! response._id ) {
            alert(`重點能力【${updateFieldsOnChangeState?.topic}－${updateFieldsOnChangeState?.project}－${updateFieldsOnChangeState?.type}】更新成功！\n( Ability ID : ${aid} )`);
        } else {
            alert(`重點能力【${updateFieldsOnChangeState?.topic}－${updateFieldsOnChangeState?.project}－${updateFieldsOnChangeState?.type}】更新失敗！\n( Ability ID : ${aid} )\nError ${response.code}：${response.error} (${response.message})`);
            console.log(response);
        }
        requestAbility();
        initialize();
    }
    /*** 刪除重點能力 ***/
    const deleteAbility = async( e, aid, name ) => {
        e?.preventDefault();
        if( window.confirm(`確定要刪除重點能力【${name}】？ ( 商品ID：${aid} )`) ) {
            const url = wpApiURL + "/setting/ability/" + aid;
            console.log(url);
            const data = { "flag": -1 }
            // console.log(searchString);
            const response = await fetch(
                url,
                {
                    method: 'DELETE',
                    body: JSON.stringify(data),
                    headers: {
                        "Accept": "*/*"
                    },
                }
            )
            .then((res) => res.json())
            .catch((err) => {
                alert(`重點能力【${name}】刪除失敗！\n( Product ID : ${aid} )\n${err}`);
                throw new Error("Delete Course failed");
            });
            console.log(response);
            if( response.code === 200 ) {
                alert(`重點能力【${name}】刪除成功！\n( Product ID : ${aid} )`);
            } else {
                alert(`重點能力【${name}】刪除失敗！\n( Product ID : ${aid} )\nError ${response.statusCode}：${response.error} ( ${response.message} )`);
                console.log(response);
            }
        } else {
            return;
        }
        requestAbility();
        initialize();
    }

    return (
        <Layout id={"WpSetAbilityLayout"}>
            <Form id={"WpSetAbilityForm"} name={"WpSetAbilityForm"} className={style.wpSetAbilityForm} onFinish={()=>{}}>
                <Content className={`${style.wpSetAbilityContent}`} >
                    <section id="WpSetAbilitySection" className={`${style.wpSetAbilitySection} ${style.wpSetAbilitySectionTitle}`}>
                        <h4 className={style.wpSetAbilityH4}>重點能力設定</h4>
                    </section>
                    <section id="WpSetAbilitySection" className={`${style.wpSetAbilitySection} ${style.wpSetAbilitySectionCourseIdName}`}> 
                        <Form.Item className={style.wpSetAbilityFormItem} name="abilityTopic">
                            <div className={`${style.wpSetAbilityDivInput} ${style.wpSetAbilityDivInputCategory}`}>
                                <Space className={`${style.wpSetAbilitySpaceInput} ${style.wpSetAbilitySpaceInputCategory}`}>
                                    <h6 className={`${style.wpSetAbilityH6} ${style.wpSetAbilityFlex1}`}>能力主要分類</h6>
                                    <Dropdown
                                        id="abilityTopic"
                                        className={`${style.wpSetAbilitySendBtn} ${style.wpSetAbilityFlex2}  ${style.wpSetAbilityDropdownTopic}`}
                                        menu={{ items: abilityTopicArray }}
                                        placement="bottom"
                                        // onOpenChange={(open)=>console.log(open)}
                                    >
                                        <Button size="middle" >
                                            <Space className={`${style.wpSetAbilityDropdownBtnSpace}`}>
                                                {!!dropdownTopicTextState?dropdownTopicTextState:"請選擇重點能力的主要分類"}
                                                <DownOutlined />
                                            </Space>
                                        </Button>    
                                    </Dropdown>
                                </Space>
                                {/* { dropdownCategoryTextState === "其他" ? (
                                    <Space className={`${style.wpSetAbilitySpaceInput}`}> 
                                        <h6 className={`${style.wpSetAbilityH6} ${style.wpSetAbilityFlex1}`}>　　　　</h6>                           
                                        <Input 
                                            id="teachingPlanCategoryOther"
                                            className={`${style.wpSetAbilityInput} ${style.wpSetAbilityInputCategory} ${style.wpSetAbilityFlex2}`} 
                                            placeholder="請輸入新的教案類別名稱" 
                                            allowClear size="middle" 
                                            onChange={(e)=>fieldsOnChange(e)}
                                        />
                                    </Space>
                                ) : (
                                    <></>
                                )} */}
                            </div>
                        </Form.Item>
                        <Form.Item className={style.wpSetAbilityFormItem} name="abilityProject">
                            <Space className={`${style.wpSetAbilitySpaceInput}`}>
                                <h6 className={`${style.wpSetAbilityH6} ${style.wpSetAbilityFlex1}`}>能力次要分類</h6>
                                <Dropdown
                                        id="abilityProject"
                                        className={`${style.wpSetAbilitySendBtn} ${style.wpSetAbilityFlex2}  ${style.wpSetAbilityDropdownProject}`}
                                        menu={{ items: abilityProjectArray }}
                                        placement="bottom"
                                        // onOpenChange={(open)=>console.log(open)}
                                    >
                                        <Button size="middle" >
                                            <Space className={`${style.wpSetAbilityDropdownBtnSpace}`}>
                                                {!!dropdownProjectTextState?dropdownProjectTextState:"請選擇重點能力的次要分類"}
                                                <DownOutlined />
                                            </Space>
                                        </Button>    
                                </Dropdown>
                            </Space>
                        </Form.Item>
                        <Form.Item className={style.wpSetAbilityFormItem} name="abilityType">
                            <div className={`${style.wpSetAbilityDivInput} ${style.wpSetAbilityDivInputCategory}`}>
                                <Space className={`${style.wpSetAbilitySpaceInput}`}>
                                    <h6 className={`${style.wpSetAbilityH6} ${style.wpSetAbilityFlex1}`}>能力代稱　　</h6>
                                    <Dropdown
                                        id="abilityType"
                                        className={`${style.wpSetAbilitySendBtn} ${style.wpSetAbilityFlex2}  ${style.wpSetAbilityDropdownType}`}
                                        menu={{ items: abilityTypeArray }}
                                        placement="bottom"
                                        // onOpenChange={(open)=>console.log(open)}
                                    >
                                        <Button size="middle" >
                                            <Space className={`${style.wpSetAbilityDropdownBtnSpace}`}>
                                                {!!dropdownTypeTextState?dropdownTypeTextState:"請選擇重點能力的代稱"}
                                                <DownOutlined />
                                            </Space>
                                        </Button>    
                                    </Dropdown>
                                </Space>
                            </div>
                        </Form.Item>                        
                        <Form.Item className={style.wpSetAbilityFormItem} name="abilityDescription">
                            <Space className={`${style.wpSetAbilitySpaceInput}`}>
                                <h6 className={`${style.wpSetAbilityH6} ${style.wpSetAbilityFlex1}`}>能力描述　　</h6>
                                <div className={`${style.wpSetAbilityDivInput} ${style.wpSetAbilityDivInputName}`}>
                                    <Input 
                                        id="abilityDescription"
                                        className={`${style.wpSetAbilityInput} ${style.wpSetAbilityInputDescription} ${style.wpSetAbilityFlex2}`} 
                                        placeholder="請輸入能力描述" 
                                        allowClear size="middle" 
                                        value={filterFieldsOnChangeState?.description}
                                        onChange={(e)=>fieldsOnChange(e)}
                                    />
                                </div>
                            </Space>
                        </Form.Item>                        
                        <Form.Item className={style.wpSetAbilityFormItem} name="abilityMonth">
                            <Space className={`${style.wpSetAbilitySpaceInput} ${style.wpSetAbilitySpaceInputMonth}`}>
                                <h6 className={`${style.wpSetAbilityH6} ${style.wpSetAbilityFlex1}`}>適用月齡　　</h6>
                                <div className={`${style.wpSetAbilityDivInput} ${style.wpSetAbilityDivInputName}`}>
                                    <InputNumber
                                        id="abilityMonth"
                                        className={`${style.wpSetAbilityInput} ${style.wpSetAbilityInputPeriod} ${style.wpSetAbilityFlex2}`} 
                                        // placeholder="0" 
                                        min={0}
                                        max={72}
                                        size="small" 
                                        value={filterFieldsOnChangeState?.monthAge}
                                        onChange={(e)=>inputNumberMonthOnChange(e)}
                                        addonAfter={<span>個月</span>}
                                    />
                                </div>
                            </Space>
                        </Form.Item>
                        <Form.Item className={style.wpSetAbilityFormItem} name="abilityMonthRange">
                            <Space className={`${style.wpSetAbilitySpaceInput} ${style.wpSetAbilitySpaceInputMonthRange}`}>
                                <h6 className={`${style.wpSetAbilityH6} ${style.wpSetAbilityFlex1}`}>月齡範圍　　</h6>
                                <div className={`${style.wpSetAbilityDivInput} ${style.wpSetAbilityDivSlider}`}>
                                    <Slider 
                                        id="abilityMonthRange"
                                        className={`${style.wpSetAbilityInput} ${style.wpSetAbilityInputMonthRange} ${style.wpSetAbilityFlex2}`} 
                                        range 
                                        marks={mark} 
                                        min={0}
                                        max={72}
                                        // defaultValue={[ 0, 72 ]} 
                                        value={[filterFieldsOnChangeState?.lowerMonthAge, filterFieldsOnChangeState?.upperMonthAge]}
                                        onChange={(e)=>inputNumberMonthRangeOnChange(e)}
                                    />
                                </div>
                            </Space>
                        </Form.Item>
                    </section>
                    <section id="WpSetAbilitySection" className={`${style.wpSetAbilitySection} ${style.wpSetAbilityBtnSection}`}>
                        <Form.Item className={`${style.wpSetAbilityFormItem} ${style.wpSetAbilityFormItemSendBtn}`} name="setAbility">
                            <Space className={style.wpSetAbilitySpaceSendBtn} >
                                <Button className={style.lineBroadcastSendBtn} type="primary" 
                                    // loading={btnLoadings[1]} 
                                    onClick={(e) => setAbility(e)}>
                                    <Space>
                                        完成設定
                                    </Space>
                                </Button>    
                            </Space>
                        </Form.Item>
                    </section>
                </Content>   
            </Form>
            <Content className={`${style.wpSetAbilityFilterConsultContent} ${style.wpSetAbilityFilterConsultContentQuery}`} style={afterFilterShow}>
                <section id={"WpSetAbilityFilterConsultSearchSec"} className={style.wpSetAbilityFilterConsultSearchSec}>
                    <div className={style.wpSetAbilityFilterConsultTitle}>
                        <h5>已新增重點能力：</h5>
                    </div>
                    <List
                        className={style.wpSetAbilityFilterConsultList}
                        locale={{}}
                        dataSource={abilityArray}
                        renderItem={(item, index) => (
                            Object.keys(abilityArray[0]).includes('_id') && isEditMode[0] === index && isEditMode[1] ? (       
                                <List.Item>
                                    <List.Item.Meta
                                        className={style.wpSetAbilityFilterConsultListItemMeta}
                                        title={
                                            <>
                                                <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItemTitleDiv} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitleDivUpdate}`}>
                                                    <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItemTitleIdNameDiv} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitleIdNameDivUpdate}`}>                                       
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"能力主要分類："}</span>
                                                            <Dropdown
                                                                id="abilityTopicUpdate"
                                                                className={`${style.wpSetAbilitySendBtn} ${style.wpSetAbilityFlex2}  ${style.wpSetAbilityDropdownTopic}`}
                                                                menu={{ items: abilityTopicArrayUpdate }}
                                                                placement="bottom"
                                                                // onOpenChange={(open)=>console.log(open)}
                                                            >
                                                                <Button size="middle" >
                                                                    <Space className={`${style.wpSetAbilityDropdownBtnSpace}`}>
                                                                        { !! dropdownTopicTextStateUpdate ? dropdownTopicTextStateUpdate :"請選擇重點能力的主要分類" }
                                                                        {/* { !! dropdownTopicTextStateUpdate ? dropdownTopicTextStateUpdate : item?.topic + "：" + abilityTypeTranslation[item?.topic] } */}
                                                                        {/* ability2AbilityTranslation([item?.topic])[0] + "：" + ability2AbilityTranslation([item?.topic])[1] */}
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Button>    
                                                            </Dropdown>                                                      
                                                            {/* <Space className={`${style.wpSetAbilitySpaceUpdateBtn}`} direction="vertical">
                                                                <Button className={style.lineBroadcastSendBtn} size="small" type="primary" 
                                                                    onClick={(e)=>{
                                                                        updateCourse(e, item?.pid);
                                                                    }}>
                                                                    <Space className={style.wpSetAbilitySpaceUpdateBtnText}>
                                                                        儲存更新
                                                                    </Space>
                                                                </Button>    
                                                            </Space> */}
                                                        </div>
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"能力次要分類："}</span>
                                                            <Dropdown
                                                                id="abilityProjectUpdate"
                                                                className={`${style.wpSetAbilitySendBtn} ${style.wpSetAbilityFlex2}  ${style.wpSetAbilityDropdownProject}`}
                                                                menu={{ items: abilityProjectArrayUpdate }}
                                                                placement="bottom"
                                                                // onOpenChange={(open)=>console.log(open)}
                                                            >
                                                                <Button size="middle" >
                                                                    <Space className={`${style.wpSetAbilityDropdownBtnSpace}`}>
                                                                        { !! dropdownProjectTextStateUpdate ? dropdownProjectTextStateUpdate :"請選擇重點能力的次要分類" }
                                                                        {/* { !! dropdownProjectTextStateUpdate ? dropdownProjectTextStateUpdate : !! dropdownTopicTextStateUpdate ? item?.project + "：" + abilityTypeTranslation[item?.project] : "請選擇重點能力的次要類別" } */}
                                                                        {/* ability2AbilityTranslation([item?.topic])[0] + "：" + ability2AbilityTranslation([item?.topic])[1] */}
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Button>    
                                                            </Dropdown>                                                           
                                                        </div>
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"能力代稱："}</span>
                                                            <Dropdown
                                                                id="abilityTypeUpdate"
                                                                className={`${style.wpSetAbilitySendBtn} ${style.wpSetAbilityFlex2}  ${style.wpSetAbilityDropdownProject}`}
                                                                menu={{ items: abilityTypeArrayUpdate }}
                                                                placement="bottom"
                                                                // onOpenChange={(open)=>console.log(open)}
                                                            >
                                                                <Button size="middle" >
                                                                    <Space className={`${style.wpSetAbilityDropdownBtnSpace}`}>
                                                                        { !! dropdownTypeTextStateUpdate ? dropdownTypeTextStateUpdate : "請選擇重點能力的代稱" }
                                                                        {/* { !! dropdownTypeTextStateUpdate ? dropdownTypeTextStateUpdate : "請選擇重點能力的代稱" } */}
                                                                        {/* ability2AbilityTranslation([item?.topic])[0] + "：" + ability2AbilityTranslation([item?.topic])[1] */}
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Button>    
                                                            </Dropdown>  
                                                        </div>                                                             
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"能力描述："}</span>
                                                            <Input 
                                                                id={`abilityDescriptionUpdate`}
                                                                className={`${style.wpSetAbilityInput} ${style.wpSetAbilityInputName} ${style.wpSetAbilityFlex2}`} 
                                                                placeholder="請輸入能力描述" 
                                                                defaultValue={item?.description}
                                                                ref={abilityDescriptionUpdateRef}
                                                                allowClear size="middle" 
                                                                onChange={(e)=>updateFieldsOnChange(e)}
                                                            />                                                        
                                                        </div>          
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"適用月齡："}</span>
                                                            <InputNumber
                                                                id={"abilityMonthUpdate"}
                                                                className={`${style.wpSetTeachingPlanInput} ${style.wpSetTeachingPlanInputPeriod} ${style.wpSetTeachingPlanFlex2}`} 
                                                                defaultValue={item?.monthAge}
                                                                ref={abilityMonthUpdateRef}
                                                                size="middle" 
                                                                onChange={(e)=>inputNumberMonthOnChangeUpdate(e)}
                                                                addonAfter={<span>個月</span>}
                                                            />                                                                                                               
                                                        </div>
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={`${style.wpSetAbilityFilterConsultCourseDescriptionName} ${style.wpSetAbilityFilterConsultCourseDescriptionMonthRange}`}>{"月齡範圍："}</span>
                                                            <Slider 
                                                                id={"abilityMonthRangeUpdate"}
                                                                className={`${style.wpSetAbilityInput} ${style.wpSetAbilityInputMonthRange}  ${style.wpSetAbilityInputMonthRangeUpdate} ${style.wpSetAbilityFlex2}`} 
                                                                range 
                                                                marks={markUpdate} 
                                                                min={0}
                                                                max={72}
                                                                defaultValue={[ item?.lowerMonthAge, item?.upperMonthAge ]} 
                                                                // ref={abilityMonthRangeUpdateRef}
                                                                // onBlur={(e) => {showAbilityMonthRangeUpdateRef(e)}}
                                                                onChange={(e)=>inputNumberMonthRangeOnChangeUpdate(e)}
                                                            />                                                  
                                                        </div>    
                                                        <Space className={`${style.wpSetAbilitySpaceUpdateBtn}`} direction="vertical">
                                                            <Button className={style.lineBroadcastSendBtn} size="small" type="primary" 
                                                                onClick={(e)=>{
                                                                    updateAbility(e, item?._id);
                                                                }}>
                                                                <Space className={style.wpSetAbilitySpaceUpdateBtnText}>
                                                                    儲存更新
                                                                </Space>
                                                            </Button>    
                                                        </Space>                                                                                                                                                           
                                                    </div>
                                                    <div className={style.wpSetAbilityFilterConsultIconDiv}>
                                                        <RollbackOutlined className={style.wpSetAbilityFilterConsultIcon} onClick={(e) => {
                                                            setIsEditMode([-1, false]); 
                                                            initialize(); 
                                                        }} />
                                                        <DeleteOutlined className={style.wpSetAbilityFilterConsultIcon} onClick={(e) => { deleteAbility(e, item?._id, `${item?.topic}－${item?.project}－${item?.type}`); setIsEditMode([-1, false]); initialize(); }} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    />
                                </List.Item>
                            ) : Object.keys(abilityArray[0]).includes('_id') ? (
                                <List.Item>
                                    <List.Item.Meta
                                        className={style.wpSetAbilityFilterConsultListItemMeta}
                                        title={
                                            <>
                                                <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItemTitleDiv}`}>
                                                    <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItemTitleIdNameDiv}`}>                                         
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"能力主要分類："}</span><span className={style.wpSetAbilityFilterConsultCourseDescriptionText}>{item?.topic}</span>
                                                        </div>
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"能力次要分類："}</span><span className={style.wpSetAbilityFilterConsultCourseDescriptionText}>{item?.project}</span>
                                                        </div>
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"能力代稱："}</span><span className={style.wpSetAbilityFilterConsultCourseDescriptionText}>{item?.type}</span>
                                                        </div>                                                           
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"能力描述："}</span><span className={style.wpSetAbilityFilterConsultCourseDescriptionText}>{item?.description}</span>
                                                        </div>
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"適用月齡："}</span><span className={style.wpSetAbilityFilterConsultCourseDescriptionText}>{item?.monthAge}<span className="wpSetAbilityMonthAgeUnit">個月</span></span>
                                                        </div>    
                                                        <div className={`${style.wpSetAbilityFilterConsultCourseDescriptionItem} ${style.wpSetAbilityFilterConsultCourseDescriptionItemTitle}`}>
                                                            <span className={style.wpSetAbilityFilterConsultCourseDescriptionName}>{"月齡範圍："}</span><span className={style.wpSetAbilityFilterConsultCourseDescriptionText}>{item?.lowerMonthAge}～{item?.upperMonthAge}<span className="wpSetAbilityMonthAgeUnit">個月</span></span>
                                                        </div>                                                     
                                                    </div>
                                                    <div className={style.wpSetAbilityFilterConsultIconDiv}>
                                                        <EditOutlined className={style.wpSetAbilityFilterConsultIcon} onClick={(e) => { 
                                                            setIsEditMode([index, true]);
                                                            // setUpdateFieldsOnChangeState({ topic: item?.topic, project: item?.project, type: item?.type, description: item?.description, monthAge: item?.monthAge, lowerMonthAge: item?.lowerMonthAge, upperMonthAge: item?.upperMonthAge });
                                                            requestTopicAbilityUpdate(e, item?.topic, item?.project, item?.type, item?.description, item?.monthAge, item?.lowerMonthAge, item?.upperMonthAge );
                                                        }} />
                                                        <DeleteOutlined className={style.wpSetAbilityFilterConsultIcon} onClick={(e) => { deleteAbility(e, item?._id, `${item?.topic}－${item?.project}－${item?.type}`); setIsEditMode([-1, false]); initialize(); }} />
                                                    </div>   
                                                </div>
                                            </>
                                        }
                                    />
                                </List.Item>                                    
                            ) : (
                                <></>
                            )
                        )}
                    />
                </section>
            </Content>                 
        </Layout>
    );
}

export default WpSetAbility