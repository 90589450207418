import { httpRequest } from '../../../utils/httpRequest';

export const cancelMessage = async ({ reqData, lineMessageId }) => {
  const res = await httpRequest(
    `/send-message/cancel-booking/${lineMessageId}`,
    {
      method: 'POST',
      body: JSON.stringify(reqData),
    }
  );

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};
