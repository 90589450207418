/* React */
// import React from 'react';

/* Styles */
import style from './styles/LineFlexMessage.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function LineFlexMessage() {
  /* JSX ---------------------------------------------------------- */
  return (
    <section className={style.container}>
      <a
        className={style.link}
        href="https://developers.line.biz/flex-simulator/"
        target="_blank"
        rel="noreferrer"
      >
        開啟編輯器
      </a>
    </section>
  );
}
