import React from "react";
import style from "./styles/SpinnerFullScreen.module.css";
import "./styles/Spinner.css";
import liff from '@line/liff'; // liff SDK

export default function Spinner(props) {
  // const {clearDataReload} = props;
  const lineId = process.env.REACT_APP_LINE_APP_ID;
  /* 清除Local Storage並登出LINE、FB */
  const clearStorage = async(event) => {
    event?.preventDefault();
    // 如果LINE有登入，執行LINE登出
    if( lineId ) {
       await liff.init({
        liffId: lineId // Use own liffId
      })
      const isLoggedIn = liff.isLoggedIn();
      if ( isLoggedIn ) {
        liff.logout();
      }
    }

    // 如果FB物件存在，且為登入中，將FB登出
    if( !!window.FB ) {
      window.FB.getLoginStatus(function( response ) {
        if( response.status === 'connected' ) {
          window.FB.logout(function (response) {
            // // // // // // // // // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    // setNOuaabindLine(false);
    // setEmail(null);
    localStorage.clear(); 
  }

  /*** 清除使用者資料並重載頁面 ***/
  const clearDataReload = async(e) => {
    e?.preventDefault();
    await clearStorage(e); 
    window.location.reload();
  }
  return (
    <section className={style.spinnerSec}>
        <div className={style.spinnerSecDiv}>
            {/* <img className={style.spinnerImg} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/035eab44-93d0-4cef-5c6e-52317441f100/public"></img> */}
            <img className={style.spinnerImg} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/626a2075-adb4-4597-7553-1fa4862eed00/public" onClick={(e)=>{clearDataReload(e)}}></img>            
            <div className={style.container}>
                <div className={`${style.text} ${style.textBackground}`}>頁面載入中 . . .</div>
                <div className={`${style.text} ${style.textColor}`}>頁面載入中 . . .</div>
            </div>
        </div>
    </section>
  );
}