/* React */
import { useMemo } from 'react';
/* ANTD */
import { Radio } from 'antd';

/* Hooks */
import { useGetTags } from '../../../../../../../hooks/useQueryTag';
/* Components */
import { Tag } from '../../../../../../../Component/Tag';
import { TagSelector } from '../../../../../../../Component/TagSelector';
// import TagSelectorModal from './TagSelectorModal';

/* Styles */
import style from './SendMethodSelector.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function SendMethodSelector({
  lineMessageId,
  sendMethod,
  onSendMethodChange,
  tagSet,
  // setTagSet,
  selectedTags,
  // setSelectedTags,
  onSelect,
}) {
  const { data: tags } = useGetTags();

  const tagsMap = useMemo(() => {
    if (!tags) return {};

    return tags.reduce((map, tag) => ({ ...map, [tag._id]: tag.name }), {});
  }, [tags]);
  /* JSX ---------------------------------------------------------- */
  return (
    <>
      <div className={style['cast-selector']}>
        <Radio.Group
          className={style['cast-selector__radio']}
          value={sendMethod}
          onChange={onSendMethodChange}
          disabled={lineMessageId !== null}
        >
          <Radio value="broadcast">廣播模式</Radio>
          <Radio value="multicast">依標籤傳送</Radio>
        </Radio.Group>

        {sendMethod === 'multicast' ? (
          <TagSelector
            onSelect={onSelect}
            defaultTagSet={tagSet}
            defaultSelectedTags={selectedTags}
          />
        ) : null}
      </div>

      {sendMethod === 'multicast' && selectedTags.length > 0 ? (
        <div className={style['selected-tags']}>
          <span>
            <span className={style['selected-tags__send-to']}>
              傳送給&nbsp;:&nbsp;
            </span>

            {tagSet === 'intersection'
              ? '交集'
              : tagSet === 'exclusion'
              ? '排除'
              : '聯集'}
          </span>

          {selectedTags.map((tagId) => (
            <Tag key={tagId}>{tagsMap[tagId]}</Tag>
          ))}
        </div>
      ) : null}
    </>
  );
}
