import { getLoginToken } from './getToken';

export const httpRequest = (uri, options) => {
  const method = options?.method;
  const headers = options?.headers;
  const body = options?.body;

  const setHeaders = headers
    ? {
        ...headers,
        Authorization: `Bearer ${getLoginToken()}`,
      }
    : {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getLoginToken()}`,
      };

  return fetch(`${process.env.REACT_APP_APIURL}${uri}`, {
    method,
    headers: setHeaders,
    body,
  });
};
