/* React */
import React, { useMemo, useState } from 'react';
/* ANTD */
import { Button, Input, Radio } from 'antd';

/* Hooks */
import { useGetTags } from '../../hooks/useQueryTag';
/* Components */
import { Tag } from '../Tag';
/* Styles */
import style from './TagSelectorForm.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function TagSelectorForm({
  onCloseModal,
  onSelect,
  defaultTagSet,
  defaultSelectedTags,
}) {
  const [filterText, setFilterText] = useState('');

  const [tagSet, setTagSet] = useState(defaultTagSet);
  const [selectedTags, setSelectedTags] = useState(defaultSelectedTags);

  const { data: tags } = useGetTags();

  const tagsMap = useMemo(() => {
    if (!tags) return {};

    return tags.reduce((map, tag) => ({ ...map, [tag._id]: tag.name }), {});
  }, [tags]);
  const selectableTags = useMemo(
    () => tags.filter((tag) => !selectedTags.includes(tag._id)),
    [tags, selectedTags]
  );
  const filteredSelectableTags = useMemo(() => {
    return selectableTags.filter((tag) => tag.name.includes(filterText));
  }, [filterText, selectableTags]);

  const handleTagSetChange = (e) => {
    setTagSet(e.target.value);
  };
  const handleSelectTag = (id) => {
    setSelectedTags((prevSelected) => [...prevSelected, id]);
  };
  const handleUnSelect = (id) => {
    setSelectedTags((prevSelected) =>
      prevSelected.filter((tagId) => tagId !== id)
    );
  };
  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };
  const handleCancel = onCloseModal;
  const handleConfirmSelect = () => {
    onSelect({ tagSet, selectedTags });
    onCloseModal?.();
  };

  /* JSX ---------------------------------------------------------- */
  return (
    <div className={style['container']}>
      <h4 className={style['heading']}>選擇標籤</h4>

      <div className={style['block']}>
        <span>集合</span>
        <div>
          <Radio.Group value={tagSet} onChange={handleTagSetChange}>
            <Radio value="union">聯集</Radio>
            <Radio value="intersection">交集</Radio>
            <Radio value="exclusion">排除</Radio>
          </Radio.Group>
        </div>
      </div>

      <div className={style['block']}>
        <span>已選擇標籤</span>
        <div className={style['tags']}>
          {selectedTags.map((tagId) => (
            <Tag
              key={tagId}
              closable
              onClick={() => handleUnSelect(tagId)}
              onClose={() => handleUnSelect(tagId)}
            >
              {tagsMap[tagId]}
            </Tag>
          ))}
        </div>
      </div>

      <div className={`${style['block']} ${style['selectable-tags']}`}>
        <span>可選擇標籤</span>
        <div className={style['tag-filter']}>
          <Input
            placeholder="篩選標籤"
            allowClear
            onChange={handleFilterChange}
          />
        </div>
        <div className={style['tags']}>
          {filteredSelectableTags.map((tag) => (
            <Tag key={tag._id} onClick={() => handleSelectTag(tag._id)}>
              {tag.name}
            </Tag>
          ))}
        </div>
      </div>

      <div className={style['buttons']}>
        <Button onClick={handleCancel}>取消</Button>
        <Button type="primary" onClick={handleConfirmSelect}>
          確定
        </Button>
      </div>
    </div>
  );
}
