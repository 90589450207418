import React, { useState, useContext, useEffect, useRef } from 'react';
import style from './styles/UserListFilter.module.css';
import { Dropdown as RceDropdown } from 'react-chat-elements';
import { Button, Dropdown } from 'antd';

function UserListFilter(props) {
  // const {globalAdminUserSocket, globalAdminPanelSocket, setGlobalAdminUserSocket, setGlobalAdminPanelSocket} = props;
  const [settingTags, setSettingTags] = useState([]); // 可設定Tag資訊
  const [tagItems, setTagItems] = useState([]); // 設定要顯示的Tag資料

  /*** 設定要顯示的Tag資料 ***/
  const getTagItems = () => {
    /*
      settingTags = [{
        "_id": "64547fd77af19c5afde1c548",
        "name": "string",
        "category": "default",
        "description": " ",
        "priority": 0,
        "__v": 0
      }]
      tagItems = [{
        "danger": "boolean",
        "disabled": "boolean",
        "icon": "ReactNode",
        "key": "string",
        "label": "ReactNode",
        "title": "string"
      }]
    */
    let tagItemsArray = new Array();
    for (let el of settingTags) {
      const key = el?._id || '';
      const label = el?.name || '';
      tagItemsArray.push({ key, label });
      setTagItems(tagItemsArray);
    }
  };
  useEffect(() => {
    getTagItems();
  }, []);
  useEffect(() => {
    getTagItems();
  }, [settingTags]);
  useEffect(() => {
    console.log(settingTags);
  }, [settingTags]);
  useEffect(() => {
    console.log(tagItems);
  }, [tagItems]);
  /*** END 設定要顯示的Tag資料 ***/

  /*** 取得標籤設定列表 ***/
  const getTagsSettingList = (e, urlArg) => {
    if (!!e) {
      e.preventDefault();
    }
    console.log('getTagsSettingList');
    console.log(urlArg);
    const apiUrl = process.env.REACT_APP_APIURL;
    const url = !!urlArg
      ? urlArg
      : apiUrl + '/chat-tags' + '?limit=50&offset=0';
    console.log(url);
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        console.log(result);
        /*
        [
          {
            "_id": "64547fd77af19c5afde1c548",
            "name": "string",
            "category": "default",
            "description": " ",
            "priority": 0,
            "__v": 0
          }
        ]
      */
        if (Array.isArray(result) && result.length > 0) {
          let resultExist = false;
          for (let v of result) {
            const settingTagId = v?._id || '';
            if (!!settingTagId) {
              resultExist = true;
              break;
            }
          }
          console.log(resultExist);
          if (resultExist) {
            setSettingTags(result);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  useEffect(() => {
    getTagsSettingList();
  }, []);
  useEffect(() => {
    console.log(settingTags);
  }, [settingTags]);
  /*** END 取得標籤設定列表 ***/

  // 測試用
  const items = [
    {
      key: '1',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item
        </a>
      ),
    },
  ];
  // const items2 = {[
  //   {
  //     text: "分類A",
  //   },
  //   {
  //     text: "分類B",
  //   },
  // ]};

  return (
    <div className={style.userListFilter}>
      {/* <RceDropdown
        // title="Dropdown Title"
        className={style.userListFilterDropdown}
        animationType="default"
        animationPosition="nortwest"
        buttonProps={{
          text: "篩選使用者",
        }}
        onSelect={(e) => {
            console.log(e);
        }}
        // menu={{ items:tagItems }}
        items={[
          {
            text: "分類A",
          },
          {
            text: "分類B",
          },
        ]}
      /> */}
      <Dropdown
        menu={{
          items: tagItems,
          onClick: ({ item, key, keyPath, selectedKeys, domEvent }) => {
            console.log({ key });
          },
        }}
        placement="bottom"
      >
        <Button className={style.userListFilterDropdown} type="primary">
          篩選使用者
        </Button>
      </Dropdown>
    </div>
  );
}

export default UserListFilter;
