import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import style from './styles/UsersRead.module.css';
import '../styles/UsersRce.css';
import './styles/UsersComponentsRce.css';
import {
  Layout,
  Menu,
  Input,
  Space,
  Button,
  InputNumber,
  List,
  Radio,
  Tag,
  Pagination,
} from 'antd';
import {
  EyeOutlined,
  EditFilled,
  EditOutlined,
  RollbackOutlined,
  DeleteFilled,
  DeleteOutlined,
} from '@ant-design/icons';

function Users() {
  const [usersCRUD, setUsersCRUD] = useState('UsersRead');
  const [searchString, setSearchString] = useState(''); // 搜尋欄輸入的字串
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [numberPerPage, setNumberPerPage] = useState(6); // 一頁有幾個User數
  const [usersDataArray, setUsersDataArray] = useState([{}]); // 各使用者資料
  const [isEditMode, setIsEditMode] = useState([-1, false]); // [第n項tag資料, 第n項是否可編輯]
  const [updateFieldsOnChangeState, setUpdateFieldsOnChangeState] = useState(
    {}
  ); //更新欄位狀態
  const usersNameRef = useRef('');
  const usersCategoryRef = useRef('');
  const usersDescriptionRef = useRef('');
  const usersPriorityRef = useRef('');
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  /*** ANT排版 ***/
  const { Header, Content, Sider } = Layout;

  /*** 搜尋 ***/
  const { Search } = Input;
  const searchOnChange = (event) => {
    const val = event?.target?.value || '';
    setSearchString(val);
  };
  const onSearch = () => {
    console.log(currentPage);
    // requestUsers();
    setIsEditMode([-1, false]); // 返回非編輯模式
  };

  /*** 查詢Users ***/
  const requestUsers = async () => {
    const url =
      apiURL +
      '/chat-users?' +
      new URLSearchParams({
        filter: searchString,
        // limit: numberPerPage,
        // offset: currentPage-1
      });
    console.log(url);
    console.log(searchString);
    console.log(numberPerPage);
    console.log(currentPage);
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`使用者查詢失敗！\n${err}`);
        throw new Error('RequestUsers failed');
      });
    console.log(response);
    setUsersDataArray(response);
    // setUpdateFieldsOnChangeState(response);
    // try {
    //   const response = await axios.get({
    //     method: 'get',
    //     url: url,
    //     responseType: 'json',
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  };
  // useEffect(() => {
  //   requestUsers();
  // }, []);
  useEffect(() => {
    console.log(usersDataArray);
  }, [usersDataArray]);

  /*** 分頁切換 ***/
  const changePage = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setCurrentPage(pageNumber);
    setIsEditMode([-1, false]); // 返回非編輯模式
  };
  const clickUsersMenuItem = (e) => {
    console.log('click ', e);
    const key = e?.key || '';
    if (key.includes('UsersRead')) {
      setUsersCRUD('UsersRead');
    } else if (key.includes('UsersAdd')) {
      setUsersCRUD('UsersAdd');
    } else if (key.includes('UsersUpdate')) {
      setUsersCRUD('UsersUpdate');
    } else if (key.includes('UsersDelete')) {
      setUsersCRUD('UsersDelete');
    } else {
      setUsersCRUD('');
    }
  };

  /*** 限制搜尋數量 ***/
  const [value, setValue] = useState(50);

  /*** 更新欄位onChange ***/
  const updateFieldsOnChange = (e) => {
    e.preventDefault();
    // const value = e?.value || "";
    const name = usersNameRef?.current?.input?.value || '';
    const category = usersCategoryRef?.current?.input?.value || '';
    const description = usersDescriptionRef?.current?.input?.value || '';
    const priority = parseInt(usersPriorityRef?.current?.input?.value) || 0;
    const data = { name, category, description, priority };
    console.log(usersNameRef);
    console.log(usersNameRef.current);
    console.log(value);
    console.log(data);
    setUpdateFieldsOnChangeState(data);
  };

  /*** 更新使用者 ***/
  const updateUser = async (e, id) => {
    e.preventDefault();
    const url = apiURL + '/chat-users/' + id;
    console.log(url);
    console.log(updateFieldsOnChangeState);
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(updateFieldsOnChangeState),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        alert(`使用者更新失敗！\n${err}`);
        throw new Error('Update User failed');
      });
    console.log(JSON.stringify(response));
    const responseId = response?._id || '';
    if (!!responseId) {
      if (Object.values(usersDataArray).length > 0) {
        for (let el of usersDataArray) {
          if (Object.values(el).includes(id)) {
            el['name'] = (await response?.name) || '';
            el['category'] = (await response?.category) || '';
            el['description'] = (await response?.description) || '';
            el['priority'] = (await response?.priority) || 0;
            alert(`使用者內容已更新！\n( ID：${id} )`);
          }
        }
        setIsEditMode([-1, false]); // 返回非編輯模式
      }
    }
  };

  /*** 刪除使用者 ***/
  const deleteUser = async (e, id, name) => {
    e.preventDefault();
    if (window.confirm(`確定要刪除【${name}】使用者？\n( ID：${id} )`)) {
      const url = apiURL + '/chat-users/' + id;
      console.log(url);
      console.log(updateFieldsOnChangeState);
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: '*/*',
        },
      }).catch((err) => {
        alert(`使用者刪除失敗！\n${err}`);
        throw new Error('Delete User failed');
      });
      // const responseId = response?._id || "";
      // if( !! responseId ) {
      for (let [index, el] of usersDataArray.entries()) {
        if (Object.values(el).includes(id)) {
          usersDataArray.splice(index, 1);
          alert(`【${el['name']}】使用者已刪除！\n（ID：${id}）`);
        }
      }
      setIsEditMode([-1, false]); // 返回非編輯模式
      // }
    } else {
      return;
    }
  };

  /*** 分頁顯示設定 ***/
  const data = [
    // 樣板資料
    {
      _id: '001',
      name: '測試使用者1',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '002',
      name: '測試使用者2',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '003',
      name: '測試使用者3',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
    {
      _id: '004',
      name: '測試使用者4',
      category: '使用者類別',
      description: '使用者描述',
      priority: 0,
      __v: 0,
    },
  ];

  useEffect(() => {
    console.log(isEditMode);
    console.log(isEditMode[0]);
    console.log(isEditMode[1]);
  }, [isEditMode]);
  return (
    <Layout id="usersReadLayout" className={style.usersReadLayout}>
      {/* <div className={`${ComponentStyle.title}`}><h1>Users</h1></div> */}
      <Content className={style.usersReadOuterContent}>
        <Content className={style.usersReadContent}>
          <section id={'usersSearchSec'} className={style.usersReadSearchSec}>
            <div className={style.usersReadSearchDiv}>
              <Search
                className={`${style.usersReadInput} ${style.usersReadSearch}`}
                addonBefore={
                  <span className={style.usersReadSearchTitle}>使用者查詢</span>
                }
                placeholder="請輸入欲查詢的使用者名稱"
                allowClear
                size="large"
                onChange={(e) => {
                  searchOnChange(e);
                }}
                onSearch={onSearch}
                enterButton
              />
            </div>
            <List
              className={style.usersReadList}
              locale={{}}
              pagination={{
                position: 'bottom',
                align: 'center',
                hideOnSinglePage: true,
                showQuickJumper: true,
                // total: 50,
                current: currentPage,
                pageSize: numberPerPage,
                onChange: changePage,
              }}
              dataSource={usersDataArray}
              renderItem={(item, index) =>
                Object.keys(usersDataArray[0]).includes('_id') &&
                isEditMode[0] === index &&
                isEditMode[1] ? (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <div className={style.usersReadUserIconDiv}>
                          <Tag
                            className={style.usersReadUserIcon}
                            color="geekblue"
                          >
                            {item.name}
                          </Tag>
                        </div>
                      }
                      title={
                        <div className={style.usersReadUserDescriptionItem}>
                          <span className={style.usersReadUserDescriptionID}>
                            {'使用者ID：'}
                          </span>
                          <span className={style.usersReadUserDescriptionText}>
                            {item._id}
                          </span>
                          <div className={style.usersReadUserEditAndDelIconDiv}>
                            <RollbackOutlined
                              className={style.usersReadUserEditAndDelIcon}
                              onClick={(e) => {
                                setIsEditMode(false);
                              }}
                            />
                            <DeleteOutlined
                              className={style.usersReadUserEditAndDelIcon}
                              onClick={(e) => {
                                deleteUser(e, item._id, item.name);
                              }}
                            />
                            {/* <DeleteFilled className={style.usersReadUserIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div
                          className={style.usersReadUserDescriptionDiv}
                          style={{ justifyContent: 'center' }}
                        >
                          <div
                            className={`${style.usersReadUserDescriptionItem} ${style.usersReadUserDescriptionItemName}`}
                          >
                            <span
                              className={`${style.usersReadUserDescriptionLabel} ${style.usersReadUserDescriptionLabelName}`}
                            >
                              {'使用者名稱：'}
                            </span>
                            <Input
                              className={`${style.usersReadUserDescriptionInput} ${style.usersReadUserDescriptionInputName}`}
                              type="text"
                              defaultValue={item.name}
                              ref={usersNameRef}
                              maxLength={10}
                              onChange={updateFieldsOnChange}
                            ></Input>
                          </div>
                          <div
                            className={`${style.usersReadUserDescriptionItem} ${style.usersReadUserDescriptionItemCategory}`}
                          >
                            <span
                              className={`${style.usersReadUserDescriptionLabel} ${style.usersReadUserDescriptionLabelCategory}`}
                            >
                              {'使用者類別：'}
                            </span>
                            <Input
                              className={`${style.usersReadUserDescriptionInput} ${style.usersReadUserDescriptionInputCategory}`}
                              type="text"
                              defaultValue={item.category}
                              ref={usersCategoryRef}
                              maxLength={20}
                              onChange={updateFieldsOnChange}
                            ></Input>
                          </div>
                          <div
                            className={`${style.usersReadUserDescriptionItem} ${style.usersReadUserDescriptionItemDescription}`}
                          >
                            <span
                              className={`${style.usersReadUserDescriptionLabel} ${style.usersReadUserDescriptionLabelDescription}`}
                            >
                              {'使用者描述：'}
                            </span>
                            <Input
                              className={`${style.usersReadUserDescriptionInput} ${style.usersReadUserDescriptionInputDescription}`}
                              type="text"
                              defaultValue={item.description}
                              ref={usersDescriptionRef}
                              maxLength={40}
                              onChange={updateFieldsOnChange}
                            ></Input>
                          </div>
                          <div
                            className={`${style.usersReadUserDescriptionItem} ${style.usersReadUserDescriptionItemPriority}`}
                          >
                            <span
                              className={`${style.usersReadUserDescriptionLabel} ${style.usersReadUserDescriptionLabelPriority}`}
                            >
                              {'優先權：'}
                            </span>
                            <Input
                              className={`${style.usersReadUserDescriptionInput} ${style.usersReadUserDescriptionInputPriority}`}
                              type="number"
                              defaultValue={item.priority}
                              ref={usersPriorityRef}
                              maxLength={2}
                              onChange={updateFieldsOnChange}
                            ></Input>
                          </div>
                          <div
                            className={`${style.usersReadUserDescriptionItem} ${style.usersReadUserDescriptionItemUpdateBtn}`}
                          >
                            <Button
                              className={`${style.usersReadUserDescriptionInputUpdateBtn}`}
                              type={'primary'}
                              size={'small'}
                              onClick={(e) => {
                                updateUser(e, item._id);
                              }}
                            >
                              更新
                            </Button>
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                ) : Object.keys(usersDataArray[0]).includes('_id') ? (
                  <List.Item>
                    <List.Item.Meta
                      className={style.usersReadListItemMeta}
                      avatar={
                        <Tag
                          className={style.usersReadUserIcon}
                          color="geekblue"
                        >
                          {item.name}
                        </Tag>
                      }
                      title={
                        <div
                          className={`${style.usersReadUserDescriptionItem} ${style.usersReadUserDescriptionItemTitle}`}
                        >
                          <span className={style.usersReadUserDescriptionID}>
                            {'使用者ID：'}
                          </span>
                          <span className={style.usersReadUserDescriptionText}>
                            {item._id}
                          </span>
                          <div className={style.usersReadUserEditAndDelIconDiv}>
                            <EditOutlined
                              className={style.usersReadUserEditAndDelIcon}
                              onClick={(e) => {
                                setIsEditMode([index, true]);
                              }}
                            />
                            {/* <EditFilled className={style.usersReadUserIcon} /> */}
                            <DeleteOutlined
                              className={style.usersReadUserEditAndDelIcon}
                              onClick={(e) => {
                                deleteUser(e, item._id, item.name);
                              }}
                            />
                            {/* <DeleteFilled className={style.usersReadUserIcon} /> */}
                          </div>
                        </div>
                      }
                      description={
                        <div className={style.usersReadUserDescriptionDiv}>
                          <div className={style.usersReadUserDescriptionItem}>
                            <span
                              className={style.usersReadUserDescriptionLabel}
                            >
                              {'使用者名稱：'}
                            </span>
                            <span
                              className={style.usersReadUserDescriptionText}
                            >
                              {item.name}
                            </span>
                          </div>
                          <div className={style.usersReadUserDescriptionItem}>
                            <span
                              className={style.usersReadUserDescriptionLabel}
                            >
                              {'使用者類別：'}
                            </span>
                            <span
                              className={style.usersReadUserDescriptionText}
                            >
                              {item.category}
                            </span>
                          </div>
                          <div className={style.usersReadUserDescriptionItem}>
                            <span
                              className={style.usersReadUserDescriptionLabel}
                            >
                              {'使用者描述：'}
                            </span>
                            <span
                              className={style.usersReadUserDescriptionText}
                            >
                              {item.description}
                            </span>
                          </div>
                          <div className={style.usersReadUserDescriptionItem}>
                            <span
                              className={style.usersReadUserDescriptionLabel}
                            >
                              {'優先權：'}
                            </span>
                            <span
                              className={style.usersReadUserDescriptionText}
                            >
                              {item.priority}
                            </span>
                          </div>
                          {/* <div className={style.usersReadUserDescriptionItem}>
                            <span className={style.usersReadUserDescriptionLabel}>{"其他資訊："}</span><span className={style.usersReadUserDescriptionText}>{item.__v}</span>
                          </div> */}
                        </div>
                      }
                    />
                  </List.Item>
                ) : (
                  <></>
                )
              }
            />
          </section>
        </Content>
      </Content>
    </Layout>
  );
}

export default Users;
