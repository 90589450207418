import liff from '@line/liff/dist/lib';
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import popupStyle from '../../Components/styles/popup.module.css';
import LoginParent  from '../login/LoginParent';
import { Container,Row,Col } from 'react-bootstrap';
// import LoadingSpinner from '../../Components/LoadingSpinner';
import SpinnerFullScreen from './components/Spinner/SpinnerFullScreen'
import style from './styles/LoginEncode.module.css'
import { useState, useEffect, useRef } from 'react';
import ReactGA from "react-ga4"; // GA4

function LoginEncode() {

  /* 設定Modal 顯示與否 */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lineinit, setLineinit] = useState(false); // 設定Liff初始化狀態
  const [RegxpError, setRegxpError] = useState(''); // 設定錯誤訊息

  const isFromCustomer = window.localStorage.getItem('isFromCustomer') ?? false;
  const isFromCustomerChatRoomLogin = window.localStorage.getItem('isFromCustomerChatRoomLogin') ?? false;
  const isFromAdmin = window.localStorage.getItem('isFromAdmin') ?? false;
  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  let lineid = process.env.REACT_APP_LINE_APP_ID ? process.env.REACT_APP_LINE_APP_ID : '' ; // 由環境變數取得lineID
  // console.log('lineid',lineid);
  // const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : '';  // 由localStorage取得loginToken(設置登入狀態)
  // const yogaballUrl = window.localStorage.getItem( 'yogaballUrl' ) ? window.localStorage.getItem( 'yogaballUrl' ) : ''; // 由localStorage取得瑜珈球頁面連結
  // // console.log('loginToken', loginToken);
  // // console.log('yogaballUrl', yogaballUrl);

  /* 取得login token */
  const loginToken = !! window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : '';
  const BearerToken = 'Bearer ' + loginToken;
  /* 取得line access token */
  const lineAccessToken = !! window.localStorage.getItem('LIFF_STORE:' + lineid + ':accessToken') ? window.localStorage.getItem('LIFF_STORE:' + lineid + ':accessToken') : '';

  /* 載入頁面時popup轉圈動畫環境 */
  const Popup = (props) => {
  return (
      <>
          <>
          <Modal className={style.modalContainer}
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
              <Col className={style.modal}>
                <Modal.Body className={style.modalBody} >
                  <div style={{width:'fit-content',height:'fit-content'}}>
                    <SpinnerFullScreen ></SpinnerFullScreen>
                    <div className={style.RegxpErrorSec}>
                          <p className={style.RegxpError}>{RegxpError}</p>
                        </div>    
                  </div>
                </Modal.Body>
              </Col>
          </Modal>
        </>
      </>
    );
  }

  /* 取得liffID，並初始化liff物件(舊版本1) */
  // let location = '';
  // const lineid = process.env.REACT_APP_LINE_APP_ID;
  // const linelogin = async() => {
  //   const init = await liff.init({
  //       liffId: lineid, // Use own liffId
  //       // withLoginOnExternalBrowser: true, // Enable automatic login processFBStatus
        
  //   }).catch((err) => {
  //       // Todo: retry init.
  //       //// // console.log('err',err);
  //   });
  //   setLineinit(true);
  //   encode();
  // }

  /* 取得liffID，並初始化liff物件(舊版本2) */
  // const liffInit = async () => {
  //   // console.log('lineid2',lineid);
  //   liff.init({
  //     liffId: lineid, // Use own liffId
  //     // withLoginOnExternalBrowser: true, // Enable automatic login process
  //   }).catch((err) => {
  //     // Todo: retry init.
  //     liff.init({
  //       liffId: lineid, // Use own liffId
  //       // withLoginOnExternalBrowser: true, // Enable automatic login process
  //     }).catch((err) => {
  //         // console.log('err',err);
  //     });      
  //     // console.log('err',err);
  //   });
  //   setLineinit(true);
  //   // console.log('liff0',liff);
  //   return liff;
  // }

  /* 解決無法取得liffId問題(舊版) */
  // useEffect(() => {
  //   async function awaitLineidToLogin() {
  //     // const resHandleShow = await handleShow(); // 載入頁面時popup轉圈動畫執行Popup
  //     // console.log('liff2',liff);
  //     const resLineIdLoading = await lineIdLoading();
  //     // console.log('liff3',liff);
  //     const resLineAuth = await LineAuth(); // Line驗證是否登入，未登入則登入
  //     // console.log('liff4',liff);
  //     const resLiffInit = await liffInit(); // LINE登入後初始化liff
  //     // console.log('liff5',liff);
  //     const resLinelogin = await linelogin(); // 使用LINE登入API進行登入
  //     // console.log('liff6',liff);
  //     // const resEncode = await encode(); // 進行頁面導向
  //     // const data = await Promise.all([
  //     //   liffInit(),
  //     //   LineAuth(),
  //     //   linelogin()
  //     // ]);
  //   }
  //   awaitLineidToLogin();
  // }, []);

  /* 取得LiffID */
  const lineIdLoading = async () => {
    const lineid = process.env.REACT_APP_LINE_APP_ID;
    // console.log('lineid1',lineid);
    return lineid;
  } 

  /* Line驗證是否登入，未登入則登入 */
  const BACKENDURL = process.env.REACT_APP_BACKENDURL;
  const LineAuth = async (loginToken) => {
      const isLoggedIn = liff.isLoggedIn();
      // console.log('isLoggedIn',isLoggedIn);
      if( ! isLoggedIn ) {
        // if( window.location.search.indexOf('LiffLoginSuccess') > -1 ) {
        //   window.location.href = "/";
        // }
        // liff.login();
        // console.log('liff.login()',liff.login());
        if( window.location.search.toLowerCase().indexOf('grantmembership') > -1 ) {
          liff.login({ redirectUri: "https://"+ BACKENDURL +"/LoginEncode?page=GrantMembership" });
        } else {
          liff.login({ redirectUri: "https://"+ BACKENDURL +"/LoginEncode?page=homepage" });
        }
        setTimeout(() => {
          alert('此瀏覽器無法進行LINE LIFF登入，請至首頁重新登入！');
          handleClose(); // 關閉轉圈
          // window.location.href = '/AdminLogin';
          if( window.localStorage.getItem('isFromAdmin') ) {
            window.location.href = '/AdminLogin';
          } else if( window.localStorage.getItem('isFromCustomerChatRoomLogin') ) {
            window.location.href = '/ChatRoomLogin';
          } else {
            window.location.href = '/';
          }
        }, 5000 );
      } else {
        // console.log('登入成功');
        alert('LINE登入成功！');
        handleClose(); // 關閉轉圈
        if( !! loginToken ) {
          window.localStorage.setItem( 'loginToken', loginToken );
          window.localStorage.setItem('LIFF_STORE', true); 
          if( window.localStorage.getItem('isFromAdmin') ) {
            window.location.href = '/Admin';
          } else if( window.localStorage.getItem('isFromCustomerChatRoomLogin') ) {
            window.location.href = '/ChatRoom';
          } else {
            window.location.href = '/?line_login=true';
          }
        } else {
          linelogin();
        }
      }

      /* 舊代碼 */
      // if( ! lineid ) {
      //   lineid = await lineIdLoading();
      //   // console.log('lineid3',lineid);
      // } else {
      //   // const liffAfterInit = await liffInit();
      //   // // console.log('liffAfterInit',liffAfterInit);
      //   const isLoggedIn = liff.isLoggedIn();
      //   // console.log('isLoggedIn',isLoggedIn);
      //   if( ! isLoggedIn ) {
      //     // liff.login({ redirectUri: "https://"+ BACKENDURL +"/LoginEncode?page=homepage" });
      //     liff.login();
      //     // console.log('line',isLoggedIn)
      //     // setLineResponse(isLoggedIn);
      //   }        
      // }
      // return true;
  }

  /* Line登入時，送資料給GA4 */
  const gaLineLogin = () =>{
    ReactGA.event( 'login', {
      method: 'Line登入'
    });
    ReactGA.send({ hitType: "event" });
    // console.log('gaLineLogin' );
  }

  /* 使用LINE登入API進行登入，再直接用encode()導向 */
  let line_liff_loginData = {};
  const linelogin = async () => {
    // console.log('linelogin');
    const isLoggedIn = liff.isLoggedIn();
    // console.log('isLoggedIn',isLoggedIn);
  
    if( isLoggedIn === true ) {
      // console.log('isLoggedIn = true');
      let redirectUrl = window.location.href;
      const accessToken = liff.getAccessToken();
      window.localStorage.setItem('lat', accessToken);  
  
      line_liff_loginData= {
        accessToken:accessToken,
      }
    
      fetch(`${process.env.REACT_APP_UAA_URL}/api/uaa/line_liff_login`, {
        method: 'POST',
        body: JSON.stringify(line_liff_loginData),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((r) => r.json())
      .then((result) => {
        // console.log('result', result);
        //  setResult(result);
        if(result.status === 200){
          gaLineLogin(); // 送出Line登入事件給GA4
          let loginToken = result.data.loginToken; 
          if( !! loginToken ) {
            window.localStorage.setItem( 'loginToken', result.data.loginToken ); // 登入成功不直接儲存，而是在判斷導向位置後才儲存
            window.localStorage.setItem( 'lineBind', true );
          }
          // window.localStorage.setItem( 'loginToken', result.data.loginToken ); // 改成登入成功不直接儲存，而是在判斷導向位置後才儲存
          // console.log('成功 200'); 
          setRegxpError('LINE登入成功！');
          // alert(`LINE登入成功！${loginToken}`);
          encode(loginToken);
          // setNOuaabindLine(false);
          // setAuth({...auth,authorized:true});
          //show 登出 token有  NOuaabindLine false
        }else if(result.statusCode === 400){
          // console.log('失敗，錯誤代碼 400')
          window.localStorage.setItem( 'lineBind', false );
          setRegxpError('LINE登入失敗(錯誤代碼 400)！');
          encode();
          // setNOuaabindLine(false);
          //show 登入 
        }else if(result.statusCode === 403){
          // console.log('此LINE帳號尚未綁定，錯誤代碼 403');
          window.localStorage.setItem( 'lineBind', false );
          setRegxpError('此LINE帳號尚未綁定(錯誤代碼 403)！');
          encode();
          // setNOuaabindLine(true);
            //show 登入
        }else{
          // alert('失敗。')
          // console.log('LINE登入失敗！');
          window.localStorage.setItem( 'lineBind', false );
          setRegxpError('LINE登入失敗！');
          encode();
          //show 登入
        }
      });
      return true;
    } else {
      LineAuth(loginToken);
      return false;
    }
  }

  /* LINE登出 */
  const linelogout =  async () => {
    liff.logout();
  }

  /* 進行頁面導向 */
  const encode = async (loginToken)=> {
    // console.log('lineAccessToken',lineAccessToken);
    if( !! lineAccessToken ) {
      let location = window.location.search.indexOf('page=');
      // console.log('location',location);
      //有抓到網址liff.state 代表line登入成功
      if( location > -1 ) {
          // // console.log('location.search: ',window.location.search.split('liff.state=')[1].split('&code=')[0]);
          // let string = window.location.search.split('liff.state=')[1].split('&code=')[0];
          // string = decodeURIComponent(string);
          // // console.log('string',string);
          if( window.location.search.indexOf('homepage') > -1 ) { // 點選LINE登入時返回homepage
            // // console.log('homepage01',liff.isLoggedIn());
            // // console.log('導向homepage');
            window.localStorage.removeItem('LIFF_STOREregister'); // 沒有LIFF_STOREregister為LINE登入情況
            if( !! loginToken ) { // 直接點LINE登入，且有綁定過，可存LINE login token進行登入
              window.localStorage.setItem( 'loginToken', loginToken );
              window.localStorage.setItem('LIFF_STORE', true); 
              // checkActivityAvailable(); 
              handleClose(); // 關閉轉圈

              // window.location.href = "/CheckActivity?redir=kidpro";
              if( window.localStorage.getItem('isFromAdmin') ) {
                window.location.href = '/Admin';
              } else if( window.localStorage.getItem('isFromCustomerChatRoomLogin') ) {
                window.location.href = '/ChatRoom';
              } else {
                window.location.href = '/?line_login=true';
              }

              // window.location.assign( window.location.protocol + "//" + window.location.host + "/CheckActivity?redir=kidpro" ); // 若是返回marketing首頁，會進入官網登入檢查頁面，若有"?redir=kidpro"字串則導向官網登入
            } else { // 直接點LINE登入，未綁定過，不存LINE login token，回首頁進入LINE未綁定表單(NoLineDataLogin)
              setRegxpError('您的帳號尚未綁定LINE，故無法以LINE進行登入！');
              setTimeout(()=>{
                handleClose(); // 關閉轉圈
                if( window.localStorage.getItem('isFromAdmin') ) {
                  window.location.href = '/AdminLogin';
                } else if( window.localStorage.getItem('isFromCustomerChatRoomLogin') ) {
                  window.location.href = '/ChatRoomLogin';
                } else {
                  window.location.href = '/';
                }
                // window.location.assign( window.location.protocol + "//" + window.location.host + "/" ); // 回到首頁由line login檢驗LINE綁定狀況
              }, 5000 );
            }
          } else if( window.location.search.toLowerCase().indexOf('grantmembership') > -1 ) { // 在GrantMembership頁面點選LINE登入，返回GrantMembership頁面
            // console.log('導向GrantMembership');
            window.localStorage.removeItem('LIFF_STOREregister');
            if( !! loginToken ) { // 直接點LINE登入，且有綁定過，可存LINE login token進行登入
              window.localStorage.setItem( 'loginToken', loginToken );
              window.localStorage.setItem( 'LIFF_STORE', true ); 
              // checkActivityAvailable(); 
              handleClose(); // 關閉轉圈
              window.location.href = "/GrantMembership";
              // window.location.assign( window.location.protocol + "//" + window.location.host + "/CheckActivity?redir=kidpro" ); // 若是返回marketing首頁，會進入官網登入檢查頁面，若有"?redir=kidpro"字串則導向官網登入
            } else { // 直接點LINE登入，未綁定過，不存LINE login token，回GrantMembership進入LINE未綁定表單(NoLineDataLogin)
              setRegxpError('您的帳號尚未綁定LINE，故無法以LINE進行登入！');
              setTimeout(()=>{
                handleClose(); // 關閉轉圈
                window.location.href = "/GrantMembership";
                // window.location.assign( window.location.protocol + "//" + window.location.host + "/" ); // 回到首頁由line login檢驗LINE綁定狀況
              }, 5000 );
            }
          } else if ( window.location.search.indexOf('SubmitPage') > -1 ) { // 在Marketing表單頁面點選LINE綁定，返回Marketing表單頁面
            // // console.log('導向SubmitPage');
            handleClose(); // 關閉轉圈
            window.location.href = "/SubmitPage";
            // window.location.assign( window.location.protocol + "//" + window.location.host + "/SubmitPage" );
          } else if(window.location.search.indexOf('register') > -1 ){ // 點選LINE註冊，會返回首頁進入 1.LINE未綁定表單(NoLineDataLogin) 2.LINE帳號已綁定(AccountExist)表單
            // console.log('register',liff.isLoggedIn());
            // window.localStorage.setItem( 'loginToken', loginToken ); // 回到首頁的狀況為：點選 立即註冊/透過LINE帳號註冊(若已綁定過，之後會再提示是否登入，有要登入才存login token；此階段不需LINE login token)
            window.localStorage.setItem('LIFF_STOREregister', true); // 點選LINE註冊狀況
            setTimeout(()=>{
              handleClose(); // 關閉轉圈
              if( window.localStorage.getItem('isFromAdmin') ) {
                window.location.href = '/AdminLogin';
              } else if( window.localStorage.getItem('isFromCustomerChatRoomLogin') ) {
                window.location.href = '/ChatRoomLogin';
              } else {
                window.location.href = '/';
              }
              // window.location.assign( window.location.protocol + "//" + window.location.host + "/" ); // 回到首頁由line login檢驗LINE綁定狀況
            }, 5000 );
          } else if( window.localStorage.getItem('isFromAdmin') ) { // 在GrantMembership頁面點選LINE登入，返回GrantMembership頁面
            // console.log('導向GrantMembership');
            window.localStorage.removeItem('LIFF_STOREregister');
            if( !! loginToken ) { // 直接點LINE登入，且有綁定過，可存LINE login token進行登入
              window.localStorage.setItem( 'loginToken', loginToken );
              window.localStorage.setItem( 'LIFF_STORE', true ); 
              // checkActivityAvailable(); 
              handleClose(); // 關閉轉圈
              window.location.href = "/Admin";
              // window.location.assign( window.location.protocol + "//" + window.location.host + "/CheckActivity?redir=kidpro" ); // 若是返回marketing首頁，會進入官網登入檢查頁面，若有"?redir=kidpro"字串則導向官網登入
            } else { // 直接點LINE登入，未綁定過，不存LINE login token，回GrantMembership進入LINE未綁定表單(NoLineDataLogin)
              setRegxpError('您的帳號尚未綁定LINE，故無法以LINE進行登入！');
              setTimeout(()=>{
                handleClose(); // 關閉轉圈
                window.location.href = "/Admin";
                // window.location.assign( window.location.protocol + "//" + window.location.host + "/" ); // 回到首頁由line login檢驗LINE綁定狀況
              }, 5000 );
            }
          } else if( window.localStorage.getItem('isFromCustomer') ) { // 在GrantMembership頁面點選LINE登入，返回GrantMembership頁面
            // console.log('導向GrantMembership');
            window.localStorage.removeItem('LIFF_STOREregister');
            if( !! loginToken ) { // 直接點LINE登入，且有綁定過，可存LINE login token進行登入
              window.localStorage.setItem( 'loginToken', loginToken );
              window.localStorage.setItem( 'LIFF_STORE', true ); 
              // checkActivityAvailable(); 
              handleClose(); // 關閉轉圈
              window.location.href = "/";
              // window.location.assign( window.location.protocol + "//" + window.location.host + "/CheckActivity?redir=kidpro" ); // 若是返回marketing首頁，會進入官網登入檢查頁面，若有"?redir=kidpro"字串則導向官網登入
            } else { // 直接點LINE登入，未綁定過，不存LINE login token，回GrantMembership進入LINE未綁定表單(NoLineDataLogin)
              setRegxpError('您的帳號尚未綁定LINE，故無法以LINE進行登入！');
              setTimeout(()=>{
                handleClose(); // 關閉轉圈
                window.location.href = "/";
                // window.location.assign( window.location.protocol + "//" + window.location.host + "/" ); // 回到首頁由line login檢驗LINE綁定狀況
              }, 5000 );
            }
          } else if( window.localStorage.getItem('isFromCustomerChatRoomLogin') ) { // 在GrantMembership頁面點選LINE登入，返回GrantMembership頁面
            // console.log('導向GrantMembership');
            window.localStorage.removeItem('LIFF_STOREregister');
            if( !! loginToken ) { // 直接點LINE登入，且有綁定過，可存LINE login token進行登入
              window.localStorage.setItem( 'loginToken', loginToken );
              window.localStorage.setItem( 'LIFF_STORE', true ); 
              // checkActivityAvailable(); 
              handleClose(); // 關閉轉圈
              const getLoginToken = async() => {
                return window.localStorage.getItem( 'loginToken' );
              }
              const promiseFunc = () => {
                return new Promise((resolve) => {
                  const loginToken = getLoginToken();
                  resolve(loginToken);
                })
              }
              await promiseFunc().then((res)=>{
                window.location.href = "/ChatRoom";
              });
              // window.location.assign( window.location.protocol + "//" + window.location.host + "/CheckActivity?redir=kidpro" ); // 若是返回marketing首頁，會進入官網登入檢查頁面，若有"?redir=kidpro"字串則導向官網登入
            } else { // 直接點LINE登入，未綁定過，不存LINE login token，回GrantMembership進入LINE未綁定表單(NoLineDataLogin)
              setRegxpError('您的帳號尚未綁定LINE，故無法以LINE進行登入！');
              setTimeout(()=>{
                handleClose(); // 關閉轉圈
                window.location.href = "/ChatRoom";
                // window.location.assign( window.location.protocol + "//" + window.location.host + "/" ); // 回到首頁由line login檢驗LINE綁定狀況
              }, 5000 );
            }
          } else {
            setTimeout(()=>{
              // alert('無法進行頁面導向，請重新登入！');
              // handleClose(); // 關閉轉圈
              LineAuth(loginToken);
              // window.location.assign( window.location.protocol + "//" + window.location.host + "/" );
            }, 5000 );
          }
      
      // } else if ( !! loginToken && !! yogaballUrl ) { // 若以https://liff.line.me/{LineDevID}?redir=...進行登入，取得先前存入localStorage的連結，加入JWT進行官網登入導向
      //   const kidproLoginYogaballUrl = 'https://kid-pro.com/wp-json/simple-jwt-login/v1/autologin?JWT=' + loginToken + "&redirectUrl=" + yogaballUrl;
      //   // console.log('kidproLoginYogaballUrl',kidproLoginYogaballUrl);
      //   // console.log('導向瑜珈球頁面前');
      //   window.location.assign( kidproLoginYogaballUrl );
      } else {
        // // console.log('homepage03',liff.isLoggedIn());
        // // console.log('沒進行line登入03');
        setTimeout(()=>{
          // alert('無法取得頁面資訊，請重新登入！');
          // handleClose(); // 關閉轉圈
          LineAuth(loginToken);
          // window.location.assign( window.location.protocol + "//" + window.location.host + "/" );
        }, 5000 );
      }
    } else {
      setTimeout(()=>{
        // alert('LINE登入失敗，請重新登入！');
        // handleClose(); // 關閉轉圈
        LineAuth(loginToken);
        // window.location.assign( window.location.protocol + "//" + window.location.host + "/" );
      }, 5000 );
    }
  }

  /* 檢查使用者是否參加過活動或已登記活動表單(成功導向官網登入) */
  // const checkActivityAvailable = async() => {

  //   const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
  //   const BearerToken = 'Bearer ' + loginToken;

  //   // POST送出手機資料，驗證是否成功
  //   await fetch('https://' + apiURL + '/api/marketing/event_user/share_yoga', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       'Authorization': BearerToken
  //     },
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     //// // console.log('result', result);
  //     if ( result.status === 200 && !! result.data ) {
  //       setRegxpError('您已經登記過此活動囉！');
  //       // console.log('Assign to CountingPage!');
  //       handleClose();
  //       window.location.assign( "/CheckActivity?redir=kidpro" ); // 登入成功後進入官網登入檢查頁面，若有"?redir=kidpro"字串則導向官網登入
  //     } else if ( result.status === 400 && result.errorCode === 1001 ) {
  //       setRegxpError('您已經參加過上次活動囉！');
  //       handleClose();
  //       window.location.assign( window.location.protocol + "//" + window.location.host + "/Forbidden" );
  //     } else if ( result.status === 404 && result.errorCode === 1002 ) {
  //       handleClose();
  //       window.location.assign( window.location.protocol + "//" + window.location.host + "/SubmitPage" );
  //     } else {
  //       setRegxpError('伺服器忙線中，請稍後再試！');
  //       handleClose();
  //     }
  //   })
  //   .catch((error) => {
  //     setRegxpError('伺服器忙線中，請稍後再試！');
  //     handleClose();
  //   }) // END Fetch          
  // }  

  /* 儲存瑜珈球連結 */
  const storeYogaballUrl = async () => {
    const queryString = window.location.search; // 由LINE Flex Message之連結取得url query字串
    // console.log('queryString', queryString);
    const urlParams = new URLSearchParams(queryString); // 分析url query字串
    // console.log('urlParams', urlParams);
    const redir = urlParams.get('redir') // 取得url query字串中的導向值
    // console.log('redir', redir);
    // 判斷查詢字串中是否有瑜珈球的連結，如果有則將值存入localStorage，後續用作返回首頁之導向判斷條件
    if( redir === 'yogaball' ) {
      window.localStorage.setItem( 'yogaballUrl', 'https://kid-pro.com/product/yogaball/' ); // 若導向為瑜珈球頁面，將此頁面連結存入localStorage
    }
  }

  /* 取得liffID，並初始化liff物件(舊版本) */
  const liffInit = () => {
    // console.log('liffInit');
    liff.init({
      liffId: lineid, // Use own liffId
    })
    .then( async () => {
      // Start to use liff's api
      // 使用者未登入則以LINE進行登入
      // if( ! loginToken ) {
        await storeYogaballUrl(); // 儲存瑜珈球連結
        await linelogin(); // 使用LINE Login API進行登入
        // await LineAuth(); // Line驗證是否登入，未登入則登入
      // 使用者已登入則直接導向  
      // } else {
      //   await encode(); // 進行頁面導向
    //  }
    })
    .catch((err) => {
      // Error happens during initialization
      // console.log(err.code, err.message);
      setTimeout(()=>{
        alert('LINE登入失敗，請至首頁重新登入！');
        handleClose(); // 關閉轉圈
        if( window.localStorage.getItem('isFromAdmin') ) {
          window.location.href = '/AdminLogin';
        } else if( window.localStorage.getItem('isFromCustomerChatRoomLogin') ) {
          window.location.href = '/ChatRoomLogin';
        } else {
          window.location.href = '/';
        }
        // window.location.assign( window.location.protocol + "//" + window.location.host + "/" );
      }, 5000 );
    });
  }


  useEffect(()=>{
    liffInit();
  }, []);
  
  /* 執行載入頁面時popup轉圈動畫(預設尚未Popup) */
  return (
    <>
      <Popup></Popup>
      <div style={{textAlign:'center',display:'flex',justifyContent:'center', alignItems:'center', marginTop:'20%'}}>
        <p style={{margin:'auto'}}><SpinnerFullScreen></SpinnerFullScreen></p>
      </div>
      
    </>
  )
}

export default LoginEncode