/* React */
import { useState, useEffect } from 'react';
/* ANTD */
import {
  Button,
  DatePicker,
  Form,
  Space,
  Radio,
  Input,
  Spin,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
/* Dayjs */
import dayjs from 'dayjs';

/* Hooks */
import {
  useGetMessageById,
  useCreateMessage,
  useModifyMessage,
} from '../../hooks/useQueryMessage.js';
/* Components */
import SendMethodSelector from './SendMethodSelector.jsx';
import MessageList from './MessageList.jsx';
/* Utils */
import { isFutureDays } from '../../../../../../../utils/isFutureDays';
import { convertAntdDateFormat } from '../../../../../../../utils/convertAntdDateFormat';
/* Styles */
import style from './MessageForm.module.css';

/* //////////////////////////////////////////////////////////////// */
export default function MessageForm({
  onCloseModal = () => {}, // [NOTE] Modal component provide props
  asModal = false,
  lineMessageId = null,
}) {
  const { data: originFormData, sendMessagesData } =
    useGetMessageById(lineMessageId);
  const { onCreateMessage, isLoading: isCreating } =
    useCreateMessage(onCloseModal);
  const { onModifyMessage, isLoading: isModifying } =
    useModifyMessage(onCloseModal);

  const [form] = Form.useForm();

  const [isReservation, setIsReservation] = useState(false);
  const [sendMethod, setSendMethod] = useState('broadcast');
  const [tagSet, setTagSet] = useState('union');
  const [selectedTags, setSelectedTags] = useState([]);

  // Prefill form
  useEffect(() => {
    if (!originFormData) return;

    setIsReservation(!sendMessagesData.isSendImmediately);
    setSendMethod(originFormData.message.sendMethod);
    // [TODO] setTagSet and setSelectedTags

    // [NOTE] defaultValue or value not working on FormItem ANTD - https://stackoverflow.com/questions/53182493/defaultvalue-or-value-not-working-on-formitem-antd
    form.setFieldsValue({
      isReservation: !sendMessagesData.isSendImmediately,
      date:
        sendMessagesData?.isSendImmediately === false
          ? dayjs(sendMessagesData?.estimatedSendingTime)
          : undefined,
      time:
        sendMessagesData?.isSendImmediately === false
          ? dayjs(sendMessagesData?.estimatedSendingTime)
          : undefined,
      purpose: sendMessagesData?.purpose,
    });
  }, [originFormData, sendMessagesData, form]);

  const handleRadioChange = (e) => {
    setIsReservation(e.target.value);
  };
  const handleSendMethodChange = (e) => {
    setSendMethod(e.target.value);
  };
  const handleSetAndTagChange = ({ tagSet, selectedTags }) => {
    setTagSet(tagSet);
    setSelectedTags(selectedTags);
  };
  const handleFormSubmit = async (data) => {
    const selectDateAndTime = convertAntdDateFormat(data.date, data.time);

    // [NOTE] Wrap the code in a try-catch block to handle exceptions that may be thrown by JSON.parse
    try {
      const reqData = {
        purpose: data.purpose,
        messages: data.sendMessages.map((message) => {
          if (message.type === 'flex')
            return {
              type: message.type,
              contents: JSON.parse(message.contents),
              altText: message.altText,
              channelType: 'LINE',
            };
          return {
            ...message,
            channelType: 'LINE',
          };
        }),
      };
      if (selectDateAndTime) {
        reqData.isSendImmediately = false;
        reqData.estimatedSendingTime = selectDateAndTime;
      } else {
        reqData.isSendImmediately = true;
      }
      if (sendMethod === 'multicast' && selectedTags.length > 0) {
        // [TODO] add tagSet and selected tags in reqData
        console.log('tags and set', selectedTags, tagSet);
      }

      // Create or Edit message depends on lineMessageId
      if (!lineMessageId) {
        onCreateMessage({ reqData, sendMethod });
      } else {
        onModifyMessage({ reqData, sendMethod, lineMessageId });
      }
    } catch (_) {
      // unused
    }
  };

  /* JSX ---------------------------------------------------------- */
  // If has lineMessageId wait until get sendMessagesData then render actual form
  if (lineMessageId && !sendMessagesData)
    return (
      <div
        className={`${style['container']} ${style['loading-spinner__container']}`}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <Form
        form={form}
        className={`${style['container']} ${
          asModal ? style['container--modal'] : ''
        }`}
        onFinish={handleFormSubmit}
      >
        <h4 className={style['heading']}>
          {lineMessageId ? '編輯訊息組' : '建立訊息組'}
          <Tooltip title="訊息組為 line 的計價單位，一則訊息組由最多 5 則單一訊息組成">
            <QuestionCircleOutlined />
          </Tooltip>
        </h4>

        {/* Date and time picker */}
        <Space className={style['date-time-picker']}>
          <Radio.Group onChange={handleRadioChange} value={isReservation}>
            <Radio value={false}>立即傳送</Radio>
            <Radio value={true}>預約傳送</Radio>
          </Radio.Group>

          {isReservation ? (
            <Space>
              <Form.Item
                name="date"
                rules={[{ required: true, message: '請選擇日期' }]}
              >
                <DatePicker
                  picker="date"
                  disabledDate={(d) => isFutureDays(30)(d['$d'])}
                ></DatePicker>
              </Form.Item>

              <Form.Item
                name="time"
                rules={[{ required: true, message: '請選擇時間' }]}
              >
                <DatePicker picker="time"></DatePicker>
              </Form.Item>
            </Space>
          ) : null}
        </Space>

        {/* Send method selector */}
        <SendMethodSelector
          lineMessageId={lineMessageId}
          sendMethod={sendMethod}
          onSendMethodChange={handleSendMethodChange}
          tagSet={tagSet}
          // setTagSet={setTagSet}
          selectedTags={selectedTags}
          // setSelectedTags={setSelectedTags}
          onSelect={handleSetAndTagChange}
        />

        {/* Purpose */}
        <label className={style['label']}>標題</label>
        <Form.Item
          name="purpose"
          rules={[
            {
              validator: (_, purpose) => {
                if (purpose.trim().length === 0) return Promise.reject();
                return Promise.resolve();
              },
              message: '標題不可為空',
            },
          ]}
        >
          <Input placeholder="標題 (內部參考)" />
        </Form.Item>

        {/* Message list */}
        <label className={style['messages__title']}>訊息</label>
        <MessageList sendMessagesData={sendMessagesData} />
        <Button
          className={style['button']}
          type="primary"
          htmlType="submit"
          loading={isCreating || isModifying}
        >
          確定
        </Button>
      </Form>
    </>
  );
}
