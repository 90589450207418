/* React */
import { createContext, useCallback, useState } from 'react';

const PaginationContext = createContext({
  curPage: 'console',
  onNavigate: (page) => {},
  curEditingId: null,
  onEdit: (id) => {},
  onUnEdit: () => {},
});

/* //////////////////////////////////////////////////////////////// */
function PaginationContextProvider({ children }) {
  const [curPage, setCurPage] = useState('console');
  const [curEditingId, setCurEditingId] = useState(null);

  const onNavigate = useCallback((page) => {
    setCurPage(page);
  }, []);
  const onEdit = useCallback((id) => {
    setCurPage('edit');
    setCurEditingId(id);
  }, []);
  const onUnEdit = useCallback(() => {
    setCurPage('console');
    setCurEditingId(null);
  }, []);

  /* JSX ---------------------------------------------------------- */
  return (
    <PaginationContext.Provider
      value={{ curPage, onNavigate, curEditingId, onEdit, onUnEdit }}
    >
      {children}
    </PaginationContext.Provider>
  );
}

export { PaginationContext, PaginationContextProvider };
