/**
 * Convert Antd date and time picker date data to javascript Date
 */
export const convertAntdDateFormat = (date, time) => {
  const selectDate = date ? new Date(date['$d']) : null;
  const dateString = selectDate
    ? `${selectDate.getFullYear()}-${
        selectDate.getMonth() + 1
      }-${selectDate.getDate()}`
    : null;
  const timeString = time ? `${time['$H']}:${time['$m']}:${time['$s']}` : null;

  const convertedDateAndTime =
    dateString && timeString ? new Date(`${dateString} ${timeString}`) : null;

  return convertedDateAndTime;
};
